import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import tireSizeFormat from '../../../../helpers/tireSizeFormat';
import {
  DetailsGeneralContainer,
  DetailsWrapper,
  DetailsLoaderPlaceholder,
  DetailsTopDateWrapper,
  DetailsSecondRowWrapper,
  DetailsSecondRowElementWrapper,
  DetailsSecondRowElementTextWrapper,
  DetailsSecondRowElementContentWrapper,
  DetailsThirdRowWrapper,
  DetailsThirdRowFirstRowWrapper,
  DetailsFifthRowWrapper,
  DetailsFifthRowFirstWrapper,
  DetailsFIfthRowSecondWrapper,
  DetailsFifthRowSecondImageWrapper,
  DetailsFifthRowSecondProductDetailsWrapper,
  DetailsFifthRowSecondProductNameWrapper,
  DetailsFifthRowSecondProductSecondDetailsWrapper,
  DetailsFifthRowSecondProductTyreSizeWrapper,
  DetailsFifthRowSecondProductSeasonOnlyWrapper,
  DetailsFifthRowSecondProductSizeOnlyWrapper,
  DetailsFifthRowSecondProductPriceWrapper,
  DetailsSixthRowWrapper,
  DetailsSixthRowContentWrapper,
  DetailsSixthRowSecondRowWrapper,
  DetailsSixthRowSecondTotaalTextWrapper,
  DetailsSixthRowSecondTotaalPriceWrapper,
  DetailsImageLoaderPlaceholder,
} from './Details.styles';
import MyAccountLayout from 'app/components/MijnAccountComponents/MijnAccoundDesktop/MyAccountLayout';
import OrderStatusBar from 'app/components/MijnAccountComponents/OrderStatusBar/OrderStatusBar';
import useWindowSize from 'app/hooks/useWindowSize';
import { breakpoints } from 'app/config/breakpoints';
import ExampleImage from 'assets/img/band_example.png';
import { dateConverter, besteldatumConverter } from 'app/helpers/utils';
import useAankoop from 'app/hooks/useAankoop';
import Loader from 'app/components/Loader/Loader';
import Toast from 'app/components/Toast/Toast';

const AankopDetails = () => {
  const [imageFile, setImageFile] = useState();

  const { width } = useWindowSize();
  const isLargeScreen = width && width >= breakpoints.lg;

  const purchaseParamId = useParams().id;

  const { data: aankoopDetailsData, isLoading, isError, error } = useAankoop(purchaseParamId);

  const properDateFormat = dateConverter(aankoopDetailsData?.payment.timestamp);

  const bestelDatumFormat = besteldatumConverter(aankoopDetailsData?.payment.timestamp);

  const bezorgadresValue = `${aankoopDetailsData?.location?.street} ${aankoopDetailsData?.location?.house_number} ${aankoopDetailsData?.location?.postalcode} ${aankoopDetailsData?.location?.city}`;

  const seasonOnly =
    aankoopDetailsData?.models[0]?.model.season === 'Summer'
      ? 'Zomer'
      : aankoopDetailsData?.models[0]?.model.season === 'Winter'
      ? 'Winter'
      : 'All season';

  const productName = `${aankoopDetailsData?.models[0].model.condition}e ${aankoopDetailsData?.tyre_set.brand} ${seasonOnly} banden`;

  const totalPrice = aankoopDetailsData?.order.price_value;

  const tyreSize = tireSizeFormat(
    aankoopDetailsData?.models[0].model.width,
    aankoopDetailsData?.models[0].model.height,
    aankoopDetailsData?.models[0].model.inches
  );

  // const tyreProfile = aankoopDetailsData?.models[0].model.profile_depth + 'mm';

  const imageUrl =
    aankoopDetailsData?.models?.[0]?.model.condition === 'Nieuw'
      ? aankoopDetailsData?.models?.[3]?.image_url
      : aankoopDetailsData?.models?.[0]?.image_url;

  useEffect(() => {
    if (aankoopDetailsData && !imageFile) {
      if (imageUrl) {
        setImageFile(imageUrl);
      } else {
        setImageFile(ExampleImage);
      }
    }
  }, [aankoopDetailsData]);

  const deliveryStatus = useMemo(
    () => (aankoopDetailsData ? JSON.parse(aankoopDetailsData?.order?.delivery_status)?.status : ''),
    [aankoopDetailsData]
  );

  const DetailsContent = (
    <DetailsGeneralContainer>
      <DetailsWrapper>
        {isLoading ||
          (!imageFile && (
            <DetailsLoaderPlaceholder>
              <Loader />
            </DetailsLoaderPlaceholder>
          ))}

        {aankoopDetailsData && !isError && imageFile && (
          <>
            <DetailsTopDateWrapper>{properDateFormat}</DetailsTopDateWrapper>
            <DetailsSecondRowWrapper>
              <DetailsSecondRowElementWrapper>
                <DetailsSecondRowElementTextWrapper>Ordernummer:</DetailsSecondRowElementTextWrapper>
                <DetailsSecondRowElementContentWrapper>
                  {aankoopDetailsData?.order.id}
                </DetailsSecondRowElementContentWrapper>
              </DetailsSecondRowElementWrapper>
              <DetailsSecondRowElementWrapper>
                <DetailsSecondRowElementTextWrapper>Besteldatum:</DetailsSecondRowElementTextWrapper>
                <DetailsSecondRowElementContentWrapper>{bestelDatumFormat}</DetailsSecondRowElementContentWrapper>
              </DetailsSecondRowElementWrapper>
              <DetailsSecondRowElementWrapper>
                <DetailsSecondRowElementTextWrapper>Bezorgadres:</DetailsSecondRowElementTextWrapper>
                <DetailsSecondRowElementContentWrapper>{bezorgadresValue}</DetailsSecondRowElementContentWrapper>
              </DetailsSecondRowElementWrapper>
            </DetailsSecondRowWrapper>
            <DetailsThirdRowWrapper>
              <DetailsThirdRowFirstRowWrapper>Status:</DetailsThirdRowFirstRowWrapper>
              <OrderStatusBar status={deliveryStatus} />
            </DetailsThirdRowWrapper>

            <DetailsFifthRowWrapper>
              <DetailsFifthRowFirstWrapper>Product:</DetailsFifthRowFirstWrapper>

              <DetailsFIfthRowSecondWrapper>
                {!imageFile ? (
                  <DetailsImageLoaderPlaceholder>
                    {/* <Loader size='22px' loaderWidth='2px' /> */}
                  </DetailsImageLoaderPlaceholder>
                ) : (
                  <DetailsFifthRowSecondImageWrapper>
                    <img src={imageFile} className="imageContainer" alt="Tyre" />
                  </DetailsFifthRowSecondImageWrapper>
                )}

                <DetailsFifthRowSecondProductDetailsWrapper>
                  <DetailsFifthRowSecondProductNameWrapper>{productName}</DetailsFifthRowSecondProductNameWrapper>

                  <DetailsFifthRowSecondProductSecondDetailsWrapper>
                    <DetailsFifthRowSecondProductTyreSizeWrapper>
                      {tyreSize}
                    </DetailsFifthRowSecondProductTyreSizeWrapper>
                    <DetailsFifthRowSecondProductSeasonOnlyWrapper>
                      {seasonOnly}
                    </DetailsFifthRowSecondProductSeasonOnlyWrapper>
                    <DetailsFifthRowSecondProductSizeOnlyWrapper>
                      {/* {tyreProfile} */}
                    </DetailsFifthRowSecondProductSizeOnlyWrapper>
                  </DetailsFifthRowSecondProductSecondDetailsWrapper>
                </DetailsFifthRowSecondProductDetailsWrapper>
                <DetailsFifthRowSecondProductPriceWrapper>
                  &euro; {`${totalPrice}`.replace('.', ',')}
                </DetailsFifthRowSecondProductPriceWrapper>
              </DetailsFIfthRowSecondWrapper>
            </DetailsFifthRowWrapper>
            <DetailsSixthRowWrapper>
              <DetailsSixthRowContentWrapper>
                <DetailsSixthRowSecondRowWrapper>
                  <DetailsSixthRowSecondTotaalTextWrapper>Totaal:</DetailsSixthRowSecondTotaalTextWrapper>
                  <DetailsSixthRowSecondTotaalPriceWrapper>
                    &euro; {`${totalPrice}`.replace('.', ',')}
                  </DetailsSixthRowSecondTotaalPriceWrapper>
                </DetailsSixthRowSecondRowWrapper>
              </DetailsSixthRowContentWrapper>
            </DetailsSixthRowWrapper>
          </>
        )}

        {isError && <Toast type="error" message={error?.message} addditionalMessage standalone standaloneAutoRemove />}
      </DetailsWrapper>
    </DetailsGeneralContainer>
  );
  return isLargeScreen ? (
    <MyAccountLayout isLargeScreen={isLargeScreen}>{DetailsContent}</MyAccountLayout>
  ) : (
    DetailsContent
  );
};

export default AankopDetails;
