import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { FormInputSize } from '../FormInputBorder';
import { FormInputFlatSide } from '../FormInputBorder/FormInputBorder';
import { BaseFormControlProps } from './FormControlProps';

export interface FormControlContextValue extends BaseFormControlProps {
  controlId?: string;
  filled?: boolean;
  setFilled?: Dispatch<SetStateAction<boolean>>;
  shrink?: boolean;
  focused?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  helpTextId?: string;
  flatSide?: FormInputFlatSide;
  setHelpTextId?: Dispatch<SetStateAction<string | undefined>>;
  errorTextId?: string;
  setErrorTextId?: Dispatch<SetStateAction<string | undefined>>;
  inputSize?: FormInputSize;
  setForceShrink?: Dispatch<SetStateAction<boolean>>;
}

const FormControlContext = createContext<FormControlContextValue>({});

export const useFormControl = () => useContext<FormControlContextValue>(FormControlContext);

export default FormControlContext;
