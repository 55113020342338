import { useQuery } from '@tanstack/react-query';
import { getOrders } from 'app/api/bestelling';
import { QueryKeys } from 'app/helpers/QueryKeys';

const useBestellingen = () => {
  const query = useQuery([QueryKeys.Bestellingen], getOrders, {
    enabled: true,
    refetchOnWindowFocus: 'always',
    retry: false,
    refetchInterval: 30000,
  });

  return {
    ...query,
    orders: query.data,
  };
};

export default useBestellingen;
