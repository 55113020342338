import React from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorPageLogo from '../../../../assets/img/error_header.png';
import {
  ErrorPageContainer,
  ErrorPageTopImageWrapper,
  ErrorPageTopImage,
  ErrorPageContentWrapper,
  ErrorPageContent,
  ErrorPageHeaderContentWrapper,
  ErrorPageInfoContentWrapper,
  ErrorPageBackToHomepageContentWrapper,
} from './ErrorPage.styles';

const ErrorPage = () => {
  const navigate = useNavigate();

  const handleHomePageClick = () => {
    navigate('/');
  };
  return (
    <ErrorPageContainer>
      <ErrorPageTopImageWrapper>
        <ErrorPageTopImage src={ErrorPageLogo} />
      </ErrorPageTopImageWrapper>
      <ErrorPageContentWrapper>
        <ErrorPageContent>
          <ErrorPageHeaderContentWrapper>Oeps. Je bent de verkeerde kant opgestuurd.</ErrorPageHeaderContentWrapper>
          <ErrorPageInfoContentWrapper>
            Op dit moment bent u geleid naar een pagina die niet bestaat.
          </ErrorPageInfoContentWrapper>
          <ErrorPageBackToHomepageContentWrapper onClick={handleHomePageClick}>
            Naar de homepage &gt;
          </ErrorPageBackToHomepageContentWrapper>
        </ErrorPageContent>
      </ErrorPageContentWrapper>
    </ErrorPageContainer>
  );
};

export default ErrorPage;
