import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import Kenteken from '../form/kenteken-input';
import FormSelect from '../bhComponents/FormSelect';
import FormLabel from '../bhComponents/FormLabel';
import FormControl from '../bhComponents/FormControl';
import { LicensePlateData } from '../bhComponents/FormInputLicensePlate/FormInputLicensePlate';
import Button from '../bhComponents/Button';

import IconUiArrowMediumBounded from '../../../assets/svg/IconUiArrowMediumBounded';
import { FormSelectOptionEventHandler, FormSelectOptionType } from '../bhComponents/FormSelectDropdown';
import { ToastListType } from '../../helpers/types';
import styles from './LicensePlateInput.module.scss';

interface AutoGegevensType {
  merk: string;
  type: string;
  bouwjaar: string;
}

interface LicensePlateInputProps {
  kentekenInputValue: string;
  setKentekenInputValue: Dispatch<SetStateAction<string>>;
  setAutogegevens: Dispatch<SetStateAction<AutoGegevensType>>;
  toastId: number;
  toastList: ToastListType[];
  setToastList: () => void;
  setToastId: Dispatch<SetStateAction<number>>;
  brandOptions: FormSelectOptionType[];
  localBrandValue: FormSelectOptionType;
  handleBrandChange: FormSelectOptionEventHandler<FormSelectOptionType | undefined>;
  modelOptions: FormSelectOptionType[];
  localModelValue: FormSelectOptionType;
  handleModelChange: FormSelectOptionEventHandler<FormSelectOptionType | undefined>;
  yearOptions: FormSelectOptionType[];
  localYearValue: FormSelectOptionType;
  handleYearChange: FormSelectOptionEventHandler<FormSelectOptionType | undefined>;
  onDisable?: (value: boolean) => void;
  carModelAndYear: LicensePlateData;
}

const LicensePlateInput = (props: LicensePlateInputProps) => {
  const {
    onDisable,
    kentekenInputValue,
    setKentekenInputValue,
    setAutogegevens,
    toastId,
    toastList,
    setToastId,
    setToastList,
    brandOptions,
    localBrandValue,
    handleBrandChange,
    modelOptions,
    localModelValue,
    handleModelChange,
    yearOptions,
    carModelAndYear,
    localYearValue,
    handleYearChange,
  } = props;

  const [showLicenseInput, setShowLicenseInput] = useState(true);

  const handleClickShowManualInput = useCallback(() => {
    setShowLicenseInput(true);
    setAutogegevens({ merk: '', type: '', bouwjaar: '' });
    setKentekenInputValue('');
    onDisable?.(true);
  }, []);
  const handleShowLicenseInput = useCallback(() => {
    setShowLicenseInput(false);
    onDisable?.(true);
  }, []);

  useEffect(() => {
    if (!showLicenseInput) {
      if (
        localBrandValue?.value &&
        localBrandValue.value !== '' &&
        localModelValue?.value &&
        localModelValue.value !== '' &&
        localYearValue?.value &&
        localYearValue.value !== ''
      ) {
        onDisable?.(false);
      } else {
        onDisable?.(true);
      }
    }
  }, [showLicenseInput, localBrandValue, localModelValue, localYearValue]);

  return (
    <>
      {showLicenseInput ? (
        <div className={styles.block}>
          <Kenteken
            onDisable={onDisable}
            data={carModelAndYear}
            kentekenInputValue={kentekenInputValue}
            setKentekenInputValue={setKentekenInputValue}
            setAutogegevens={setAutogegevens}
            toastId={toastId}
            setToastId={setToastId}
            toastList={toastList}
            setToastList={setToastList}
          />
          <Button
            className={styles['button-licanse']}
            endAdornment={<IconUiArrowMediumBounded />}
            onClick={handleShowLicenseInput}
            appearance="clear"
          >
            Handmatig invoeren
          </Button>
        </div>
      ) : (
        <div className={styles.grid}>
          <FormControl id="brand">
            <FormSelect
              label={<FormLabel>Merk</FormLabel>}
              options={brandOptions}
              value={localBrandValue}
              onChange={handleBrandChange}
            />
          </FormControl>
          <FormControl id="model" disabled={!modelOptions.length}>
            <FormSelect
              label={<FormLabel>Model</FormLabel>}
              options={modelOptions}
              value={localModelValue}
              onChange={handleModelChange}
            />
          </FormControl>
          <FormControl id="year" disabled={!yearOptions.length}>
            <FormSelect
              label={<FormLabel>Bouwjaar</FormLabel>}
              options={yearOptions}
              value={localYearValue}
              onChange={handleYearChange}
            />
          </FormControl>
          <Button
            className={styles.button}
            endAdornment={<IconUiArrowMediumBounded />}
            onClick={handleClickShowManualInput}
            appearance="clear"
          >
            Kenteken invoeren
          </Button>
        </div>
      )}
    </>
  );
};

export default LicensePlateInput;
