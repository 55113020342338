import { useLocation } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import classes from '../../pages/ProductDetails/index.module.scss';
import Typography from '../../bhComponents/Typography';
import AttentionBox from '../../bhComponents/AttentionBox';
import IconErrorCircle from '../../../../assets/svg/IconErrorCircle';
import Anchor from '../../bhComponents/Anchor';
import IconExternalLink from '../../../../assets/svg/IconExternalLink';
import useVerifyCarInfo, { VerifyCarInfoPropsType } from '../../../hooks/useVerifyCarInfo';
import IconVinkjeCircle from '../../../../assets/svg/IconVinkjeCircle';

interface ListItem {
  key: string;
  value: string;
}

interface ProductDetailCarInfoProps {
  listData: ListItem[];
}

const extractValuesFromUrl = (search: string) => {
  const parameters = search.replace('?', '').split('&');
  const extractedValues: { [key: string]: string } = {};

  parameters.forEach((param) => {
    const [key, value] = param.split('=');
    if (key && value) {
      extractedValues[key] = value;
    }
  });

  return extractedValues;
};

const ProductDetailCarInfo = (props: ProductDetailCarInfoProps) => {
  const { listData } = props;

  const { verifyCarInfoMutation, verifyCarInfoData } = useVerifyCarInfo();
  const location = useLocation();

  const extractedValues = useMemo(() => extractValuesFromUrl(location.search), [location.search]);
  const shouldCarInfoLabelBeVisible = extractedValues.merk && extractedValues.type && extractedValues.bouwjaar;

  useEffect(() => {
    if (shouldCarInfoLabelBeVisible) {
      const datatest: VerifyCarInfoPropsType = {
        brand: extractedValues.merk,
        model: extractedValues.type,
        build_year: parseInt(extractedValues.bouwjaar),
      };

      verifyCarInfoMutation.mutate(datatest);
    }
  }, []);

  const hasWarning = useMemo(() => {
    const type = listData?.find((item) => item.key === 'Inchmaat');
    return !verifyCarInfoData?.bandenvelgen?.replace(/\s/g, '')?.split(',').includes(type?.value?.replace(/\s/g, ''));
  }, [verifyCarInfoData, listData]);

  return (
    <div className={classes['car-info']}>
      <Typography as="h3" fontFamily="bold" className={classes.header} variant="heading-20">
        Jouw voertuig
      </Typography>
      <div className={classes['flex-box']}>
        <Typography as="p" fontFamily="bold" variant="text-20">
          {extractedValues.merk} {extractedValues.type}
        </Typography>
        <Typography as="p" fontFamily="regular" variant="text-20">
          {extractedValues.bouwjaar}
        </Typography>
      </div>
      {shouldCarInfoLabelBeVisible ? (
        <AttentionBox className={classes.attention} header="Velg" icon={<IconVinkjeCircle />} variant="success">
          De velg past onder jouw auto.
        </AttentionBox>
      ) : (
        <AttentionBox className={classes.attention} header="Velg" icon={<IconErrorCircle />} variant="warning">
          De velg past mogelijk niet onder jouw auto.
        </AttentionBox>
      )}
      {hasWarning ? (
        <AttentionBox className={classes.attention} header="Band" icon={<IconErrorCircle />} variant="warning">
          De band past mogelijk niet onder jouw auto. Gebruik de omrekentabel om een passende bandenmaat te vinden.
          <Anchor
            fontWeight="bold"
            endAdornment={<IconExternalLink />}
            className={classes.anchor}
            appearance="link"
            href="https://www.desprint.nl/omrekentabel"
            target="_blank"
          >
            Bandenomrekentabel
          </Anchor>
        </AttentionBox>
      ) : (
        <AttentionBox className={classes.attention} header="Band" icon={<IconVinkjeCircle />} variant="success">
          De band past onder jouw auto.
        </AttentionBox>
      )}
    </div>
  );
};

export default ProductDetailCarInfo;
