import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const FAQMainContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - 70px);
  padding-top: 53px;
  padding-bottom: 10px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 10px;
    margin-top: 9px;
    padding: 11px;
  }
`;

export const FAQWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  width: 55%;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    width: 100%;
    max-width: 625px;
  }
`;

export const AccordionWrapper = styled.div`
  width: 100%;
  padding: 35px 70px 25px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    padding: 10px 20px 0px 20px;
  }
`;
