import { configureStore } from '@reduxjs/toolkit';
import type { ThunkAction, Action } from '@reduxjs/toolkit';
import ReduxThunk from 'redux-thunk';
import velgenReducer from 'redux/velgen-slice';
import formReducer from 'redux/form-slice';
import uiReducer from 'redux/ui-slice';
import apiReducer from 'redux/api-slice';
import orderReducer from 'redux/order-slice';
import { toevoegenReducer } from 'redux/toevoegen-slice';
import { tyreSetReducer } from 'redux/tyreSet-slice';
import { bestellingenReducer } from 'redux/bestellingen-slice';
import { reduxMiddleWare } from 'redux/middleware/localstorage';

const parsed = JSON.parse(localStorage.getItem('app-kdp') as string);
const localStorageState = parsed || {};

export const store = configureStore({
  reducer: {
    velgen: velgenReducer,
    form: formReducer,
    ui: uiReducer,
    api: apiReducer,
    orders: orderReducer,
    toevoegen: toevoegenReducer.reducer,
    tyreSet: tyreSetReducer.reducer,
    bestellingen: bestellingenReducer.reducer,
  },
  middleware: [reduxMiddleWare, ReduxThunk],
  preloadedState: localStorageState,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
