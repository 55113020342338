import styled from 'styled-components';

export const LoaderContainer = styled.div`
  display: flex;
`;

export const LoaderWrapper = styled.div`
  width: ${(props) => props.size};
  height: ${(props) => props.size};

  border: ${(props) => props.loaderWidth} solid #e6e6e6;
  border-top-color: #136ce2;
  border-radius: 50%;

  animation: loading 1.75s linear infinite;

  @keyframes loading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
