import { forwardRef, useMemo } from 'react';
import { StandardProps } from '../../../helpers/types';
import DropdownList from '../DropdownList';
import spacing from '../../../../scss/bhStyles/variables/spacing.module.scss';
import useClassNames from 'app/helpers/useClassNames';
import './Dropdown.scss';

export interface DropdownProps extends StandardProps {
  maxItemHeight?: number;
  dividerAmount?: number;
}

const Dropdown = forwardRef<HTMLDivElement, DropdownProps>((props: DropdownProps, ref) => {
  const { className, children, dividerAmount = 0, maxItemHeight = 4, style: styleProp } = props;
  const { withClassPrefix, merge } = useClassNames('dropdown');

  const classes = merge(className, withClassPrefix());
  const style = useMemo(
    () => ({
      ...styleProp,
      maxHeight: `calc(${maxItemHeight} * ${spacing.spacing10} + ${dividerAmount} * 1.0625rem + ${spacing.spacing4})`,
    }),
    [dividerAmount, maxItemHeight, styleProp]
  );

  return (
    <div ref={ref} className={classes} style={style}>
      <DropdownList>{children}</DropdownList>
    </div>
  );
});

Dropdown.displayName = 'Dropdown';

export default Dropdown;
