import { useQuery } from '@tanstack/react-query';
import { getInchmaat } from 'app/api/tyreProperties/inchmaat';
import { QueryKeys } from 'app/helpers/QueryKeys';

const useInchmaat = () => {
  const query = useQuery([QueryKeys.Inchmaat], getInchmaat, {
    enabled: true,
    refetchOnWindowFocus: 'always',
    retry: false,
  });

  return {
    ...query,
    inchmaat: query.data,
  };
};

export default useInchmaat;
