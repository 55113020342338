import { createSlice } from '@reduxjs/toolkit';

export const toevoegenReducer = createSlice({
  name: 'toevoegenStep',
  initialState: { step: 0 },
  reducers: {
    setToevoegenStepValue: (state, action) => {
      state.step = action.payload;
    },
    decrementToevoegenStep: (state) => {
      state.step--;
    },
    resetToevoegenStep: (state) => {
      state.step = 0;
    },
  },
});

export const toevoegenActions = toevoegenReducer.actions;
