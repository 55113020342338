import { forwardRef, useEffect } from 'react';
import { StandardProps } from '../../../helpers/types';
import { useFormControl } from '../FormControl';
import IconErrorCircle from '../../../../assets/svg/IconErrorCircle';
import useClassNames from 'app/helpers/useClassNames';
import './FormErrorText.scss';

export interface FormErrorTextProps extends StandardProps {
  /**
   * To add a custom id
   */
  id?: string;
}

/**
 * A example of a FormErrorText component
 */
const FormErrorText = forwardRef<HTMLSpanElement, FormErrorTextProps>((props: FormErrorTextProps, ref) => {
  const { className, children, id: idProp, style } = props;

  const { controlId, setErrorTextId } = useFormControl();
  const { merge, withClassPrefix } = useClassNames('form-error-text');
  const classes = merge(className, withClassPrefix());

  const id = controlId ? `${controlId}-error-text` : idProp;

  // tell the input element that there is an error text for `aria-errormessage`
  useEffect(() => {
    if (setErrorTextId && id) {
      setErrorTextId(id);
    }
  }, [id, setErrorTextId]);

  return (
    <span id={id} ref={ref} className={classes} style={style}>
      <IconErrorCircle />
      {children}
    </span>
  );
});

FormErrorText.displayName = 'FormErrorText';

export default FormErrorText;
