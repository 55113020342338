import { useState, useEffect, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import InfoIcon from '@mui/icons-material/Info';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from 'react-router-dom';
import styles from '../../../scss/_variables.scss';
import { bandenmerken, velgenmerker } from '../../../dropdownData';
import ToastContainer from '../Toast/ToastContainer';
import { Toevoegen2Dotcode, Toevoegen2SpecsWrapper } from '../ToevoegenComponents/Toevoegen2/Toevoegen2.styles';
import {
  BewerkenContainer,
  BewerkenLoaderPlaceholder,
  BewerkenWrapper,
  Toevoegen3DropdownItem,
  Toevoegen3DropdownMobileWrapper,
  Toevoegen3DropdownSelect,
  Toevoegen3DropdownWrapper,
  Toevoegen3InfoContainer,
  Toevoegen3InfoContainer3,
  Toevoegen3InfoContainer4,
  Toevoegen3InfoContainer5,
  Toevoegen3MobileDropdownItem,
  Toevoegen3SelectItem,
  Toevoegen3SelectWrapper,
  Toevoegen3SelectWrapper2,
  Toevoegen3Subtitle,
  Toevoegen3SubtitleInfoWrapper,
  Toevoegen3Title,
  Toevoegen3TyreImage,
  Toevoegen3TyreImageWrapper,
  Toevoegen2AddPhotoInput,
  Toevoegen2AddPhotoLabel,
  Toevoegen2AddPhotoWrapper,
  Toevoegen2AddUsedPhotoWrapper,
  Toevoegen2AddUsedSubtitle,
  Toevoegen2Button,
  Toevoegen2InputP,
  Toevoegen2InputWrapper,
  Toevoegen2ISelectItem,
  Toevoegen2SelectItem,
  Toevoegen2SelectUsedWrapper,
  Toevoegen2SelectWrapper,
  Toevoegen2Subtitle,
  Toevoegen2TitleMinWrapper,
  Toevoegen2UploadedImg,
  Toevoegen2UploadedImgFix,
  Toevoegen2UploadedPhotoWrapper,
  Toevoegen2UsedDropdownWrapper,
  Toevoegen2UsedP,
  Toevoegen2UsedPhotoWrapper,
  Toevoegen2UsedWrapper,
  BewerkenButtonWrapper,
  BewerkenInputWrapper,
  BewerkenInputItem,
  Toevoegen4Subtitle2,
  Toevoegen4InfoP2,
  Toevoegen4OpslagplaatsInput2,
} from './Bewerken.styles';
// Colors
// MUI Icons
// Break Points
import useWindowSize from 'app/hooks/useWindowSize';
import { breakpoints } from 'app/config/breakpoints';
// Dropdown Data
import useProduct from 'app/hooks/useProduct';
import useBreedte from 'app/hooks/TyreProperties/useBreedte';
import useHoogte from 'app/hooks/TyreProperties/useHoogte';
import useInchmaat from 'app/hooks/TyreProperties/useInchmaat';
import useCreateImage from 'app/hooks/useCreateImage';
import useUpdateProduct from 'app/hooks/useUpdateProduct';
import Loader from 'app/components/Loader/Loader';

const ProfileDepths = [
  { label: '1 tot 2', value: 'ONETWO' },
  { label: '2 tot 3', value: 'TWOTHREE' },
  { label: '3 tot 4', value: 'THREEFOUR' },
  { label: '4 tot 5', value: 'FOURFIVE' },
  { label: '5 tot 6', value: 'FIVESIX' },
  { label: '7 tot 8', value: 'SIXSEVEN' },
  { label: '8 +', value: 'EIGHTPLUS' },
];

const Bewerken = () => {
  const { width } = useWindowSize();
  const [isProductDetailsEnabled, setProductDetailsEnabled] = useState(false);
  const [buttonActive, setButtonActive] = useState(true);
  const [areFieldsFilled, setFieldsFilled] = useState(false);

  const { id } = useParams();

  const queryClient = useQueryClient();

  const {
    data: breedteDropdownValues,
    isLoading: isBreedteDropdownLoading,
    isSuccess: isBreedteDropdownSuccess,
  } = useBreedte();
  const {
    data: hoogteDropdownValues,
    isLoading: isHoogteDropdownLoading,
    isSuccess: isHoogteDropdownSuccess,
  } = useHoogte();
  const {
    data: inchmaatDropdownValues,
    isLoading: isInchmaatDropdownLoading,
    isSuccess: isInchmaatSuccess,
  } = useInchmaat();
  const {
    data: productDetailsData,
    isLoading: isProductDetailsDataLoading,
    isError: isProductDetailsDataError,
    error: productDetailsDataError,
  } = useProduct(isProductDetailsEnabled, id, false);

  useEffect(() => {
    if (isProductDetailsDataError) {
      const toast = {
        id: toastId,
        type: 'error',
        message: productDetailsDataError?.message,
        additionalMessage: productDetailsDataError?.data?.detail,
      };
      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isProductDetailsDataError]);

  const {
    isUpdateProductError,
    updateProductError,
    onClearErrorMessage,
    isUpdateProductSuccess,
    onClickUpdateProduct,
  } = useUpdateProduct();

  useEffect(() => {
    if (isUpdateProductError) {
      const toast = {
        id: toastId,
        type: 'error',
        message: updateProductError?.message,
        additionalMessage: updateProductError?.response?.statusText,
        onClose: onClearErrorMessage,
      };
      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isUpdateProductError]);

  useEffect(() => {
    if (isUpdateProductSuccess) {
      const toast = {
        id: toastId,
        type: 'success',
        message: 'De informatie van de bandenset is succesvol geüpdatet',
      };
      setToastId(toastId + 1);
      setToastList([...toastList, toast]);
      queryClient.refetchQueries(['priceRange']);
    }
  }, [isUpdateProductSuccess]);

  if (isBreedteDropdownSuccess && isHoogteDropdownSuccess && isInchmaatSuccess) {
    if (!isProductDetailsEnabled) {
      setProductDetailsEnabled(true);
    }
  }

  const [seizoen, setSeizoen] = useState('');
  const [conditie, setConditie] = useState('');

  const [file, setFile] = useState(null);

  const [tyreStatus, setTyreStatus] = useState('');

  const [isSelected2, setIsSelected2] = useState(-1);

  const [infoActive, setInfoActive] = useState({
    info1: false,
    info2: false,
    info3: false,
    info4: false,
    info5: false,
  });
  const [isSelected, setIsSelected] = useState({
    seizoen: -1,
    ms: -1,
    pmsf: -1,
    tpms: -1,
    runflat: -1,
  });
  const [changed, setChanged] = useState({
    breedte: false,
    hoogte: false,
    inchmaat: false,
    bandenmerk: false,
    velgenmerk: false,
  });
  const [touchedNewAndSlightlyUsedProfileFields, setTouchedNewAndSlightlyUsedProfileFields] = useState({
    dropdown1: false,
    dropdown2: false,
    dropdown3: false,
    dropdown4: false,
  });

  const [touchedGebruiktProfileFields, setTouchedGebruiktProfileFields] = useState({
    dropdown1: false,
    dropdown2: false,
    dropdown3: false,
    dropdown4: false,
  });

  const [usedFiles, setUsedFiles] = useState({
    file1: null,
    file2: null,
    file3: null,
    file4: null,
  });

  const [nieuwImage, setNieuwImage] = useState(null);
  const [nieuwPhotoId, setNieuwPhotoId] = useState();

  const [file1Image, setFile1Image] = useState();
  const [file1ImageId, setFile1ImageId] = useState();

  const [file2Image, setFile2Image] = useState();
  const [file2ImageId, setFile2ImageId] = useState();

  const [file3Image, setFile3Image] = useState();
  const [file3ImageId, setFile3ImageId] = useState();

  const [file4Image, setFile4Image] = useState();
  const [file4ImageId, setFile4ImageId] = useState();

  const [usedDropdown, setUsedDropdown] = useState({
    weil1: -1,
    weil2: -1,
    weil3: -1,
    weil4: -1,
  });

  const [breedte, setBreedte] = useState('');
  const [hoogte, setHoogte] = useState('');
  const [inchMaat, setInchmaat] = useState('');
  const [tyreBandenmerk, setTyreBandenMerk] = useState('');
  const [velgenMerkOptional, setVelgenMerkOptional] = useState('');

  const [verkoopprijs, setVerkoopprijs] = useState('');
  const [opslagplaatsOptional, setOpslagplaatsOptional] = useState('');

  const [owner, setOwner] = useState('');
  const [modelId, setModelId] = useState({
    first: 0,
    second: 0,
    third: 0,
    fourth: 0,
  });

  const [newAndSlightlyUsedDropdownValues, setNewAndSlightlyUsedDropdownValues] = useState({
    dropdown1Value: '',
    dropdown2Value: '',
    dropdown3Value: '',
    dropdown4Value: '',
  });

  const [gebruiktDropDownValues, setGebruiktDropdownValues] = useState({
    dropdown1Value: '',
    dropdown2Value: '',
    dropdown3Value: '',
    dropdown4Value: '',
  });

  const [dotCode, setDotCode] = useState({
    dot1: '',
    dot2: '',
    dot3: '',
    dot4: '',
  });

  const [toastId, setToastId] = useState(0);
  const [toastList, setToastList] = useState([]);

  useEffect(() => {
    if (isSelected2 === 0) {
      setConditie('Nieuw');
    } else if (isSelected2 === 1) {
      setConditie('Zo goed als nieuw');
    } else if (isSelected2 === 2) {
      setConditie('Gebruikt');
    }
  }, [isSelected2]);

  const handlePhotoChange = (event) => {
    setFile(URL.createObjectURL(event.target.files[0]));
    setNieuwImage(event.target.files[0]);
    setButtonActive(true);
  };

  const tyreImage =
    width >= breakpoints.md
      ? 'https://dam.broekhuis.online/online/tyreswap/tire-size-graphic-large.png'
      : 'https://dam.broekhuis.online/online/tyreswap/tire-size-graphic-small.png';

  useEffect(() => {
    if (isSelected.seizoen === 0) {
      setSeizoen('Summer');
    } else if (isSelected.seizoen === 1) {
      setSeizoen('Winter');
    } else if (isSelected.seizoen === 2) {
      setSeizoen('All Season');
    }
  }, [isSelected.seizoen]);

  const handleBandenmerkChange = (event) => {
    setTyreBandenMerk(event.target.value);
    setButtonActive(true);
    setChanged((prev) => ({ ...prev, bandenmerk: true }));
  };

  const handleClick = () => {
    onClickUpdateProduct(payLoadData());
  };

  const payLoadData = () => {
    const imageValuesOverride =
      conditie !== 'Gebruikt' && conditie !== 'Zo goed als nieuw'
        ? {
            first: nieuwPhotoId,
            second: nieuwPhotoId,
            third: nieuwPhotoId,
            fourth: nieuwPhotoId,
          }
        : {
            first: file1ImageId,
            second: file2ImageId,
            third: file3ImageId,
            fourth: file4ImageId,
          };

    const profielDepthValuesOverride =
      conditie !== 'Gebruikt' && conditie !== 'Zo goed als nieuw'
        ? newAndSlightlyUsedDropdownValues
        : gebruiktDropDownValues;

    const profileDepthValuesFormat = {
      dropdown1Value: profielDepthValuesOverride?.dropdown1Value,
      dropdown2Value: profielDepthValuesOverride?.dropdown2Value,
      dropdown3Value: profielDepthValuesOverride?.dropdown3Value,
      dropdown4Value: profielDepthValuesOverride?.dropdown4Value,
    };

    const dotCodeFormat = {
      dot1: dotCode?.dot1,
      dot2: dotCode?.dot2,
      dot3: dotCode?.dot3,
      dot4: dotCode?.dot4,
    };

    const ms = isSelected.ms === 0;
    const pmsf3 = isSelected.pmsf === 0;
    const tpms = isSelected.tpms === 0 ? 'yes' : isSelected.tpms === 1 ? 'no' : 'unknown';
    const runFlat = isSelected.runflat === 0 ? 'yes' : isSelected.runflat === 1 ? 'no' : 'unknown';

    const price = tyreStatus !== 'FOR_SALE' ? 0 : Number(verkoopprijs);

    const schadeForAllTires =
      conditie === 'Gebruikt' || conditie === 'Zo goed als nieuw'
        ? {
            schade1: usedDropdown.weil1 === 0,
            schade2: usedDropdown.weil2 === 0,
            schade3: usedDropdown.weil3 === 0,
            schade4: usedDropdown.weil4 === 0,
          }
        : {
            schade1: false,
            schade2: false,
            schade3: false,
            schade4: false,
          };

    const data = {
      storage: opslagplaatsOptional,
      tyreBandenmerk,
      velgenMerkOptional,
      price,
      runflat: runFlat,
      TPMS: tpms,
      season: seizoen,
      profile_depth: profileDepthValuesFormat,
      dot_code: dotCodeFormat,
      condition: conditie,
      width: Number(breedte),
      height: Number(hoogte),
      inches: Number(inchMaat),

      m_plus_s: ms,
      drie_PMSF: pmsf3,
      image_ids: imageValuesOverride,
      damage: schadeForAllTires,

      owner,
      id: modelId,
      paramId: Number(id),
    };

    return data;
  };

  useEffect(() => {
    if (productDetailsData && breedte === '') {
      setTyreStatus(productDetailsData?.tyre_set.sale_status);

      setBreedte(productDetailsData?.models[0]?.model.width);
      setHoogte(productDetailsData?.models[0]?.model.height);
      setInchmaat(productDetailsData?.models[0]?.model.inches);
      setTyreBandenMerk(productDetailsData?.tyre_set.brand);
      setVelgenMerkOptional(productDetailsData?.models[0]?.model.brand);

      setChanged({
        breedte: true,
        hoogte: true,
        inchmaat: true,
        bandenmerk: true,
        velgenmerk: !!(
          productDetailsData?.models[0]?.model.brand !== null || productDetailsData?.models[0]?.model.brand !== ''
        ),
      });

      if (productDetailsData?.tyre_set.sale_status === 'INVENTORY') {
        setVerkoopprijs(0);
      } else {
        setVerkoopprijs(productDetailsData?.tyre_set.price_value);
      }

      setOpslagplaatsOptional(productDetailsData?.tyre_set.storage);

      setOwner(productDetailsData?.tyre_set.owner);

      setModelId({
        first: productDetailsData?.models[0].model.id,
        second: productDetailsData?.models[1].model.id,
        third: productDetailsData?.models[2].model.id,
        fourth: productDetailsData?.models[3].model.id,
      });

      setIsSelected({
        seizoen:
          productDetailsData?.models[0]?.model.season === 'Summer'
            ? 0
            : productDetailsData?.models[0]?.model.season === 'Winter'
            ? 1
            : 2,
        ms: productDetailsData?.models[0]?.model.m_plus_s === true ? 0 : 1,
        pmsf: productDetailsData?.models[0]?.model.drie_PMSF === true ? 0 : 1,
        tpms:
          productDetailsData?.models[0]?.model.is_TPMS === 'yes'
            ? 0
            : productDetailsData?.models[0]?.model.is_TPMS === 'no'
            ? 1
            : 2,
        runflat:
          productDetailsData?.models[0]?.model.is_runflat === 'yes'
            ? 0
            : productDetailsData?.models[0]?.model.is_runflat === 'no'
            ? 1
            : 2,
      });

      setIsSelected2(
        productDetailsData?.models[0]?.model.condition === 'Nieuw'
          ? 0
          : productDetailsData?.models[0]?.model.condition === 'Zo goed als nieuw'
          ? 1
          : 2
      );

      setDotCode({
        dot1: productDetailsData?.models[0]?.model.dot_code,
        dot2: productDetailsData?.models[1]?.model.dot_code,
        dot3: productDetailsData?.models[2]?.model.dot_code,
        dot4: productDetailsData?.models[3]?.model.dot_code,
      });

      if (productDetailsData?.models[0]?.model.condition === 'Nieuw') {
        setNewAndSlightlyUsedDropdownValues({
          dropdown1Value: productDetailsData?.models[0]?.model.profile_depth,
          dropdown2Value: productDetailsData?.models[1]?.model.profile_depth,
          dropdown3Value: productDetailsData?.models[2]?.model.profile_depth,
          dropdown4Value: productDetailsData?.models[3]?.model.profile_depth,
        });

        setTouchedNewAndSlightlyUsedProfileFields({
          dropdown1: true,
          dropdown2: true,
          dropdown3: true,
          dropdown4: true,
        });
        setNieuwPhotoId(productDetailsData?.models[3]?.image_id);
        setFile(productDetailsData?.models[3]?.image_url);
      } else if (
        productDetailsData?.models[0]?.model.condition === 'Gebruikt' ||
        productDetailsData?.models[0]?.model.condition === 'Zo goed als nieuw'
      ) {
        setGebruiktDropdownValues({
          dropdown1Value: productDetailsData?.models[0]?.model.profile_depth,
          dropdown2Value: productDetailsData?.models[1]?.model.profile_depth,
          dropdown3Value: productDetailsData?.models[2]?.model.profile_depth,
          dropdown4Value: productDetailsData?.models[3]?.model.profile_depth,
        });

        setTouchedGebruiktProfileFields({
          dropdown1: true,
          dropdown2: true,
          dropdown3: true,
          dropdown4: true,
        });

        setUsedDropdown({
          weil1: productDetailsData?.models[0]?.damage.is_damaged === true ? 0 : 1,
          weil2: productDetailsData?.models[1]?.damage.is_damaged === true ? 0 : 1,
          weil3: productDetailsData?.models[2]?.damage.is_damaged === true ? 0 : 1,
          weil4: productDetailsData?.models[3]?.damage.is_damaged === true ? 0 : 1,
        });

        setFile1ImageId(productDetailsData?.models[0]?.image_id);
        setFile2ImageId(productDetailsData?.models[1]?.image_id);
        setFile3ImageId(productDetailsData?.models[2]?.image_id);
        setFile4ImageId(productDetailsData?.models[3]?.image_id);

        setUsedFiles({
          file1: productDetailsData?.models[0]?.image_url,
          file2: productDetailsData?.models[1]?.image_url,
          file3: productDetailsData?.models[2]?.image_url,
          file4: productDetailsData?.models[3]?.image_url,
        });
      }
    }
  }, [productDetailsData]);

  useEffect(() => {
    if (
      newAndSlightlyUsedDropdownValues.dropdown1Value !== '' ||
      newAndSlightlyUsedDropdownValues.dropdown2Value !== '' ||
      newAndSlightlyUsedDropdownValues.dropdown3Value !== '' ||
      newAndSlightlyUsedDropdownValues.dropdown4Value !== ''
    ) {
      setGebruiktDropdownValues(newAndSlightlyUsedDropdownValues);
      setTouchedGebruiktProfileFields({
        dropdown1: true,
        dropdown2: true,
        dropdown3: true,
        dropdown4: true,
      });
    }
  }, [newAndSlightlyUsedDropdownValues]);

  useEffect(() => {
    if (
      gebruiktDropDownValues.dropdown1Value !== '' ||
      gebruiktDropDownValues.dropdown2Value !== '' ||
      gebruiktDropDownValues.dropdown3Value !== '' ||
      gebruiktDropDownValues.dropdown4Value !== ''
    ) {
      setNewAndSlightlyUsedDropdownValues(gebruiktDropDownValues);
      setTouchedNewAndSlightlyUsedProfileFields({
        dropdown1: true,
        dropdown2: true,
        dropdown3: true,
        dropdown4: true,
      });
    }
  }, [gebruiktDropDownValues]);

  // creating images section

  const {
    isCreateImageLoading,
    isCreateImageError,
    createImageError,
    createImageData,
    isCreateImageSuccess,
    onClickCreateImage,
    OnClearCreateImageErrorMessage,
  } = useCreateImage();

  const createImage = () => {
    const formData = new FormData();
    formData.append('afbeelding', nieuwImage);

    onClickCreateImage(formData);
  };

  useEffect(() => {
    if (nieuwImage) {
      createImage();
    }
  }, [nieuwImage]);

  useEffect(() => {
    if (isCreateImageError) {
      const toast = {
        id: toastId,
        type: 'error',
        message: createImageError?.message,
        additionalMessage: createImageError?.response?.statusText,
        onClose: OnClearCreateImageErrorMessage,
      };
      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isCreateImageError]);

  useEffect(() => {
    if (isCreateImageSuccess) {
      setNieuwPhotoId(createImageData?.image_id);
    }
  }, [isCreateImageSuccess]);

  const {
    isCreateImageLoading: isCreateImage1Loading,
    isCreateImageError: isCreateImage1Error,
    createImageError: createImage1Error,
    createImageData: createImage1Data,
    isCreateImageSuccess: isCreateImage1Success,
    onClickCreateImage: onClickCreateImage1,
    OnClearCreateImageErrorMessage: OnClearCreateImage1ErrorMessage,
  } = useCreateImage();

  const createImage1 = () => {
    const formData = new FormData();
    formData.append('afbeelding', file1Image);

    onClickCreateImage1(formData);
  };

  useEffect(() => {
    if (file1Image) {
      createImage1();
    }
  }, [file1Image]);

  useEffect(() => {
    if (isCreateImage1Error) {
      const toast = {
        id: toastId,
        type: 'error',
        message: createImage1Error?.message,
        additionalMessage: createImage1Error?.response?.statusText,
        onClose: OnClearCreateImage1ErrorMessage,
      };
      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isCreateImage1Error]);

  useEffect(() => {
    if (isCreateImage1Success) {
      setFile1ImageId(createImage1Data?.image_id);
    }
  }, [isCreateImage1Success]);

  const {
    isCreateImageLoading: isCreateImage2Loading,
    isCreateImageError: isCreateImage2Error,
    createImageError: createImage2Error,
    createImageData: createImage2Data,
    isCreateImageSuccess: isCreateImage2Success,
    onClickCreateImage: onClickCreateImage2,
    OnClearCreateImageErrorMessage: OnClearCreateImage2ErrorMessage,
  } = useCreateImage();

  const createImage2 = () => {
    const formData = new FormData();
    formData.append('afbeelding', file2Image);

    onClickCreateImage2(formData);
  };

  useEffect(() => {
    if (file2Image) {
      createImage2();
    }
  }, [file2Image]);

  useEffect(() => {
    if (isCreateImage2Error) {
      const toast = {
        id: toastId,
        type: 'error',
        message: createImage2Error?.message,
        additionalMessage: createImage2Error?.response?.statusText,
        onClose: OnClearCreateImage2ErrorMessage,
      };
      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isCreateImage2Error]);

  useEffect(() => {
    if (isCreateImage2Success) {
      setFile2ImageId(createImage2Data?.image_id);
    }
  }, [isCreateImage2Success]);

  const {
    isCreateImageLoading: isCreateImage3Loading,
    isCreateImageError: isCreateImage3Error,
    createImageError: createImage3Error,
    createImageData: createImage3Data,
    isCreateImageSuccess: isCreateImage3Success,
    onClickCreateImage: onClickCreateImage3,
    OnClearCreateImageErrorMessage: OnClearCreateImage3ErrorMessage,
  } = useCreateImage();

  const createImage3 = () => {
    const formData = new FormData();
    formData.append('afbeelding', file3Image);

    onClickCreateImage3(formData);
  };

  useEffect(() => {
    if (file3Image) {
      createImage3();
    }
  }, [file3Image]);

  useEffect(() => {
    if (isCreateImage3Error) {
      const toast = {
        id: toastId,
        type: 'error',
        message: createImage3Error?.message,
        additionalMessage: createImage3Error?.response?.statusText,
        onClose: OnClearCreateImage3ErrorMessage,
      };
      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isCreateImage3Error]);

  useEffect(() => {
    if (isCreateImage3Success) {
      setFile3ImageId(createImage3Data?.image_id);
    }
  }, [isCreateImage3Success]);

  const {
    isCreateImageLoading: isCreateImage4Loading,
    isCreateImageError: isCreateImage4Error,
    createImageError: createImage4Error,
    createImageData: createImage4Data,
    isCreateImageSuccess: isCreateImage4Success,
    onClickCreateImage: onClickCreateImage4,
    OnClearCreateImageErrorMessage: OnClearCreateImage4ErrorMessage,
  } = useCreateImage();

  const createImage4 = () => {
    const formData = new FormData();
    formData.append('afbeelding', file4Image);

    onClickCreateImage4(formData);
  };

  useEffect(() => {
    if (file4Image) {
      createImage4();
    }
  }, [file4Image]);

  useEffect(() => {
    if (isCreateImage4Error) {
      const toast = {
        id: toastId,
        type: 'error',
        message: createImage4Error?.message,
        additionalMessage: createImage4Error?.response?.statusText,
        onClose: OnClearCreateImage4ErrorMessage,
      };
      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isCreateImage4Error]);

  useEffect(() => {
    if (isCreateImage4Success) {
      setFile4ImageId(createImage4Data?.image_id);
    }
  }, [isCreateImage4Success]);

  // validation
  useEffect(() => {
    const generalFieldsForEveryConditionNotEmpty =
      breedte !== '' &&
      hoogte !== '' &&
      inchMaat !== '' &&
      tyreBandenmerk !== 0 &&
      isSelected.seizoen !== -1 &&
      isSelected.ms !== -1 &&
      isSelected.pmsf !== -1 &&
      isSelected.tpms !== -1 &&
      isSelected.runflat !== -1 &&
      conditie !== '' &&
      newAndSlightlyUsedDropdownValues.dropdown1Value !== '' &&
      newAndSlightlyUsedDropdownValues.dropdown2Value !== '' &&
      newAndSlightlyUsedDropdownValues.dropdown3Value !== '' &&
      newAndSlightlyUsedDropdownValues.dropdown4Value !== '' &&
      dotCode.dot1.length === 4 &&
      dotCode.dot2.length === 4 &&
      dotCode.dot3.length === 4 &&
      dotCode.dot4.length === 4;

    if (productDetailsData && !isProductDetailsDataError) {
      if (conditie === 'Nieuw') {
        if (productDetailsData?.tyre_set.sale_status === 'FOR_SALE') {
          if (
            generalFieldsForEveryConditionNotEmpty &&
            verkoopprijs !== 0 &&
            verkoopprijs !== '0' &&
            verkoopprijs[0] !== '0' &&
            verkoopprijs !== '' &&
            file !== null &&
            !isCreateImageLoading &&
            !isCreateImageError
          ) {
            setFieldsFilled(true);
          } else {
            setFieldsFilled(false);
          }
        } else if (productDetailsData?.tyre_set.sale_status === 'INVENTORY') {
          if (generalFieldsForEveryConditionNotEmpty && file !== null && !isCreateImageLoading && !isCreateImageError) {
            setFieldsFilled(true);
          } else {
            setFieldsFilled(false);
          }
        }
      } else if (conditie === 'Zo goed als nieuw' || conditie === 'Gebruikt') {
        if (productDetailsData?.tyre_set.sale_status === 'FOR_SALE') {
          if (
            generalFieldsForEveryConditionNotEmpty &&
            verkoopprijs !== 0 &&
            verkoopprijs !== '0' &&
            verkoopprijs[0] !== '0' &&
            verkoopprijs !== '' &&
            usedFiles.file1 !== null &&
            usedFiles.file2 !== null &&
            usedFiles.file3 !== null &&
            usedFiles.file4 !== null &&
            !isCreateImage1Loading &&
            !isCreateImage1Error &&
            !isCreateImage2Loading &&
            !isCreateImage2Error &&
            !isCreateImage3Loading &&
            !isCreateImage3Error &&
            !isCreateImage4Loading &&
            !isCreateImage4Error &&
            usedDropdown.weil1 !== -1 &&
            usedDropdown.weil2 !== -1 &&
            usedDropdown.weil3 !== -1 &&
            usedDropdown.weil4 !== -1
          ) {
            setFieldsFilled(true);
          } else {
            setFieldsFilled(false);
          }
        } else if (productDetailsData?.tyre_set.sale_status === 'INVENTORY') {
          if (
            generalFieldsForEveryConditionNotEmpty &&
            usedFiles.file1 !== null &&
            usedFiles.file2 !== null &&
            usedFiles.file3 !== null &&
            usedFiles.file4 !== null &&
            !isCreateImage1Loading &&
            !isCreateImage1Error &&
            !isCreateImage2Loading &&
            !isCreateImage2Error &&
            !isCreateImage3Loading &&
            !isCreateImage3Error &&
            !isCreateImage4Loading &&
            !isCreateImage4Error &&
            usedDropdown.weil1 !== -1 &&
            usedDropdown.weil2 !== -1 &&
            usedDropdown.weil3 !== -1 &&
            usedDropdown.weil4 !== -1
          ) {
            setFieldsFilled(true);
          } else {
            setFieldsFilled(false);
          }
        }
      }
    }
  }, [
    breedte,
    hoogte,
    inchMaat,
    tyreBandenmerk,
    verkoopprijs,
    isSelected,
    conditie,
    file,
    newAndSlightlyUsedDropdownValues,
    usedFiles,
    dotCode,
    usedDropdown,
    isCreateImageLoading,
    isCreateImageError,
    isCreateImage1Loading,
    isCreateImage1Error,
    isCreateImage2Loading,
    isCreateImage2Error,
    isCreateImage3Loading,
    isCreateImage3Error,
    isCreateImage4Loading,
    isCreateImage4Error,
  ]);

  const handleDotCode = useCallback(
    (event) => {
      const { name, value } = event.target;
      if (value.length > 4) {
        return;
      }
      setDotCode((prev) => ({ ...prev, [name]: value }));
    },
    [setDotCode]
  );

  if (
    isBreedteDropdownLoading ||
    isHoogteDropdownLoading ||
    isInchmaatDropdownLoading ||
    isProductDetailsDataLoading ||
    !(file || (usedFiles.file1 && usedFiles.file2 && usedFiles.file3 && usedFiles.file4))
  ) {
    return (
      <BewerkenLoaderPlaceholder>
        <Loader />
      </BewerkenLoaderPlaceholder>
    );
  }

  return (
    <BewerkenContainer>
      <ToastContainer toastList={toastList} autoRemove removeInterval={5000} />
      <BewerkenWrapper>
        <Toevoegen3Title>Bewerken</Toevoegen3Title>
        <Toevoegen3TyreImageWrapper>
          <Toevoegen3TyreImage src={tyreImage} alt="" />
        </Toevoegen3TyreImageWrapper>
        {width > breakpoints.md ? (
          <Toevoegen3DropdownWrapper>
            <Toevoegen3DropdownItem>
              <Toevoegen3Subtitle>Breedte</Toevoegen3Subtitle>
              <Toevoegen3DropdownSelect
                value={breedte}
                onChange={(event) => {
                  setChanged((prev) => ({ ...prev, breedte: true }));
                  setBreedte(event.target.value);
                  setButtonActive(true);
                }}
                color={changed.breedte ? '#000' : styles.greyNormal}
              >
                <option value="" hidden />
                {breedteDropdownValues &&
                  breedteDropdownValues.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
              </Toevoegen3DropdownSelect>
            </Toevoegen3DropdownItem>
            <Toevoegen3DropdownItem>
              <Toevoegen3Subtitle>Hoogte</Toevoegen3Subtitle>
              <Toevoegen3DropdownSelect
                value={hoogte}
                onChange={(event) => {
                  setChanged((prev) => ({ ...prev, hoogte: true }));
                  setHoogte(event.target.value);
                  setButtonActive(true);
                }}
                color={changed.hoogte ? '#000' : styles.greyNormal}
              >
                <option value="" hidden />
                {hoogteDropdownValues &&
                  hoogteDropdownValues.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
              </Toevoegen3DropdownSelect>
            </Toevoegen3DropdownItem>
            <Toevoegen3DropdownItem>
              <Toevoegen3Subtitle>Inchmaat</Toevoegen3Subtitle>
              <Toevoegen3DropdownSelect
                value={inchMaat}
                onChange={(event) => {
                  setChanged((prev) => ({ ...prev, inchmaat: true }));
                  setInchmaat(event.target.value);
                  setButtonActive(true);
                }}
                color={changed.inchmaat ? '#000' : styles.greyNormal}
              >
                <option value="" hidden />
                {inchmaatDropdownValues &&
                  inchmaatDropdownValues.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
              </Toevoegen3DropdownSelect>
            </Toevoegen3DropdownItem>
          </Toevoegen3DropdownWrapper>
        ) : (
          <Toevoegen3DropdownWrapper>
            <Toevoegen3DropdownMobileWrapper>
              <Toevoegen3MobileDropdownItem style={{ marginRight: '10px' }}>
                <Toevoegen3Subtitle>Breedte</Toevoegen3Subtitle>
                <Toevoegen3DropdownSelect
                  value={breedte}
                  onChange={(event) => {
                    setChanged((prev) => ({ ...prev, breedte: true }));
                    setBreedte(event.target.value);
                    setButtonActive(true);
                  }}
                  color={changed.breedte ? '#000' : styles.greyNormal}
                >
                  <option value="" hidden />
                  {breedteDropdownValues &&
                    breedteDropdownValues.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                </Toevoegen3DropdownSelect>
              </Toevoegen3MobileDropdownItem>
              <Toevoegen3MobileDropdownItem style={{ marginLeft: '10px' }}>
                <Toevoegen3Subtitle>Hoogte</Toevoegen3Subtitle>
                <Toevoegen3DropdownSelect
                  value={hoogte}
                  onChange={(event) => {
                    setChanged((prev) => ({ ...prev, hoogte: true }));
                    setHoogte(event.target.value);
                    setButtonActive(true);
                  }}
                  color={changed.hoogte ? '#000' : styles.greyNormal}
                >
                  <option value="" hidden />
                  {hoogteDropdownValues &&
                    hoogteDropdownValues.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                </Toevoegen3DropdownSelect>
              </Toevoegen3MobileDropdownItem>
            </Toevoegen3DropdownMobileWrapper>
            <Toevoegen3DropdownItem style={{ marginTop: '20px', marginBottom: '20px' }}>
              <Toevoegen3Subtitle>Inchmaat</Toevoegen3Subtitle>
              <Toevoegen3DropdownSelect
                value={inchMaat}
                onChange={(event) => {
                  setChanged((prev) => ({ ...prev, inchmaat: true }));
                  setInchmaat(event.target.value);
                  setButtonActive(true);
                }}
                color={changed.inchmaat ? '#000' : styles.greyNormal}
              >
                <option value="" hidden />
                {inchmaatDropdownValues &&
                  inchmaatDropdownValues.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
              </Toevoegen3DropdownSelect>
            </Toevoegen3DropdownItem>
          </Toevoegen3DropdownWrapper>
        )}
        <Toevoegen3DropdownWrapper>
          <Toevoegen3DropdownItem>
            <Toevoegen3DropdownSelect
              value={tyreBandenmerk}
              onChange={(event) => handleBandenmerkChange(event)}
              color={changed.bandenmerk ? '#000' : styles.greyNormal}
            >
              <option style={{ color: '#000' }} value="" disabled>
                Bandenmerk
              </option>
              {bandenmerken.map((benden) => (
                <option key={benden.id} style={{ color: '#000' }} value={benden.value}>
                  {benden.value}
                </option>
              ))}
            </Toevoegen3DropdownSelect>
          </Toevoegen3DropdownItem>
          <Toevoegen3DropdownItem nThChild>
            <Toevoegen3DropdownSelect
              value={velgenMerkOptional}
              onChange={(event) => {
                setChanged((prev) => ({ ...prev, velgenmerk: true }));
                setVelgenMerkOptional(event.target.value);
                setButtonActive(true);
              }}
              color={changed.velgenmerk ? '#000' : styles.greyNormal}
            >
              <option style={{ color: '#000' }} value="" disabled>
                Velgenmerk (optioneel)
              </option>
              {velgenmerker.map((velgen) => (
                <option key={velgen.id} style={{ color: '#000' }} value={velgen.value}>
                  {velgen.value}
                </option>
              ))}
            </Toevoegen3DropdownSelect>
          </Toevoegen3DropdownItem>
        </Toevoegen3DropdownWrapper>
        <BewerkenInputWrapper>
          {productDetailsData?.tyre_set.sale_status === 'FOR_SALE' && (
            <BewerkenInputItem>
              <Toevoegen4Subtitle2>Verkoopprijs</Toevoegen4Subtitle2>
              <Toevoegen4OpslagplaatsInput2
                min={0}
                value={verkoopprijs}
                focus={styles.primaryColor}
                type="number"
                onChange={(event) => {
                  setVerkoopprijs(event.target.value);
                  setButtonActive(true);
                }}
              />
              <Toevoegen4InfoP2>Inclusief transportkosten en handelings fee</Toevoegen4InfoP2>
            </BewerkenInputItem>
          )}

          <BewerkenInputItem>
            <Toevoegen4Subtitle2>Opslagplaats (optioneel)</Toevoegen4Subtitle2>
            <Toevoegen4OpslagplaatsInput2
              focus={styles.primaryColor}
              type="text"
              value={opslagplaatsOptional}
              onChange={(event) => {
                setOpslagplaatsOptional(event.target.value);
                setButtonActive(true);
              }}
            />
            <Toevoegen4InfoP2>Waar op de vestiging ligt de set opgeslagen</Toevoegen4InfoP2>
          </BewerkenInputItem>
        </BewerkenInputWrapper>
        <Toevoegen3Subtitle>Seizoen</Toevoegen3Subtitle>
        <Toevoegen3SelectWrapper>
          <Toevoegen3SelectItem
            backgroundColor={isSelected.seizoen === 0 ? styles.primaryColorContact : styles.white}
            color={isSelected.seizoen === 0 ? styles.primaryColor : '#000'}
            fw={isSelected.seizoen === 0 ? '700' : '500'}
            borderColor={isSelected.seizoen === 0 ? styles.primaryColor : styles.greyNormal}
            style={
              width > breakpoints.md ? { borderRadius: '10px 0px 0px 10px' } : { borderRadius: '10px 10px 0px 0px' }
            }
            onClick={() => {
              setIsSelected((prev) => ({ ...prev, seizoen: 0 }));
              setButtonActive(true);
            }}
          >
            Zomer
          </Toevoegen3SelectItem>
          <Toevoegen3SelectItem
            backgroundColor={isSelected.seizoen === 1 ? styles.primaryColorContact : styles.white}
            color={isSelected.seizoen === 1 ? styles.primaryColor : '#000'}
            fw={isSelected.seizoen === 1 ? '700' : '500'}
            borderColor={isSelected.seizoen === 1 ? styles.primaryColor : styles.greyNormal}
            onClick={() => {
              setIsSelected((prev) => ({ ...prev, seizoen: 1 }));
              setButtonActive(true);
            }}
          >
            Winter
          </Toevoegen3SelectItem>
          <Toevoegen3SelectItem
            backgroundColor={isSelected.seizoen === 2 ? styles.primaryColorContact : styles.white}
            color={isSelected.seizoen === 2 ? styles.primaryColor : '#000'}
            fw={isSelected.seizoen === 2 ? '700' : '500'}
            borderColor={isSelected.seizoen === 2 ? styles.primaryColor : styles.greyNormal}
            style={
              width > breakpoints.md
                ? { borderRadius: '0px 10px 10px 0px' }
                : {
                    borderRadius: '0px 0px 10px 10px',
                  }
            }
            onClick={() => {
              setIsSelected((prev) => ({ ...prev, seizoen: 2 }));
              setButtonActive(true);
            }}
          >
            All-season
          </Toevoegen3SelectItem>
        </Toevoegen3SelectWrapper>
        <Toevoegen3SubtitleInfoWrapper>
          <Toevoegen3Subtitle>M + S</Toevoegen3Subtitle>
          <InfoIcon
            style={{ color: styles.primaryColor, cursor: 'pointer' }}
            fontSize="inherit"
            onMouseEnter={() => setInfoActive((prev) => ({ ...prev, info2: true }))}
            onMouseLeave={() => setInfoActive((prev) => ({ ...prev, info2: false }))}
          />
          {infoActive.info2 && (
            <Toevoegen3InfoContainer3>
              Dit staat voor Mud + Snow, deze banden zijn geschikt voor rijden op modder en sneeuw. Niet alle banden
              waar M+S op staat zijn winterbanden.
            </Toevoegen3InfoContainer3>
          )}
        </Toevoegen3SubtitleInfoWrapper>
        <Toevoegen3SelectWrapper2>
          <Toevoegen3SelectItem
            backgroundColor={isSelected.ms === 0 ? styles.primaryColorContact : styles.white}
            color={isSelected.ms === 0 ? styles.primaryColor : '#000'}
            fw={isSelected.ms === 0 ? '700' : '500'}
            borderColor={isSelected.ms === 0 ? styles.primaryColor : styles.greyNormal}
            style={{ borderRadius: '10px 0px 0px 10px' }}
            onClick={() => {
              setIsSelected((prev) => ({ ...prev, ms: 0 }));
              setButtonActive(true);
            }}
          >
            Ja
          </Toevoegen3SelectItem>
          <Toevoegen3SelectItem
            backgroundColor={isSelected.ms === 1 ? styles.primaryColorContact : styles.white}
            color={isSelected.ms === 1 ? styles.primaryColor : '#000'}
            fw={isSelected.ms === 1 ? '700' : '500'}
            borderColor={isSelected.ms === 1 ? styles.primaryColor : styles.greyNormal}
            style={{ borderRadius: '0px 10px 10px 0px' }}
            onClick={() => {
              setIsSelected((prev) => ({ ...prev, ms: 1 }));
              setButtonActive(true);
            }}
          >
            Nee
          </Toevoegen3SelectItem>
        </Toevoegen3SelectWrapper2>
        <Toevoegen3SubtitleInfoWrapper>
          <Toevoegen3Subtitle>3PMSF</Toevoegen3Subtitle>
          <InfoIcon
            style={{ color: styles.primaryColor, cursor: 'pointer' }}
            fontSize="inherit"
            onMouseEnter={() => setInfoActive((prev) => ({ ...prev, info3: true }))}
            onMouseLeave={() => setInfoActive((prev) => ({ ...prev, info3: false }))}
          />
          {infoActive.info3 && (
            <Toevoegen3InfoContainer4>
              Naast het M+S kenmerk hebben sommige banden ook pictogram van een sneeuwvlok tegen een berg achtergrond
              aan. Wanneer dit teken op de band staat weet je dat je band garantie biedt op prestaties in de sneeuw. Het
              beste kan je daarom winterbanden nemen met dit kenmerk, zo weet je zeker dat je banden voldoende tractie
              hebben in winterse omstandigheden.
            </Toevoegen3InfoContainer4>
          )}
        </Toevoegen3SubtitleInfoWrapper>
        <Toevoegen3SelectWrapper2>
          <Toevoegen3SelectItem
            backgroundColor={isSelected.pmsf === 0 ? styles.primaryColorContact : styles.white}
            color={isSelected.pmsf === 0 ? styles.primaryColor : '#000'}
            fw={isSelected.pmsf === 0 ? '700' : '500'}
            borderColor={isSelected.pmsf === 0 ? styles.primaryColor : styles.greyNormal}
            style={{ borderRadius: '10px 0px 0px 10px' }}
            onClick={() => {
              setIsSelected((prev) => ({ ...prev, pmsf: 0 }));
              setButtonActive(true);
            }}
          >
            Ja
          </Toevoegen3SelectItem>
          <Toevoegen3SelectItem
            backgroundColor={isSelected.pmsf === 1 ? styles.primaryColorContact : styles.white}
            color={isSelected.pmsf === 1 ? styles.primaryColor : '#000'}
            fw={isSelected.pmsf === 1 ? '700' : '500'}
            borderColor={isSelected.pmsf === 1 ? styles.primaryColor : styles.greyNormal}
            style={{ borderRadius: '0px 10px 10px 0px' }}
            onClick={() => {
              setIsSelected((prev) => ({ ...prev, pmsf: 1 }));
              setButtonActive(true);
            }}
          >
            Nee
          </Toevoegen3SelectItem>
        </Toevoegen3SelectWrapper2>
        <Toevoegen3SubtitleInfoWrapper>
          <Toevoegen3Subtitle>TPMS</Toevoegen3Subtitle>
          <InfoIcon
            style={{ color: styles.primaryColor, cursor: 'pointer' }}
            fontSize="inherit"
            onMouseEnter={() => setInfoActive((prev) => ({ ...prev, info4: true }))}
            onMouseLeave={() => setInfoActive((prev) => ({ ...prev, info4: false }))}
          />
          {infoActive.info4 && (
            <Toevoegen3InfoContainer5>
              Dit is de aanduiding van het bandenspanningscontrolesysteem (Tyre Pressure Monitoring System TPMS). Sinds
              2014 is het verplicht dat elke nieuwe auto dit systeem aan boord heeft
            </Toevoegen3InfoContainer5>
          )}
        </Toevoegen3SubtitleInfoWrapper>
        <Toevoegen3SelectWrapper>
          <Toevoegen3SelectItem
            backgroundColor={isSelected.tpms === 0 ? styles.primaryColorContact : styles.white}
            color={isSelected.tpms === 0 ? styles.primaryColor : '#000'}
            fw={isSelected.tpms === 0 ? '700' : '500'}
            borderColor={isSelected.tpms === 0 ? styles.primaryColor : styles.greyNormal}
            style={
              width > breakpoints.md ? { borderRadius: '10px 0px 0px 10px' } : { borderRadius: '10px 10px 0px 0px' }
            }
            onClick={() => {
              setIsSelected((prev) => ({ ...prev, tpms: 0 }));
              setButtonActive(true);
            }}
          >
            Ja
          </Toevoegen3SelectItem>
          <Toevoegen3SelectItem
            backgroundColor={isSelected.tpms === 1 ? styles.primaryColorContact : styles.white}
            color={isSelected.tpms === 1 ? styles.primaryColor : '#000'}
            fw={isSelected.tpms === 1 ? '700' : '500'}
            borderColor={isSelected.tpms === 1 ? styles.primaryColor : styles.greyNormal}
            onClick={() => {
              setIsSelected((prev) => ({ ...prev, tpms: 1 }));
              setButtonActive(true);
            }}
          >
            Nee
          </Toevoegen3SelectItem>
          <Toevoegen3SelectItem
            backgroundColor={isSelected.tpms === 2 ? styles.primaryColorContact : styles.white}
            color={isSelected.tpms === 2 ? styles.primaryColor : '#000'}
            fw={isSelected.tpms === 2 ? '700' : '500'}
            borderColor={isSelected.tpms === 2 ? styles.primaryColor : styles.greyNormal}
            style={
              width > breakpoints.md ? { borderRadius: '0px 10px 10px 0px' } : { borderRadius: '0px 0px 10px 10px' }
            }
            onClick={() => {
              setIsSelected((prev) => ({ ...prev, tpms: 2 }));
              setButtonActive(true);
            }}
          >
            Onbekend
          </Toevoegen3SelectItem>
        </Toevoegen3SelectWrapper>
        <Toevoegen3SubtitleInfoWrapper>
          <Toevoegen3Subtitle>Runflat</Toevoegen3Subtitle>
          <InfoIcon
            style={{ color: styles.primaryColor, cursor: 'pointer' }}
            fontSize="inherit"
            onMouseEnter={() => setInfoActive((prev) => ({ ...prev, info5: true }))}
            onMouseLeave={() => setInfoActive((prev) => ({ ...prev, info5: false }))}
          />
          {infoActive.info5 && (
            <Toevoegen3InfoContainer>
              Wanneer je een Runflat band onder je auto hebt kan je bij een lekke band tot ongeveer 80 KM doorrijden.
              Dit komt door de versterkte zijkanten van de band, houd er wel rekening mee dat je niet harder dan 80
              kilometer per uur rijdt. Runflat banden kunnen alleen op een auto gemonteerd worden welke een TPMS heeft.
            </Toevoegen3InfoContainer>
          )}
        </Toevoegen3SubtitleInfoWrapper>
        <Toevoegen3SelectWrapper>
          <Toevoegen3SelectItem
            backgroundColor={isSelected.runflat === 0 ? styles.primaryColorContact : styles.white}
            color={isSelected.runflat === 0 ? styles.primaryColor : '#000'}
            fw={isSelected.runflat === 0 ? '700' : '500'}
            borderColor={isSelected.runflat === 0 ? styles.primaryColor : styles.greyNormal}
            style={
              width > breakpoints.md ? { borderRadius: '10px 0px 0px 10px' } : { borderRadius: '10px 10px 0px 0px' }
            }
            onClick={() => {
              setIsSelected((prev) => ({ ...prev, runflat: 0 }));
              setButtonActive(true);
            }}
          >
            Ja
          </Toevoegen3SelectItem>
          <Toevoegen3SelectItem
            backgroundColor={isSelected.runflat === 1 ? styles.primaryColorContact : styles.white}
            color={isSelected.runflat === 1 ? styles.primaryColor : '#000'}
            fw={isSelected.runflat === 1 ? '700' : '500'}
            borderColor={isSelected.runflat === 1 ? styles.primaryColor : styles.greyNormal}
            onClick={() => {
              setIsSelected((prev) => ({ ...prev, runflat: 1 }));
              setButtonActive(true);
            }}
          >
            Nee
          </Toevoegen3SelectItem>
          <Toevoegen3SelectItem
            backgroundColor={isSelected.runflat === 2 ? styles.primaryColorContact : styles.white}
            color={isSelected.runflat === 2 ? styles.primaryColor : '#000'}
            fw={isSelected.runflat === 2 ? '700' : '500'}
            borderColor={isSelected.runflat === 2 ? styles.primaryColor : styles.greyNormal}
            style={
              width > breakpoints.md
                ? { borderRadius: '0px 10px 10px 0px' }
                : {
                    borderRadius: '0px 0px 10px 10px',
                  }
            }
            onClick={() => {
              setIsSelected((prev) => ({ ...prev, runflat: 2 }));
              setButtonActive(true);
            }}
          >
            Onbekend
          </Toevoegen3SelectItem>
        </Toevoegen3SelectWrapper>
        <Toevoegen2Subtitle>Conditie</Toevoegen2Subtitle>
        <Toevoegen2SelectWrapper borderColor={isSelected2 === 0 ? styles.primaryColor : styles.greyNormal}>
          <Toevoegen2SelectItem
            backgroundColor={isSelected2 === 0 ? styles.primaryColorContact : styles.white}
            color={isSelected2 === 0 ? styles.primaryColor : '#000'}
            fw={isSelected2 === 0 ? '700' : '500'}
            borderColor={isSelected2 === 0 ? styles.primaryColor : styles.greyNormal}
            style={
              width > breakpoints.md ? { borderRadius: '10px 0px 0px 10px' } : { borderRadius: '10px 10px 0px 0px' }
            }
            onClick={() => {
              setIsSelected2(0);
              setButtonActive(true);
            }}
          >
            Nieuw
          </Toevoegen2SelectItem>
          <Toevoegen2SelectItem
            backgroundColor={isSelected2 === 1 ? styles.primaryColorContact : styles.white}
            color={isSelected2 === 1 ? styles.primaryColor : '#000'}
            fw={isSelected2 === 1 ? '700' : '500'}
            borderColor={isSelected2 === 1 ? styles.primaryColor : styles.greyNormal}
            onClick={() => {
              setIsSelected2(1);
              setButtonActive(true);
            }}
          >
            Zo goed als nieuw
          </Toevoegen2SelectItem>
          <Toevoegen2SelectItem
            backgroundColor={isSelected2 === 2 ? styles.primaryColorContact : styles.white}
            color={isSelected2 === 2 ? styles.primaryColor : '#000'}
            fw={isSelected2 === 2 ? '700' : '500'}
            borderColor={isSelected2 === 2 ? styles.primaryColor : styles.greyNormal}
            style={
              width > breakpoints.md
                ? { borderRadius: '0px 10px 10px 0px' }
                : {
                    borderRadius: '0px 0px 10px 10px',
                  }
            }
            onClick={() => {
              setIsSelected2(2);
              setButtonActive(true);
            }}
          >
            Gebruikt
          </Toevoegen2SelectItem>
        </Toevoegen2SelectWrapper>
        {isSelected2 === 2 || isSelected2 === 1 ? (
          <>
            {usedFiles.file1 === null ? (
              <Toevoegen2UsedWrapper>
                {width > breakpoints.md ? (
                  <>
                    <Toevoegen2UsedPhotoWrapper>
                      <Toevoegen2AddUsedPhotoWrapper>
                        <AddPhotoAlternateIcon style={{ color: styles.primaryColor }} />
                        <Toevoegen2AddPhotoLabel htmlFor="file" color={styles.primaryColor}>
                          Toevoegen
                        </Toevoegen2AddPhotoLabel>
                        <Toevoegen2AddPhotoInput
                          type="file"
                          name="file"
                          id="file"
                          onChange={(event) => {
                            setUsedFiles((prev) => ({
                              ...prev,
                              file1: URL.createObjectURL(event.target.files[0]),
                            }));
                            setFile1Image(event.target.files[0]);
                            setButtonActive(true);
                          }}
                        />
                      </Toevoegen2AddUsedPhotoWrapper>
                      <Toevoegen2UsedP color={styles.greyNormal}>
                        Let op! Zorg dat de velg van voren gefotografeerd is en in het midden gepositioneerd is. Je kunt
                        maximaal 1 foto per velg uploaden.
                      </Toevoegen2UsedP>
                    </Toevoegen2UsedPhotoWrapper>
                    <Toevoegen2UsedDropdownWrapper marginTop="10px">
                      <Toevoegen2AddUsedSubtitle marginTop="60px">Wiel 1</Toevoegen2AddUsedSubtitle>
                      <Toevoegen2ISelectItem
                        value={gebruiktDropDownValues.dropdown1Value}
                        onChange={(event) => {
                          setTouchedGebruiktProfileFields((prev) => ({ ...prev, dropdown1: true }));
                          setGebruiktDropdownValues((prev) => ({
                            ...prev,
                            dropdown1Value: event.target.value,
                          }));
                          setButtonActive(true);
                        }}
                        color={touchedGebruiktProfileFields.dropdown1 ? '#000' : styles.greyNormal}
                      >
                        {ProfileDepths.map((option) => (
                          <option key={option.value} value={option.value}>
                            {`${option.label} mm`}
                          </option>
                        ))}
                      </Toevoegen2ISelectItem>

                      <Toevoegen2Dotcode
                        focus={styles.primaryColor}
                        value={dotCode.dot1}
                        name="dot1"
                        min={0}
                        onChange={handleDotCode}
                        type="number"
                        placeholder="DOT code"
                      />

                      <Toevoegen2Subtitle>Schade?</Toevoegen2Subtitle>
                      <Toevoegen2SelectUsedWrapper>
                        <Toevoegen2SelectItem
                          backgroundColor={usedDropdown.weil1 === 0 ? styles.primaryColorContact : styles.white}
                          color={usedDropdown.weil1 === 0 ? styles.primaryColor : '#000'}
                          fw={usedDropdown.weil1 === 0 ? '700' : '500'}
                          borderColor={usedDropdown.weil1 === 0 ? styles.primaryColor : styles.greyNormal}
                          style={{ borderRadius: '10px 0px 0px 10px' }}
                          onClick={() => {
                            setUsedDropdown((prev) => ({ ...prev, weil1: 0 }));
                            setButtonActive(true);
                          }}
                        >
                          Ja
                        </Toevoegen2SelectItem>
                        <Toevoegen2SelectItem
                          backgroundColor={usedDropdown.weil1 === 1 ? styles.primaryColorContact : styles.white}
                          color={usedDropdown.weil1 === 1 ? styles.primaryColor : '#000'}
                          fw={usedDropdown.weil1 === 1 ? '700' : '500'}
                          borderColor={usedDropdown.weil1 === 1 ? styles.primaryColor : styles.greyNormal}
                          style={{ borderRadius: '0px 10px 10px 0px' }}
                          onClick={() => {
                            setUsedDropdown((prev) => ({ ...prev, weil1: 1 }));
                            setButtonActive(true);
                          }}
                        >
                          Nee
                        </Toevoegen2SelectItem>
                      </Toevoegen2SelectUsedWrapper>
                    </Toevoegen2UsedDropdownWrapper>
                  </>
                ) : (
                  <>
                    <Toevoegen2UsedPhotoWrapper>
                      <Toevoegen2TitleMinWrapper>
                        <Toevoegen2AddUsedSubtitle marginTop="20px">Wiel 1</Toevoegen2AddUsedSubtitle>
                      </Toevoegen2TitleMinWrapper>
                      <Toevoegen2AddUsedPhotoWrapper>
                        <AddPhotoAlternateIcon style={{ color: styles.primaryColor }} />
                        <Toevoegen2AddPhotoLabel htmlFor="file" color={styles.primaryColor}>
                          Toevoegen
                        </Toevoegen2AddPhotoLabel>
                        <Toevoegen2AddPhotoInput
                          type="file"
                          name="file"
                          id="file"
                          onChange={(event) => {
                            setUsedFiles((prev) => ({
                              ...prev,
                              file1: URL.createObjectURL(event.target.files[0]),
                            }));
                            setFile1Image(event.target.files[0]);
                            setButtonActive(true);
                          }}
                        />
                      </Toevoegen2AddUsedPhotoWrapper>
                      <Toevoegen2UsedP color={styles.greyNormal} style={{ marginBottom: '-10px' }}>
                        Let op! Zorg dat de velg van voren gefotografeerd is en in het midden gepositioneerd is. Je kunt
                        maximaal 1 foto per velg uploaden.
                      </Toevoegen2UsedP>
                    </Toevoegen2UsedPhotoWrapper>
                    <Toevoegen2UsedDropdownWrapper marginTop="20px">
                      <Toevoegen2ISelectItem
                        value={gebruiktDropDownValues.dropdown1Value}
                        onChange={(event) => {
                          setTouchedGebruiktProfileFields((prev) => ({ ...prev, dropdown1: true }));
                          setGebruiktDropdownValues((prev) => ({
                            ...prev,
                            dropdown1Value: event.target.value,
                          }));
                          setButtonActive(true);
                        }}
                        color={touchedGebruiktProfileFields.dropdown1 ? '#000' : styles.greyNormal}
                      >
                        {ProfileDepths.map((option) => (
                          <option key={option.value} value={option.value}>
                            {`${option.label} mm`}
                          </option>
                        ))}
                      </Toevoegen2ISelectItem>

                      <Toevoegen2Dotcode
                        focus={styles.primaryColor}
                        value={dotCode.dot1}
                        name="dot1"
                        min={0}
                        onChange={handleDotCode}
                        type="number"
                        placeholder="DOT code"
                      />

                      <Toevoegen2Subtitle>Schade?</Toevoegen2Subtitle>
                      <Toevoegen2SelectUsedWrapper>
                        <Toevoegen2SelectItem
                          backgroundColor={usedDropdown.weil1 === 0 ? styles.primaryColorContact : styles.white}
                          color={usedDropdown.weil1 === 0 ? styles.primaryColor : '#000'}
                          fw={usedDropdown.weil1 === 0 ? '700' : '500'}
                          borderColor={usedDropdown.weil1 === 0 ? styles.primaryColor : styles.greyNormal}
                          style={{ borderRadius: '10px 0px 0px 10px' }}
                          onClick={() => {
                            setUsedDropdown((prev) => ({ ...prev, weil1: 0 }));
                            setButtonActive(true);
                          }}
                        >
                          Ja
                        </Toevoegen2SelectItem>
                        <Toevoegen2SelectItem
                          backgroundColor={usedDropdown.weil1 === 1 ? styles.primaryColorContact : styles.white}
                          color={usedDropdown.weil1 === 1 ? styles.primaryColor : '#000'}
                          fw={usedDropdown.weil1 === 1 ? '700' : '500'}
                          borderColor={usedDropdown.weil1 === 1 ? styles.primaryColor : styles.greyNormal}
                          style={{ borderRadius: '0px 10px 10px 0px' }}
                          onClick={() => {
                            setUsedDropdown((prev) => ({ ...prev, weil1: 1 }));
                            setButtonActive(true);
                          }}
                        >
                          Nee
                        </Toevoegen2SelectItem>
                      </Toevoegen2SelectUsedWrapper>
                    </Toevoegen2UsedDropdownWrapper>
                  </>
                )}
              </Toevoegen2UsedWrapper>
            ) : (
              <Toevoegen2UsedWrapper>
                {width > breakpoints.md ? (
                  <Toevoegen2UploadedPhotoWrapper>
                    <Toevoegen2UploadedImg src={usedFiles.file1} alt="" />
                    <Toevoegen2UploadedImgFix backgroundColor={styles.primaryColorContact}>
                      <EditIcon style={{ color: styles.primaryColor }} />
                      <Toevoegen2AddPhotoLabel htmlFor="file" color={styles.primaryColor}>
                        Afbeelding wijzigen
                      </Toevoegen2AddPhotoLabel>
                      <Toevoegen2AddPhotoInput
                        type="file"
                        name="file"
                        id="file"
                        onChange={(event) => {
                          setUsedFiles((prev) => ({
                            ...prev,
                            file1: URL.createObjectURL(event.target.files[0]),
                          }));
                          setFile1Image(event.target.files[0]);
                          setButtonActive(true);
                        }}
                      />
                    </Toevoegen2UploadedImgFix>
                  </Toevoegen2UploadedPhotoWrapper>
                ) : (
                  <>
                    <Toevoegen2TitleMinWrapper>
                      <Toevoegen2AddUsedSubtitle marginTop="0px">Wiel 1</Toevoegen2AddUsedSubtitle>
                    </Toevoegen2TitleMinWrapper>
                    <Toevoegen2UploadedPhotoWrapper>
                      <Toevoegen2UploadedImg src={usedFiles.file1} alt="" />
                      <Toevoegen2UploadedImgFix backgroundColor={styles.primaryColorContact}>
                        <EditIcon style={{ color: styles.primaryColor }} />
                        <Toevoegen2AddPhotoLabel htmlFor="file" color={styles.primaryColor}>
                          Afbeelding wijzigen
                        </Toevoegen2AddPhotoLabel>
                        <Toevoegen2AddPhotoInput
                          type="file"
                          name="file"
                          id="file"
                          onChange={(event) => {
                            setUsedFiles((prev) => ({
                              ...prev,
                              file1: URL.createObjectURL(event.target.files[0]),
                            }));
                            setFile1Image(event.target.files[0]);
                            setButtonActive(true);
                          }}
                        />
                      </Toevoegen2UploadedImgFix>
                    </Toevoegen2UploadedPhotoWrapper>
                  </>
                )}
                <Toevoegen2UsedDropdownWrapper marginTop="0px">
                  {width > breakpoints.md && (
                    <Toevoegen2AddUsedSubtitle marginTop="0px">Wiel 1</Toevoegen2AddUsedSubtitle>
                  )}
                  <Toevoegen2ISelectItem
                    value={gebruiktDropDownValues.dropdown1Value}
                    onChange={(event) => {
                      setTouchedGebruiktProfileFields((prev) => ({ ...prev, dropdown1: true }));
                      setGebruiktDropdownValues((prev) => ({ ...prev, dropdown1Value: event.target.value }));

                      setButtonActive(true);
                    }}
                    color={touchedGebruiktProfileFields.dropdown1 ? '#000' : styles.greyNormal}
                  >
                    {ProfileDepths.map((option) => (
                      <option key={option.value} value={option.value}>
                        {`${option.label} mm`}
                      </option>
                    ))}
                  </Toevoegen2ISelectItem>

                  <Toevoegen2Dotcode
                    focus={styles.primaryColor}
                    value={dotCode.dot1}
                    name="dot1"
                    min={0}
                    onChange={handleDotCode}
                    type="number"
                    placeholder="DOT code"
                  />

                  <Toevoegen2Subtitle>Schade?</Toevoegen2Subtitle>
                  <Toevoegen2SelectUsedWrapper>
                    <Toevoegen2SelectItem
                      backgroundColor={usedDropdown.weil1 === 0 ? styles.primaryColorContact : styles.white}
                      color={usedDropdown.weil1 === 0 ? styles.primaryColor : '#000'}
                      fw={usedDropdown.weil1 === 0 ? '700' : '500'}
                      borderColor={usedDropdown.weil1 === 0 ? styles.primaryColor : styles.greyNormal}
                      style={{ borderRadius: '10px 0px 0px 10px' }}
                      onClick={() => {
                        setUsedDropdown((prev) => ({ ...prev, weil1: 0 }));
                        setButtonActive(true);
                      }}
                    >
                      Ja
                    </Toevoegen2SelectItem>
                    <Toevoegen2SelectItem
                      backgroundColor={usedDropdown.weil1 === 1 ? styles.primaryColorContact : styles.white}
                      color={usedDropdown.weil1 === 1 ? styles.primaryColor : '#000'}
                      fw={usedDropdown.weil1 === 1 ? '700' : '500'}
                      borderColor={usedDropdown.weil1 === 1 ? styles.primaryColor : styles.greyNormal}
                      style={{ borderRadius: '0px 10px 10px 0px' }}
                      onClick={() => {
                        setUsedDropdown((prev) => ({ ...prev, weil1: 1 }));
                        setButtonActive(true);
                      }}
                    >
                      Nee
                    </Toevoegen2SelectItem>
                  </Toevoegen2SelectUsedWrapper>
                </Toevoegen2UsedDropdownWrapper>
              </Toevoegen2UsedWrapper>
            )}
            {usedFiles.file2 === null ? (
              <Toevoegen2UsedWrapper>
                {width > breakpoints.md ? (
                  <>
                    <Toevoegen2UsedPhotoWrapper>
                      <Toevoegen2AddUsedPhotoWrapper>
                        <AddPhotoAlternateIcon style={{ color: styles.primaryColor }} />
                        <Toevoegen2AddPhotoLabel htmlFor="file1" color={styles.primaryColor}>
                          Toevoegen
                        </Toevoegen2AddPhotoLabel>
                        <Toevoegen2AddPhotoInput
                          type="file"
                          name="file1"
                          id="file1"
                          onChange={(event) => {
                            setUsedFiles((prev) => ({
                              ...prev,
                              file2: URL.createObjectURL(event.target.files[0]),
                            }));
                            setFile2Image(event.target.files[0]);
                            setButtonActive(true);
                          }}
                        />
                      </Toevoegen2AddUsedPhotoWrapper>
                      <Toevoegen2UsedP color={styles.greyNormal}>
                        Let op! Zorg dat de velg van voren gefotografeerd is en in het midden gepositioneerd is. Je kunt
                        maximaal 1 foto per velg uploaden.
                      </Toevoegen2UsedP>
                    </Toevoegen2UsedPhotoWrapper>
                    <Toevoegen2UsedDropdownWrapper marginTop="20px">
                      <Toevoegen2AddUsedSubtitle marginTop="60px">Wiel 2</Toevoegen2AddUsedSubtitle>
                      <Toevoegen2ISelectItem
                        value={gebruiktDropDownValues.dropdown2Value}
                        onChange={(event) => {
                          setTouchedGebruiktProfileFields((prev) => ({ ...prev, dropdown2: true }));
                          setGebruiktDropdownValues((prev) => ({
                            ...prev,
                            dropdown2Value: event.target.value,
                          }));
                          setButtonActive(true);
                        }}
                        color={touchedGebruiktProfileFields.dropdown2 ? '#000' : styles.greyNormal}
                      >
                        {ProfileDepths.map((option) => (
                          <option key={option.value} value={option.value}>
                            {`${option.label} mm`}
                          </option>
                        ))}
                      </Toevoegen2ISelectItem>

                      <Toevoegen2Dotcode
                        focus={styles.primaryColor}
                        value={dotCode.dot2}
                        name="dot2"
                        min={0}
                        onChange={handleDotCode}
                        type="number"
                        placeholder="DOT code"
                      />

                      <Toevoegen2Subtitle>Schade?</Toevoegen2Subtitle>
                      <Toevoegen2SelectUsedWrapper>
                        <Toevoegen2SelectItem
                          backgroundColor={usedDropdown.weil2 === 0 ? styles.primaryColorContact : styles.white}
                          color={usedDropdown.weil2 === 0 ? styles.primaryColor : '#000'}
                          fw={usedDropdown.weil2 === 0 ? '700' : '500'}
                          borderColor={usedDropdown.weil2 === 0 ? styles.primaryColor : styles.greyNormal}
                          style={{ borderRadius: '10px 0px 0px 10px' }}
                          onClick={() => {
                            setUsedDropdown((prev) => ({ ...prev, weil2: 0 }));
                            setButtonActive(true);
                          }}
                        >
                          Ja
                        </Toevoegen2SelectItem>
                        <Toevoegen2SelectItem
                          backgroundColor={usedDropdown.weil2 === 1 ? styles.primaryColorContact : styles.white}
                          color={usedDropdown.weil2 === 1 ? styles.primaryColor : '#000'}
                          fw={usedDropdown.weil2 === 1 ? '700' : '500'}
                          borderColor={usedDropdown.weil2 === 1 ? styles.primaryColor : styles.greyNormal}
                          style={{ borderRadius: '0px 10px 10px 0px' }}
                          onClick={() => {
                            setUsedDropdown((prev) => ({ ...prev, weil2: 1 }));

                            setButtonActive(true);
                          }}
                        >
                          Nee
                        </Toevoegen2SelectItem>
                      </Toevoegen2SelectUsedWrapper>
                    </Toevoegen2UsedDropdownWrapper>
                  </>
                ) : (
                  <>
                    <Toevoegen2UsedPhotoWrapper>
                      <Toevoegen2TitleMinWrapper>
                        <Toevoegen2AddUsedSubtitle marginTop="20px">Wiel 2</Toevoegen2AddUsedSubtitle>
                      </Toevoegen2TitleMinWrapper>
                      <Toevoegen2AddUsedPhotoWrapper>
                        <AddPhotoAlternateIcon style={{ color: styles.primaryColor }} />
                        <Toevoegen2AddPhotoLabel htmlFor="file1" color={styles.primaryColor}>
                          Toevoegen
                        </Toevoegen2AddPhotoLabel>
                        <Toevoegen2AddPhotoInput
                          type="file"
                          name="file1"
                          id="file1"
                          onChange={(event) => {
                            setUsedFiles((prev) => ({
                              ...prev,
                              file2: URL.createObjectURL(event.target.files[0]),
                            }));
                            setFile2Image(event.target.files[0]);
                            setButtonActive(true);
                          }}
                        />
                      </Toevoegen2AddUsedPhotoWrapper>
                      <Toevoegen2UsedP color={styles.greyNormal} style={{ marginBottom: '-10px' }}>
                        Let op! Zorg dat de velg van voren gefotografeerd is en in het midden gepositioneerd is. Je kunt
                        maximaal 1 foto per velg uploaden.
                      </Toevoegen2UsedP>
                    </Toevoegen2UsedPhotoWrapper>
                    <Toevoegen2UsedDropdownWrapper marginTop="20px">
                      <Toevoegen2ISelectItem
                        value={gebruiktDropDownValues.dropdown2Value}
                        onChange={(event) => {
                          setTouchedGebruiktProfileFields((prev) => ({ ...prev, dropdown2: true }));
                          setGebruiktDropdownValues((prev) => ({
                            ...prev,
                            dropdown2Value: event.target.value,
                          }));
                          setButtonActive(true);
                        }}
                        color={touchedGebruiktProfileFields.dropdown2 ? '#000' : styles.greyNormal}
                      >
                        {ProfileDepths.map((option) => (
                          <option key={option.value} value={option.value}>
                            {`${option.label} mm`}
                          </option>
                        ))}
                      </Toevoegen2ISelectItem>

                      <Toevoegen2Dotcode
                        focus={styles.primaryColor}
                        value={dotCode.dot2}
                        name="dot2"
                        min={0}
                        onChange={handleDotCode}
                        type="number"
                        placeholder="DOT code"
                      />

                      <Toevoegen2Subtitle>Schade?</Toevoegen2Subtitle>
                      <Toevoegen2SelectUsedWrapper>
                        <Toevoegen2SelectItem
                          backgroundColor={usedDropdown.weil2 === 0 ? styles.primaryColorContact : styles.white}
                          color={usedDropdown.weil2 === 0 ? styles.primaryColor : '#000'}
                          borderColor={usedDropdown.weil2 === 0 ? styles.primaryColor : styles.greyNormal}
                          fw={usedDropdown.weil2 === 0 ? '700' : '500'}
                          style={{ borderRadius: '10px 0px 0px 10px' }}
                          onClick={() => {
                            setUsedDropdown((prev) => ({ ...prev, weil2: 0 }));
                            setButtonActive(true);
                          }}
                        >
                          Ja
                        </Toevoegen2SelectItem>
                        <Toevoegen2SelectItem
                          backgroundColor={usedDropdown.weil2 === 1 ? styles.primaryColorContact : styles.white}
                          color={usedDropdown.weil2 === 1 ? styles.primaryColor : '#000'}
                          fw={usedDropdown.weil2 === 1 ? '700' : '500'}
                          borderColor={usedDropdown.weil2 === 1 ? styles.primaryColor : styles.greyNormal}
                          style={{ borderRadius: '0px 10px 10px 0px' }}
                          onClick={() => {
                            setUsedDropdown((prev) => ({ ...prev, weil2: 1 }));
                            setButtonActive(true);
                          }}
                        >
                          Nee
                        </Toevoegen2SelectItem>
                      </Toevoegen2SelectUsedWrapper>
                    </Toevoegen2UsedDropdownWrapper>
                  </>
                )}
              </Toevoegen2UsedWrapper>
            ) : (
              <Toevoegen2UsedWrapper>
                {width > breakpoints.md ? (
                  <Toevoegen2UploadedPhotoWrapper>
                    <Toevoegen2UploadedImg src={usedFiles.file2} alt="" />
                    <Toevoegen2UploadedImgFix backgroundColor={styles.primaryColorContact}>
                      <EditIcon style={{ color: styles.primaryColor }} />
                      <Toevoegen2AddPhotoLabel htmlFor="file1" color={styles.primaryColor}>
                        Afbeelding wijzigen
                      </Toevoegen2AddPhotoLabel>
                      <Toevoegen2AddPhotoInput
                        type="file"
                        name="file1"
                        id="file1"
                        onChange={(event) => {
                          setUsedFiles((prev) => ({
                            ...prev,
                            file2: URL.createObjectURL(event.target.files[0]),
                          }));
                          setFile2Image(event.target.files[0]);
                          setButtonActive(true);
                        }}
                      />
                    </Toevoegen2UploadedImgFix>
                  </Toevoegen2UploadedPhotoWrapper>
                ) : (
                  <>
                    <Toevoegen2TitleMinWrapper>
                      <Toevoegen2AddUsedSubtitle marginTop="0px">Wiel 2</Toevoegen2AddUsedSubtitle>
                    </Toevoegen2TitleMinWrapper>
                    <Toevoegen2UploadedPhotoWrapper>
                      <Toevoegen2UploadedImg src={usedFiles.file2} alt="" />
                      <Toevoegen2UploadedImgFix backgroundColor={styles.primaryColorContact}>
                        <EditIcon style={{ color: styles.primaryColor }} />
                        <Toevoegen2AddPhotoLabel htmlFor="file1" color={styles.primaryColor}>
                          Afbeelding wijzigen
                        </Toevoegen2AddPhotoLabel>
                        <Toevoegen2AddPhotoInput
                          type="file"
                          name="file1"
                          id="file1"
                          onChange={(event) => {
                            setUsedFiles((prev) => ({
                              ...prev,
                              file2: URL.createObjectURL(event.target.files[0]),
                            }));
                            setFile2Image(event.target.files[0]);
                            setButtonActive(true);
                          }}
                        />
                      </Toevoegen2UploadedImgFix>
                    </Toevoegen2UploadedPhotoWrapper>
                  </>
                )}
                <Toevoegen2UsedDropdownWrapper marginTop="0px">
                  {width > breakpoints.md && (
                    <Toevoegen2AddUsedSubtitle marginTop="0px">Wiel 2</Toevoegen2AddUsedSubtitle>
                  )}
                  <Toevoegen2ISelectItem
                    value={gebruiktDropDownValues.dropdown2Value}
                    onChange={(event) => {
                      setTouchedGebruiktProfileFields((prev) => ({ ...prev, dropdown2: true }));
                      setGebruiktDropdownValues((prev) => ({ ...prev, dropdown2Value: event.target.value }));
                      setButtonActive(true);
                    }}
                    color={touchedGebruiktProfileFields.dropdown2 ? '#000' : styles.greyNormal}
                  >
                    {ProfileDepths.map((option) => (
                      <option key={option.value} value={option.value}>
                        {`${option.label} mm`}
                      </option>
                    ))}
                  </Toevoegen2ISelectItem>

                  <Toevoegen2Dotcode
                    focus={styles.primaryColor}
                    value={dotCode.dot2}
                    name="dot2"
                    min={0}
                    onChange={handleDotCode}
                    type="number"
                    placeholder="DOT code"
                  />

                  <Toevoegen2Subtitle>Schade?</Toevoegen2Subtitle>
                  <Toevoegen2SelectUsedWrapper>
                    <Toevoegen2SelectItem
                      backgroundColor={usedDropdown.weil2 === 0 ? styles.primaryColorContact : styles.white}
                      color={usedDropdown.weil2 === 0 ? styles.primaryColor : '#000'}
                      borderColor={usedDropdown.weil2 === 0 ? styles.primaryColor : styles.greyNormal}
                      fw={usedDropdown.weil2 === 0 ? '700' : '500'}
                      style={{ borderRadius: '10px 0px 0px 10px' }}
                      onClick={() => {
                        setUsedDropdown((prev) => ({ ...prev, weil2: 0 }));
                        setButtonActive(true);
                      }}
                    >
                      Ja
                    </Toevoegen2SelectItem>
                    <Toevoegen2SelectItem
                      backgroundColor={usedDropdown.weil2 === 1 ? styles.primaryColorContact : styles.white}
                      color={usedDropdown.weil2 === 1 ? styles.primaryColor : '#000'}
                      fw={usedDropdown.weil2 === 1 ? '700' : '500'}
                      borderColor={usedDropdown.weil2 === 1 ? styles.primaryColor : styles.greyNormal}
                      style={{ borderRadius: '0px 10px 10px 0px' }}
                      onClick={() => {
                        setUsedDropdown((prev) => ({ ...prev, weil2: 1 }));
                        setButtonActive(true);
                      }}
                    >
                      Nee
                    </Toevoegen2SelectItem>
                  </Toevoegen2SelectUsedWrapper>
                </Toevoegen2UsedDropdownWrapper>
              </Toevoegen2UsedWrapper>
            )}
            {usedFiles.file3 === null ? (
              <Toevoegen2UsedWrapper>
                {width > breakpoints.md ? (
                  <>
                    <Toevoegen2UsedPhotoWrapper>
                      <Toevoegen2AddUsedPhotoWrapper>
                        <AddPhotoAlternateIcon style={{ color: styles.primaryColor }} />
                        <Toevoegen2AddPhotoLabel htmlFor="file2" color={styles.primaryColor}>
                          Toevoegen
                        </Toevoegen2AddPhotoLabel>
                        <Toevoegen2AddPhotoInput
                          type="file"
                          name="file2"
                          id="file2"
                          onChange={(event) => {
                            setUsedFiles((prev) => ({
                              ...prev,
                              file3: URL.createObjectURL(event.target.files[0]),
                            }));
                            setFile3Image(event.target.files[0]);
                            setButtonActive(true);
                          }}
                        />
                      </Toevoegen2AddUsedPhotoWrapper>
                      <Toevoegen2UsedP color={styles.greyNormal}>
                        Let op! Zorg dat de velg van voren gefotografeerd is en in het midden gepositioneerd is. Je kunt
                        maximaal 1 foto per velg uploaden.
                      </Toevoegen2UsedP>
                    </Toevoegen2UsedPhotoWrapper>
                    <Toevoegen2UsedDropdownWrapper marginTop="20px">
                      <Toevoegen2AddUsedSubtitle marginTop="60px">Wiel 3</Toevoegen2AddUsedSubtitle>
                      <Toevoegen2ISelectItem
                        value={gebruiktDropDownValues.dropdown3Value}
                        onChange={(event) => {
                          setTouchedGebruiktProfileFields((prev) => ({ ...prev, dropdown3: true }));
                          setGebruiktDropdownValues((prev) => ({
                            ...prev,
                            dropdown3Value: event.target.value,
                          }));
                          setButtonActive(true);
                        }}
                        color={touchedGebruiktProfileFields.dropdown3 ? '#000' : styles.greyNormal}
                      >
                        {ProfileDepths.map((option) => (
                          <option key={option.value} value={option.value}>
                            {`${option.label} mm`}
                          </option>
                        ))}
                      </Toevoegen2ISelectItem>

                      <Toevoegen2Dotcode
                        focus={styles.primaryColor}
                        value={dotCode.dot3}
                        name="dot3"
                        min={0}
                        onChange={handleDotCode}
                        type="number"
                        placeholder="DOT code"
                      />

                      <Toevoegen2Subtitle>Schade?</Toevoegen2Subtitle>
                      <Toevoegen2SelectUsedWrapper>
                        <Toevoegen2SelectItem
                          backgroundColor={usedDropdown.weil3 === 0 ? styles.primaryColorContact : styles.white}
                          color={usedDropdown.weil3 === 0 ? styles.primaryColor : '#000'}
                          fw={usedDropdown.weil3 === 0 ? '700' : '500'}
                          borderColor={usedDropdown.weil3 === 0 ? styles.primaryColor : styles.greyNormal}
                          style={{ borderRadius: '10px 0px 0px 10px' }}
                          onClick={() => {
                            setUsedDropdown((prev) => ({ ...prev, weil3: 0 }));
                            setButtonActive(true);
                          }}
                        >
                          Ja
                        </Toevoegen2SelectItem>
                        <Toevoegen2SelectItem
                          backgroundColor={usedDropdown.weil3 === 1 ? styles.primaryColorContact : styles.white}
                          color={usedDropdown.weil3 === 1 ? styles.primaryColor : '#000'}
                          fw={usedDropdown.weil3 === 1 ? '700' : '500'}
                          borderColor={usedDropdown.weil3 === 1 ? styles.primaryColor : styles.greyNormal}
                          style={{ borderRadius: '0px 10px 10px 0px' }}
                          onClick={() => {
                            setUsedDropdown((prev) => ({ ...prev, weil3: 1 }));
                            setButtonActive(true);
                          }}
                        >
                          Nee
                        </Toevoegen2SelectItem>
                      </Toevoegen2SelectUsedWrapper>
                    </Toevoegen2UsedDropdownWrapper>
                  </>
                ) : (
                  <>
                    <Toevoegen2UsedPhotoWrapper>
                      <Toevoegen2TitleMinWrapper>
                        <Toevoegen2AddUsedSubtitle marginTop="20px">Wiel 3</Toevoegen2AddUsedSubtitle>
                      </Toevoegen2TitleMinWrapper>
                      <Toevoegen2AddUsedPhotoWrapper>
                        <AddPhotoAlternateIcon style={{ color: styles.primaryColor }} />
                        <Toevoegen2AddPhotoLabel htmlFor="file2" color={styles.primaryColor}>
                          Toevoegen
                        </Toevoegen2AddPhotoLabel>
                        <Toevoegen2AddPhotoInput
                          type="file"
                          name="file2"
                          id="file2"
                          onChange={(event) => {
                            setUsedFiles((prev) => ({
                              ...prev,
                              file3: URL.createObjectURL(event.target.files[0]),
                            }));
                            setFile3Image(event.target.files[0]);
                            setButtonActive(true);
                          }}
                        />
                      </Toevoegen2AddUsedPhotoWrapper>
                      <Toevoegen2UsedP color={styles.greyNormal} style={{ marginBottom: '-10px' }}>
                        Let op! Zorg dat de velg van voren gefotografeerd is en in het midden gepositioneerd is. Je kunt
                        maximaal 1 foto per velg uploaden.
                      </Toevoegen2UsedP>
                    </Toevoegen2UsedPhotoWrapper>
                    <Toevoegen2UsedDropdownWrapper marginTop="20px">
                      <Toevoegen2ISelectItem
                        value={gebruiktDropDownValues.dropdown3Value}
                        onChange={(event) => {
                          setTouchedGebruiktProfileFields((prev) => ({ ...prev, dropdown3: true }));
                          setGebruiktDropdownValues((prev) => ({
                            ...prev,
                            dropdown3Value: event.target.value,
                          }));
                          setButtonActive(true);
                        }}
                        color={touchedGebruiktProfileFields.dropdown3 ? '#000' : styles.greyNormal}
                      >
                        {ProfileDepths.map((option) => (
                          <option key={option.value} value={option.value}>
                            {`${option.label} mm`}
                          </option>
                        ))}
                      </Toevoegen2ISelectItem>

                      <Toevoegen2Dotcode
                        focus={styles.primaryColor}
                        value={dotCode.dot3}
                        name="dot3"
                        min={0}
                        onChange={handleDotCode}
                        type="number"
                        placeholder="DOT code"
                      />

                      <Toevoegen2Subtitle>Schade?</Toevoegen2Subtitle>
                      <Toevoegen2SelectUsedWrapper>
                        <Toevoegen2SelectItem
                          backgroundColor={usedDropdown.weil3 === 0 ? styles.primaryColorContact : styles.white}
                          color={usedDropdown.weil3 === 0 ? styles.primaryColor : '#000'}
                          fw={usedDropdown.weil3 === 0 ? '700' : '500'}
                          borderColor={usedDropdown.weil3 === 0 ? styles.primaryColor : styles.greyNormal}
                          style={{ borderRadius: '10px 0px 0px 10px' }}
                          onClick={() => {
                            setUsedDropdown((prev) => ({ ...prev, weil3: 0 }));
                            setButtonActive(true);
                          }}
                        >
                          Ja
                        </Toevoegen2SelectItem>
                        <Toevoegen2SelectItem
                          backgroundColor={usedDropdown.weil3 === 1 ? styles.primaryColorContact : styles.white}
                          color={usedDropdown.weil3 === 1 ? styles.primaryColor : '#000'}
                          fw={usedDropdown.weil3 === 1 ? '700' : '500'}
                          borderColor={usedDropdown.weil3 === 1 ? styles.primaryColor : styles.greyNormal}
                          style={{ borderRadius: '0px 10px 10px 0px' }}
                          onClick={() => {
                            setUsedDropdown((prev) => ({ ...prev, weil3: 1 }));
                            setButtonActive(true);
                          }}
                        >
                          Nee
                        </Toevoegen2SelectItem>
                      </Toevoegen2SelectUsedWrapper>
                    </Toevoegen2UsedDropdownWrapper>
                  </>
                )}
              </Toevoegen2UsedWrapper>
            ) : (
              <Toevoegen2UsedWrapper>
                {width > breakpoints.md ? (
                  <Toevoegen2UploadedPhotoWrapper>
                    <Toevoegen2UploadedImg src={usedFiles.file3} alt="" />
                    <Toevoegen2UploadedImgFix backgroundColor={styles.primaryColorContact}>
                      <EditIcon style={{ color: styles.primaryColor }} />
                      <Toevoegen2AddPhotoLabel htmlFor="file2" color={styles.primaryColor}>
                        Afbeelding wijzigen
                      </Toevoegen2AddPhotoLabel>
                      <Toevoegen2AddPhotoInput
                        type="file"
                        name="file2"
                        id="file2"
                        onChange={(event) => {
                          setUsedFiles((prev) => ({
                            ...prev,
                            file3: URL.createObjectURL(event.target.files[0]),
                          }));
                          setFile3Image(event.target.files[0]);
                          setButtonActive(true);
                        }}
                      />
                    </Toevoegen2UploadedImgFix>
                  </Toevoegen2UploadedPhotoWrapper>
                ) : (
                  <>
                    <Toevoegen2TitleMinWrapper>
                      <Toevoegen2AddUsedSubtitle marginTop="0px">Wiel 3</Toevoegen2AddUsedSubtitle>
                    </Toevoegen2TitleMinWrapper>
                    <Toevoegen2UploadedPhotoWrapper>
                      <Toevoegen2UploadedImg src={usedFiles.file3} alt="" />
                      <Toevoegen2UploadedImgFix backgroundColor={styles.primaryColorContact}>
                        <EditIcon style={{ color: styles.primaryColor }} />
                        <Toevoegen2AddPhotoLabel htmlFor="file2" color={styles.primaryColor}>
                          Afbeelding wijzigen
                        </Toevoegen2AddPhotoLabel>
                        <Toevoegen2AddPhotoInput
                          type="file"
                          name="file2"
                          id="file2"
                          onChange={(event) => {
                            setUsedFiles((prev) => ({
                              ...prev,
                              file3: URL.createObjectURL(event.target.files[0]),
                            }));
                            setFile3Image(event.target.files[0]);
                            setButtonActive(true);
                          }}
                        />
                      </Toevoegen2UploadedImgFix>
                    </Toevoegen2UploadedPhotoWrapper>
                  </>
                )}
                <Toevoegen2UsedDropdownWrapper marginTop="0px">
                  {width > breakpoints.md && (
                    <Toevoegen2AddUsedSubtitle marginTop="0px">Wiel 3</Toevoegen2AddUsedSubtitle>
                  )}
                  <Toevoegen2ISelectItem
                    value={gebruiktDropDownValues.dropdown3Value}
                    onChange={(event) => {
                      setTouchedGebruiktProfileFields((prev) => ({ ...prev, dropdown3: true }));
                      setGebruiktDropdownValues((prev) => ({ ...prev, dropdown3Value: event.target.value }));
                      setButtonActive(true);
                    }}
                    color={touchedGebruiktProfileFields.dropdown3 ? '#000' : styles.greyNormal}
                  >
                    {ProfileDepths.map((option) => (
                      <option key={option.value} value={option.value}>
                        {`${option.label} mm`}
                      </option>
                    ))}
                  </Toevoegen2ISelectItem>

                  <Toevoegen2Dotcode
                    focus={styles.primaryColor}
                    value={dotCode.dot3}
                    name="dot3"
                    min={0}
                    onChange={handleDotCode}
                    type="number"
                    placeholder="DOT code"
                  />

                  <Toevoegen2Subtitle>Schade?</Toevoegen2Subtitle>
                  <Toevoegen2SelectUsedWrapper>
                    <Toevoegen2SelectItem
                      backgroundColor={usedDropdown.weil3 === 0 ? styles.primaryColorContact : styles.white}
                      color={usedDropdown.weil3 === 0 ? styles.primaryColor : '#000'}
                      fw={usedDropdown.weil3 === 0 ? '700' : '500'}
                      borderColor={usedDropdown.weil3 === 0 ? styles.primaryColor : styles.greyNormal}
                      style={{ borderRadius: '10px 0px 0px 10px' }}
                      onClick={() => {
                        setUsedDropdown((prev) => ({ ...prev, weil3: 0 }));
                        setButtonActive(true);
                      }}
                    >
                      Ja
                    </Toevoegen2SelectItem>
                    <Toevoegen2SelectItem
                      backgroundColor={usedDropdown.weil3 === 1 ? styles.primaryColorContact : styles.white}
                      color={usedDropdown.weil3 === 1 ? styles.primaryColor : '#000'}
                      fw={usedDropdown.weil3 === 1 ? '700' : '500'}
                      borderColor={usedDropdown.weil3 === 1 ? styles.primaryColor : styles.greyNormal}
                      style={{ borderRadius: '0px 10px 10px 0px' }}
                      onClick={() => {
                        setUsedDropdown((prev) => ({ ...prev, weil3: 1 }));
                        setButtonActive(true);
                      }}
                    >
                      Nee
                    </Toevoegen2SelectItem>
                  </Toevoegen2SelectUsedWrapper>
                </Toevoegen2UsedDropdownWrapper>
              </Toevoegen2UsedWrapper>
            )}
            {usedFiles.file4 === null ? (
              <Toevoegen2UsedWrapper>
                {width > breakpoints.md ? (
                  <>
                    <Toevoegen2UsedPhotoWrapper>
                      <Toevoegen2AddUsedPhotoWrapper>
                        <AddPhotoAlternateIcon style={{ color: styles.primaryColor }} />
                        <Toevoegen2AddPhotoLabel htmlFor="file3" color={styles.primaryColor}>
                          Toevoegen
                        </Toevoegen2AddPhotoLabel>
                        <Toevoegen2AddPhotoInput
                          type="file"
                          name="file3"
                          id="file3"
                          onChange={(event) => {
                            setUsedFiles((prev) => ({
                              ...prev,
                              file4: URL.createObjectURL(event.target.files[0]),
                            }));
                            setFile4Image(event.target.files[0]);
                            setButtonActive(true);
                          }}
                        />
                      </Toevoegen2AddUsedPhotoWrapper>
                      <Toevoegen2UsedP color={styles.greyNormal}>
                        Let op! Zorg dat de velg van voren gefotografeerd is en in het midden gepositioneerd is. Je kunt
                        maximaal 1 foto per velg uploaden.
                      </Toevoegen2UsedP>
                    </Toevoegen2UsedPhotoWrapper>
                    <Toevoegen2UsedDropdownWrapper marginTop="20px">
                      <Toevoegen2AddUsedSubtitle marginTop="60px">Wiel 4</Toevoegen2AddUsedSubtitle>
                      <Toevoegen2ISelectItem
                        value={gebruiktDropDownValues.dropdown4Value}
                        onChange={(event) => {
                          setTouchedGebruiktProfileFields((prev) => ({ ...prev, dropdown4: true }));
                          setGebruiktDropdownValues((prev) => ({
                            ...prev,
                            dropdown4Value: event.target.value,
                          }));
                          setButtonActive(true);
                        }}
                        color={touchedGebruiktProfileFields.dropdown4 ? '#000' : styles.greyNormal}
                      >
                        {ProfileDepths.map((option) => (
                          <option key={option.value} value={option.value}>
                            {`${option.label} mm`}
                          </option>
                        ))}
                      </Toevoegen2ISelectItem>

                      <Toevoegen2Dotcode
                        focus={styles.primaryColor}
                        value={dotCode.dot4}
                        name="dot4"
                        min={0}
                        onChange={handleDotCode}
                        type="number"
                        placeholder="DOT code"
                      />

                      <Toevoegen2Subtitle>Schade?</Toevoegen2Subtitle>
                      <Toevoegen2SelectUsedWrapper>
                        <Toevoegen2SelectItem
                          backgroundColor={usedDropdown.weil4 === 0 ? styles.primaryColorContact : styles.white}
                          color={usedDropdown.weil4 === 0 ? styles.primaryColor : '#000'}
                          fw={usedDropdown.weil4 === 0 ? '700' : '500'}
                          borderColor={usedDropdown.weil4 === 0 ? styles.primaryColor : styles.greyNormal}
                          style={{ borderRadius: '10px 0px 0px 10px' }}
                          onClick={() => {
                            setUsedDropdown((prev) => ({ ...prev, weil4: 0 }));
                            setButtonActive(true);
                          }}
                        >
                          Ja
                        </Toevoegen2SelectItem>
                        <Toevoegen2SelectItem
                          backgroundColor={usedDropdown.weil4 === 1 ? styles.primaryColorContact : styles.white}
                          color={usedDropdown.weil4 === 1 ? styles.primaryColor : '#000'}
                          fw={usedDropdown.weil4 === 1 ? '700' : '500'}
                          borderColor={usedDropdown.weil4 === 1 ? styles.primaryColor : styles.greyNormal}
                          style={{ borderRadius: '0px 10px 10px 0px' }}
                          onClick={() => {
                            setUsedDropdown((prev) => ({ ...prev, weil4: 1 }));
                            setButtonActive(true);
                          }}
                        >
                          Nee
                        </Toevoegen2SelectItem>
                      </Toevoegen2SelectUsedWrapper>
                    </Toevoegen2UsedDropdownWrapper>
                  </>
                ) : (
                  <>
                    <Toevoegen2UsedPhotoWrapper>
                      <Toevoegen2TitleMinWrapper>
                        <Toevoegen2AddUsedSubtitle marginTop="20px">Wiel 4</Toevoegen2AddUsedSubtitle>
                      </Toevoegen2TitleMinWrapper>
                      <Toevoegen2AddUsedPhotoWrapper>
                        <AddPhotoAlternateIcon style={{ color: styles.primaryColor }} />
                        <Toevoegen2AddPhotoLabel htmlFor="file3" color={styles.primaryColor}>
                          Toevoegen
                        </Toevoegen2AddPhotoLabel>
                        <Toevoegen2AddPhotoInput
                          type="file"
                          name="file3"
                          id="file3"
                          onChange={(event) => {
                            setUsedFiles((prev) => ({
                              ...prev,
                              file4: URL.createObjectURL(event.target.files[0]),
                            }));
                            setFile4Image(event.target.files[0]);
                            setButtonActive(true);
                          }}
                        />
                      </Toevoegen2AddUsedPhotoWrapper>
                      <Toevoegen2UsedP color={styles.greyNormal} style={{ marginBottom: '-10px' }}>
                        Let op! Zorg dat de velg van voren gefotografeerd is en in het midden gepositioneerd is. Je kunt
                        maximaal 1 foto per velg uploaden.
                      </Toevoegen2UsedP>
                    </Toevoegen2UsedPhotoWrapper>
                    <Toevoegen2UsedDropdownWrapper marginTop="20px">
                      <Toevoegen2ISelectItem
                        value={gebruiktDropDownValues.dropdown4Value}
                        onChange={(event) => {
                          setTouchedGebruiktProfileFields((prev) => ({ ...prev, dropdown4: true }));
                          setGebruiktDropdownValues((prev) => ({
                            ...prev,
                            dropdown4Value: event.target.value,
                          }));
                          setButtonActive(true);
                        }}
                        color={touchedGebruiktProfileFields.dropdown4 ? '#000' : styles.greyNormal}
                      >
                        {ProfileDepths.map((option) => (
                          <option key={option.value} value={option.value}>
                            {`${option.label} mm`}
                          </option>
                        ))}
                      </Toevoegen2ISelectItem>

                      <Toevoegen2Dotcode
                        focus={styles.primaryColor}
                        value={dotCode.dot4}
                        name="dot4"
                        min={0}
                        onChange={handleDotCode}
                        type="number"
                        placeholder="DOT code"
                      />

                      <Toevoegen2Subtitle>Schade?</Toevoegen2Subtitle>
                      <Toevoegen2SelectUsedWrapper>
                        <Toevoegen2SelectItem
                          backgroundColor={usedDropdown.weil4 === 0 ? styles.primaryColorContact : styles.white}
                          color={usedDropdown.weil4 === 0 ? styles.primaryColor : '#000'}
                          fw={usedDropdown.weil4 === 0 ? '700' : '500'}
                          borderColor={usedDropdown.weil4 === 0 ? styles.primaryColor : styles.greyNormal}
                          style={{ borderRadius: '10px 0px 0px 10px' }}
                          onClick={() => {
                            setUsedDropdown((prev) => ({ ...prev, weil4: 0 }));
                            setButtonActive(true);
                          }}
                        >
                          Ja
                        </Toevoegen2SelectItem>
                        <Toevoegen2SelectItem
                          backgroundColor={usedDropdown.weil4 === 1 ? styles.primaryColorContact : styles.white}
                          color={usedDropdown.weil4 === 1 ? styles.primaryColor : '#000'}
                          fw={usedDropdown.weil4 === 1 ? '700' : '500'}
                          borderColor={usedDropdown.weil4 === 1 ? styles.primaryColor : styles.greyNormal}
                          style={{ borderRadius: '0px 10px 10px 0px' }}
                          onClick={() => {
                            setUsedDropdown((prev) => ({ ...prev, weil4: 1 }));
                            setButtonActive(true);
                          }}
                        >
                          Nee
                        </Toevoegen2SelectItem>
                      </Toevoegen2SelectUsedWrapper>
                    </Toevoegen2UsedDropdownWrapper>
                  </>
                )}
              </Toevoegen2UsedWrapper>
            ) : (
              <Toevoegen2UsedWrapper>
                {width > breakpoints.md ? (
                  <Toevoegen2UploadedPhotoWrapper>
                    <Toevoegen2UploadedImg src={usedFiles.file4} alt="" />
                    <Toevoegen2UploadedImgFix backgroundColor={styles.primaryColorContact}>
                      <EditIcon style={{ color: styles.primaryColor }} />
                      <Toevoegen2AddPhotoLabel htmlFor="file3" color={styles.primaryColor}>
                        Afbeelding wijzigen
                      </Toevoegen2AddPhotoLabel>
                      <Toevoegen2AddPhotoInput
                        type="file"
                        name="file3"
                        id="file3"
                        onChange={(event) => {
                          setUsedFiles((prev) => ({
                            ...prev,
                            file4: URL.createObjectURL(event.target.files[0]),
                          }));
                          setFile4Image(event.target.files[0]);
                          setButtonActive(true);
                        }}
                      />
                    </Toevoegen2UploadedImgFix>
                  </Toevoegen2UploadedPhotoWrapper>
                ) : (
                  <>
                    <Toevoegen2TitleMinWrapper>
                      <Toevoegen2AddUsedSubtitle marginTop="0px">Wiel 4</Toevoegen2AddUsedSubtitle>
                    </Toevoegen2TitleMinWrapper>
                    <Toevoegen2UploadedPhotoWrapper>
                      <Toevoegen2UploadedImg src={usedFiles.file4} alt="" />
                      <Toevoegen2UploadedImgFix backgroundColor={styles.primaryColorContact}>
                        <EditIcon style={{ color: styles.primaryColor }} />
                        <Toevoegen2AddPhotoLabel htmlFor="file3" color={styles.primaryColor}>
                          Afbeelding wijzigen
                        </Toevoegen2AddPhotoLabel>
                        <Toevoegen2AddPhotoInput
                          type="file"
                          name="file3"
                          id="file3"
                          onChange={(event) => {
                            setUsedFiles((prev) => ({
                              ...prev,
                              file4: URL.createObjectURL(event.target.files[0]),
                            }));
                            setFile4Image(event.target.files[0]);
                            setButtonActive(true);
                          }}
                        />
                      </Toevoegen2UploadedImgFix>
                    </Toevoegen2UploadedPhotoWrapper>
                  </>
                )}
                <Toevoegen2UsedDropdownWrapper marginTop="0px">
                  {width > breakpoints.md && (
                    <Toevoegen2AddUsedSubtitle marginTop="0px">Wiel 4</Toevoegen2AddUsedSubtitle>
                  )}
                  <Toevoegen2ISelectItem
                    value={gebruiktDropDownValues.dropdown4Value}
                    onChange={(event) => {
                      setTouchedGebruiktProfileFields((prev) => ({ ...prev, dropdown4: true }));
                      setGebruiktDropdownValues((prev) => ({ ...prev, dropdown4Value: event.target.value }));
                      setButtonActive(true);
                    }}
                    color={touchedGebruiktProfileFields.dropdown4 ? '#000' : styles.greyNormal}
                  >
                    {ProfileDepths.map((option) => (
                      <option key={option.value} value={option.value}>
                        {`${option.label} mm`}
                      </option>
                    ))}
                  </Toevoegen2ISelectItem>

                  <Toevoegen2Dotcode
                    focus={styles.primaryColor}
                    value={dotCode.dot4}
                    name="dot4"
                    min={0}
                    onChange={handleDotCode}
                    type="number"
                    placeholder="DOT code"
                  />

                  <Toevoegen2Subtitle>Schade?</Toevoegen2Subtitle>
                  <Toevoegen2SelectUsedWrapper>
                    <Toevoegen2SelectItem
                      backgroundColor={usedDropdown.weil4 === 0 ? styles.primaryColorContact : styles.white}
                      color={usedDropdown.weil4 === 0 ? styles.primaryColor : '#000'}
                      fw={usedDropdown.weil4 === 0 ? '700' : '500'}
                      borderColor={usedDropdown.weil4 === 0 ? styles.primaryColor : styles.greyNormal}
                      style={{ borderRadius: '10px 0px 0px 10px' }}
                      onClick={() => {
                        setUsedDropdown((prev) => ({ ...prev, weil4: 0 }));
                        setButtonActive(true);
                      }}
                    >
                      Ja
                    </Toevoegen2SelectItem>
                    <Toevoegen2SelectItem
                      backgroundColor={usedDropdown.weil4 === 1 ? styles.primaryColorContact : styles.white}
                      color={usedDropdown.weil4 === 1 ? styles.primaryColor : '#000'}
                      fw={usedDropdown.weil4 === 1 ? '700' : '500'}
                      borderColor={usedDropdown.weil4 === 1 ? styles.primaryColor : styles.greyNormal}
                      style={{ borderRadius: '0px 10px 10px 0px' }}
                      onClick={() => {
                        setUsedDropdown((prev) => ({ ...prev, weil4: 1 }));
                        setButtonActive(true);
                      }}
                    >
                      Nee
                    </Toevoegen2SelectItem>
                  </Toevoegen2SelectUsedWrapper>
                </Toevoegen2UsedDropdownWrapper>
              </Toevoegen2UsedWrapper>
            )}
          </>
        ) : (
          isSelected2 === 0 && (
            <>
              {file === null ? (
                <>
                  <Toevoegen2AddPhotoWrapper>
                    <AddPhotoAlternateIcon style={{ color: styles.primaryColor }} />
                    <Toevoegen2AddPhotoLabel htmlFor="file" color={styles.primaryColor}>
                      Toevoegen
                    </Toevoegen2AddPhotoLabel>
                    <Toevoegen2AddPhotoInput
                      type="file"
                      name="file"
                      id="file"
                      onChange={(event) => handlePhotoChange(event)}
                    />
                  </Toevoegen2AddPhotoWrapper>
                  <Toevoegen2InputP color={styles.greyNormal}>
                    Let op! Zorg dat de velg van voren gefotografeerd is en in het midden gepositioneerd is. Je kunt
                    maximaal 1 foto uploaden.
                  </Toevoegen2InputP>
                </>
              ) : (
                <Toevoegen2UploadedPhotoWrapper style={{ marginTop: '35px', marginBottom: '30px' }}>
                  <Toevoegen2UploadedImg src={file} alt="" />
                  <Toevoegen2UploadedImgFix backgroundColor={styles.primaryColorContact}>
                    <EditIcon style={{ color: styles.primaryColor }} />
                    <Toevoegen2AddPhotoLabel htmlFor="file" color={styles.primaryColor}>
                      Afbeelding wijzigen
                    </Toevoegen2AddPhotoLabel>
                    <Toevoegen2AddPhotoInput
                      type="file"
                      name="file"
                      id="file"
                      onChange={(event) => handlePhotoChange(event)}
                    />
                  </Toevoegen2UploadedImgFix>
                </Toevoegen2UploadedPhotoWrapper>
              )}
              <Toevoegen2InputWrapper color={styles.greyNormal}>
                <Toevoegen2SpecsWrapper>
                  <Toevoegen2ISelectItem
                    value={newAndSlightlyUsedDropdownValues.dropdown1Value}
                    onChange={(event) => {
                      setTouchedNewAndSlightlyUsedProfileFields((prev) => ({ ...prev, dropdown1: true }));
                      setNewAndSlightlyUsedDropdownValues((prev) => ({ ...prev, dropdown1Value: event.target.value }));
                      setButtonActive(true);
                    }}
                    color={touchedNewAndSlightlyUsedProfileFields.dropdown1 ? '#000' : styles.greyNormal}
                  >
                    {ProfileDepths.map((option) => (
                      <option key={option.value} value={option.value}>
                        {`${option.label} mm`}
                      </option>
                    ))}
                  </Toevoegen2ISelectItem>

                  <Toevoegen2Dotcode
                    focus={styles.primaryColor}
                    value={dotCode.dot1}
                    name="dot1"
                    min={0}
                    onChange={handleDotCode}
                    type="number"
                    placeholder="DOT code"
                  />
                </Toevoegen2SpecsWrapper>

                <Toevoegen2SpecsWrapper>
                  <Toevoegen2ISelectItem
                    value={newAndSlightlyUsedDropdownValues.dropdown2Value}
                    onChange={(event) => {
                      setTouchedNewAndSlightlyUsedProfileFields((prev) => ({ ...prev, dropdown2: true }));
                      setNewAndSlightlyUsedDropdownValues((prev) => ({ ...prev, dropdown2Value: event.target.value }));
                      setButtonActive(true);
                    }}
                    color={touchedNewAndSlightlyUsedProfileFields.dropdown2 ? '#000' : styles.greyNormal}
                  >
                    {ProfileDepths.map((option) => (
                      <option key={option.value} value={option.value}>
                        {`${option.label} mm`}
                      </option>
                    ))}
                  </Toevoegen2ISelectItem>

                  <Toevoegen2Dotcode
                    focus={styles.primaryColor}
                    value={dotCode.dot2}
                    name="dot2"
                    min={0}
                    onChange={handleDotCode}
                    type="number"
                    placeholder="DOT code"
                  />
                </Toevoegen2SpecsWrapper>

                <Toevoegen2SpecsWrapper>
                  <Toevoegen2ISelectItem
                    value={newAndSlightlyUsedDropdownValues.dropdown3Value}
                    onChange={(event) => {
                      setTouchedNewAndSlightlyUsedProfileFields((prev) => ({ ...prev, dropdown3: true }));
                      setNewAndSlightlyUsedDropdownValues((prev) => ({ ...prev, dropdown3Value: event.target.value }));

                      setButtonActive(true);
                    }}
                    color={touchedNewAndSlightlyUsedProfileFields.dropdown3 ? '#000' : styles.greyNormal}
                  >
                    {ProfileDepths.map((option) => (
                      <option key={option.value} value={option.value}>
                        {`${option.label} mm`}
                      </option>
                    ))}
                  </Toevoegen2ISelectItem>

                  <Toevoegen2Dotcode
                    focus={styles.primaryColor}
                    value={dotCode.dot3}
                    name="dot3"
                    min={0}
                    onChange={handleDotCode}
                    type="number"
                    placeholder="DOT code"
                  />
                </Toevoegen2SpecsWrapper>

                <Toevoegen2SpecsWrapper>
                  <Toevoegen2ISelectItem
                    value={newAndSlightlyUsedDropdownValues.dropdown4Value}
                    onChange={(event) => {
                      setTouchedNewAndSlightlyUsedProfileFields((prev) => ({ ...prev, dropdown4: true }));
                      setNewAndSlightlyUsedDropdownValues((prev) => ({ ...prev, dropdown4Value: event.target.value }));
                      setButtonActive(true);
                    }}
                    color={touchedNewAndSlightlyUsedProfileFields.dropdown4 ? '#000' : styles.greyNormal}
                  >
                    {ProfileDepths.map((option) => (
                      <option key={option.value} value={option.value}>
                        {`${option.label} mm`}
                      </option>
                    ))}
                  </Toevoegen2ISelectItem>

                  <Toevoegen2Dotcode
                    focus={styles.primaryColor}
                    value={dotCode.dot4}
                    name="dot4"
                    min={0}
                    onChange={handleDotCode}
                    type="number"
                    placeholder="DOT code"
                  />
                </Toevoegen2SpecsWrapper>
              </Toevoegen2InputWrapper>
            </>
          )
        )}
        <BewerkenButtonWrapper>
          {!isProductDetailsDataError &&
            (productDetailsData?.tyre_set.sale_status === 'INVENTORY' ||
              productDetailsData?.tyre_set.sale_status === 'FOR_SALE') && (
              <Toevoegen2Button
                disabled={!(buttonActive && areFieldsFilled)}
                hover={buttonActive && areFieldsFilled ? styles.primaryColorContactHoverContent : styles.greyNormal}
                backgroundColor={buttonActive && areFieldsFilled ? styles.primaryColor : styles.greyNormal}
                cursor={buttonActive && areFieldsFilled ? 'pointer' : 'not-allowed'}
                onClick={() => handleClick()}
              >
                Opslaan
              </Toevoegen2Button>
            )}
        </BewerkenButtonWrapper>
      </BewerkenWrapper>
    </BewerkenContainer>
  );
};

export default Bewerken;
