import React from 'react';
import { useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition, config } from 'react-transition-group';
import useWindowSize from 'app/hooks/useWindowSize';
import { breakpoints } from 'app/config/breakpoints';
import NavBar from 'app/components/navbar/navbar';

type AppLayoutPropsType = {
  children: React.ReactNode;
};

const AppLayout: React.FC<AppLayoutPropsType> = ({ children }) => {
  const { width } = useWindowSize();
  const isLargeScreen = width && width >= breakpoints.lg;

  const location = useLocation();
  const nodeRef = React.useRef(null);

  if (isLargeScreen) {
    config.disabled = true;
  } else {
    config.disabled = false;
  }

  return (
    <div className="app">
      <NavBar />

      <TransitionGroup>
        {/*
                   This is no different than other usage of
                   <CSSTransition>, just make sure to pass
                   `location` to `Switch` so it can match
                   the old location as it animates out.
                 */}
        <CSSTransition classNames="slide" timeout={300} key={location.key} noderef={nodeRef} disabled>
          {children}
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default AppLayout;
