import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const SamenvattingGeneralWrapper = styled.div`
  width: 20%;
  min-width: 354px;
  height: fit-content;
  border-radius: 8px;
  padding: 40px 35px;
  background-color: ${(props) => props.background};
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    display: none;
  }
`;

export const SamenvattingTitleWrapper = styled.div`
  font-size: 33px;
  font-weight: 700;
  margin-bottom: 37px;
`;

export const SamenvattingDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

export const SamenvattingDetailsTextWrapper = styled.div`
  font-size: 18px;
`;

export const SamenvattingDetailsValueWrapper = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

export const SamenvattingSingleBorderWrapper = styled.div`
  border-bottom: 1px solid #e6e6e6;
  margin: 27px 0px;
`;

export const SamenvattingTotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SamenvattingTotalTextWrapper = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

export const SamenvattingTotalValueWrapper = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #136ce2;
`;
