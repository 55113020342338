import styled from 'styled-components';

export const TyreLabelsGeneralContainer = styled.div`
  margin: 12px;
`;

export const TyreLabelLoaderPlaceholder = styled.div` 
height: 89vh;
display: flex;
justify-content: center;
align-items: center;
'

`;

export const TyreLabelErrorPlaceholder = styled.div``;

export const TyreLabelsPrintButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
  margin-bottom: 20px;
`;

export const TyreLabelsPrintButton = styled.button`
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: none;
  background-color: ${(props) => props.backgroundColor};
  cursor: pointer;
  height: 42px;
  width: 100%;
  max-width: 500px;
  border-radius: 8px;
  font-size: 18.5px;
  font-weight: 700;
  transition: 0.2s;

  :focus {
    background-color: ${(props) => props.backgroundColor};
  }
  :hover {
    background-color: ${(props) => props.hover};
  }
`;

export const TyreLabelsPrintIconWrapper = styled.div``;

export const TyreLabelsPrintTextWrapper = styled.div``;

export const TyreLabelsContentWrapper = styled.div`
  min-height: 750px;
`;

export const TyreLabelsContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15%;

  justify-content: center;

  @media screen and (min-width: 500px) and (max-width: 1065px) {
    gap: 5%;
  }

  @media screen and (max-width: 500px) {
    gap: 0%;
  }

  @media screen and (min-width: 1800px) {
    gap: 17%;
  }
`;

export const TyreLabelWrapper = styled.div`
  border: 2px solid black;
  line-height: 1.4;
  width: 395px;
  height: 572px;
  margin: 20px;
  background-color: #ffffff;

  @media screen and (max-width: 500px) {
    margin: 5px 0px;
  }
`;

export const TyreLabelHeaderLogoWrapper = styled.div`
  border-bottom: 1px solid black;
  height: 51px;
  display: flex;
`;

export const TyreLabelHeaderLeftLogoWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TyreLabelHeaderLogoContent = styled.img`
  width: 92%;
`;

export const TyreLabelHeaderRightTextWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 39.5px;
  background-color: black;
  color: white;
`;

export const TyreLabelVanWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  padding: 3px 8px;
  font-size: 16px;
`;

export const TyreLabelVanTextWrapper = styled.div`
  font-weight: 700;
`;

export const TyreLabelVanContentWrapper = styled.div`
  font-weight: 600;
  font-size: 15px;
  font-style: italic;
`;

export const TyreLabelAANGeneralWrapper = styled.div`
  border-top: 3px solid black;
  border-bottom: 3px solid black;
  font-size: 16px;
  padding: 3px 8px;
  display: flex;
  gap: 9px;
  font-weight: 600;
`;

export const TyreLabelAANTextWrapper = styled.div`
  font-weight: 700;
`;

export const TyreLabelAANRightContainerWrapper = styled.div`
  font-size: 15px;
`;

export const TyreLabelAANRightBuyerWrapper = styled.div`
  padding-top: 1px;
`;

export const TyreLabelAANRightTavWrapper = styled.div`
  display: flex;
  gap: 7px;
`;

export const TyreLabelAANRightTavTextWrapper = styled.div`
  font-weight: 700;
  color: black;
`;

export const TyreLabelWrapperTavContentWrapper = styled.div``;

//
export const TyreLabelContentOrangeWrapper = styled.div``;

export const TyreLabelWrapperAANBuyerCountryWrapper = styled.div`
  margin-top: 10px;
`;

export const TyreLabelZendinggevensContentWrapper = styled.div`
  padding: 3px 8px;
  font-weight: 600;
  font-size: 16px;
`;

export const TyreLabelZendinggegevensHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-weight: 700;
`;

export const TyreLabelZendinggegevensFirstWrapper = styled.div``;

export const TyreLabelZendinggegevensSecondWrapper = styled.div`
  padding-right: 25px;
  font-size: 15px;
`;

export const TyreLabelZendinggegevensReferentieWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  margin-top: 2px;
`;

export const TyreLabelZendinggegevensReferentieFirstWrapper = styled.div`
  padding-left: 17px;
  display: flex;
  gap: 6px;
`;

export const TyreLabelZendinggegevensReferentieFirstWrapperFirst = styled.div`
  font-weight: 700;
`;

export const TyreLabelZendinggegevensReferentieFirstWrapperSecond = styled.div``;

export const TyreLabelZendinggegevensReferentieSecondWrapper = styled.div`
  padding-right: 38px;
`;

export const TyreLabelZendinggegevensTotalGewichtWrapper = styled.div`
  display: flex;
  gap: 6px;
  font-size: 15px;
`;

export const TyreLabelZendinggegevensTotalGewichtFirstWrapper = styled.div`
  padding-left: 17px;
  font-weight: 700;
`;

export const TyreLabelZendinggegevensTotalGewichtSecondWrapper = styled.div``;

export const TyreLabelZendinggegevensColliWrapper = styled.div`
  display: flex;
  gap: 6px;
  margin-top: 10px;
  font-size: 15px;
`;

export const TyreLabelZendinggegevensColliTextWrapper = styled.div`
  padding-left: 17px;
`;

export const TyreLabelZendinggegevensColliContentWrapper = styled.div``;

export const TyreLabelZendinggegevensTyreNumberWrapper = styled.div`
  display: flex;
  gap: 4px;
  font-weight: 700;
  font-size: 15px;
`;

export const TyreLabelZendinggegevensTyreNumberWrapperFirst = styled.div`
  padding-left: 17px;
`;

export const TyreLAbelZendinggegevendTyreNumberWrapperSecond = styled.div``;

export const TyreLabelBarcodeWrapper = styled.div`
  height: 179px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const TyreLabelBarcode = styled.img`
  width: 68%;
`;

export const TyreLabelBarcodeCode = styled.div`
  margin-top: -24px;
  padding-left: 70px;
`;

export const TyreLabelFooterLogoWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: flex-end;
  padding-left: 6px;
`;

export const TyreLabelFooterLogo = styled.img`
  width: 52%;
`;
