import apiClient from 'app/axios/api-client';

type StatusSaleToInventoryPropsType = {
  id: number;
};

export const saleToInventoryProduct = async (data: StatusSaleToInventoryPropsType) => {
  const response = await apiClient.delete(`advertentie/${data.id}`);

  return response.data;
};
