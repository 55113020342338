import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StraightenIcon from '@mui/icons-material/Straighten';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Divider } from '@mui/material';
import Popup from '../Popup';
import Toast from '../Toast/Toast';
import Loader from '../Loader/Loader';
import styles from '../../../scss/_variables.scss';
import tireSizeFormat from '../../helpers/tireSizeFormat';
import {
  ProductDetailVersionOneBottomDetailKey,
  ProductDetailVersionOneBottomDetailValue,
  ProductDetailVersionOneBottomDetailWrapper,
  ProductDetailsVersionOneLoaderPlaceholder,
  ProductDetailVersionOneBottomLeftTitle,
  ProductDetailVersionOneMainContainer,
  ProductDetailVersionOneTopContainer,
  ProductDetailVersionOneUpLeftContainer,
  ProductDetailVersionOneUpRightContainer,
  ProductDetailVersionOneUpRightTitle,
  ProductDetailVersionOneUpRightWrapper,
  ProductDetailVersionOneWrapper,
  MobileProductDetailVersionOneContainer,
  MobileProductDetailVersionOneContent,
  MobileProductDetailVersionOne,
  MobileProductDetailVersionOneTitle,
  MobileProductDetailVersionOneSpecifications,
  MobileProductDetailVersionOneDetailKey,
  MobileProductDetailVersionOneDetailValue,
  ProductDetailVersionOneUpRightSubtitleWrapper,
  ProductDetailVersionOneUpRightSubtitleKey,
  ProductDetailVersionOneUpRightSubtitleValue,
  ProductDetailVersionOneUpRightPriceWrapper,
  ProductDetailVersionOneUpRightIconWrapper,
  ProductDetailVersionOneUpRightDateWrapper,
  ProductDetailVersionOneUpRightDateKey,
  ProductDetailVersionOneUpRightDateValue,
  ProductDetailVersionOneUpRightOptionsWrapper,
  ProductDetailVersionOneUpRightOptionSpan,
  ProductDetailVersionOneUpRightOptionItem,
  MobileSliderWrapper,
  ProductDetailVersionOneMobileIconWrapper,
  ProductDetailVersionOneUpMobileWrapper,
  ProductDetailVersionOneOptiesWrapper,
  ProductDetailVersionOneOptiesItem,
  ProductDetailVersionOnePopupBackground,
  ProductDetailVersionOneBox,
  MobileProductDetailsNavBarLogos,
  MobileProductDetailsNavBarEditLogo,
  MobileProductDetailsNavbarMoreDotsLogo,
} from './ProductDetailVersionOne.styles';
import classes from './index.module.scss';
import useWindowSize from 'app/hooks/useWindowSize';
import useProduct from 'app/hooks/useProduct';
import { breakpoints } from 'app/config/breakpoints';
import Slider from 'app/components/Slider';
import { besteldatumConverter } from 'app/helpers/utils';

const ProductDetailVersionOne = () => {
  const { width } = useWindowSize();
  const { id } = useParams();
  const isLargeScreen = width && width >= breakpoints.lg;
  const navigate = useNavigate();

  const [opties, setOpties] = useState(false);
  const [selectedOpties, setSelectedOpties] = useState('');

  const [toastId, setToastId] = useState(0);
  const [toastList, setToastList] = useState([]);

  const [nieuwImage, setNieuwImage] = useState(null);
  const [nieuwPhotoId, setNieuwPhotoId] = useState();

  const [file1Image, setFile1Image] = useState();
  const [file1ImageId, setFile1ImageId] = useState();

  const [file2Image, setFile2Image] = useState();
  const [file2ImageId, setFile2ImageId] = useState();

  const [file3Image, setFile3Image] = useState();
  const [file3ImageId, setFile3ImageId] = useState();

  const [file4Image, setFile4Image] = useState();
  const [file4ImageId, setFile4ImageId] = useState();

  const {
    data: productDetailsData,
    isLoading: isProductDetailsDataLoading,
    isError: isProductDetailsDataError,
    error: productDetailsDataError,
  } = useProduct(true, id, true);

  useEffect(() => {
    if (productDetailsData) {
      if (productDetailsData?.models[0]?.model.condition === 'Nieuw') {
        setNieuwPhotoId(productDetailsData?.models[3]?.image_url);
      } else if (
        productDetailsData?.models[0]?.model.condition === 'Gebruikt' ||
        productDetailsData?.models[0]?.model.condition === 'Zo goed als nieuw'
      ) {
        setFile1ImageId(productDetailsData?.models[0]?.image_url);
        setFile2ImageId(productDetailsData?.models[1]?.image_url);
        setFile3ImageId(productDetailsData?.models[2]?.image_url);
        setFile4ImageId(productDetailsData?.models[3]?.image_url);
      }
    }
  }, [productDetailsData]);

  useEffect(() => {
    if (nieuwPhotoId) {
      setNieuwImage(nieuwPhotoId);
    }
  }, [nieuwPhotoId]);

  useEffect(() => {
    if (file1ImageId && file2ImageId && file3ImageId && file4ImageId) {
      setFile1Image(file1ImageId);
      setFile2Image(file2ImageId);
      setFile3Image(file3ImageId);
      setFile4Image(file4ImageId);
    }
  }, [file1ImageId, file1ImageId, file1ImageId, file1ImageId]);

  const schadeValue = () => {
    if (
      !productDetailsData?.models[0]?.damage.is_damaged &&
      !productDetailsData?.models[1]?.damage.is_damaged &&
      !productDetailsData?.models[2]?.damage.is_damaged &&
      !productDetailsData?.models[3]?.damage.is_damaged
    ) {
      return 'Geen';
    } else {
      const damagedWheels = [];
      if (productDetailsData?.models[0]?.damage.is_damaged) {
        damagedWheels.push(1);
      }
      if (productDetailsData?.models[1]?.damage.is_damaged) {
        damagedWheels.push(2);
      }
      if (productDetailsData?.models[2]?.damage.is_damaged) {
        damagedWheels.push(3);
      }
      if (productDetailsData?.models[3]?.damage.is_damaged) {
        damagedWheels.push(4);
      }

      return `Wiel ${damagedWheels.map((item) => ` ${item}`)}`;
    }
  };

  const dataLeftSide = [
    {
      id: 1,
      key: 'Merk',
      value: productDetailsData?.tyre_set.brand,
    },
    {
      id: 2,
      key: 'Inchmaat',
      value: tireSizeFormat(
        productDetailsData?.models[0].model.width,
        productDetailsData?.models[0].model.height,
        productDetailsData?.models[0].model.inches
      ),
    },
    {
      id: 3,
      key: 'Seizoen',
      value:
        productDetailsData?.models[0]?.model.season === 'Summer'
          ? 'Zomer'
          : productDetailsData?.models[0]?.model.season === 'Winter'
          ? 'Winter'
          : 'All season',
    },
    {
      id: 4,
      key: 'TPMS',
      value:
        productDetailsData?.models[0]?.model.is_TPMS === 'yes'
          ? 'Ja'
          : productDetailsData?.models[0]?.model.is_TPMS === 'no'
          ? 'Nee'
          : 'Onbekend',
    },
    {
      id: 5,
      key: 'M + S',
      value: productDetailsData?.models[0]?.model.m_plus_s ? 'Ja' : 'Nee',
    },
    {
      id: 6,
      key: '3PMSF',
      value: productDetailsData?.models[0]?.model.drie_PMSF ? 'Ja' : 'Nee',
    },
    {
      id: 7,
      key: 'Runflat',
      value:
        productDetailsData?.models[0]?.model.is_runflat === 'yes'
          ? 'Ja'
          : productDetailsData?.models[0]?.model.is_runflat === 'no'
          ? 'Nee'
          : 'Onbekend',
    },
    {
      id: 8,
      key: 'Conditie',
      value: productDetailsData?.models[0]?.model.condition,
    },
    {
      id: 9,
      key: 'Schade',
      value: schadeValue(),
    },
  ];

  const profileDepth = (value) => {
    const depthMap = {
      ONETWO: '1 tot 2',
      TWOTHREE: '2 tot 3',
      THREEFOUR: '3 tot 4',
      FOURFIVE: '4 tot 5',
      FIVESIX: '5 tot 6',
      SIXSEVEN: '6 tot 7',
      SEVENEIGHT: '7 tot 8',
      EIGHTPLUS: '8+',
    };

    return `${depthMap[value]} mm` || value;
  };

  const dataRightSide = [
    {
      id: 2,
      key: 'Profieldiepte wiel 1',
      value: `${profileDepth(productDetailsData?.models[0]?.model.profile_depth)}`,
    },
    {
      id: 3,
      key: 'Profieldiepte wiel 2',
      value: `${profileDepth(productDetailsData?.models[1]?.model.profile_depth)}`,
    },
    {
      id: 4,
      key: 'Profieldiepte wiel 3',
      value: `${profileDepth(productDetailsData?.models[2]?.model.profile_depth)}`,
    },
    {
      id: 5,
      key: 'Profieldiepte wiel 4',
      value: `${profileDepth(productDetailsData?.models[3]?.model.profile_depth)}`,
    },
    {
      id: 6,
      key: 'Dot-code wiel 1',
      value: productDetailsData?.models[0].model.dot_code,
    },
    {
      id: 7,
      key: 'Dot-code wiel 2',
      value: productDetailsData?.models[1].model.dot_code,
    },
    {
      id: 8,
      key: 'Dot-code wiel 3',
      value: productDetailsData?.models[2].model.dot_code,
    },
    {
      id: 9,
      key: 'Dot-code wiel 4',
      value: productDetailsData?.models[3].model.dot_code,
    },
  ];

  const title = `${productDetailsData?.models[0]?.model.condition}e ${productDetailsData?.tyre_set.brand} ${
    productDetailsData?.models[0]?.model.season === 'Summer'
      ? 'zomer'
      : productDetailsData?.models[0]?.model.season === 'Winter'
      ? 'winter'
      : 'all season'
  } banden.`;

  const storagePlace = productDetailsData?.tyre_set?.storage;

  const viewCount = productDetailsData?.tyre_set?.views_counter;
  const favoriteCount = productDetailsData?.tyre_set?.favorites_counter;

  const created = besteldatumConverter(productDetailsData?.tyre_set?.date_added);
  const onlineSince = besteldatumConverter(productDetailsData?.advertisement?.start_date);

  const price = productDetailsData?.tyre_set.price_value;

  const saleStatus = productDetailsData?.tyre_set.sale_status;

  const noPrice = saleStatus === 'INVENTORY';

  const advertentieId = productDetailsData?.advertisement?.id;

  const condition = productDetailsData?.models[0]?.model.condition;

  const sliderItems =
    condition === 'Nieuw'
      ? [
          {
            id: 1,
            img: nieuwImage,
          },
        ]
      : [
          {
            id: 1,
            img: file1Image,
          },
          {
            id: 2,
            img: file2Image,
          },
          {
            id: 3,
            img: file3Image,
          },
          {
            id: 4,
            img: file4Image,
          },
        ];

  const fract = (n) => String(n).split('.')[1] || 0;

  const priceLabel = (
    <div
      className="price-label"
      style={{
        marginLeft: '0px',
        marginTop: `${isLargeScreen ? '30px' : '10px'}`,
      }}
    >
      <span className="integer" style={{ fontSize: `${isLargeScreen ? '36px' : '30px'}` }}>
        &euro; {Math.floor(price)},
      </span>
      <span className="fraction" style={{ fontSize: `${isLargeScreen ? '22px' : '19px'}` }}>
        {fract(price) === '00' ? ' -' : fract(price)}
      </span>
    </div>
  );

  const handleBewerken = () => {
    navigate(`/bewerken/${id}`);
  };

  if (isProductDetailsDataError) {
    return (
      <Toast
        type="error"
        message={productDetailsDataError?.message}
        additionalMessage={productDetailsDataError?.response?.data?.detail}
        standalone
        standaloneAutoRemove
      />
    );
  }

  return isLargeScreen ? (
    <ProductDetailVersionOneMainContainer>
      {selectedOpties !== '' && (
        <>
          <ProductDetailVersionOnePopupBackground backgroundColor={styles.popupBackground} />
          <Popup
            selectedOpties={selectedOpties}
            setSelectedOpties={setSelectedOpties}
            setOpties={setOpties}
            toastId={toastId}
            setToastId={setToastId}
            toastList={toastList}
            setToastList={setToastList}
            advertentieId={advertentieId}
          />
        </>
      )}
      {isProductDetailsDataLoading || !(nieuwImage || (file1Image && file2Image && file3Image && file4Image)) ? (
        <ProductDetailsVersionOneLoaderPlaceholder>
          <Loader />
        </ProductDetailsVersionOneLoaderPlaceholder>
      ) : (
        <ProductDetailVersionOneWrapper>
          <ProductDetailVersionOneTopContainer>
            <ProductDetailVersionOneUpLeftContainer>
              <Slider sliderItems={sliderItems} />
            </ProductDetailVersionOneUpLeftContainer>
            <ProductDetailVersionOneUpRightContainer>
              <ProductDetailVersionOneUpRightWrapper>
                <ProductDetailVersionOneUpRightOptionsWrapper>
                  <ProductDetailVersionOneUpRightOptionItem
                    hover={styles.primaryColorContactHoverContent}
                    onClick={handleBewerken}
                  >
                    <EditIcon style={{ color: styles.primaryColor, cursor: 'pointer' }} fontSize="medium" />
                    <ProductDetailVersionOneUpRightOptionSpan color={styles.primaryColor}>
                      Bewerken
                    </ProductDetailVersionOneUpRightOptionSpan>
                  </ProductDetailVersionOneUpRightOptionItem>
                  <ProductDetailVersionOneUpRightOptionItem hover={styles.primaryColorContactHoverContent}>
                    <MoreHorizIcon
                      onClick={() => setOpties(!opties)}
                      style={{
                        color: styles.primaryColor,
                        marginLeft: '30px',
                        cursor: 'pointer',
                      }}
                      fontSize="medium"
                    />
                    <ProductDetailVersionOneUpRightOptionSpan
                      color={styles.primaryColor}
                      onClick={() => setOpties(!opties)}
                    >
                      Opties
                    </ProductDetailVersionOneUpRightOptionSpan>
                  </ProductDetailVersionOneUpRightOptionItem>
                  {!noPrice ? (
                    <>
                      {opties && (
                        <ProductDetailVersionOneOptiesWrapper>
                          <ProductDetailVersionOneOptiesItem
                            borderRadius="8px 8px 0px 0px"
                            onClick={() => setSelectedOpties('Advertentie intrekken')}
                          >
                            Advertentie intrekken
                          </ProductDetailVersionOneOptiesItem>
                          {/* <ProductDetailVersionOneOptiesItem
											onClick={() => setSelectedOpties("Delen")}>
											Delen
										</ProductDetailVersionOneOptiesItem> */}
                          <ProductDetailVersionOneOptiesItem
                            borderRadius="0px 0px 8px 8px"
                            onClick={() => setSelectedOpties('Verwijderen')}
                          >
                            Verwijderen
                          </ProductDetailVersionOneOptiesItem>
                        </ProductDetailVersionOneOptiesWrapper>
                      )}
                    </>
                  ) : (
                    <>
                      {opties && (
                        <ProductDetailVersionOneOptiesWrapper>
                          <ProductDetailVersionOneOptiesItem
                            borderRadius="8px 8px 0px 0px"
                            onClick={() => setSelectedOpties('Te koop aanbieden')}
                          >
                            Te koop aanbieden
                          </ProductDetailVersionOneOptiesItem>
                          {/* <ProductDetailVersionOneOptiesItem
												onClick={() => setSelectedOpties("Delen")}>
												Delen
											</ProductDetailVersionOneOptiesItem> */}
                          <ProductDetailVersionOneOptiesItem
                            borderRadius="0px 0px 8px 8px"
                            onClick={() => setSelectedOpties('Verwijderen')}
                          >
                            Verwijderen
                          </ProductDetailVersionOneOptiesItem>
                        </ProductDetailVersionOneOptiesWrapper>
                      )}
                    </>
                  )}
                </ProductDetailVersionOneUpRightOptionsWrapper>
                <ProductDetailVersionOneUpRightTitle>{title}</ProductDetailVersionOneUpRightTitle>
                {storagePlace ? (
                  <ProductDetailVersionOneUpRightSubtitleWrapper>
                    <ProductDetailVersionOneUpRightSubtitleKey>Opslagplaats:</ProductDetailVersionOneUpRightSubtitleKey>
                    <ProductDetailVersionOneUpRightSubtitleValue>
                      {storagePlace}
                    </ProductDetailVersionOneUpRightSubtitleValue>
                  </ProductDetailVersionOneUpRightSubtitleWrapper>
                ) : (
                  <ProductDetailVersionOneBox />
                )}

                <ProductDetailVersionOneUpRightPriceWrapper>
                  {noPrice ? (
                    <ProductDetailVersionOneUpRightTitle style={{ fontSize: '36px' }} paddingTop="30px">
                      Voorraad
                    </ProductDetailVersionOneUpRightTitle>
                  ) : (
                    <>
                      {priceLabel}
                      <ProductDetailVersionOneUpRightIconWrapper>
                        <VisibilityIcon
                          style={{
                            marginLeft: '10px',
                            marginRight: '10px',
                            color: styles.variantIconColor,
                          }}
                          fontSize="small"
                        />
                        {viewCount}
                        <FavoriteIcon
                          style={{
                            marginLeft: '20px',
                            marginRight: '10px',
                            color: styles.variantIconColor,
                          }}
                          fontSize="small"
                        />
                        {favoriteCount}
                      </ProductDetailVersionOneUpRightIconWrapper>
                    </>
                  )}
                </ProductDetailVersionOneUpRightPriceWrapper>
                <ProductDetailVersionOneUpRightDateWrapper>
                  <ProductDetailVersionOneUpRightDateKey>Aangemaakt:</ProductDetailVersionOneUpRightDateKey>
                  <ProductDetailVersionOneUpRightDateValue>{created}</ProductDetailVersionOneUpRightDateValue>
                </ProductDetailVersionOneUpRightDateWrapper>
                {saleStatus !== 'INVENTORY' && (
                  <ProductDetailVersionOneUpRightDateWrapper>
                    <ProductDetailVersionOneUpRightDateKey>Online sinds:</ProductDetailVersionOneUpRightDateKey>
                    <ProductDetailVersionOneUpRightDateValue>
                      {noPrice ? '-' : onlineSince}
                    </ProductDetailVersionOneUpRightDateValue>
                  </ProductDetailVersionOneUpRightDateWrapper>
                )}
              </ProductDetailVersionOneUpRightWrapper>
            </ProductDetailVersionOneUpRightContainer>
          </ProductDetailVersionOneTopContainer>
          <div className={classes.grid}>
            <div className={classes.header}>
              <StraightenIcon style={{ color: '#136CE2' }} />
              <ProductDetailVersionOneBottomLeftTitle>Specificaties</ProductDetailVersionOneBottomLeftTitle>
            </div>
            <span aria-hidden="true" />
            <div className={classes.block}>
              {dataLeftSide.map((data) => (
                <ProductDetailVersionOneBottomDetailWrapper key={data.id} margin={`${isLargeScreen ? '4px' : '8px'}`}>
                  <ProductDetailVersionOneBottomDetailKey>{data.key}:</ProductDetailVersionOneBottomDetailKey>
                  <ProductDetailVersionOneBottomDetailValue>{data.value}</ProductDetailVersionOneBottomDetailValue>
                </ProductDetailVersionOneBottomDetailWrapper>
              ))}
            </div>
            <div className={classes.block}>
              {dataRightSide.map((data) => (
                <ProductDetailVersionOneBottomDetailWrapper key={data.id} margin={`${isLargeScreen ? '4px' : '8px'}`}>
                  <ProductDetailVersionOneBottomDetailKey>{data.key}:</ProductDetailVersionOneBottomDetailKey>
                  <ProductDetailVersionOneBottomDetailValue>{data.value}</ProductDetailVersionOneBottomDetailValue>
                </ProductDetailVersionOneBottomDetailWrapper>
              ))}
            </div>
          </div>
        </ProductDetailVersionOneWrapper>
      )}
    </ProductDetailVersionOneMainContainer>
  ) : (
    <MobileProductDetailVersionOneContainer>
      {selectedOpties !== '' && (
        <>
          <ProductDetailVersionOnePopupBackground backgroundColor={styles.popupBackground} />
          <Popup
            selectedOpties={selectedOpties}
            setSelectedOpties={setSelectedOpties}
            setOpties={setOpties}
            toastId={toastId}
            setToastId={setToastId}
            toastList={toastList}
            setToastList={setToastList}
            advertentieId={advertentieId}
          />
        </>
      )}
      {!isProductDetailsDataLoading && !isProductDetailsDataError && (
        <MobileProductDetailsNavBarLogos>
          <MobileProductDetailsNavBarEditLogo>
            <EditIcon
              style={{ color: styles.primaryColor, cursor: 'pointer' }}
              fontSize="inherit"
              onClick={handleBewerken}
            />
          </MobileProductDetailsNavBarEditLogo>

          <MobileProductDetailsNavbarMoreDotsLogo>
            <MoreHorizIcon
              onClick={() => setOpties(!opties)}
              style={{
                color: styles.primaryColor,
                cursor: 'pointer',
              }}
              fontSize="inherit"
            />
          </MobileProductDetailsNavbarMoreDotsLogo>

          {!noPrice ? (
            <>
              {opties && (
                <ProductDetailVersionOneOptiesWrapper>
                  <ProductDetailVersionOneOptiesItem
                    borderRadius="8px 8px 0px 0px"
                    onClick={() => setSelectedOpties('Advertentie intrekken')}
                  >
                    Advertentie intrekken
                  </ProductDetailVersionOneOptiesItem>
                  {/* <ProductDetailVersionOneOptiesItem
											onClick={() => setSelectedOpties("Delen")}>
											Delen
										</ProductDetailVersionOneOptiesItem> */}
                  <ProductDetailVersionOneOptiesItem
                    borderRadius="0px 0px 8px 8px"
                    onClick={() => setSelectedOpties('Verwijderen')}
                  >
                    Verwijderen
                  </ProductDetailVersionOneOptiesItem>
                </ProductDetailVersionOneOptiesWrapper>
              )}
            </>
          ) : (
            <>
              {opties && (
                <ProductDetailVersionOneOptiesWrapper>
                  <ProductDetailVersionOneOptiesItem
                    borderRadius="8px 8px 0px 0px"
                    onClick={() => setSelectedOpties('Te koop aanbieden')}
                  >
                    Te koop aanbieden
                  </ProductDetailVersionOneOptiesItem>
                  {/* <ProductDetailVersionOneOptiesItem
												onClick={() => setSelectedOpties("Delen")}>
												Delen
											</ProductDetailVersionOneOptiesItem> */}
                  <ProductDetailVersionOneOptiesItem
                    borderRadius="0px 0px 8px 8px"
                    onClick={() => setSelectedOpties('Verwijderen')}
                  >
                    Verwijderen
                  </ProductDetailVersionOneOptiesItem>
                </ProductDetailVersionOneOptiesWrapper>
              )}
            </>
          )}
        </MobileProductDetailsNavBarLogos>
      )}
      {isProductDetailsDataLoading || !(nieuwImage || (file1Image && file2Image && file3Image && file4Image)) ? (
        <ProductDetailsVersionOneLoaderPlaceholder>
          <Loader />
        </ProductDetailsVersionOneLoaderPlaceholder>
      ) : (
        <MobileProductDetailVersionOneContent>
          <MobileSliderWrapper>
            <Slider sliderItems={sliderItems} border="10px 10px 0px 0px" />
          </MobileSliderWrapper>
          <MobileProductDetailVersionOne>
            <MobileProductDetailVersionOneTitle>{title}</MobileProductDetailVersionOneTitle>
            <ProductDetailVersionOneUpRightPriceWrapper>
              {noPrice ? (
                <ProductDetailVersionOneUpRightTitle style={{ fontSize: '36px' }}>
                  Voorraad
                </ProductDetailVersionOneUpRightTitle>
              ) : (
                <>
                  {priceLabel}
                  <ProductDetailVersionOneMobileIconWrapper>
                    <VisibilityIcon
                      style={{
                        marginLeft: '10px',
                        marginRight: '10px',
                        color: styles.variantIconColor,
                      }}
                      fontSize="medium"
                    />
                    {viewCount}
                    <FavoriteIcon
                      style={{
                        marginLeft: '20px',
                        marginRight: '10px',
                        color: styles.variantIconColor,
                      }}
                      fontSize="medium"
                    />
                    {favoriteCount}
                  </ProductDetailVersionOneMobileIconWrapper>
                </>
              )}
            </ProductDetailVersionOneUpRightPriceWrapper>
            {storagePlace && (
              <ProductDetailVersionOneUpRightSubtitleWrapper>
                <ProductDetailVersionOneUpRightSubtitleKey>Opslagplaats:</ProductDetailVersionOneUpRightSubtitleKey>
                <ProductDetailVersionOneUpRightSubtitleValue>
                  {storagePlace}
                </ProductDetailVersionOneUpRightSubtitleValue>
              </ProductDetailVersionOneUpRightSubtitleWrapper>
            )}

            <MobileProductDetailVersionOneSpecifications>
              <StraightenIcon style={{ color: '#136CE2', marginRight: '12px' }} />
              Specificaties
            </MobileProductDetailVersionOneSpecifications>
            <div>
              {dataLeftSide.map((data) => (
                <ProductDetailVersionOneBottomDetailWrapper key={data.id} margin={`${isLargeScreen ? '10px' : '4px'}`}>
                  <MobileProductDetailVersionOneDetailKey>{data.key}:</MobileProductDetailVersionOneDetailKey>
                  <MobileProductDetailVersionOneDetailValue>{data.value}</MobileProductDetailVersionOneDetailValue>
                </ProductDetailVersionOneBottomDetailWrapper>
              ))}
            </div>
            <Divider />
            <div>
              {dataRightSide.map((data) => (
                <ProductDetailVersionOneBottomDetailWrapper key={data.id} margin={`${isLargeScreen ? '10px' : '4px'}`}>
                  <MobileProductDetailVersionOneDetailKey>{data.key}:</MobileProductDetailVersionOneDetailKey>
                  <MobileProductDetailVersionOneDetailValue>{data.value}</MobileProductDetailVersionOneDetailValue>
                </ProductDetailVersionOneBottomDetailWrapper>
              ))}
            </div>
            <ProductDetailVersionOneUpMobileWrapper>
              <ProductDetailVersionOneUpRightDateWrapper>
                <ProductDetailVersionOneUpRightDateKey>Aangemaakt:</ProductDetailVersionOneUpRightDateKey>
                <ProductDetailVersionOneUpRightDateValue>{created}</ProductDetailVersionOneUpRightDateValue>
              </ProductDetailVersionOneUpRightDateWrapper>
              {saleStatus !== 'INVENTORY' && (
                <ProductDetailVersionOneUpRightDateWrapper>
                  <ProductDetailVersionOneUpRightDateKey>Online sinds:</ProductDetailVersionOneUpRightDateKey>
                  <ProductDetailVersionOneUpRightDateValue>
                    {noPrice ? '-' : onlineSince}
                  </ProductDetailVersionOneUpRightDateValue>
                </ProductDetailVersionOneUpRightDateWrapper>
              )}
            </ProductDetailVersionOneUpMobileWrapper>
          </MobileProductDetailVersionOne>
        </MobileProductDetailVersionOneContent>
      )}
    </MobileProductDetailVersionOneContainer>
  );
};

export default ProductDetailVersionOne;
