import { forwardRef } from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';
import { ForwardedRef } from 'app/helpers/types';
const IconLicensePlateCountryNL = forwardRef((props: SvgIconProps, ref: ForwardedRef<SVGSVGElement>) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={10.598}
    viewBox="0 0 20 10.598"
    name="IconLicensePlateCountryNL"
    ref={ref}
    {...props}
  >
    <path
      id="licence-plate-country-nl"
      d="M-.248-11.2V-.6H-2.262L-7.546-7.036V-.6H-9.968V-11.2h2.029L-2.67-4.765V-11.2Zm2.513,0H4.718v8.6h5.314v2H2.265Z"
      transform="translate(9.968 11.2)"
    />
  </SvgIcon>
));
IconLicensePlateCountryNL.displayName = 'IconLicensePlateCountryNL';
export default IconLicensePlateCountryNL;
