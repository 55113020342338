import { AccordionWrapper, FAQMainContainer, FAQWrapper } from './FAQ.styles';
import Accordion from 'app/components/Accordion';
import Contact from 'app/components/Contact';

const FAQ = () => {
  const accordionData = [
    {
      id: 0,
      title: 'Wat gebeurt er na aankoop?',
      content: `Na aankoop van de set velgen, krijgt onze transportpartner de opdracht om de set op te halen.
          Dit gebeurt vaak al binnen 24 uur na aankoop, waarna de velgen op transport gaan en uw kant op komen.`,
    },
    {
      id: 1,
      title: 'Hoe weet ik zeker dat de velgen onder de auto passen?',
      content: `Aan de hand van de steekmaat, naafdiameter en ET-waarde wordt bepaald of de set onder de auto past.`,
    },
    {
      id: 2,
      title: 'Wanneer kan ik de velgen set verwachten?',
      content: `In de regel kunt u ze met 3 dagen op uw locatie verwachten.`,
    },
    {
      id: 3,
      title: 'Hoe wordt de set velgen afgeleverd?',
      content: `Onze transportpartner zorgt ervoor dat de aangekochte set in folie wordt bezorgd.`,
    },
    {
      id: 4,
      title: 'Hoe werkt retour sturen?',
      content: `U kan de set retour sturen, als deze van lagere kwaliteit is dan omschreven.
         Wilt u een zending terugsturen? Klik dan op uw bestelling en doorloop het stappenplan.`,
    },
    {
      id: 5,
      title: 'Wat gebeurt er na het plaatsen van de set?',
      content: `Is uw advertentie klaar om geplaatst te worden? Druk op ‘Adverteren’ en de advertentie wordt automatisch geplaatst.
        Een paar minuten later staat deze advertentie op TyreSwap.`,
    },
    {
      id: 6,
      title: 'Kan mijn set dubbel verkocht worden?',
      content: `Dit is niet mogelijk, bij het aankopen van de set is uw advertentie direct offline.
        De advertentie is nog wel terug te vinden in uw eigen omgeving bij ‘Mijn voorraad’ of in het menu bij ‘Mijn aankopen’.`,
    },
    {
      id: 7,
      title: 'Wie ziet de set als deze op ‘Eigen voorraad’ staat?',
      content: `Wanneer u kiest om de set op ‘voorraad’ te zetten, dan bent u de enige die hem kan terugvinden. De set wordt niet meer te koop aangeboden.`,
    },
    {
      id: 8,
      title: 'Hoeveel sets kan ik maximaal toevoegen aan TyreSwap?',
      content: `Hier zit geen maximum aan. U kunt een onbeperkt aantal sets toevoegen en advertenties plaatsen. `,
    },
    {
      id: 9,
      title: 'Wat is de maximale verkoopprijs van een set?',
      content: `We hanteren geen maximale prijzen. Het is aan u om een (concurrerende) prijs te bepalen. `,
    },
  ];

  return (
    <FAQMainContainer>
      <FAQWrapper>
        <AccordionWrapper>
          {accordionData.map(({ id, title, content }, index) => (
            <Accordion key={id} title={title} content={content} index={index} length={accordionData.length} />
          ))}
        </AccordionWrapper>
      </FAQWrapper>
      <Contact />
    </FAQMainContainer>
  );
};

export default FAQ;
