import styled from 'styled-components';

export const PageNavWrapper = styled.button`
  padding: 0;
  border: none;
  outline: inherit;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0px 0px 10px #00000014;
  cursor: ${(props) => props.cursor};
  padding-top: ${(props) => props.paddingTop};
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};

  :focus {
    background-color: ${(props) => props.backgroundColor};
  }
`;
