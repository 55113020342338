import type { Auth0DecodedHash, Auth0ParseHashError } from 'auth0-js';
import webAuth from './webAuth';
import { getAuthState, removeAuthState, setAuthAccessToken, setAuthExpireTimestamp } from './auth-session';

interface CallbackHandlerOptions {
  hash: string;
  onSuccess: () => void;
  onError: (error: Auth0ParseHashError) => void;
}

const handleCallbackFactory = () => {
  let previousHash = '';
  return ({ hash, onSuccess, onError }: CallbackHandlerOptions) => {
    const state = getAuthState();
    if (!state) {
      return onError({ error: 'invalid_token', errorDescription: 'Missing state to validate' });
    }

    if (previousHash !== hash && state) {
      previousHash = hash;

      webAuth.parseHash({ hash, state }, (error: Auth0ParseHashError | null, authResult: Auth0DecodedHash | null) => {
        if (error) {
          return onError(error);
        }

        removeAuthState();

        if (authResult?.expiresIn) {
          let expiresTimestamp = authResult.idTokenPayload.exp * 1000; // expires timestamp in milliseconds
          expiresTimestamp -= 300000; // subtract 5 minutes to avoid any time differences between services
          setAuthExpireTimestamp(expiresTimestamp);
        }

        if (authResult?.accessToken) {
          setAuthAccessToken(authResult.accessToken);

          return onSuccess();
        }

        onError({ error: 'invalid_token', errorDescription: 'Missing access token in the hash' });
      });
    }
  };
};

const handleCallback = handleCallbackFactory();

export default handleCallback;
