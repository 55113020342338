import { forwardRef, HTMLAttributes } from 'react';
import useClassNames from 'app/helpers/useClassNames';
import './DropdownList.scss';

export type DropdownListProps = HTMLAttributes<HTMLUListElement>;

const DropdownList = forwardRef<HTMLUListElement, DropdownListProps>((props: DropdownListProps, ref) => {
  const { className, children, ...rest } = props;
  const { withClassPrefix, merge } = useClassNames('dropdown-list');

  const classes = merge(className, withClassPrefix());

  return (
    <ul {...rest} ref={ref} className={classes}>
      {children}
    </ul>
  );
});

DropdownList.displayName = 'DropdownList';

export default DropdownList;
