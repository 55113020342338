import React, { useState, useEffect } from 'react';
import { ToastAllContainer } from './ToastContainer.styles';
import Toast from './Toast';
const ToastContainer = ({ toastList, autoRemove, removeInterval }) => {
  const [list, setList] = useState(toastList);

  useEffect(() => {
    setList(toastList);
  }, [toastList, list]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoRemove && list.length > 0) {
        deleteToast(list[0].id);
      }
    }, removeInterval);

    return () => {
      clearInterval(interval);
    };
  }, [list]);

  const deleteToast = (id) => {
    const index = list.findIndex((e) => e.id === id);
    list.splice(index, 1);

    setList([...list]);
  };

  return (
    <ToastAllContainer>
      {list.length > 0 &&
        list.map((toast, i) => (
          <Toast
            key={i}
            id={toast.id}
            type={toast.type}
            message={toast.message}
            additionalMessage={toast.additionalMessage}
            // eslint-disable-next-line react/jsx-handler-names
            onClose={toast.onClose}
            deleteToast={deleteToast}
          />
        ))}
    </ToastAllContainer>
  );
};

export default ToastContainer;
