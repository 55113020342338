import { useMutation } from '@tanstack/react-query';

import { deleteProduct } from 'app/api/deleteProduct';

type DeleteProductPropsType = {
  id: number;
};

const useDeleteProduct = () => {
  const deleteProductMutation = useMutation(deleteProduct);

  const onClickDeleteProduct = (data: DeleteProductPropsType) => {
    deleteProductMutation.mutate(data);
  };

  const onClearDeleteProductErrorMessage = () => deleteProductMutation.reset();

  const {
    isLoading: isDeleteProductLoading,
    isError: isDeleteProductError,
    error: deleteProductError,
    data: deleteProductData,
    isSuccess: isDeleteProductSuccess,
  } = deleteProductMutation;

  return {
    isDeleteProductLoading,
    isDeleteProductError,
    deleteProductError,
    deleteProductData,
    isDeleteProductSuccess,
    deleteProductMutation,
    onClickDeleteProduct,
    onClearDeleteProductErrorMessage,
  };
};

export default useDeleteProduct;
