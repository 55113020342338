import apiClient from 'app/axios/api-client';

type PlaceOrderPropsType = {
  tyre_set_id: string;
};

export const placeOrder = async (data: PlaceOrderPropsType) => {
  const response = await apiClient.post(`bestelling/${data.tyre_set_id}`);

  return response.data;
};
