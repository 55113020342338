import { forwardRef } from 'react';
import { StandardProps } from '../../../helpers/types';
import useClassNames from 'app/helpers/useClassNames';
import './FormInputLabelWrapper.scss';

export type FormInputLabelWrapperProps = StandardProps;

const FormInputLabelWrapper = forwardRef<HTMLDivElement, FormInputLabelWrapperProps>(
  (props: FormInputLabelWrapperProps, ref) => {
    const { children, className, style } = props;
    const { merge, withClassPrefix } = useClassNames('form-input-label-wrapper');
    const classes = merge(className, withClassPrefix());

    return (
      <div className={classes} ref={ref} style={style}>
        {children}
      </div>
    );
  }
);

FormInputLabelWrapper.displayName = 'FormInputLabelWrapper';

export default FormInputLabelWrapper;
