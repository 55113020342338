import { useState, useRef, useEffect, useCallback } from 'react';
// Material UI Icons
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// Styled Components
import FullscreenSlider from '../FullscreenSlider/FullscreenSlider';
import { Arrow, Container, Image, ImgContainer, Slide, Wrapper } from './Slider.styles';

const Slider = ({ sliderItems, border }) => {
  const nrOfSliderItems = sliderItems.length;
  const desktopSliderRef = useRef();

  const [slideIndex, setSlideIndex] = useState(0);
  const [fullscreenSlider, setFullscreenSlider] = useState(false);
  const [currentSliderWidth, setCurrentSliderWidth] = useState(null);

  const handleClick = (direction) => {
    if (direction === 'left') {
      setSlideIndex(slideIndex > 0 ? slideIndex - 1 : sliderItems.length - 1);
    } else {
      setSlideIndex(slideIndex < sliderItems.length - 1 ? slideIndex + 1 : 0);
    }
  };

  const handleImageClick = useCallback(() => {
    setFullscreenSlider((value) => !value);
  }, []);

  useEffect(() => {
    if (desktopSliderRef.current) {
      setCurrentSliderWidth(desktopSliderRef.current.clientWidth);
    }
  });

  return (
    <>
      <Container ref={desktopSliderRef} border={border}>
        <Arrow direction="left" display={nrOfSliderItems === 1 ? 'none' : 'flex'} onClick={() => handleClick('left')}>
          <ArrowBackIosIcon sx={{ color: '#dadada' }} style={{ fontSize: 45 }} />
        </Arrow>
        <Wrapper slideIndex={slideIndex} width={currentSliderWidth}>
          {sliderItems.map((item) => (
            <Slide key={item.id}>
              <ImgContainer>
                <Image src={item.img} width={currentSliderWidth} onClick={handleImageClick} />
              </ImgContainer>
            </Slide>
          ))}
        </Wrapper>
        <Arrow direction="right" display={nrOfSliderItems === 1 ? 'none' : 'flex'} onClick={() => handleClick('right')}>
          <ArrowForwardIosIcon sx={{ color: '#dadada' }} style={{ fontSize: 45 }} />
        </Arrow>
      </Container>
      {fullscreenSlider && <FullscreenSlider items={sliderItems} slideIndex={slideIndex} onClose={handleImageClick} />}
    </>
  );
};

export default Slider;
