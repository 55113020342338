export const dateConverter = (value: string) => {
  const dateFormat = new Date(value);

  const day = dateFormat.getDate() < 10 ? `0${dateFormat.getDate()}` : dateFormat.getDate();
  const month = dateFormat.toLocaleString('default', { month: 'long' }).toLocaleLowerCase();
  const year = dateFormat.getFullYear();

  return `${day} ${month} ${year}`;
};

export const besteldatumConverter = (value: string) => {
  const dateFormatObj = new Date(value);

  const day = dateFormatObj.getDate() < 10 ? `0${dateFormatObj.getDate()}` : dateFormatObj.getDate();

  const month = dateFormatObj.getMonth() + 1 < 10 ? `0${dateFormatObj.getMonth() + 1}` : dateFormatObj.getMonth() + 1;

  const year = dateFormatObj.getFullYear();

  return `${day}-${month}-${year}`;
};

export const filterKeyEnter = (handler: () => void) => (e: { keyCode: number }) => {
  if (e.keyCode === 13) {
    handler();
  }
};

export const accessibleOnClick = (handler: () => void) => ({
  role: 'button',
  tabIndex: 0,
  onKeyDown: filterKeyEnter(handler),
  onClick: handler,
});

export const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  event.preventDefault();
};
