import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StraightenIcon from '@mui/icons-material/Straighten';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Divider } from '@mui/material';
import styles from '../../../scss/_variables.scss';
import tireSizeFormat from '../../helpers/tireSizeFormat';
import {
  ProductDetailVersionTwoBottomContainer,
  ProductDetailVersionTwoBottomDetailKey,
  ProductDetailVersionTwoBottomDetailValue,
  ProductDetailVersionTwoBottomDetailWrapper,
  ProductDetailVersionTwoBottomLeftContainer,
  ProductDetailVersionTwoBottomLeftTitle,
  ProductDetailVersionTwoBottomLeftWrapper,
  ProductDetailVersionTwoBottomRightContainer,
  ProductDetailVersionTwoBottomTitleWrapper,
  ProductDetailVersionTwoMainContainer,
  ProductDetailsVersionTwoLoaderPlaceholder,
  ProductDetailVersionTwoTopContainer,
  ProductDetailVersionTwoUpLeftContainer,
  ProductDetailVersionTwoUpRightContainer,
  ProductDetailVersionTwoUpRightTitle,
  ProductDetailVersionTwoUpRightWrapper,
  ProductDetailVersionTwoWrapper,
  MobileProductDetailVersionTwoContainer,
  MobileProductDetailVersionTwoContent,
  MobileProductDetailVersionTwo,
  MobileProductDetailVersionTwoTitle,
  MobileProductDetailVersionTwoSpecifications,
  MobileProductDetailVersionTwoDetailKey,
  MobileProductDetailVersionTwoDetailValue,
  ProductDetailVersionTwoUpRightSubtitleWrapper,
  ProductDetailVersionTwoUpRightSubtitleKey,
  ProductDetailVersionTwoUpRightSubtitleValue,
  ProductDetailVersionTwoUpRightPriceWrapper,
  ProductDetailVersionTwoUpRightIconWrapper,
  ProductDetailVersionTwoUpRightDateWrapper,
  ProductDetailVersionTwoUpRightDateKey,
  ProductDetailVersionTwoUpRightDateValue,
  ProductDetailVersionTwoButton,
  MobileSliderWrapper,
  ProductDetailVersionTwoMobileIconWrapper,
  ProductDetailVersionTwoUpMobileWrapper,
} from './ProductDetailVersionTwo.styles';
import useWindowSize from 'app/hooks/useWindowSize';
import { breakpoints } from 'app/config/breakpoints';
import Slider from 'app/components/Slider';
import useProduct from 'app/hooks/useProduct';
import { besteldatumConverter } from 'app/helpers/utils';
import Loader from 'app/components/Loader/Loader';
import Toast from 'app/components/Toast/Toast';

const ProductDetailVersionTwo = () => {
  const [nieuwImage, setNieuwImage] = useState(null);
  const [nieuwPhotoId, setNieuwPhotoId] = useState();

  const [file1Image, setFile1Image] = useState();
  const [file1ImageId, setFile1ImageId] = useState();

  const [file2Image, setFile2Image] = useState();
  const [file2ImageId, setFile2ImageId] = useState();

  const [file3Image, setFile3Image] = useState();
  const [file3ImageId, setFile3ImageId] = useState();

  const [file4Image, setFile4Image] = useState();
  const [file4ImageId, setFile4ImageId] = useState();

  const { width } = useWindowSize();
  const isLargeScreen = width && width >= breakpoints.lg;
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: productDetailsData,
    isLoading: isProductDetailsDataLoading,
    isError: isProductDetailsDataError,
    error: productDetailsDataError,
  } = useProduct(true, id, true);

  const isOwner = productDetailsData?.tyre_set?.is_owner;

  useEffect(() => {
    if (productDetailsData) {
      if (productDetailsData?.models[0]?.model.condition === 'Nieuw') {
        setNieuwPhotoId(productDetailsData?.models[3]?.image_url);
      } else if (
        productDetailsData?.models[0]?.model.condition === 'Gebruikt' ||
        productDetailsData?.models[0]?.model.condition === 'Zo goed als nieuw'
      ) {
        setFile1ImageId(productDetailsData?.models[0]?.image_url);
        setFile2ImageId(productDetailsData?.models[1]?.image_url);
        setFile3ImageId(productDetailsData?.models[2]?.image_url);
        setFile4ImageId(productDetailsData?.models[3]?.image_url);
      }
    }
  }, [productDetailsData]);

  useEffect(() => {
    if (nieuwPhotoId) {
      setNieuwImage(nieuwPhotoId);
    }
  }, [nieuwPhotoId]);

  useEffect(() => {
    if (file1ImageId && file2ImageId && file3ImageId && file4ImageId) {
      setFile1Image(file1ImageId);
      setFile2Image(file2ImageId);
      setFile3Image(file3ImageId);
      setFile4Image(file4ImageId);
    }
  }, [file1ImageId, file1ImageId, file1ImageId, file1ImageId]);

  const schadeValue = () => {
    if (
      !productDetailsData?.models[0]?.damage.is_damaged &&
      !productDetailsData?.models[1]?.damage.is_damaged &&
      !productDetailsData?.models[2]?.damage.is_damaged &&
      !productDetailsData?.models[3]?.damage.is_damaged
    ) {
      return 'Geen';
    } else {
      const damagedWheels = [];
      if (productDetailsData?.models[0]?.damage.is_damaged) {
        damagedWheels.push(1);
      }
      if (productDetailsData?.models[1]?.damage.is_damaged) {
        damagedWheels.push(2);
      }
      if (productDetailsData?.models[2]?.damage.is_damaged) {
        damagedWheels.push(3);
      }
      if (productDetailsData?.models[3]?.damage.is_damaged) {
        damagedWheels.push(4);
      }

      return `Wiel ${damagedWheels.map((item) => ` ${item}`)}`;
    }
  };

  const dataLeftSide = [
    {
      id: 1,
      key: 'Merk',
      value: productDetailsData?.tyre_set.brand,
    },
    {
      id: 2,
      key: 'Inchmaat',
      value: tireSizeFormat(
        productDetailsData?.models[0].model.width,
        productDetailsData?.models[0].model.height,
        productDetailsData?.models[0].model.inches
      ),
    },
    {
      id: 3,
      key: 'Seizoen',
      value:
        productDetailsData?.models[0]?.model.season === 'Summer'
          ? 'Zomer'
          : productDetailsData?.models[0]?.model.season === 'Winter'
          ? 'Winter'
          : 'All season',
    },
    {
      id: 4,
      key: 'TPMS',
      value:
        productDetailsData?.models[0]?.model.is_TPMS === 'yes'
          ? 'Ja'
          : productDetailsData?.models[0]?.model.is_TPMS === 'no'
          ? 'Nee'
          : 'Onbekend',
    },
    {
      id: 5,
      key: 'M + S',
      value: productDetailsData?.models[0]?.model.m_plus_s ? 'Ja' : 'Nee',
    },
    {
      id: 6,
      key: '3PMSF',
      value: productDetailsData?.models[0]?.model.drie_PMSF ? 'Ja' : 'Nee',
    },
    {
      id: 7,
      key: 'Runflat',
      value:
        productDetailsData?.models[0]?.model.is_runflat === 'yes'
          ? 'Ja'
          : productDetailsData?.models[0]?.model.is_runflat === 'no'
          ? 'Nee'
          : 'Onbekend',
    },
    {
      id: 8,
      key: 'Conditie',
      value: productDetailsData?.models[0]?.model.condition,
    },
    {
      id: 9,
      key: 'Schade',
      value: schadeValue(),
    },
  ];

  const profileDepth = (value) => {
    const depthMap = {
      ONETWO: '1 tot 2',
      TWOTHREE: '2 tot 3',
      THREEFOUR: '3 tot 4',
      FOURFIVE: '4 tot 5',
      FIVESIX: '5 tot 6',
      SIXSEVEN: '6 tot 7',
      SEVENEIGHT: '7 tot 8',
      EIGHTPLUS: '8+',
    };

    return `${depthMap[value]} mm` || value;
  };

  const dataRightSide = [
    {
      id: 2,
      key: 'Profieldiepte wiel 1',
      value: `${profileDepth(productDetailsData?.models[0]?.model.profile_depth)}`,
    },
    {
      id: 3,
      key: 'Profieldiepte wiel 2',
      value: `${profileDepth(productDetailsData?.models[1]?.model.profile_depth)}`,
    },
    {
      id: 4,
      key: 'Profieldiepte wiel 3',
      value: `${profileDepth(productDetailsData?.models[2]?.model.profile_depth)}`,
    },
    {
      id: 5,
      key: 'Profieldiepte wiel 4',
      value: `${profileDepth(productDetailsData?.models[3]?.model.profile_depth)}`,
    },
    {
      id: 6,
      key: 'Dot-code wiel 1',
      value: productDetailsData?.models[0].model.dot_code,
    },
    {
      id: 7,
      key: 'Dot-code wiel 2',
      value: productDetailsData?.models[1].model.dot_code,
    },
    {
      id: 8,
      key: 'Dot-code wiel 3',
      value: productDetailsData?.models[2].model.dot_code,
    },
    {
      id: 9,
      key: 'Dot-code wiel 4',
      value: productDetailsData?.models[3].model.dot_code,
    },
  ];

  const title = `${productDetailsData?.models[0]?.model.condition}e ${productDetailsData?.tyre_set.brand} ${
    productDetailsData?.models[0]?.model.season === 'Summer'
      ? 'zomer'
      : productDetailsData?.models[0]?.model.season === 'Winter'
      ? 'winter'
      : 'all season'
  } banden.`;

  const storagePlace = productDetailsData?.tyre_set?.storage;

  const viewCount = productDetailsData?.tyre_set?.views_counter;
  const favoriteCount = productDetailsData?.tyre_set?.favorites_counter;

  const created = besteldatumConverter(productDetailsData?.tyre_set?.date_added);
  const onlineSince = besteldatumConverter(productDetailsData?.advertisement?.start_date);

  const orderId = productDetailsData?.tyre_set?.order;

  const condition = productDetailsData?.models[0]?.model.condition;

  const sliderItems =
    condition === 'Nieuw'
      ? [
          {
            id: 1,
            img: nieuwImage,
          },
        ]
      : [
          {
            id: 1,
            img: file1Image,
          },
          {
            id: 2,
            img: file2Image,
          },
          {
            id: 3,
            img: file3Image,
          },
          {
            id: 4,
            img: file4Image,
          },
        ];

  const handleBestellingBekijkenClick = () => {
    navigate(`/user/bestellingen/order-details/${orderId}`);
  };

  if (isProductDetailsDataError) {
    return (
      <Toast
        type="error"
        message={productDetailsDataError?.message}
        additionalMessage={productDetailsDataError?.response?.data?.detail}
        standalone
        standaloneAutoRemove
      />
    );
  }

  return isLargeScreen ? (
    <ProductDetailVersionTwoMainContainer>
      {isProductDetailsDataLoading || !(nieuwImage || (file1Image && file2Image && file3Image && file4Image)) ? (
        <ProductDetailsVersionTwoLoaderPlaceholder>
          <Loader />
        </ProductDetailsVersionTwoLoaderPlaceholder>
      ) : (
        <ProductDetailVersionTwoWrapper>
          <ProductDetailVersionTwoTopContainer>
            <ProductDetailVersionTwoUpLeftContainer>
              <Slider sliderItems={sliderItems} />
            </ProductDetailVersionTwoUpLeftContainer>
            <ProductDetailVersionTwoUpRightContainer>
              <ProductDetailVersionTwoUpRightWrapper>
                <ProductDetailVersionTwoUpRightTitle>{title}</ProductDetailVersionTwoUpRightTitle>
                {storagePlace && (
                  <ProductDetailVersionTwoUpRightSubtitleWrapper>
                    <ProductDetailVersionTwoUpRightSubtitleKey>Opslagplaats:</ProductDetailVersionTwoUpRightSubtitleKey>
                    <ProductDetailVersionTwoUpRightSubtitleValue>
                      {storagePlace}
                    </ProductDetailVersionTwoUpRightSubtitleValue>
                  </ProductDetailVersionTwoUpRightSubtitleWrapper>
                )}

                <ProductDetailVersionTwoUpRightPriceWrapper>
                  <ProductDetailVersionTwoUpRightTitle style={{ fontSize: '36px' }}>
                    Verkocht
                  </ProductDetailVersionTwoUpRightTitle>
                  {isOwner && (
                    <ProductDetailVersionTwoUpRightIconWrapper>
                      <VisibilityIcon
                        style={{
                          marginLeft: '10px',
                          marginRight: '10px',
                          color: styles.variantIconColor,
                        }}
                        fontSize="small"
                      />
                      {viewCount}
                      <FavoriteIcon
                        style={{
                          marginLeft: '20px',
                          marginRight: '10px',
                          color: styles.variantIconColor,
                        }}
                        fontSize="small"
                      />
                      {favoriteCount}
                    </ProductDetailVersionTwoUpRightIconWrapper>
                  )}
                </ProductDetailVersionTwoUpRightPriceWrapper>
                {isOwner && (
                  <>
                    <ProductDetailVersionTwoUpRightDateWrapper>
                      <ProductDetailVersionTwoUpRightDateKey>Aangemaakt:</ProductDetailVersionTwoUpRightDateKey>
                      <ProductDetailVersionTwoUpRightDateValue>{created}</ProductDetailVersionTwoUpRightDateValue>
                    </ProductDetailVersionTwoUpRightDateWrapper>
                    <ProductDetailVersionTwoUpRightDateWrapper>
                      <ProductDetailVersionTwoUpRightDateKey>Online sinds:</ProductDetailVersionTwoUpRightDateKey>
                      <ProductDetailVersionTwoUpRightDateValue>{onlineSince}</ProductDetailVersionTwoUpRightDateValue>
                    </ProductDetailVersionTwoUpRightDateWrapper>
                    <ProductDetailVersionTwoButton
                      onClick={handleBestellingBekijkenClick}
                      marginTop="25px"
                      hover={styles.primaryColorContactHoverContent}
                      backgroundColor={styles.primaryColor}
                    >
                      Bestelling bekijken
                    </ProductDetailVersionTwoButton>
                  </>
                )}
              </ProductDetailVersionTwoUpRightWrapper>
            </ProductDetailVersionTwoUpRightContainer>
          </ProductDetailVersionTwoTopContainer>
          <ProductDetailVersionTwoBottomContainer>
            <ProductDetailVersionTwoBottomLeftContainer>
              <ProductDetailVersionTwoBottomTitleWrapper>
                <StraightenIcon style={{ color: '#136CE2' }} />
                <ProductDetailVersionTwoBottomLeftTitle>Specificaties</ProductDetailVersionTwoBottomLeftTitle>
              </ProductDetailVersionTwoBottomTitleWrapper>
              <ProductDetailVersionTwoBottomLeftWrapper>
                {dataLeftSide.map((data) => (
                  <ProductDetailVersionTwoBottomDetailWrapper key={data.id} margin={`${isLargeScreen ? '4px' : '8px'}`}>
                    <ProductDetailVersionTwoBottomDetailKey>{data.key}:</ProductDetailVersionTwoBottomDetailKey>
                    <ProductDetailVersionTwoBottomDetailValue>{data.value}</ProductDetailVersionTwoBottomDetailValue>
                  </ProductDetailVersionTwoBottomDetailWrapper>
                ))}
              </ProductDetailVersionTwoBottomLeftWrapper>
            </ProductDetailVersionTwoBottomLeftContainer>
            <ProductDetailVersionTwoBottomRightContainer>
              {dataRightSide.map((data) => (
                <ProductDetailVersionTwoBottomDetailWrapper key={data.id} margin={`${isLargeScreen ? '4px' : '8px'}`}>
                  <ProductDetailVersionTwoBottomDetailKey>{data.key}:</ProductDetailVersionTwoBottomDetailKey>
                  <ProductDetailVersionTwoBottomDetailValue>{data.value}</ProductDetailVersionTwoBottomDetailValue>
                </ProductDetailVersionTwoBottomDetailWrapper>
              ))}
            </ProductDetailVersionTwoBottomRightContainer>
          </ProductDetailVersionTwoBottomContainer>
        </ProductDetailVersionTwoWrapper>
      )}
    </ProductDetailVersionTwoMainContainer>
  ) : (
    <MobileProductDetailVersionTwoContainer>
      {isProductDetailsDataLoading || !(nieuwImage || (file1Image && file2Image && file3Image && file4Image)) ? (
        <ProductDetailsVersionTwoLoaderPlaceholder>
          <Loader />
        </ProductDetailsVersionTwoLoaderPlaceholder>
      ) : (
        <MobileProductDetailVersionTwoContent>
          <MobileSliderWrapper>
            <Slider sliderItems={sliderItems} border="10px 10px 0px 0px" />
          </MobileSliderWrapper>
          <MobileProductDetailVersionTwo>
            <MobileProductDetailVersionTwoTitle>{title}</MobileProductDetailVersionTwoTitle>
            <ProductDetailVersionTwoUpRightPriceWrapper>
              <ProductDetailVersionTwoUpRightTitle style={{ fontSize: '36px' }}>
                Verkocht
              </ProductDetailVersionTwoUpRightTitle>
              {isOwner && (
                <ProductDetailVersionTwoMobileIconWrapper>
                  <VisibilityIcon
                    style={{
                      marginLeft: '10px',
                      marginRight: '10px',
                      color: styles.variantIconColor,
                    }}
                    fontSize="medium"
                  />
                  {viewCount}
                  <FavoriteIcon
                    style={{
                      marginLeft: '20px',
                      marginRight: '10px',
                      color: styles.variantIconColor,
                    }}
                    fontSize="medium"
                  />
                  {favoriteCount}
                </ProductDetailVersionTwoMobileIconWrapper>
              )}
            </ProductDetailVersionTwoUpRightPriceWrapper>
            {storagePlace && (
              <ProductDetailVersionTwoUpRightSubtitleWrapper>
                <ProductDetailVersionTwoUpRightSubtitleKey>Opslagplaats:</ProductDetailVersionTwoUpRightSubtitleKey>
                <ProductDetailVersionTwoUpRightSubtitleValue>
                  {storagePlace}
                </ProductDetailVersionTwoUpRightSubtitleValue>
              </ProductDetailVersionTwoUpRightSubtitleWrapper>
            )}
            {isOwner && (
              <ProductDetailVersionTwoButton
                onClick={handleBestellingBekijkenClick}
                marginTop="20px"
                hover={styles.primaryColorContactHoverContent}
                backgroundColor={styles.primaryColor}
              >
                Bestelling bekijken
              </ProductDetailVersionTwoButton>
            )}

            <MobileProductDetailVersionTwoSpecifications>
              <StraightenIcon style={{ color: '#136CE2', marginRight: '12px' }} />
              Specificaties
            </MobileProductDetailVersionTwoSpecifications>
            <div>
              {dataLeftSide.map((data) => (
                <ProductDetailVersionTwoBottomDetailWrapper key={data.id} margin={`${isLargeScreen ? '10px' : '4px'}`}>
                  <MobileProductDetailVersionTwoDetailKey>{data.key}:</MobileProductDetailVersionTwoDetailKey>
                  <MobileProductDetailVersionTwoDetailValue>{data.value}</MobileProductDetailVersionTwoDetailValue>
                </ProductDetailVersionTwoBottomDetailWrapper>
              ))}
            </div>
            <Divider />
            <div>
              {dataRightSide.map((data) => (
                <ProductDetailVersionTwoBottomDetailWrapper key={data.id} margin={`${isLargeScreen ? '10px' : '4px'}`}>
                  <MobileProductDetailVersionTwoDetailKey>{data.key}:</MobileProductDetailVersionTwoDetailKey>
                  <MobileProductDetailVersionTwoDetailValue>{data.value}</MobileProductDetailVersionTwoDetailValue>
                </ProductDetailVersionTwoBottomDetailWrapper>
              ))}
            </div>
            {isOwner && (
              <ProductDetailVersionTwoUpMobileWrapper>
                <ProductDetailVersionTwoUpRightDateWrapper>
                  <ProductDetailVersionTwoUpRightDateKey>Aangemaakt:</ProductDetailVersionTwoUpRightDateKey>
                  <ProductDetailVersionTwoUpRightDateValue>{created}</ProductDetailVersionTwoUpRightDateValue>
                </ProductDetailVersionTwoUpRightDateWrapper>
                <ProductDetailVersionTwoUpRightDateWrapper>
                  <ProductDetailVersionTwoUpRightDateKey>Online sinds:</ProductDetailVersionTwoUpRightDateKey>
                  <ProductDetailVersionTwoUpRightDateValue>{onlineSince}</ProductDetailVersionTwoUpRightDateValue>
                </ProductDetailVersionTwoUpRightDateWrapper>
              </ProductDetailVersionTwoUpMobileWrapper>
            )}
          </MobileProductDetailVersionTwo>
        </MobileProductDetailVersionTwoContent>
      )}
    </MobileProductDetailVersionTwoContainer>
  );
};

export default ProductDetailVersionTwo;
