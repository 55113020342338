import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const BetalenWrapper = styled.div`
  width: 35%;
  max-height: 665px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  min-width: 410px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    width: 100%;
    min-width: auto;
    max-width: 625px;
    max-height: 100%;
    margin: 20px 11px;
    padding: 20px;
    min-height: 100%;
  }
`;

export const BetalenTitleWrapper = styled.div`
  font-size: 33px;
  font-weight: 700;
  margin-bottom: 44px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    margin-bottom: 20px;
  }
`;

export const BetalenPayInformationWrapper = styled.div`
  font-size: 16px;
`;

export const BetalenOneButtonAditionalInfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const BetalenButtonWrapper = styled.div`
  width: 48%;
  min-width: 240px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    width: 100%;
  }
`;

export const BetalenButton = styled.button`
  margin-top: ${(props) => props.margin};
  color: #fff;
  border: none;
  background-color: ${(props) => props.backgroundColor};
  cursor: ${(props) => props.cursor};
  height: 42px;
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  transition: 0.2s;

  :focus {
    background-color: ${(props) => props.backgroundColor};
  }
  :hover {
    background-color: ${(props) => props.hover};
  }
`;

export const BetalenInfoMessageWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  border-radius: 8px;
  padding: 7px 15px;
  display: flex;
  gap: 9px;
  background-color: ${(props) => props.backgroundColor};
  margin-bottom: 15px;

  @media screen and (min-width: ${breakpoints.lg}px) {
    display: none;
  }

  display: none;
`;

export const BetalenCalendarIconWrapper = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BetalenMessageContentWrapper = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

export const BetalenTotalWrapper = styled.div`
  background-color: ${(props) => props.backgroundColor};
  width: 100%;
  padding: 7px 15px;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  margin-bottom: 17px;

  @media screen and (min-width: ${breakpoints.lg}px) {
    display: none;
  }
`;

export const BetalenTotalTextWrapper = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

export const BetalenTotalValueWrapper = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.color};
`;
