import React from 'react';
import styles from '../../../scss/_variables.scss';
import {
  ContactButtonContent,
  ContactButtonItem,
  ContactButtonWrapper,
  ContactButtonElementContainer,
  ContactContainer,
  ContactContent,
  ContactTitle,
  ContactWrapper,
} from './Contact.styles';

const Contact = () => {
  const handleEmailButtonClick = () => {
    window.open('https://helpdesk-broekhuis.freshservice.com/support/tickets/new', '_blank');
  };
  return (
    <ContactContainer backgroundColor={styles.white}>
      <ContactWrapper>
        <ContactTitle>Vragen? Neem contact op</ContactTitle>
        <ContactContent>
          Heeft u een vraag over ons aanbod, over uw bestelling, of heeft u een technisch probleem. We staan graag voor
          u klaar om het antwoord op uw vraag te vinden.
        </ContactContent>
        <ContactButtonWrapper>
          {/* <ContactButtonElementContainer>
						<ContactButtonItem
							color={styles.primaryColor}
							colorHover={styles.primaryColorContactHoverContent}
							backgroundColor={styles.primaryColorContact}
							backgroundColorHover={styles.primaryColorContactHover}
						>
							<PhoneIcon style={{ marginRight: '8px', fontSize: '26px' }} />
							<ContactButtonContent>052 575 0904</ContactButtonContent>
						</ContactButtonItem>
					</ContactButtonElementContainer> */}
          <ContactButtonElementContainer>
            {/* hides the chat button */}
            {/* <ContactButtonItem
							color={styles.primaryColor}
							colorHover={styles.primaryColorContactHoverContent}
							backgroundColor={styles.primaryColorContact}
							backgroundColorHover={styles.primaryColorContactHover}
						>
							<ChatIcon style={{ marginRight: '8px', fontSize: '26px' }} />
							<ContactButtonContent>Chat</ContactButtonContent>
						</ContactButtonItem> */}
          </ContactButtonElementContainer>
          <ContactButtonElementContainer>
            <ContactButtonItem
              color={styles.primaryColor}
              colorHover={styles.primaryColorContactHoverContent}
              backgroundColor={styles.primaryColorContact}
              backgroundColorHover={styles.primaryColorContactHover}
              onClick={handleEmailButtonClick}
            >
              <ContactButtonContent>Contact via Freshdesk</ContactButtonContent>
            </ContactButtonItem>
          </ContactButtonElementContainer>
        </ContactButtonWrapper>
      </ContactWrapper>
    </ContactContainer>
  );
};

export default Contact;
