import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const BestellingenGeneralContainer = styled.div`
  height: 100%;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    display: flex;
    justify-content: center;
  }
`;
export const BestellingenWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
  padding: 35px 40px;

  background-color: #fff;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    max-width: 625px;
    margin: 16px 11px;
    padding: 25px 20px;
  }
`;

export const BestellingenContentWrapper = styled.div`
  @media screen and (min-width: ${breakpoints.lg}px) {
    padding-right: ${(props) => props.padding};
    padding-bottom: 54px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 90%;
    width: 100%;
  }
`;

export const BestellingenLoaderPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 345px;
`;

export const BestellingenTogglerWrapper = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
  padding: 4px;
  display: flex;
  background-color: #f2f2f2;
  border-radius: 8px;
  gap: 10px;
`;

export const BestellingenActueelWrapper = styled.div`
  flex: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 6px;
  // background-color: #fff;
  transition: 0.2s ease-in-out;
  background-color: ${(props) => props.backgroundColor};
`;

export const BestellingenHistorieWrapper = styled.div`
  flex: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 6px;
  transition: 0.2s ease-in-out;
  background-color: ${(props) => props.backgroundColor};
`;

export const BestellingenContentTopFader = styled.div`
  @media screen and (min-width: ${breakpoints.lg}px) {
    position: absolute;
    height: 23px;
    left: 40px;
    top: 92px;
    right: 40px;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 1) 90%);
  }
`;

export const BestellingenContentBottomFader = styled.div`
  @media screen and (min-width: ${breakpoints.lg}px) {
    position: absolute;
    height: 23px;
    left: 40px;
    bottom: 37px;
    right: 40px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 1) 90%);
  }
`;

export const BestellingenNoResultsPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 95%;
  gap: 17px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    padding-bottom: 70px;
    min-height: calc(100vh - 210px);
  }
`;

export const BestellingenNoResultsIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  border-radius: 80px;
  background-color: ${(props) => props.background};
`;

export const BestellingenNoResultsIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 45px;
  font-weight: 700;
  color: ${(props) => props.color};
`;

export const BestellingenNoResultsText = styled.div`
  font-size: 16px;
  font-weight: 700;
`;
