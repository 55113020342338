import { StandardProps } from '../../../helpers/types';
import useClassNames from 'app/helpers/useClassNames';
import './DropdownItemSlot.scss';

export type DropdownItemSlotProps = StandardProps;

const DropdownItemSlot = (props: DropdownItemSlotProps) => {
  const { className, children, style } = props;
  const { merge, withClassPrefix } = useClassNames('dropdown-item-slot');
  const classes = merge(className, withClassPrefix());

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
};

export default DropdownItemSlot;
