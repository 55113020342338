import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const Toevoegen3Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 45%;
  height: auto;

  @media (max-width: 1600px) {
    width: 60%;
  }
  @media (max-width: 1300px) {
    width: 70%;
  }
  @media (max-width: 1100px) {
    width: 80%;
  }
  @media (max-width: ${breakpoints.md}px) {
    width: 95%;
  }
`;

export const Toevoegen3MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 70px;

  @media (max-width: ${breakpoints.md}px) {
    padding: 20px 20px;
  }
`;

export const Toevoegen3InfoContainer = styled.div`
  position: absolute;
  top: -240px;
  right: -215px;
  width: 300px;
  height: auto;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  z-index: 1;
  background-color: #fff;
`;

export const Toevoegen3InfoContainer2 = styled.div`
  position: absolute;
  top: -160px;
  right: -230px;
  width: 300px;
  height: auto;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  z-index: 1;
  background-color: #fff;
`;

export const Toevoegen3InfoContainer3 = styled.div`
  position: absolute;
  top: -150px;
  right: -200px;
  width: 300px;
  height: auto;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  z-index: 1;
  background-color: #fff;
`;

export const Toevoegen3InfoContainer4 = styled.div`
  position: absolute;
  top: -285px;
  right: -210px;
  width: 300px;
  height: auto;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  z-index: 1;
  background-color: #fff;
`;

export const Toevoegen3InfoContainer5 = styled.div`
  position: absolute;
  top: -175px;
  right: -200px;
  width: 300px;
  height: auto;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  z-index: 1;
  background-color: #fff;
`;

export const Toevoegen3Title = styled.h1`
  font-size: 40px;
  font-weight: 800;
`;

export const Toevoegen3TyreImageWrapper = styled.div`
  width: 98%;
  position: relative;
`;

export const Toevoegen3TyreImage = styled.img`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  border-bottom: 2px solid #979797;
`;

export const Toevoegen3SubtitleInfoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 30px;
  width: 25%;
`;

export const Toevoegen3Subtitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
`;

export const Toevoegen3DropdownWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;
    margin-bottom: 20px;
    font-size: 18px;
  }
`;

export const Toevoegen3DropdownMobileWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Toevoegen3DropdownItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 20px;
  margin-bottom: 40px;

  @media (max-width: ${breakpoints.md}px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

export const Toevoegen3MobileDropdownItem = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Toevoegen3DropdownSelect = styled.select`
  color: ${(props) => props.color};
  display: flex !important;
  border: 1px solid #999 !important;
  border-radius: 10px;
  margin-top: 10px;
  height: 50px;
  padding: 0px 15px !important;
  /* StackOverflow answer from Romualds Cirsis except SVG Url */
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='grey' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
  /**/
  cursor: pointer;
`;

export const Toevoegen3SelectWrapper = styled.div`
  width: 75%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-top: 10px;

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;
    width: 100%;
    height: 220px;
  }
`;

export const Toevoegen3SelectWrapper2 = styled.div`
  width: 50%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-top: 10px;

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
  }
`;

export const Toevoegen3SelectItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 50px;
  border: 1px solid ${(props) => props.borderColor};
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  font-size: 18px;
  font-weight: ${(props) => props.fw};
  cursor: pointer;
  transition: all 0.3s ease;

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
  }
`;

export const Toevoegen3Button = styled.button`
  margin-top: 70px;
  margin-bottom: 20px;
  color: #fff;
  border: none;
  background-color: ${(props) => props.backgroundColor};
  height: 45px;
  width: 50%;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 400;
  transition: 0.2s;
  cursor: ${(props) => props.cursor};

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
    margin-top: 90px;
  }

  :hover {
    background-color: ${(props) => props.hover};
  }

  :focus {
    background-color: ${(props) => props.focusBackgroundColor};
  }
`;
