import { forwardRef, useMemo } from 'react';
import { StandardProps } from '../../../helpers/types';
import ButtonGroupContext, { ButtonGroupContextValue } from './ButtonGroupContext';
import useClassNames from 'app/helpers/useClassNames';

import './ButtonGroup.scss';

export interface ButtonGroupProps extends StandardProps {
  spacing?: 'dense' | 'regular' | 'spacious';
  direction?: 'horizontal' | 'vertical' | 'vertical-reverse';
  fullwidth?: boolean;
  placement?: 'start' | 'center' | 'end';
}

const ButtonGroup = forwardRef<HTMLDivElement, ButtonGroupProps>((props: ButtonGroupProps, ref) => {
  const { className, children, direction, spacing, fullwidth, placement = 'center', ...rest } = props;

  const { withClassPrefix, merge } = useClassNames('btn-group');
  const classes = merge(className, withClassPrefix(direction, spacing, placement));

  const buttonGroupContextValue = useMemo<ButtonGroupContextValue>(() => {
    const value: ButtonGroupContextValue = {
      fullwidth,
    };
    return value;
  }, [fullwidth]);

  return (
    <ButtonGroupContext.Provider value={buttonGroupContextValue}>
      <div role="group" ref={ref} className={classes} {...rest}>
        {children}
      </div>
    </ButtonGroupContext.Provider>
  );
});

ButtonGroup.displayName = 'ButtonGroup';

export default ButtonGroup;
