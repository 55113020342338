import styled from 'styled-components';

export const PopupContainer = styled.div`
  position: fixed;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 520px;
  height: ${(props) => props.height}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 10px;
  opacity: 1 !important;
  z-index: 99;

  @media screen and (max-width: 570px) {
    left: 17px;
    right: 17px;
    width: auto;
  }
`;

export const PopupWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding: 10px 40px;
`;

export const PopupTitle = styled.h3`
  font-size: 22px;
  color: ${(props) => props.color};
  font-weight: bold;
`;

export const PopupSpan = styled.span`
  font-size: ${(props) => props.fontSize}px;
  font-weight: 500;
`;

export const PopupInputLabel = styled.span`
  font-size: 15px;
  font-weight: bold;
  margin-top: 20px;
`;

export const PoptupInput = styled.input`
  width: 95% !important;
  height: 40px !important;
  border: 1px solid black !important;
  border-radius: 8px !important;
  margin-top: 10px !important;
  padding: 0px 9px !important;

  :focus {
    border: 1px solid ${(props) => props.border} !important;
  }
`;

export const PopupInputP = styled.p`
  font-size: 12px;
  color: ${(props) => props.color};
  margin-top: 0;
  font-weight: 500;
`;

export const AnnulerenButton = styled.button`
  font-size: 17px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 8px;
  margin-top: ${(props) => props.margin}px;
  cursor: pointer;

  :focus {
    background-color: ${(props) => props.hoverBackgroundColor};
    color: ${(props) => props.hoverColor};
  }

  :hover {
    background-color: ${(props) => props.hoverBackgroundColor};
    color: ${(props) => props.hoverColor};
  }
`;

export const DeleteButton = styled.button`
  font-size: 17px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 8px;
  margin-top: 10px;
  cursor: pointer;
  cursor: ${(props) => props.cursor};
  transition: 0.2s;

  :focus {
    background-color: ${(props) => props.hoverBackgroundColor};
    color: ${(props) => props.hoverColor};
  }

  :hover {
    background-color: ${(props) => props.hoverBackgroundColor};
    color: ${(props) => props.hoverColor};
  }
`;

export const AdvertentieIntrekkenButton = styled.button`
  font-size: 17px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 8px;
  margin-top: 10px;
  cursor: pointer;

  :focus {
    background-color: ${(props) => props.hoverBackgroundColor};
    color: ${(props) => props.hoverColor};
  }

  :hover {
    background-color: ${(props) => props.hoverBackgroundColor};
    color: ${(props) => props.hoverColor};
  }
`;
