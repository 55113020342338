import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const AccordionContainer = styled.div`
  border-bottom: ${(props) => props.borderBottom};
  margin: 10px;
`;

export const AccordionTitle = styled.div`
  margin: 31px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    font-size: 16px;
  }
`;

export const AccordionTitleItem = styled.div`
  cursor: pointer;
`;

export const AccordionTitleIcon = styled.div``;

export const AccordionContent = styled.div`
  font-size: 18px;
  color: #666666;

  transition: max-height 0.3s ease;
  max-height: ${(props) => props.visibility};
  overflow: hidden;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    font-size: 14px;
  }
`;

export const AccordionActualContent = styled.div`
  padding-bottom: 20px;
`;

export const AccordionIconContainer = styled.div`
  color: #666666;
  display: flex;
  transition: 0.3s ease;
  transform: ${(props) => props.transform};
`;
