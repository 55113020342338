import { useMutation } from '@tanstack/react-query';

import { createProduct, CreateProductsPropsType } from 'app/api/createProduct';

const useCreateProduct = () => {
  const createProductMutation = useMutation(createProduct);

  const onClickCreate = (data: CreateProductsPropsType) => createProductMutation.mutate(data);

  const onClearErrorMessage = () => createProductMutation.reset();

  const { isLoading, isError, error, data, isSuccess } = createProductMutation;

  return {
    createProductMutation,
    onClickCreate,
    onClearErrorMessage,
    isLoading,
    isError,
    error,
    isSuccess,
    data,
  };
};

export default useCreateProduct;
