import apiClient from 'app/axios/api-client';

type DeleteProductPropsType = {
  id: number;
};

export const deleteProduct = async (data: DeleteProductPropsType) => {
  const response = await apiClient.delete(`bandenset/${data.id}`);

  return response.data;
};
