import { useMutation } from '@tanstack/react-query';

import { verifyCarInfo } from 'app/api/verifyCarInfo';

export type VerifyCarInfoPropsType = {
  brand: string;
  model: string;
  build_year: number;
};

const useVerifyCarInfo = () => {
  const verifyCarInfoMutation = useMutation(verifyCarInfo);

  const onClickVerify = (data: VerifyCarInfoPropsType) => verifyCarInfoMutation.mutate(data);

  const onClearErrorMessage = () => verifyCarInfoMutation.reset();

  const {
    isLoading: isVerifyCarInfoLoading,
    isError: isVerifyCarInfoError,
    error: verifyCarInfoError,
    data: verifyCarInfoData,
    isSuccess: isVerfiyCarInfoSuccess,
  } = verifyCarInfoMutation;

  return {
    isVerifyCarInfoLoading,
    isVerifyCarInfoError,
    verifyCarInfoError,
    verifyCarInfoData,
    isVerfiyCarInfoSuccess,
    verifyCarInfoMutation,
    onClickVerify,
    onClearErrorMessage,
  };
};

export default useVerifyCarInfo;
