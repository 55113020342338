import { useState, useEffect } from 'react';
// Styled Components
import InfoIcon from '@mui/icons-material/Info';
import styles from '../../../../scss/_variables.scss';
import { bandenmerken, velgenmerker } from '../../../../dropdownData';
import {
  Toevoegen3Button,
  Toevoegen3Container,
  Toevoegen3DropdownItem,
  Toevoegen3DropdownMobileWrapper,
  Toevoegen3DropdownSelect,
  Toevoegen3DropdownWrapper,
  Toevoegen3InfoContainer,
  Toevoegen3InfoContainer3,
  Toevoegen3InfoContainer4,
  Toevoegen3InfoContainer5,
  Toevoegen3MainWrapper,
  Toevoegen3MobileDropdownItem,
  Toevoegen3SelectItem,
  Toevoegen3SelectWrapper,
  Toevoegen3SelectWrapper2,
  Toevoegen3Subtitle,
  Toevoegen3SubtitleInfoWrapper,
  Toevoegen3Title,
  Toevoegen3TyreImage,
  Toevoegen3TyreImageWrapper,
} from './Toevoegen3.styles';
// Colors
// MUI Icons
// Break Points
import useWindowSize from 'app/hooks/useWindowSize';
import { breakpoints } from 'app/config/breakpoints';
// Dropdown Data
import useBreedte from 'app/hooks/TyreProperties/useBreedte';
import useHoogte from 'app/hooks/TyreProperties/useHoogte';
import useInchmaat from 'app/hooks/TyreProperties/useInchmaat';

const Toevoegen3 = ({
  setToevoegenStep,
  setBandenmerk,
  setSeizoen,
  stepThreeValues,
  setStepThreeValues,
  breedte,
  setBreedte,
  hoogte,
  setHoogte,
  inchMaat,
  setInchmaat,
  tyreBandenmerk,
  setTyreBandenMerk,
  velgenMerkOptional,
  setVelgenMerkOptional,
}) => {
  const { width } = useWindowSize();

  const { data: breedteDropdownValues } = useBreedte();
  const { data: hoogteDropdownValues } = useHoogte();
  const { data: inchmaatDropdownValues } = useInchmaat();

  const tyreImage =
    width >= breakpoints.md
      ? 'https://dam.broekhuis.online/online/tyreswap/tire-size-graphic-large.png'
      : 'https://dam.broekhuis.online/online/tyreswap/tire-size-graphic-small.png';

  const [buttonActive3, setButtonActive3] = useState(false);

  const [infoActive, setInfoActive] = useState({
    info1: false,
    info2: false,
    info3: false,
    info4: false,
    info5: false,
  });

  const [changed, setChanged] = useState({
    breedte: false,
    hoogte: false,
    inchmaat: false,
    bandenmerk: false,
    velgenmerk: false,
  });

  useEffect(() => {
    if (stepThreeValues.seizoen === 0) {
      setSeizoen('Summer');
    } else if (stepThreeValues.seizoen === 1) {
      setSeizoen('Winter');
    } else if (stepThreeValues.seizoen === 2) {
      setSeizoen('All Season');
    }
  }, [stepThreeValues.seizoen]);

  // validation
  useEffect(() => {
    if (
      breedte !== '' &&
      hoogte !== '' &&
      inchMaat !== '' &&
      tyreBandenmerk !== '' &&
      stepThreeValues.seizoen !== -1 &&
      (stepThreeValues.seizoen === 0 || (stepThreeValues.ms !== -1 && stepThreeValues.pmsf !== -1)) &&
      stepThreeValues.tpms !== -1 &&
      stepThreeValues.runflat !== -1
    ) {
      setButtonActive3(true);
    } else {
      if (buttonActive3) {
        setButtonActive3(false);
      }
    }
  }, [breedte, hoogte, inchMaat, tyreBandenmerk, stepThreeValues]);

  const handleClick = () => {
    if (buttonActive3) {
      setToevoegenStep(3);
    }
  };

  const handleBandenmerkChange = (event) => {
    setBandenmerk(event.target.value);
    setTyreBandenMerk(event.target.value);
    setChanged((prev) => ({ ...prev, bandenmerk: true }));
  };

  return (
    <Toevoegen3Container backgroundColor={styles.white}>
      <Toevoegen3MainWrapper>
        <Toevoegen3Title>Specificaties</Toevoegen3Title>
        <Toevoegen3TyreImageWrapper>
          <Toevoegen3TyreImage src={tyreImage} alt="" />
        </Toevoegen3TyreImageWrapper>
        {width > breakpoints.md ? (
          <Toevoegen3DropdownWrapper>
            <Toevoegen3DropdownItem>
              <Toevoegen3Subtitle>Breedte</Toevoegen3Subtitle>
              <Toevoegen3DropdownSelect
                value={breedte}
                onChange={(event) => {
                  setChanged((prev) => ({ ...prev, breedte: true }));
                  setBreedte(event.target.value);
                }}
                color={changed.breedte ? '#000' : styles.greyNormal}
              >
                <option value="" hidden />
                {breedteDropdownValues &&
                  breedteDropdownValues.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
              </Toevoegen3DropdownSelect>
            </Toevoegen3DropdownItem>
            <Toevoegen3DropdownItem>
              <Toevoegen3Subtitle>Hoogte</Toevoegen3Subtitle>
              <Toevoegen3DropdownSelect
                value={hoogte}
                onChange={(event) => {
                  setChanged((prev) => ({ ...prev, hoogte: true }));
                  setHoogte(event.target.value);
                }}
                color={changed.hoogte ? '#000' : styles.greyNormal}
              >
                <option value="" hidden />
                {hoogteDropdownValues &&
                  hoogteDropdownValues.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
              </Toevoegen3DropdownSelect>
            </Toevoegen3DropdownItem>
            <Toevoegen3DropdownItem>
              <Toevoegen3Subtitle>Inchmaat</Toevoegen3Subtitle>
              <Toevoegen3DropdownSelect
                value={inchMaat}
                onChange={(event) => {
                  setChanged((prev) => ({ ...prev, inchmaat: true }));
                  setInchmaat(event.target.value);
                }}
                color={changed.inchmaat ? '#000' : styles.greyNormal}
              >
                <option value="" hidden />
                {inchmaatDropdownValues &&
                  inchmaatDropdownValues.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
              </Toevoegen3DropdownSelect>
            </Toevoegen3DropdownItem>
          </Toevoegen3DropdownWrapper>
        ) : (
          <Toevoegen3DropdownWrapper>
            <Toevoegen3DropdownMobileWrapper>
              <Toevoegen3MobileDropdownItem style={{ marginRight: '10px' }}>
                <Toevoegen3Subtitle>Breedte</Toevoegen3Subtitle>
                <Toevoegen3DropdownSelect
                  value={breedte}
                  onChange={(event) => {
                    setChanged((prev) => ({ ...prev, breedte: true }));
                    setBreedte(event.target.value);
                  }}
                  color={changed.breedte ? '#000' : styles.greyNormal}
                >
                  <option value="" hidden />
                  {breedteDropdownValues &&
                    breedteDropdownValues.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                </Toevoegen3DropdownSelect>
              </Toevoegen3MobileDropdownItem>
              <Toevoegen3MobileDropdownItem style={{ marginLeft: '10px' }}>
                <Toevoegen3Subtitle>Hoogte</Toevoegen3Subtitle>
                <Toevoegen3DropdownSelect
                  value={hoogte}
                  onChange={(event) => {
                    setChanged((prev) => ({ ...prev, hoogte: true }));
                    setHoogte(event.target.value);
                  }}
                  color={changed.hoogte ? '#000' : styles.greyNormal}
                >
                  <option value="" hidden />
                  {hoogteDropdownValues &&
                    hoogteDropdownValues.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                </Toevoegen3DropdownSelect>
              </Toevoegen3MobileDropdownItem>
            </Toevoegen3DropdownMobileWrapper>
            <Toevoegen3DropdownItem style={{ marginTop: '20px', marginBottom: '20px' }}>
              <Toevoegen3Subtitle>Inchmaat</Toevoegen3Subtitle>
              <Toevoegen3DropdownSelect
                value={inchMaat}
                onChange={(event) => {
                  setChanged((prev) => ({ ...prev, inchmaat: true }));
                  setInchmaat(event.target.value);
                }}
                color={changed.inchmaat ? '#000' : styles.greyNormal}
              >
                <option value="" hidden />
                {inchmaatDropdownValues &&
                  inchmaatDropdownValues.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
              </Toevoegen3DropdownSelect>
            </Toevoegen3DropdownItem>
          </Toevoegen3DropdownWrapper>
        )}
        <Toevoegen3DropdownWrapper>
          <Toevoegen3DropdownItem>
            <Toevoegen3DropdownSelect
              value={tyreBandenmerk}
              onChange={(event) => handleBandenmerkChange(event)}
              color={changed.bandenmerk ? '#000' : styles.greyNormal}
            >
              <option style={{ color: '#000' }} value="" disabled>
                Bandenmerk
              </option>
              {bandenmerken.map((benden) => (
                <option key={benden.id} style={{ color: '#000' }} value={benden.value}>
                  {benden.value}
                </option>
              ))}
            </Toevoegen3DropdownSelect>
          </Toevoegen3DropdownItem>
          <Toevoegen3DropdownItem>
            <Toevoegen3DropdownSelect
              value={velgenMerkOptional}
              onChange={(event) => {
                setChanged((prev) => ({ ...prev, velgenmerk: true }));
                setVelgenMerkOptional(event.target.value);
              }}
              color={changed.velgenmerk ? '#000' : styles.greyNormal}
            >
              <option style={{ color: '#000' }} value="" disabled>
                Velgenmerk (optioneel)
              </option>
              {velgenmerker.map((velgen) => (
                <option key={velgen.id} style={{ color: '#000' }} value={velgen.value}>
                  {velgen.value}
                </option>
              ))}
            </Toevoegen3DropdownSelect>
          </Toevoegen3DropdownItem>
        </Toevoegen3DropdownWrapper>
        <Toevoegen3Subtitle>Seizoen</Toevoegen3Subtitle>
        <Toevoegen3SelectWrapper>
          <Toevoegen3SelectItem
            backgroundColor={stepThreeValues.seizoen === 0 ? styles.primaryColorContact : styles.white}
            color={stepThreeValues.seizoen === 0 ? styles.primaryColor : '#000'}
            fw={stepThreeValues.seizoen === 0 ? '700' : '500'}
            borderColor={stepThreeValues.seizoen === 0 ? styles.primaryColor : styles.greyNormal}
            style={
              width > breakpoints.md ? { borderRadius: '10px 0px 0px 10px' } : { borderRadius: '10px 10px 0px 0px' }
            }
            onClick={() => setStepThreeValues((prev) => ({ ...prev, seizoen: 0 }))}
          >
            Zomer
          </Toevoegen3SelectItem>
          <Toevoegen3SelectItem
            backgroundColor={stepThreeValues.seizoen === 1 ? styles.primaryColorContact : styles.white}
            color={stepThreeValues.seizoen === 1 ? styles.primaryColor : '#000'}
            fw={stepThreeValues.seizoen === 1 ? '700' : '500'}
            borderColor={stepThreeValues.seizoen === 1 ? styles.primaryColor : styles.greyNormal}
            onClick={() => setStepThreeValues((prev) => ({ ...prev, seizoen: 1 }))}
          >
            Winter
          </Toevoegen3SelectItem>
          <Toevoegen3SelectItem
            backgroundColor={stepThreeValues.seizoen === 2 ? styles.primaryColorContact : styles.white}
            color={stepThreeValues.seizoen === 2 ? styles.primaryColor : '#000'}
            fw={stepThreeValues.seizoen === 2 ? '700' : '500'}
            borderColor={stepThreeValues.seizoen === 2 ? styles.primaryColor : styles.greyNormal}
            style={
              width > breakpoints.md
                ? { borderRadius: '0px 10px 10px 0px' }
                : {
                    borderRadius: '0px 0px 10px 10px',
                  }
            }
            onClick={() => setStepThreeValues((prev) => ({ ...prev, seizoen: 2 }))}
          >
            All-season
          </Toevoegen3SelectItem>
        </Toevoegen3SelectWrapper>

        {stepThreeValues.seizoen !== 0 && (
          <>
            <Toevoegen3SubtitleInfoWrapper>
              <Toevoegen3Subtitle>M + S</Toevoegen3Subtitle>
              <InfoIcon
                style={{ color: styles.primaryColor, cursor: 'pointer' }}
                fontSize="inherit"
                onMouseEnter={() => setInfoActive((prev) => ({ ...prev, info2: true }))}
                onMouseLeave={() => setInfoActive((prev) => ({ ...prev, info2: false }))}
              />
              {infoActive.info2 && (
                <Toevoegen3InfoContainer3>
                  Dit staat voor Mud + Snow, deze banden zijn geschikt voor rijden op modder en sneeuw. Niet alle banden
                  waar M+S op staat zijn winterbanden.
                </Toevoegen3InfoContainer3>
              )}
            </Toevoegen3SubtitleInfoWrapper>
            <Toevoegen3SelectWrapper2>
              <Toevoegen3SelectItem
                backgroundColor={stepThreeValues.ms === 0 ? styles.primaryColorContact : styles.white}
                color={stepThreeValues.ms === 0 ? styles.primaryColor : '#000'}
                fw={stepThreeValues.ms === 0 ? '700' : '500'}
                borderColor={stepThreeValues.ms === 0 ? styles.primaryColor : styles.greyNormal}
                style={{ borderRadius: '10px 0px 0px 10px' }}
                onClick={() => setStepThreeValues((prev) => ({ ...prev, ms: 0 }))}
              >
                Ja
              </Toevoegen3SelectItem>
              <Toevoegen3SelectItem
                backgroundColor={stepThreeValues.ms === 1 ? styles.primaryColorContact : styles.white}
                color={stepThreeValues.ms === 1 ? styles.primaryColor : '#000'}
                fw={stepThreeValues.ms === 1 ? '700' : '500'}
                borderColor={stepThreeValues.ms === 1 ? styles.primaryColor : styles.greyNormal}
                style={{ borderRadius: '0px 10px 10px 0px' }}
                onClick={() => setStepThreeValues((prev) => ({ ...prev, ms: 1 }))}
              >
                Nee
              </Toevoegen3SelectItem>
            </Toevoegen3SelectWrapper2>
            <Toevoegen3SubtitleInfoWrapper>
              <Toevoegen3Subtitle>3PMSF</Toevoegen3Subtitle>
              <InfoIcon
                style={{ color: styles.primaryColor, cursor: 'pointer' }}
                fontSize="inherit"
                onMouseEnter={() => setInfoActive((prev) => ({ ...prev, info3: true }))}
                onMouseLeave={() => setInfoActive((prev) => ({ ...prev, info3: false }))}
              />
              {infoActive.info3 && (
                <Toevoegen3InfoContainer4>
                  Naast het M+S kenmerk hebben sommige banden ook pictogram van een sneeuwvlok tegen een berg
                  achtergrond aan. Wanneer dit teken op de band staat weet je dat je band garantie biedt op prestaties
                  in de sneeuw. Het beste kan je daarom winterbanden nemen met dit kenmerk, zo weet je zeker dat je
                  banden voldoende tractie hebben in winterse omstandigheden.
                </Toevoegen3InfoContainer4>
              )}
            </Toevoegen3SubtitleInfoWrapper>
            <Toevoegen3SelectWrapper2>
              <Toevoegen3SelectItem
                backgroundColor={stepThreeValues.pmsf === 0 ? styles.primaryColorContact : styles.white}
                color={stepThreeValues.pmsf === 0 ? styles.primaryColor : '#000'}
                fw={stepThreeValues.pmsf === 0 ? '700' : '500'}
                borderColor={stepThreeValues.pmsf === 0 ? styles.primaryColor : styles.greyNormal}
                style={{ borderRadius: '10px 0px 0px 10px' }}
                onClick={() => setStepThreeValues((prev) => ({ ...prev, pmsf: 0 }))}
              >
                Ja
              </Toevoegen3SelectItem>
              <Toevoegen3SelectItem
                backgroundColor={stepThreeValues.pmsf === 1 ? styles.primaryColorContact : styles.white}
                color={stepThreeValues.pmsf === 1 ? styles.primaryColor : '#000'}
                fw={stepThreeValues.pmsf === 1 ? '700' : '500'}
                borderColor={stepThreeValues.pmsf === 1 ? styles.primaryColor : styles.greyNormal}
                style={{ borderRadius: '0px 10px 10px 0px' }}
                onClick={() => setStepThreeValues((prev) => ({ ...prev, pmsf: 1 }))}
              >
                Nee
              </Toevoegen3SelectItem>
            </Toevoegen3SelectWrapper2>
          </>
        )}

        <Toevoegen3SubtitleInfoWrapper>
          <Toevoegen3Subtitle>TPMS</Toevoegen3Subtitle>
          <InfoIcon
            style={{ color: styles.primaryColor, cursor: 'pointer' }}
            fontSize="inherit"
            onMouseEnter={() => setInfoActive((prev) => ({ ...prev, info4: true }))}
            onMouseLeave={() => setInfoActive((prev) => ({ ...prev, info4: false }))}
          />
          {infoActive.info4 && (
            <Toevoegen3InfoContainer5>
              Dit is de aanduiding van het bandenspanningscontrolesysteem (Tyre Pressure Monitoring System TPMS). Sinds
              2014 is het verplicht dat elke nieuwe auto dit systeem aan boord heeft
            </Toevoegen3InfoContainer5>
          )}
        </Toevoegen3SubtitleInfoWrapper>
        <Toevoegen3SelectWrapper>
          <Toevoegen3SelectItem
            backgroundColor={stepThreeValues.tpms === 0 ? styles.primaryColorContact : styles.white}
            color={stepThreeValues.tpms === 0 ? styles.primaryColor : '#000'}
            fw={stepThreeValues.tpms === 0 ? '700' : '500'}
            borderColor={stepThreeValues.tpms === 0 ? styles.primaryColor : styles.greyNormal}
            style={
              width > breakpoints.md ? { borderRadius: '10px 0px 0px 10px' } : { borderRadius: '10px 10px 0px 0px' }
            }
            onClick={() => setStepThreeValues((prev) => ({ ...prev, tpms: 0 }))}
          >
            Ja
          </Toevoegen3SelectItem>
          <Toevoegen3SelectItem
            backgroundColor={stepThreeValues.tpms === 1 ? styles.primaryColorContact : styles.white}
            color={stepThreeValues.tpms === 1 ? styles.primaryColor : '#000'}
            fw={stepThreeValues.tpms === 1 ? '700' : '500'}
            borderColor={stepThreeValues.tpms === 1 ? styles.primaryColor : styles.greyNormal}
            onClick={() => setStepThreeValues((prev) => ({ ...prev, tpms: 1 }))}
          >
            Nee
          </Toevoegen3SelectItem>
          <Toevoegen3SelectItem
            backgroundColor={stepThreeValues.tpms === 2 ? styles.primaryColorContact : styles.white}
            color={stepThreeValues.tpms === 2 ? styles.primaryColor : '#000'}
            fw={stepThreeValues.tpms === 2 ? '700' : '500'}
            borderColor={stepThreeValues.tpms === 2 ? styles.primaryColor : styles.greyNormal}
            style={
              width > breakpoints.md ? { borderRadius: '0px 10px 10px 0px' } : { borderRadius: '0px 0px 10px 10px' }
            }
            onClick={() => setStepThreeValues((prev) => ({ ...prev, tpms: 2 }))}
          >
            Onbekend
          </Toevoegen3SelectItem>
        </Toevoegen3SelectWrapper>
        <Toevoegen3SubtitleInfoWrapper>
          <Toevoegen3Subtitle>Runflat</Toevoegen3Subtitle>
          <InfoIcon
            style={{ color: styles.primaryColor, cursor: 'pointer' }}
            fontSize="inherit"
            onMouseEnter={() => setInfoActive((prev) => ({ ...prev, info5: true }))}
            onMouseLeave={() => setInfoActive((prev) => ({ ...prev, info5: false }))}
          />
          {infoActive.info5 && (
            <Toevoegen3InfoContainer>
              Wanneer je een Runflat band onder je auto hebt kan je bij een lekke band tot ongeveer 80 KM doorrijden.
              Dit komt door de versterkte zijkanten van de band, houd er wel rekening mee dat je niet harder dan 80
              kilometer per uur rijdt. Runflat banden kunnen alleen op een auto gemonteerd worden welke een TPMS heeft.
            </Toevoegen3InfoContainer>
          )}
        </Toevoegen3SubtitleInfoWrapper>
        <Toevoegen3SelectWrapper>
          <Toevoegen3SelectItem
            backgroundColor={stepThreeValues.runflat === 0 ? styles.primaryColorContact : styles.white}
            color={stepThreeValues.runflat === 0 ? styles.primaryColor : '#000'}
            fw={stepThreeValues.runflat === 0 ? '700' : '500'}
            borderColor={stepThreeValues.runflat === 0 ? styles.primaryColor : styles.greyNormal}
            style={
              width > breakpoints.md ? { borderRadius: '10px 0px 0px 10px' } : { borderRadius: '10px 10px 0px 0px' }
            }
            onClick={() => setStepThreeValues((prev) => ({ ...prev, runflat: 0 }))}
          >
            Ja
          </Toevoegen3SelectItem>
          <Toevoegen3SelectItem
            backgroundColor={stepThreeValues.runflat === 1 ? styles.primaryColorContact : styles.white}
            color={stepThreeValues.runflat === 1 ? styles.primaryColor : '#000'}
            fw={stepThreeValues.runflat === 1 ? '700' : '500'}
            borderColor={stepThreeValues.runflat === 1 ? styles.primaryColor : styles.greyNormal}
            onClick={() => setStepThreeValues((prev) => ({ ...prev, runflat: 1 }))}
          >
            Nee
          </Toevoegen3SelectItem>
          <Toevoegen3SelectItem
            backgroundColor={stepThreeValues.runflat === 2 ? styles.primaryColorContact : styles.white}
            color={stepThreeValues.runflat === 2 ? styles.primaryColor : '#000'}
            fw={stepThreeValues.runflat === 2 ? '700' : '500'}
            borderColor={stepThreeValues.runflat === 2 ? styles.primaryColor : styles.greyNormal}
            style={
              width > breakpoints.md
                ? { borderRadius: '0px 10px 10px 0px' }
                : {
                    borderRadius: '0px 0px 10px 10px',
                  }
            }
            onClick={() => setStepThreeValues((prev) => ({ ...prev, runflat: 2 }))}
          >
            Onbekend
          </Toevoegen3SelectItem>
        </Toevoegen3SelectWrapper>
        <Toevoegen3Button
          disabled={!buttonActive3}
          hover={buttonActive3 ? styles.primaryColorContactHoverContent : styles.greyNormal}
          backgroundColor={buttonActive3 ? styles.primaryColor : styles.greyNormal}
          focusBackgroundColor={styles.primaryColor}
          cursor={buttonActive3 ? 'pointer' : 'not-allowed'}
          onClick={() => handleClick()}
        >
          Verder
        </Toevoegen3Button>
      </Toevoegen3MainWrapper>
    </Toevoegen3Container>
  );
};

export default Toevoegen3;
