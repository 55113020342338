import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const HomePageContainer = styled.div`
  min-height: calc(100vh - 68px);
  padding-top: 53px;
  padding-botton: 10px;
  display: flex;
  justify-content: center;
  gap: 18px;
  width: 100%;
  position: relative;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    padding-top: 0px;
  }
`;

export const MobileHomePageTransparentBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #262626;
  z-index: 1;
  opacity: 0.4;
`;
