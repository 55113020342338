import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Favorite from '@mui/icons-material/Favorite';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import Person from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import useAccount from '../../hooks/useAccount';
import Card from 'app/components/card';
import { accessibleOnClick } from 'app/helpers/utils';
import { logout } from 'auth/auth-logout';
import { GlobalStateType } from 'app/helpers/types';
const activePathNameTextStyle = {
  color: '#136CE2',
  fontWeight: 700,
};
interface AccountPropsType {
  isLargeScreen?: boolean | 0;
}

const Account = ({ isLargeScreen }: AccountPropsType) => {
  const location = useLocation();
  const navigate = useNavigate();

  const aantalBestellingen = useSelector((state: GlobalStateType) => state.bestellingen.aantalBestellingen);
  const ordersBadge = <div className="badge red">{aantalBestellingen}</div>;
  const user = useAccount();

  let givenName = '';
  let familyName = '';
  let dhwID = '';
  let companyID = '';
  let scopes = '';
  if (user.status === 'success') {
    givenName = user.data.given_name;
    familyName = user.data.family_name;
    dhwID = user.data.company.dwh_id;
    companyID = user.data.company_id;
    scopes = user.data.scopes;
  }
  return (
    <div className="accountWrapper">
      <Card className="account">
        {!isLargeScreen ? (
          <div className="accountName">
            <div className="personLogoWrapper">
              <Person style={{ fill: '#75C42E' }} fontSize="large" />
            </div>
            <div>
              <div className="userName">
                {givenName} {familyName}
              </div>
              <div className="userPosition">dwh_id: {dhwID}</div>
              <div className="userPosition">salaris administratie id vestiging: {companyID}</div>
              <div className="userPosition">scopes (rechten): {scopes}</div>
            </div>
          </div>
        ) : (
          <div className="mijnAccountTitle">Mijn account</div>
        )}
        <ul className="collection customCollection">
          <div>
            <li className="collection-item avatar" {...accessibleOnClick(() => navigate('/user/bestellingen'))}>
              <LocalShippingIcon />
              <span
                className="title"
                style={isLargeScreen && location.pathname.includes('bestellingen') ? activePathNameTextStyle : {}}
              >
                Bestellingen {aantalBestellingen > 0 && ordersBadge}
              </span>
              <div
                className={`${isLargeScreen && location.pathname.includes('bestellingen') && 'activePathnameWrapper'}`}
              />
              <a href="#!" className="secondary-content">
                <ChevronRightIcon
                  style={isLargeScreen && location.pathname.includes('bestellingen') ? activePathNameTextStyle : {}}
                />
              </a>
            </li>
            {/* <li
          className="collection-item avatar"
          onClick={() => navigate("/orders")}
        >
          <InventoryIcon />
          <span className="title">Mijn voorraad</span>
          <a href="#!" className="secondary-content">
            <ChevronRightIcon />
          </a>
        </li> */}
            {/* /BUSINESS */}
            <li className="collection-item avatar" {...accessibleOnClick(() => navigate('/user/mijn-aankopen'))}>
              <ShoppingBagIcon />
              <span
                className="title"
                style={isLargeScreen && location.pathname.includes('mijn-aankopen') ? activePathNameTextStyle : {}}
              >
                Mijn aankopen
              </span>
              <div
                className={`${isLargeScreen && location.pathname.includes('mijn-aankopen') && 'activePathnameWrapper'}`}
              />
              <a href="#!" className="secondary-content">
                <ChevronRightIcon
                  style={isLargeScreen && location.pathname.includes('mijn-aankopen') ? activePathNameTextStyle : {}}
                />
              </a>
            </li>
            <li className="collection-item avatar" {...accessibleOnClick(() => navigate('/user/favorieten'))}>
              <Favorite />
              <span
                className="title"
                style={isLargeScreen && location.pathname.includes('favorieten') ? activePathNameTextStyle : {}}
              >
                Favorieten
              </span>
              <div
                className={`${isLargeScreen && location.pathname === '/user/favorieten' && 'activePathnameWrapper'}`}
              />
              <a href="#!" className="secondary-content">
                <ChevronRightIcon
                  style={isLargeScreen && location.pathname.includes('favorieten') ? activePathNameTextStyle : {}}
                />
              </a>
            </li>
            {/* <li
          className="collection-item avatar"
          onClick={() => navigate("/user/profile")}
        >
          <Person />
          <span className="title">Mijn gegevens</span>
          <a href="#!" className="secondary-content">
            <ChevronRightIcon />
          </a>
        </li> */}
            <li className="collection-item empty" />
            {/* <li
          className="collection-item avatar"
          onClick={() => navigate("/orders")}
        >
          <Settings />
          <span className="title">App-instellingen</span>
          <a href="#!" className="secondary-content">
            <ChevronRightIcon />
          </a>
        </li> */}
            {/* </div> */}
          </div>
          <div className="liWrapper">
            <li className="collection-item avatar" {...accessibleOnClick(logout)}>
              <LogoutIcon />
              <span className="title">Log uit</span>
              <a href="#!" className="secondary-content">
                <ChevronRightIcon />
              </a>
            </li>
          </div>
        </ul>
      </Card>
    </div>
  );
};

export default Account;
