import { createSlice } from '@reduxjs/toolkit';

export const tyreSetReducer = createSlice({
  name: 'tyreSet',
  initialState: { isTyreSetAdded: false },
  reducers: {
    setTyreSetTrue: (state) => {
      state.isTyreSetAdded = true;
    },
    setTyreSetFalse: (state) => {
      state.isTyreSetAdded = false;
    },
  },
});

export const tyreSetActions = tyreSetReducer.actions;
