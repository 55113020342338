import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const MijnAankopenGeneralContainer = styled.div`
  height: 100%;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 95px);
  }
`;
export const MijnAankopenWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
  padding: 35px 40px;

  background-color: #fff;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    max-width: 625px;
    margin: 16px 11px;
    padding: 25px 20px;
  }
`;

export const MijnAankopenContentWrapper = styled.div`
  @media screen and (min-width: ${breakpoints.lg}px) {
    padding-right: ${(props) => props.padding};
    padding-bottom: 70px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 99%;
    width: 100%;
  }
`;

export const MijnAankopenLoaderPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 345px;
`;

export const NoAankopeenWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 29px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    padding-bottom: 60px;
    min-height: calc(100vh - 147px);
  }
`;

export const NoAankopeenIconWrapper = styled.div`
  width: 75px;
  height: 75px;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #136ce2;
  background-color: #e7f0fc;
  font-size: 45px;
`;

export const NoAankopeenText = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const NoAnkopeenWinkelenButton = styled.button`
  color: #136ce2;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  padding: 10px;
  width: 220px;
  background-color: #ffffff;
  margin-top: 8px;
  border: 2px solid #136ce2;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-out;
  cursor: pointer;
  &:hover {
    color: #0f56b5;
    border-color: #0f56b5;
  }
  &: focus {
    background-color: #ffffff;
  }
`;

export const MijnAankopenContentTopFader = styled.div`
  @media screen and (min-width: ${breakpoints.lg}px) {
    position: absolute;
    height: 23px;
    left: 40px;
    right: 40px;
    top: 35px;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 1) 90%);
  }
`;

export const MijnAankopenContentBottomFader = styled.div`
  @media screen and (min-width: ${breakpoints.lg}px) {
    position: absolute;
    height: 23px;
    bottom: 41px;
    left: 40px;
    right: 40px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 1) 90%);
  }
`;
