import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const ProductDetailVersionTwoMainContainer = styled.div`
  display: flex;
  padding-top: 53px;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 70px);
`;

export const ProductDetailsVersionTwoLoaderPlaceholder = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 50px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
  {
    height: calc(100vh - 95px);
  }
`;

export const ProductDetailVersionTwoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  width: 60%;
  min-height: 685px;
  height: 850px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 10px;

  @media screen and (min-width: ${breakpoints.lg}px) {
    min-width: 930px;
  }

  @media screen and (min-width: ${breakpoints.xl}px) {
    min-width: 1050px;
  }
`;

export const ProductDetailVersionTwoTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  width: 100%;
  margin-top: 70px;
`;

export const ProductDetailVersionTwoUpLeftContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 42%;
  height: 90%;
  flex: 1;
  margin-left: 40px;
`;

export const ProductDetailVersionTwoUpRightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-bottom: 30px;
`;

export const ProductDetailVersionTwoUpRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 80%;
  margin-top: 30px;
`;

export const ProductDetailVersionTwoUpRightTitle = styled.h3`
  font-size: 30px;
  font-weight: 700;
  margin-top: 20px;
`;

export const ProductDetailVersionTwoUpRightSubtitleWrapper = styled.div`
  display: flex;
  justify-content: start;
`;

export const ProductDetailVersionTwoUpRightSubtitleKey = styled.span`
  font-size: 18px;
  color: #666666;
`;

export const ProductDetailVersionTwoUpRightSubtitleValue = styled.span`
  font-size: 18px;
  margin-left: 10px;
  font-weight: 700;
`;

export const ProductDetailVersionTwoUpRightPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 0px;
`;

export const ProductDetailVersionTwoUpRightIconWrapper = styled.div`
  display: flex;
`;

export const ProductDetailVersionTwoUpRightDateWrapper = styled.div`
  display: flex;
  justify-content: start;
`;

export const ProductDetailVersionTwoUpRightDateKey = styled.span`
  font-size: 14px;
  color: #666666;
`;

export const ProductDetailVersionTwoUpRightDateValue = styled.span`
  font-size: 14px;
  margin-left: 5px;
  font-weight: 700;
`;

export const ProductDetailVersionTwoRightButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ProductDetailVersionTwoRightFavButton = styled.button`
  background-color: #e7f0fc;
  width: 40%;
  height: 45px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  margin-right: 22px;
`;

export const ProductDetailVersionTwoButton = styled.button`
  margin-top: ${(props) => props.marginTop};
  margin-bottom: 20px;
  color: #fff;
  border: none;
  background-color: ${(props) => props.backgroundColor};
  height: 45px;
  width: 100%;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;

  :focus {
    background-color: ${(props) => props.backgroundColor};
  }

  :hover {
    background-color: ${(props) => props.hover};
  }
`;

export const ProductDetailVersionTwoRightOrderButton = styled.button`
  background-color: #136ce2;
  color: white;
  font-size: 18px;
  font-weight: 300;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
`;

export const ProductDetailVersionTwoRightModelWrapper = styled.div`
  background-color: #f1fae9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  border-radius: 5px;
  font-size: 13px;
  font-weight: bold;
  height: 45px;
`;

export const ProductDetailVersionTwoBottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  width: 100%;
  padding-bottom: 30px;
  margin-bottom: 20px;
`;

export const ProductDetailVersionTwoBottomLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  padding: 55px 40px;
`;

export const ProductDetailVersionTwoBottomTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 50%;
`;

export const ProductDetailVersionTwoBottomLeftTitle = styled.span`
  font-size: 22px;
  color: #136ce2;
  margin-left: 10px;
  font-weight: bold;
`;

export const ProductDetailVersionTwoBottomLeftWrapper = styled.div`
  height: 90%;
`;

export const ProductDetailVersionTwoBottomDetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid #e4e4e4;
  margin-top: ${(props) => props.margin};

  :last-child {
    border-bottom: none;
  }
`;

export const ProductDetailVersionTwoBottomDetailKey = styled.span`
  font-size: 18px;
  color: #666666;
  margin-bottom: 4px;
`;

export const ProductDetailVersionTwoBottomDetailValue = styled.span`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 4px;
`;

export const ProductDetailVersionTwoBottomRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1.1;
  margin-top: 80px;
  height: 100%;
  padding: 22px 55px;
`;

// --------------MOBILE-------------------- //

export const MobileProductDetailVersionTwoContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 64px);
  padding: 11px;
`;

export const MobileProductDetailVersionTwoContent = styled.div`
  background-color: white;
  border-radius: 10px;
  width: 100%;
  max-width: 625px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding-bottom: 10px;
  margin-bottom: 5px;
  margin-top: 3px;
`;

export const MobileProductDetailVersionTwo = styled.div`
  margin: 22px 18px 5px 18px;
  padding-bottom: 20px;
`;

export const MobileProductDetailVersionTwoTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin: 0px;
`;

export const MobileProductDetailVersionTwoButtonWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  gap: 20px;
`;

export const MobileProductDetailVersionTwoFavButton = styled.button`
  background-color: #e7f0fc;
  width: 60px;
  height: 40px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobileProductDetailVersionTwoOrderButton = styled.button`
  background-color: #136ce2;
  color: white;
  font-size: 16px;
  font-weight: 300;
  width: 100%;
  max-width: 350px;
  height: 40px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
`;

export const MobileProductDetailVersionTwotModelWrapper = styled.div`
  background-color: #f1fae9;
  display: flex;
  align-items: center;
  margin-top: 30px;
  width: 100%;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  height: 40px;
  padding: 0px 10px;
`;

export const MobileProductDetailVersionTwoSpecifications = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #136ce2;
  font-weight: bold;
  margin-top: 26px;
`;

export const MobileProductDetailVersionTwoDetailKey = styled.span`
  font-size: 16px;
  color: #666666;
  margin-bottom: 4px;
`;

export const MobileProductDetailVersionTwoDetailValue = styled.span`
  font-size: 16px;
`;

export const MobileSliderWrapper = styled.div`
  height: 300px;
`;

export const ProductDetailVersionTwoMobileIconWrapper = styled.div`
  display: flex;
  color: #a5a5a5;
`;
export const ProductDetailVersionTwoUpMobileWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

export const MobilePriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 0px;
`;
