import { forwardRef } from 'react';
import SvgIcon, { SvgIconProps } from '../../app/components/bhComponents/SvgIcon';
import { ForwardedRef } from 'app/helpers/types';
const IconKruis = forwardRef((props: SvgIconProps, ref: ForwardedRef<SVGSVGElement>) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    name="IconKruis"
    ref={ref}
    {...props}
  >
    <path data-name="Path 1407" d="M0 0h24v24H0Z" fill="none" />
    <path
      data-name="Path 1408"
      d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12Z"
    />
  </SvgIcon>
));
IconKruis.displayName = 'IconKruis';
export default IconKruis;
