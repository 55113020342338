import { ElementType, forwardRef, HTMLAttributes } from 'react';
import useClassNames from 'app/helpers/useClassNames';
import './Typography.scss';

export interface TypographyProps extends HTMLAttributes<HTMLElement> {
  /**
   * What tag the title has
   */
  as?: ElementType;
  /**
   * Type of font-family the text has
   */
  fontFamily?:
    | 'black'
    | 'black-italic'
    | 'extra-bold'
    | 'extra-bold-italic'
    | 'bold'
    | 'bold-italic'
    | 'semi-bold'
    | 'semi-bold-italic'
    | 'medium'
    | 'medium-italic'
    | 'regular'
    | 'italic'
    | 'light'
    | 'light-italic'
    | 'extra-light'
    | 'extra-light-italic'
    | 'thin'
    | 'thin-italic';
  /**
   * How big the text is and if its a heading or not
   */
  variant?:
    | 'heading-48'
    | 'heading-40'
    | 'heading-36'
    | 'heading-32'
    | 'heading-28'
    | 'heading-24'
    | 'heading-20'
    | 'heading-18'
    | 'heading-16'
    | 'heading-14'
    | 'text-20'
    | 'text-18'
    | 'text-16'
    | 'text-14'
    | 'text-12'
    | 'text-10';
}

const Typography = forwardRef<HTMLElement, TypographyProps>((props: TypographyProps, ref) => {
  const { as: Component = 'span', className, variant = 'text-16', fontFamily, children, ...rest } = props;
  const { prefix, merge } = useClassNames('tg');
  const [type] = variant.split('-');
  const classes = merge(className, prefix(type), prefix(variant), prefix(fontFamily));

  return (
    <Component {...rest} ref={ref} className={classes}>
      {children}
    </Component>
  );
});

Typography.displayName = 'Typography';

export default Typography;
