import { AxiosRequestConfig } from 'axios';
import apiClient from './api-client';
import { getAuthAccessToken } from 'auth/auth-session';
export const addAuthToken = () => {
  apiClient.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      config.headers = config.headers ?? {};

      config.headers.authorization = config.headers.authorization = `Bearer ${getAuthAccessToken()}`;
      return config;
    },
    (error) => Promise.reject(error)
  );
};
