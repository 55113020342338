import { createSlice } from '@reduxjs/toolkit';
import { fetch } from './api-slice';

export interface OrderState {
  orders: Array<string>;
}

const initialState: OrderState = {
  orders: [],
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetch.fulfilled, (state, action) => {
      if (action.meta.arg.formId === 'orders') {
        state.orders = action.payload.data.resultaat;
      }
    });
  },
});

// export const { setKenteken } = kentekenSlice.actions;

export default orderSlice.reducer;
