import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const ToastAllContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;

  width: 475px;
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 3;

  @media screen and (max-width: ${breakpoints.md}px) {
    left: 11px;
    right: 11px;
    bottom: 15px;
    width: auto;
  }
`;
