import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BezorgenLoaderPlaceholder, BezorgenMainContainer } from './Bezorgen.styles';
import Betalen from 'app/components/BezorgenComponents/Betalen';
import Bevestiging from 'app/components/BezorgenComponents/Bevestiging';
import Samenvatting from 'app/components/BezorgenComponents/Samenvatting/Samenvatting';
import useProduct from 'app/hooks/useProduct';
import usePlaceOrder from 'app/hooks/usePlaceOrder';
import Toast from 'app/components/Toast/Toast';
import ToastContainer from 'app/components/Toast/ToastContainer';
import Loader from 'app/components/Loader/Loader';

const Index = () => {
  const [bezorgenStep, setBezorgenStep] = useState(0);

  const [toastId, setToastId] = useState(0);
  const [toastList, setToastList] = useState([]);

  const { id } = useParams();

  const {
    data: productDetailsData,
    isLoading: isProductDetailsDataLoading,
    isError: isProductDetailsDataError,
    error: productDetailsDataError,
  } = useProduct(true, id, false);

  useEffect(() => {
    if (isProductDetailsDataError) {
      const toast = {
        id: toastId,
        type: 'error',
        message: productDetailsDataError?.message,
        additionalMessage: productDetailsDataError?.data?.detail,
      };
      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isProductDetailsDataError]);

  const tyreBrand = productDetailsData?.tyre_set?.brand;
  const season =
    productDetailsData?.models[0]?.model.season === 'Summer'
      ? 'Zomer'
      : productDetailsData?.models[0]?.model.season === 'Winter'
      ? 'Winter'
      : 'All season';
  const price = productDetailsData?.tyre_set?.price;

  const { isPlaceOrderError, placeOrderError, isPlaceOrderSuccess, onClickPlaceOrder, onClearPlaceOrderErrorMessage } =
    usePlaceOrder();

  useEffect(() => {
    if (isPlaceOrderError) {
      const toast = {
        id: toastId,
        type: 'error',
        message: placeOrderError?.message,
        additionalMessage: placeOrderError?.response?.statusText,
        onClose: onClearPlaceOrderErrorMessage,
      };
      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isPlaceOrderError]);

  useEffect(() => {
    if (isPlaceOrderSuccess) {
      setBezorgenStep(1);
    }
  }, [isPlaceOrderSuccess]);

  const placeOrderPayload = () => {
    const data = {
      tyre_set_id: id,
    };

    return data;
  };

  const placeOrder = () => {
    onClickPlaceOrder(placeOrderPayload());
  };

  if (isProductDetailsDataLoading) {
    return (
      <BezorgenLoaderPlaceholder>
        <Loader />
      </BezorgenLoaderPlaceholder>
    );
  }

  if (isProductDetailsDataError) {
    return (
      <Toast
        type="error"
        message={productDetailsDataError?.message}
        additionalMessage={productDetailsDataError?.response?.data?.detail}
        standalone
        standaloneAutoRemove
      />
    );
  }

  return (
    <BezorgenMainContainer>
      {bezorgenStep === 0 ? <Betalen placeOrder={placeOrder} price={price} /> : bezorgenStep === 1 && <Bevestiging />}

      {bezorgenStep !== 1 && <Samenvatting tyreBrand={tyreBrand} season={season} price={price} />}
      <ToastContainer toastList={toastList} autoRemove removeInterval={4750} />
    </BezorgenMainContainer>
  );
};

export default Index;
