import { forwardRef, HTMLAttributes, MouseEventHandler, ReactNode } from 'react';
import IconButton from '../IconButton';
import Typography from '../Typography';
import IconKruis from '../../../../assets/svg/IconKruis';
import useClassNames from 'app/helpers/useClassNames';
import './AttentionBox.scss';

export type AttentionBoxVariant = 'primary' | 'neutral' | 'alert' | 'success' | 'warning';

export interface AttentionBoxProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Color variant
   */
  variant?: AttentionBoxVariant;
  /**
   * Header title
   */
  header?: string;
  /**
   * Icon displayed in the top left corner
   */
  icon?: ReactNode;
  /**
   * Optional handler to add a close button
   */
  onClose?: MouseEventHandler;
}

/**
 * Example text for this component
 */
const AttentionBox = forwardRef<HTMLDivElement, AttentionBoxProps>((props: AttentionBoxProps, ref) => {
  const { className, children, variant = 'primary', header, icon, onClose } = props;

  const { prefix, withClassPrefix, merge } = useClassNames('attention-box');
  const classes = merge(className, withClassPrefix(variant));

  return (
    <div ref={ref} className={classes}>
      {header ? (
        <>
          <div className={prefix(`header${onClose ? '-close' : ''}`)}>
            <div className={prefix('icon')}>
              {icon}
              <Typography variant="heading-16">{header}</Typography>
            </div>
            {onClose && (
              <div className={prefix('button-wrapper')}>
                <IconButton onClick={onClose} appearance="clear" icon={<IconKruis />} color="gray" />
              </div>
            )}
          </div>
          <Typography variant="text-14">{children}</Typography>
        </>
      ) : (
        <div className={prefix('no-header')}>
          <div className={prefix('no-header-icon-wrapper')}>{icon}</div>
          <Typography variant="text-14">{children}</Typography>
        </div>
      )}
    </div>
  );
});

AttentionBox.displayName = 'AttentionBox';

export default AttentionBox;
