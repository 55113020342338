import { useQuery } from '@tanstack/react-query';
import { getProductDetails } from 'app/api/product';
import { QueryKeys } from 'app/helpers/QueryKeys';

const useProduct = (isProductDetailsEnabled: boolean, id: number, count: boolean) => {
  const query = useQuery([QueryKeys.Product, id], () => getProductDetails(id, count), {
    enabled: isProductDetailsEnabled,
    refetchOnWindowFocus: 'always',
    retry: false,
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: 'always',
  });

  return {
    ...query,
    product: query.data,
  };
};

export default useProduct;
