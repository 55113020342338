import styles from '../../scss/_variables.scss';

export const bulletStatus = (status: string, step: number) => {
  if (
    (status === 'NIEUW' && step <= 1) ||
    (status === 'GELADEN' && step <= 2) ||
    (status === 'AFGELEVERD' && step <= 3)
  ) {
    return styles.primaryColor;
  } else {
    return styles.progressBarGrey;
  }
};

export const orderProgressStatus = (status: string) => {
  if (status === 'NIEUW') {
    return 24.5;
  } else if (status === 'GELADEN') {
    return 74.5;
  } else if (status === 'AFGELEVERD') {
    return 100;
  }
};
