import styled from 'styled-components';

export const ProductDetailsRightButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
`;

export const ProductDetailsRightFavButton = styled.button`
  background-color: #e7f0fc;
  width: 40%;
  height: 40px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  margin-right: 22px;

  :focus {
    background-color: #e7f0fc;
  }
`;

export const ProductDetailsRightOrderButton = styled.button`
  background-color: #136ce2;
  color: white;
  font-size: 18px;
  font-weight: 300;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: none;
  cursor: pointer;

  :focus {
    background-color: #136ce2;
  }
`;
