import apiClient from 'app/axios/api-client';

export const getOrders = async () => {
  const result = await apiClient.get('/bestelling/');

  return result.data;
};

export const getOrder = async (id: number) => {
  const result = await apiClient.get(`bestelling/${id}`);

  return result.data;
};
