import { forwardRef, useCallback, MouseEvent, ButtonHTMLAttributes } from 'react';

export type ButtonBaseProps = ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonBase = forwardRef<HTMLButtonElement, ButtonBaseProps>((props: ButtonBaseProps, ref) => {
  const { children, disabled, onClick, ...rest } = props;

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (!disabled) {
        onClick?.(event);
      }
    },
    [disabled, onClick]
  );

  return (
    <button {...rest} ref={ref} disabled={disabled} aria-disabled={disabled} onClick={handleClick}>
      {children}
    </button>
  );
});

ButtonBase.displayName = 'ButtonBase';

export default ButtonBase;
