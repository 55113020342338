import { forwardRef, useMemo, useState } from 'react';
import FormControlContext, { FormControlContextValue } from './FormControlContext';
import FormControlProps from './FormControlProps';
import useClassNames from 'app/helpers/useClassNames';
import './FormControl.scss';

const FormControl = forwardRef<HTMLDivElement, FormControlProps>((props: FormControlProps, ref) => {
  const [helpTextId, setHelpTextId] = useState<string>();
  const [errorTextId, setErrorTextId] = useState<string>();
  const [filled, setFilled] = useState<boolean>(false);
  const [focused, setFocus] = useState<boolean>(false);
  const [forceShrink, setForceShrink] = useState<boolean>(false);
  const {
    id,
    className,
    disabled = false,
    required = false,
    success = false,
    error = false,
    optional = false,
    inputSize = 'large',
    children,
    flatSide = 'none',
    ...other
  } = props;

  const formControlContextValue = useMemo<FormControlContextValue>(() => {
    const value: FormControlContextValue = {
      disabled,
      filled,
      setFilled,
      focused,
      optional,
      onFocus: () => setFocus(true),
      onBlur: () => setFocus(false),
      shrink: forceShrink || filled || focused,
      required,
      flatSide,
      error,
      success,
      controlId: id,
      helpTextId,
      setHelpTextId,
      errorTextId,
      setErrorTextId,
      inputSize,
      setForceShrink,
    };

    return value;
  }, [
    flatSide,
    disabled,
    filled,
    focused,
    optional,
    forceShrink,
    required,
    error,
    success,
    id,
    helpTextId,
    errorTextId,
    inputSize,
  ]);

  const { withClassPrefix, merge } = useClassNames('form-control');
  const classes = merge(className, withClassPrefix());

  return (
    <FormControlContext.Provider value={formControlContextValue}>
      <div ref={ref} className={classes} {...other}>
        {children}
      </div>
    </FormControlContext.Provider>
  );
});

FormControl.displayName = 'FormControl';

export default FormControl;
