import webAuth from './webAuth';
import { removeAuthState, removeAuthAccessToken } from './auth-session';
import { getAuthConfig } from './auth-config';

export const logout = async () => {
  removeAuthState();
  removeAuthAccessToken();
  const authConfig = getAuthConfig();

  await webAuth.logout({
    clientID: authConfig.clientID,
    returnTo: authConfig.redirectUri,
  });
};
