import { useQuery } from '@tanstack/react-query';
import { getModels } from 'app/api/models';
import { QueryKeys } from 'app/helpers/QueryKeys';

const useModels = (brand: string) => {
  const query = useQuery([QueryKeys.Models, brand], () => getModels(brand), {
    enabled: brand !== undefined && brand !== '',
    retry: false,
  });

  return {
    ...query,
    models: query.data,
  };
};

export default useModels;
