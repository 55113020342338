import { createSlice } from '@reduxjs/toolkit';

export const bestellingenReducer = createSlice({
  name: 'bestellingenList',
  initialState: {
    bestellingenData: undefined,
    isLoading: false,
    isError: false,
    error: undefined,
    actueelBestellingen: undefined,
    aantalBestellingen: undefined,
    historieBestellingen: undefined,
  },
  reducers: {
    setBestellingenData: (state, action) => {
      state.bestellingenData = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsError: (state, action) => {
      state.isError = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setActueelBestellingen: (state, action) => {
      state.actueelBestellingen = action.payload;
    },
    setAantalBestellingen: (state, action) => {
      state.aantalBestellingen = action.payload;
    },
    setHistorieBestellingen: (state, action) => {
      state.historieBestellingen = action.payload;
    },
  },
});

export const bestellingenActions = bestellingenReducer.actions;
