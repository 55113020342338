// Styled Components
import StraightenIcon from '@mui/icons-material/Straighten';
import { Divider } from '@mui/material';
import styles from '../../../../scss/_variables.scss';
import tireSizeFormat from '../../../helpers/tireSizeFormat';
import {
  Toevoegen5BottomContainer,
  Toevoegen5BottomDetailKey,
  Toevoegen5BottomDetailValue,
  Toevoegen5BottomDetailWrapper,
  Toevoegen5BottomLeftContainer,
  Toevoegen5BottomLeftTitle,
  Toevoegen5BottomLeftWrapper,
  Toevoegen5BottomRightContainer,
  Toevoegen5BottomTitleWrapper,
  Toevoegen5MainContainer,
  Toevoegen5TopContainer,
  Toevoegen5UpLeftContainer,
  Toevoegen5UpRightContainer,
  Toevoegen5UpRightTitle,
  Toevoegen5UpRightWrapper,
  Toevoegen5Wrapper,
  Toevoegen5TitlePlusInfoContainer,
  Toevoegen5TitleBevestigenWrapper,
  Toevoegen5MoreInfoBevestigenWrapper,
  MobileToevoegen5Container,
  MobileToevoegen5Content,
  MobileToevoegen5,
  MobileToevoegen5Title,
  MobileToevoegen5Specifications,
  MobileToevoegen5DetailKey,
  MobileToevoegen5DetailValue,
  Toevoegen5UpRightSubtitleWrapper,
  Toevoegen5VoorraadTextContainer,
  Toevoegen5UpRightSubtitleKey,
  Toevoegen5UpRightSubtitleValue,
  Toevoegen5Button,
  Toevoegen5ButtonWrapper,
  MobileSliderWrapper,
  Toevoegen5MobileButton,
} from './Toevoegen5.styles';
// MUI Icons
// Break Points
import useWindowSize from 'app/hooks/useWindowSize';
import { breakpoints } from 'app/config/breakpoints';
// Slider
import Slider from 'app/components/Slider';
// Colors
import Loader from 'app/components/Loader/Loader';

const Toevoegen5 = ({ conditie, bandenmerk, seizoen, payLoadData, isLoading, onClickCreate }) => {
  const data = payLoadData();

  const price = data?.price;

  const { width } = useWindowSize();
  const isLargeScreen = width && width >= breakpoints.lg;

  const isCreateProductLoading = isLoading;

  const title = `${conditie}e ${bandenmerk} ${
    seizoen === 'Summer' ? 'zomer' : seizoen === 'Winter' ? 'winter' : 'all season'
  } banden.`;

  const damageValue = () => {
    if (!data.damage.schade1 && !data.damage.schade2 && !data.damage.schade3 && !data.damage.schade4) {
      return 'Geen';
    } else {
      const damagedWheels = [];

      if (data.damage.schade1) {
        damagedWheels.push(1);
      }
      if (data.damage.schade2) {
        damagedWheels.push(2);
      }
      if (data.damage.schade3) {
        damagedWheels.push(3);
      }
      if (data.damage.schade4) {
        damagedWheels.push(4);
      }

      return `Wiel ${damagedWheels.map((item) => ` ${item}`)}`;
    }
  };

  const dataLeftSide = [
    {
      id: 1,
      key: 'Merk',
      value: data?.tyreBandenmerk,
    },
    {
      id: 2,
      key: 'Inchmaat',
      value: tireSizeFormat(data?.width, data?.height, data?.inches),
    },
    {
      id: 3,
      key: 'Seizoen',
      value: data?.season === 'Summer' ? 'Zomer' : data?.season === 'Winter' ? 'Winter' : 'All season',
    },
    {
      id: 4,
      key: 'TPMS',
      value: data?.TPMS === 'yes' ? 'Ja' : data?.TPMS === 'no' ? 'Nee' : 'Onbekend',
    },
    {
      id: 5,
      key: 'M + S',
      value: data?.m_plus_s ? 'Ja' : 'Nee',
    },
    {
      id: 6,
      key: '3PMSF',
      value: data?.drie_PMSF ? 'Ja' : 'Nee',
    },
    {
      id: 7,
      key: 'Runflat',
      value: data?.runflat === 'yes' ? 'Ja' : data?.runflat === 'no' ? 'Nee' : 'Onbekend',
    },
    {
      id: 8,
      key: 'Conditie',
      value: data?.condition,
    },
    {
      id: 9,
      key: 'Schade',
      value: damageValue(),
    },
  ];

  const filteredDataLeftSide = dataLeftSide.filter((entry) => {
    if (data?.season === 'Summer') {
      return entry.key !== '3PMSF' && entry.key !== 'M + S';
    }
    return true;
  });

  const profileDepth = (value) => {
    const depthMap = {
      ONETWO: '1 tot 2',
      TWOTHREE: '2 tot 3',
      THREEFOUR: '3 tot 4',
      FOURFIVE: '4 tot 5',
      FIVESIX: '5 tot 6',
      SIXSEVEN: '6 tot 7',
      SEVENEIGHT: '7 tot 8',
      EIGHTPLUS: '8+',
    };

    return `${depthMap[value]} mm` || value;
  };

  const dataRightSide = [
    {
      id: 2,
      key: 'Profieldiepte wiel 1',
      value: profileDepth(data?.profile_depth.dropdown1Value),
    },
    {
      id: 3,
      key: 'Profieldiepte wiel 2',
      value: profileDepth(data?.profile_depth.dropdown2Value),
    },
    {
      id: 4,
      key: 'Profieldiepte wiel 3',
      value: profileDepth(data?.profile_depth.dropdown3Value),
    },
    {
      id: 5,
      key: 'Profieldiepte wiel 4',
      value: profileDepth(data?.profile_depth.dropdown4Value),
    },
    {
      id: 6,
      key: 'Dot-code wiel 1',
      value: data?.dot_code.dot1,
    },
    {
      id: 7,
      key: 'Dot-code wiel 2',
      value: data?.dot_code.dot2,
    },
    {
      id: 8,
      key: 'Dot-code wiel 3',
      value: data?.dot_code.dot3,
    },
    {
      id: 9,
      key: 'Dot-code wiel 4',
      value: data?.dot_code.dot4,
    },
  ];

  const sliderItems =
    data?.condition !== 'Gebruikt' && data?.condition !== 'Zo goed als nieuw'
      ? [
          {
            id: 1,
            img: data?.imagesContent,
          },
        ]
      : [
          {
            id: 1,
            img: data?.imagesContent?.file1,
          },
          {
            id: 2,
            img: data?.imagesContent?.file2,
          },
          {
            id: 3,
            img: data?.imagesContent?.file3,
          },
          {
            id: 4,
            img: data?.imagesContent?.file4,
          },
        ];

  const baseNum = (n) => Number(String(n).split('.')[0]);

  const fract = (n) => {
    const number = Number(String(n).split('.')[1]);
    const isNumber = !isNaN(number);
    return number.toString().length === 1 && isNumber
      ? `${number.toString()}0`
      : number.toString().length === 3 && !isNumber
      ? ' -'
      : number;
  };

  const priceLabel = (
    <div
      className="price-label"
      style={{
        marginLeft: '0px',
        marginTop: `${isLargeScreen ? '30px' : '10px'}`,
      }}
    >
      <span className="integer" style={{ fontSize: `${isLargeScreen ? '36px' : '30px'}` }}>
        &euro; {baseNum(price)},
      </span>
      <span className="fraction" style={{ fontSize: `${isLargeScreen ? '22px' : '19px'}` }}>
        {fract(price)}
      </span>
    </div>
  );

  return isLargeScreen ? (
    <Toevoegen5MainContainer>
      <Toevoegen5Wrapper>
        <Toevoegen5TitlePlusInfoContainer>
          <Toevoegen5TitleBevestigenWrapper>Bevestigen</Toevoegen5TitleBevestigenWrapper>
          <Toevoegen5MoreInfoBevestigenWrapper>
            Controleer de ingevoerde gegevens en bevestig met de knop ‘Opslaan’.
          </Toevoegen5MoreInfoBevestigenWrapper>
        </Toevoegen5TitlePlusInfoContainer>
        <Toevoegen5TopContainer>
          <Toevoegen5UpLeftContainer>
            <Slider sliderItems={sliderItems} />
          </Toevoegen5UpLeftContainer>
          <Toevoegen5UpRightContainer>
            <Toevoegen5UpRightWrapper>
              <Toevoegen5UpRightTitle>{title}</Toevoegen5UpRightTitle>
              {data?.sale_status === 'INVENTORY' ? (
                <Toevoegen5VoorraadTextContainer>Voorraad</Toevoegen5VoorraadTextContainer>
              ) : (
                priceLabel
              )}

              {data?.storage && (
                <Toevoegen5UpRightSubtitleWrapper>
                  <Toevoegen5UpRightSubtitleKey>Opslagplaats:</Toevoegen5UpRightSubtitleKey>
                  <Toevoegen5UpRightSubtitleValue>{data?.storage}</Toevoegen5UpRightSubtitleValue>
                </Toevoegen5UpRightSubtitleWrapper>
              )}
            </Toevoegen5UpRightWrapper>
          </Toevoegen5UpRightContainer>
        </Toevoegen5TopContainer>
        <Toevoegen5BottomContainer>
          <Toevoegen5BottomLeftContainer>
            <Toevoegen5BottomTitleWrapper>
              <StraightenIcon style={{ color: '#136CE2' }} />
              <Toevoegen5BottomLeftTitle>Specificaties</Toevoegen5BottomLeftTitle>
            </Toevoegen5BottomTitleWrapper>
            <Toevoegen5BottomLeftWrapper>
              {filteredDataLeftSide.map((data) => (
                <Toevoegen5BottomDetailWrapper key={data.id} margin={`${isLargeScreen ? '4px' : '8px'}`}>
                  <Toevoegen5BottomDetailKey>{data.key}:</Toevoegen5BottomDetailKey>
                  <Toevoegen5BottomDetailValue>{data.value}</Toevoegen5BottomDetailValue>
                </Toevoegen5BottomDetailWrapper>
              ))}
            </Toevoegen5BottomLeftWrapper>
          </Toevoegen5BottomLeftContainer>
          <Toevoegen5BottomRightContainer>
            {dataRightSide.map((data) => (
              <Toevoegen5BottomDetailWrapper key={data.id} margin={`${isLargeScreen ? '4px' : '8px'}`}>
                <Toevoegen5BottomDetailKey>{data.key}:</Toevoegen5BottomDetailKey>
                <Toevoegen5BottomDetailValue>{data.value}</Toevoegen5BottomDetailValue>
              </Toevoegen5BottomDetailWrapper>
            ))}
            <Toevoegen5ButtonWrapper>
              <Toevoegen5Button
                disabled={isCreateProductLoading}
                backgroundColor={!isCreateProductLoading ? styles.primaryColor : styles.greyNormal}
                hover={!isCreateProductLoading ? styles.primaryColorContactHoverContent : styles.greyNormal}
                cursor={!isCreateProductLoading ? 'pointer' : 'not-allowed'}
                onClick={onClickCreate}
              >
                {!isCreateProductLoading ? 'Opslaan' : <Loader size="27px" loaderWidth="3px" />}
              </Toevoegen5Button>
            </Toevoegen5ButtonWrapper>
          </Toevoegen5BottomRightContainer>
        </Toevoegen5BottomContainer>
      </Toevoegen5Wrapper>
    </Toevoegen5MainContainer>
  ) : (
    <MobileToevoegen5Container>
      <MobileToevoegen5Content>
        <Toevoegen5TitlePlusInfoContainer>
          <Toevoegen5TitleBevestigenWrapper>Bevestigen</Toevoegen5TitleBevestigenWrapper>
          <Toevoegen5MoreInfoBevestigenWrapper>
            Controleer de ingevoerde gegevens en bevestig met de knop ‘Opslaan’.
          </Toevoegen5MoreInfoBevestigenWrapper>
        </Toevoegen5TitlePlusInfoContainer>
        <MobileSliderWrapper>
          <Slider sliderItems={sliderItems} border="10px 10px 10px 10px" />
        </MobileSliderWrapper>
        <MobileToevoegen5>
          <MobileToevoegen5Title>{title}</MobileToevoegen5Title>
          {data?.sale_status === 'INVENTORY' ? (
            <Toevoegen5VoorraadTextContainer>Voorraad</Toevoegen5VoorraadTextContainer>
          ) : (
            priceLabel
          )}

          {data?.storage && (
            <Toevoegen5UpRightSubtitleWrapper>
              <Toevoegen5UpRightSubtitleKey>Opslagplaats:</Toevoegen5UpRightSubtitleKey>
              <Toevoegen5UpRightSubtitleValue>{data?.storage}</Toevoegen5UpRightSubtitleValue>
            </Toevoegen5UpRightSubtitleWrapper>
          )}

          <MobileToevoegen5Specifications>
            <StraightenIcon style={{ color: '#136CE2', marginRight: '12px' }} />
            Specificaties
          </MobileToevoegen5Specifications>
          <div>
            {filteredDataLeftSide.map((data) => (
              <Toevoegen5BottomDetailWrapper key={data.id} margin={`${isLargeScreen ? '10px' : '3px'}`}>
                <MobileToevoegen5DetailKey>{data.key}:</MobileToevoegen5DetailKey>
                <MobileToevoegen5DetailValue>{data.value}</MobileToevoegen5DetailValue>
              </Toevoegen5BottomDetailWrapper>
            ))}
          </div>
          <Divider />
          <div>
            {dataRightSide.map((data) => (
              <Toevoegen5BottomDetailWrapper key={data.id} margin={`${isLargeScreen ? '10px' : '3px'}`}>
                <MobileToevoegen5DetailKey>{data.key}:</MobileToevoegen5DetailKey>
                <MobileToevoegen5DetailValue>{data.value}</MobileToevoegen5DetailValue>
              </Toevoegen5BottomDetailWrapper>
            ))}
          </div>
          <Toevoegen5ButtonWrapper>
            <Toevoegen5MobileButton
              disabled={isCreateProductLoading}
              backgroundColor={!isCreateProductLoading ? styles.primaryColor : styles.greyNormal}
              hover={!isCreateProductLoading ? styles.primaryColorContactHoverContent : styles.greyNormal}
              cursor={!isCreateProductLoading ? 'pointer' : 'not-allowed'}
              onClick={onClickCreate}
            >
              {!isCreateProductLoading ? 'Opslaan' : <Loader size="27px" loaderWidth="3px" />}
            </Toevoegen5MobileButton>
          </Toevoegen5ButtonWrapper>
        </MobileToevoegen5>
      </MobileToevoegen5Content>
    </MobileToevoegen5Container>
  );
};

export default Toevoegen5;
