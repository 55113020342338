import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const ProductDetailVersionOneMainContainer = styled.div`
  display: flex;
  padding-top: 53px;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 70px);
  position: relative;
  background-color: ${(props) => props.backgroundColor};
  opacity: ${(props) => props.opacity};
`;

export const ProductDetailVersionOneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  width: 60%;
  min-height: 685px;
  height: 850px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 10px;
  opacity: ${(props) => props.opacity};

  @media screen and (min-width: ${breakpoints.lg}px) {
    min-width: 930px;
  }

  @media screen and (min-width: ${breakpoints.xl}px) {
    min-width: 1050px;
  }
`;

export const ProductDetailsVersionOneLoaderPlaceholder = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 50px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
  {
    height: calc(100vh - 95px);
  }
  `;

export const ProductDetailVersionOneTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  width: 100%;
  margin-top: 70px;
`;

export const ProductDetailVersionOneUpLeftContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 42%;
  height: 90%;
  flex: 1;
  margin-left: 40px;
`;

export const ProductDetailVersionOneUpRightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-bottom: 30px;
`;

export const ProductDetailVersionOneUpRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 80%;
`;

export const ProductDetailVersionOneUpRightOptionsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 50px;
  border-bottom: 0.5px solid #e4e4e4;
  margin-top: 20px;
`;

export const ProductDetailVersionOneUpRightOptionItem = styled.div`
  display: flex;
  align-items: center;

  :hover {
    color: ${(props) => props.hover}!important;
  }
`;

export const ProductDetailVersionOneUpRightOptionSpan = styled.span`
  color: ${(props) => props.color};
  font-size: 18px;
  font-weight: 700;
  margin-left: 5px;
  cursor: pointer;
`;

export const ProductDetailVersionOneUpRightTitle = styled.h3`
  font-size: 30px;
  font-weight: 700;
  margin-top: 10px;
`;

export const ProductDetailVersionOneBox = styled.div`
  min-height: 45px;
`;

export const ProductDetailVersionOneUpRightSubtitleWrapper = styled.div`
  display: flex;
  justify-content: start;
`;

export const ProductDetailVersionOneUpRightSubtitleKey = styled.span`
  font-size: 18px;
  color: #666666;
`;

export const ProductDetailVersionOneUpRightSubtitleValue = styled.span`
  font-size: 18px;
  margin-left: 10px;
  font-weight: 700;
`;

export const ProductDetailVersionOneUpRightPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ProductDetailVersionOneUpRightIconWrapper = styled.div`
  display: flex;
  margin-top: 30px;
`;

export const ProductDetailVersionOneUpRightDateWrapper = styled.div`
  display: flex;
  justify-content: start;
`;

export const ProductDetailVersionOneUpRightDateKey = styled.span`
  font-size: 14px;
  color: #666666;
`;

export const ProductDetailVersionOneUpRightDateValue = styled.span`
  font-size: 14px;
  margin-left: 5px;
  font-weight: 700;
`;

export const ProductDetailVersionOneBottomLeftTitle = styled.span`
  font-size: 22px;
  color: #136ce2;
  margin-left: 10px;
  font-weight: bold;
`;

export const ProductDetailVersionOneBottomDetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid #e4e4e4;
  margin-top: ${(props) => props.margin};

  :last-child {
    border-bottom: none;
  }
`;

export const ProductDetailVersionOneBottomDetailKey = styled.span`
  font-size: 18px;
  color: #666666;
  margin-bottom: 4px;
`;

export const ProductDetailVersionOneBottomDetailValue = styled.span`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 4px;
`;

export const ProductDetailVersionOneOptiesWrapper = styled.div`
  position: absolute;
  top: 47px;
  right: -2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 165px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #fff;
  z-index: 2;
`;

export const ProductDetailVersionOneOptiesItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  height: 37px;
  border-bottom: 0.5px solid #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding: 0px 9px;
  cursor: pointer;
  border-radius: ${(props) => props.borderRadius};

  :last-child {
    color: #ff3d00;
    border-bottom: none;
  }

  :hover {
    background-color: #f3f3f3;
  }
`;

// --------------MOBILE-------------------- //

export const MobileProductDetailVersionOneContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 64px);
  padding: 11px;
`;

export const MobileProductDetailsNavBarLogos = styled.div`
  position: fixed;
  top: 0;
  right: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  z-index: 5;

  @media screen and (max-width: 600px) {
    height: 56px;
  }

  @media screen and (min-width: 601px) {
    height: 64px;
  }
`;

export const MobileProductDetailsNavBarEditLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  cursor: pointer;
`;

export const MobileProductDetailsNavbarMoreDotsLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  cursor: pointer;
`;

export const MobileProductDetailVersionOneContent = styled.div`
  background-color: white;
  border-radius: 10px;
  width: 100%;
  max-width: 625px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding-bottom: 10px;
  margin-bottom: 5px;
  margin-top: 3px;
`;

export const MobileProductDetailVersionOne = styled.div`
  margin: 22px 18px 5px 18px;
  padding: 0px;
`;

export const MobileProductDetailVersionOneTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin: 0px;
`;

export const MobileProductDetailVersionOneButtonWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  gap: 20px;
`;

export const MobileProductDetailVersionOneFavButton = styled.button`
  background-color: #e7f0fc;
  width: 60px;
  height: 40px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobileProductDetailVersionOneSpecifications = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #136ce2;
  font-weight: bold;
  margin-top: 26px;
`;

export const MobileProductDetailVersionOneDetailKey = styled.span`
  font-size: 16px;
  color: #666666;
  margin-bottom: 4px;
`;

export const MobileProductDetailVersionOneDetailValue = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

export const MobileSliderWrapper = styled.div`
  height: 300px;
`;

export const ProductDetailVersionOneMobileIconWrapper = styled.div`
  display: flex;
  color: #a5a5a5;
`;
export const ProductDetailVersionOneUpMobileWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 20px;
`;

export const ProductDetailVersionOnePopupBackground = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 3;
  background-color: ${(props) => props.backgroundColor};
  opacity: 0.4;
`;
