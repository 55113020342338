import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const Toevoegen4Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 45%;
  height: auto;

  @media (max-width: 1600px) {
    width: 60%;
  }
  @media (max-width: 1300px) {
    width: 70%;
  }
  @media (max-width: 1100px) {
    width: 80%;
  }
  @media (max-width: ${breakpoints.md}px) {
    width: 95%;
  }
`;

export const Toevoegen4MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 70px;

  @media (max-width: ${breakpoints.md}px) {
    padding: 20px 20px;
  }
`;

export const Toevoegen4Title = styled.h1`
  font-size: 40px;
  font-weight: 800;
`;

export const Toevoegen4SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-top: 40px;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const Toevoegen4SelectItem = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: start;
  border: 1px solid #949494;
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
`;

export const Toevoegen4SelectItem2 = styled.div`
  width: 100%;
  transition: max-height 1s ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background-color: ${(props) => props.backgroundColor};
  border: 1px solid ${(props) => props.border};
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  cursor: pointer;

  animation-duration: 0.1s;
  animation-name: lineInserted;
  transition: height 0.1s;

  @keyframes lineInserted {
    from {
      height: 0;
    }
    to {
      height: 220px; /* your line height here */
    }
  }
`;

export const Toevoegen4SelectItem2Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 20px;
`;

export const Toevoegen4Subtitle2 = styled.div`
  width: 90%;
  font-size: 17px;
  font-weight: bold;
  margin-top: 40px;
`;

export const Toevoegen4OpslagplaatsInput2 = styled.input`
  width: 85% !important;
  height: 50px !important;
  border: 1px solid #949494 !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  padding: 0px 10px !important;
  margin-top: 10px !important;
  background-color: white !important;

  @media (max-width: 1000px) {
    width: 87% !important;
  }

  :focus {
    border: 1px solid ${(props) => props.focus} !important;
  }
`;

export const Toevoegen4InfoP2 = styled.p`
  width: 90%;
  color: #949494;
  font-size: 14px;
  margin-top: 0px;
`;

export const Toevoegen4SubtitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 40px;
`;

export const Toevoegen4Subtitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-right: 5px;
`;

export const Toevoegen4SubtitleP = styled.p`
  font-size: 16px;
`;

export const Toevoegen4OpslagplaatsInput = styled.input`
  width: 47% !important;
  height: 50px !important;
  border: 1px solid #949494 !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  padding: 0px 10px !important;
  margin-bottom: 0 !important;

  :focus {
    border: 1px solid ${(props) => props.focus} !important;
  }

  @media (max-width: 1000px) {
    width: 95% !important;
  }
`;

export const Toevoegen4InfoP = styled.p`
  color: #949494;
  font-size: 14px;
  margin-top: 5px;
`;

export const Toevoegen4Button = styled.button`
  margin-top: ${(props) => props.marginTop};
  margin-bottom: 20px;
  color: #fff;
  border: none;
  background-color: ${(props) => props.backgroundColor};
  height: 45px;
  width: 50%;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 400;
  cursor: ${(props) => props.cursor};

  :hover {
    background-color: ${(props) => props.hover};
  }

  :focus {
    background-color: ${(props) => props.focusBackgroundColor};
  }

  @media (max-width: 1000px) {
    width: 100%;
  }
`;
