import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import React, { ChangeEvent, useMemo } from 'react';
import { Toevoegen2AddPhotoInput, Toevoegen2AddPhotoLabel, Toevoegen2UploadedImg } from '../Toevoegen2.styles';
import IconEditItem from '../../../../../assets/svg/IconEditItem';
import Typography from '../../../bhComponents/Typography';
import { ProfileDepths } from '../AddTyreBlock/AddTyreBlock';
import styles from './Toevoegen2NewItem.module.scss';
import Toevoegen2NewRow from './Toevoegen2NewRow/Toevoegen2NewRow';

interface Toevoegen2NewItemProps {
  file: File;
  setDotCode: (value: (prevProfile: Record<string, string>) => { [p: string]: string }) => void;
  dotCode: {
    dot1: string;
    dot2: string;
    dot3: string;
    dot4: string;
  };
  initialSelect: {
    dropdown1Value: string;
    dropdown2Value: string;
    dropdown3Value: string;
    dropdown4Value: string;
  };
  setProfileDepth: (value: (prevProfile: Record<string, string>) => { [p: string]: string }) => void;
  handlePhotoChange: (value: ChangeEvent<HTMLInputElement>) => void;
}

const Toevoegen2NewItem = (props: Toevoegen2NewItemProps) => {
  const { file, dotCode, initialSelect, setDotCode, handlePhotoChange, setProfileDepth } = props;

  const { initialValueSelect1, initialValueSelect2, initialValueSelect3, initialValueSelect4 } = useMemo(
    () => ({
      initialValueSelect1: ProfileDepths.find((item) => item.value === initialSelect.dropdown1Value) || undefined,
      initialValueSelect2: ProfileDepths.find((item) => item.value === initialSelect.dropdown2Value) || undefined,
      initialValueSelect3: ProfileDepths.find((item) => item.value === initialSelect.dropdown3Value) || undefined,
      initialValueSelect4: ProfileDepths.find((item) => item.value === initialSelect.dropdown4Value) || undefined,
    }),
    [initialSelect]
  );
  return (
    <div className={styles.main}>
      {file === null ? (
        <div className={styles['add-img-wrapper']}>
          <div className={styles['img-wrapper-add']}>
            <AddPhotoAlternateIcon className={styles.icon} />
            <Toevoegen2AddPhotoLabel htmlFor="file">Toevoegen</Toevoegen2AddPhotoLabel>
            <Toevoegen2AddPhotoInput
              type="file"
              name="file"
              id="file"
              onChange={(event: ChangeEvent<HTMLInputElement>) => handlePhotoChange(event)}
            />
          </div>
          <Typography as="p" variant="text-12" className={styles.description}>
            Let op! Zorg dat de velg van voren gefotografeerd is en in het midden gepositioneerd is. Je kunt maximaal 1
            foto uploaden.
          </Typography>
        </div>
      ) : (
        <div className={styles['img-wrapper-upload']}>
          <Toevoegen2UploadedImg src={file} alt="" />
          <Toevoegen2AddPhotoInput
            type="file"
            name="file"
            id="file"
            onChange={(event: ChangeEvent<HTMLInputElement>) => handlePhotoChange(event)}
          />
          <div className={styles['img-button']}>
            <IconEditItem className={styles.icon} />
            <Toevoegen2AddPhotoLabel htmlFor="file">Afbeelding wijzigen</Toevoegen2AddPhotoLabel>
          </div>
        </div>
      )}
      <div>
        <div className={styles.grid}>
          <Toevoegen2NewRow
            initialSelect={initialValueSelect1}
            initialDotCode={dotCode.dot1}
            setProfileDepth={setProfileDepth}
            setDotCode={setDotCode}
            id={1}
          />
          <Toevoegen2NewRow
            initialSelect={initialValueSelect2}
            initialDotCode={dotCode.dot2}
            setProfileDepth={setProfileDepth}
            setDotCode={setDotCode}
            id={2}
          />
          <Toevoegen2NewRow
            initialSelect={initialValueSelect3}
            initialDotCode={dotCode.dot3}
            setProfileDepth={setProfileDepth}
            setDotCode={setDotCode}
            id={3}
          />
          <Toevoegen2NewRow
            initialSelect={initialValueSelect4}
            initialDotCode={dotCode.dot4}
            setProfileDepth={setProfileDepth}
            setDotCode={setDotCode}
            id={4}
          />
        </div>
      </div>
    </div>
  );
};

export default Toevoegen2NewItem;
