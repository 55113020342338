import Typography from '../../bhComponents/Typography';
import Divider from '../../bhComponents/Divider';
import styles from './ProductListItem.module.scss';

interface ProductListItemProps {
  data: {
    key: string;
    value: string;
  };
  showDivider?: boolean;
}

const ProductListItem = (props: ProductListItemProps) => {
  const { data, showDivider } = props;

  return (
    <div className={styles.main}>
      <Typography className={styles.key} as="p" variant="text-18">
        {data.key}:
      </Typography>
      <Typography className={styles.value} as="p" variant="text-18">
        {data.value}
      </Typography>
      {showDivider && <Divider className={styles.divider} />}
    </div>
  );
};

export default ProductListItem;
