import {
  ChangeEvent,
  ChangeEventHandler,
  forwardRef,
  InputHTMLAttributes,
  MouseEvent,
  Ref,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { StandardProps } from '../../../helpers/types';
import RadioGroupContext from '../RadioGroup/RadioGroupContext';
import useClassNames from 'app/helpers/useClassNames';

export type RadioValueType = string | number;

export interface FormControlBaseProps<ValueType = InputHTMLAttributes<HTMLInputElement>['value']> {
  name?: string;
  value?: ValueType;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  readOnly?: boolean;
}

const handleInputClick = (event: MouseEvent<HTMLInputElement>) => event.stopPropagation();

export interface RadioBaseProps<T = RadioValueType> extends StandardProps, FormControlBaseProps<T> {
  title?: string;
  /**
   * Sets state of RadioBase to checked
   */
  checked?: boolean;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  inputRef?: Ref<HTMLInputElement>;
  /**
   * Tabs order of RadoButton
   */
  tabIndex?: number;
  label?: string;
}

const RadioBase = forwardRef<HTMLDivElement, RadioBaseProps>((props: RadioBaseProps, ref) => {
  const {
    name: nameContext,
    /**
     * Sets state of RadioBase to disabled
     */
    disabled: disabledContext,
    readOnly: readOnlyContext,
    /**
     * Action when radios state changes
     */
    onChange: onGroupChange,
    /**
     * Value of the RadioBase
     */
    value: contextValue,
    isRadioGroup,
  } = useContext(RadioGroupContext);

  const {
    checked: checkedProp,
    className,
    children,
    title,
    tabIndex = 0,
    inputRef,
    inputProps,
    disabled = disabledContext,
    readOnly = readOnlyContext,
    name = nameContext,
    value,
    onChange,
    style,
    label,
  } = props;

  // Either <Radio> is checked itself or by parent <RadioGroup>
  const checked = useMemo(() => {
    if (!isRadioGroup) {
      return checkedProp;
    }

    return contextValue === value;
  }, [checkedProp, contextValue, isRadioGroup, value]);

  const { merge, prefix } = useClassNames('radio');
  const classes = merge(className, prefix({ disabled, checked }));
  const { classWrapper, classInner, classInput } = useMemo(
    () => ({
      classWrapper: prefix('wrapper'),
      classInner: prefix('inner'),
      classInput: prefix('input'),
    }),
    [prefix]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (disabled || readOnly) {
        return;
      }

      if (isRadioGroup) {
        onGroupChange?.(event);
      } else {
        onChange?.(event);
      }
    },
    [disabled, readOnly, isRadioGroup, onGroupChange, onChange]
  );

  return (
    <div ref={ref} className={classes} style={style}>
      <label title={title} data-label={label}>
        <span className={classWrapper} aria-checked={checked} aria-disabled={disabled}>
          <input
            {...inputProps}
            ref={inputRef}
            type="radio"
            className={classInput}
            name={name}
            value={value}
            checked={checked}
            tabIndex={tabIndex}
            readOnly={readOnly}
            disabled={disabled}
            onChange={handleChange}
            onClick={handleInputClick}
          />
          <span className={classInner} aria-hidden />
        </span>
        {children}
      </label>
    </div>
  );
});

RadioBase.displayName = 'RadioBase';

export default RadioBase;
