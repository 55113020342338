import { useMutation } from '@tanstack/react-query';

import { createImage } from 'app/api/createImage';

const useCreateImage = () => {
  const createImageMutation = useMutation(createImage);

  const onClickCreateImage = (image: FormData) => {
    createImageMutation.mutate(image);
  };

  const onClearCreateImageErrorMessage = () => createImageMutation.reset();

  const {
    isLoading: isCreateImageLoading,
    isError: isCreateImageError,
    error: createImageError,
    data: createImageData,
    isSuccess: isCreateImageSuccess,
  } = createImageMutation;

  return {
    isCreateImageLoading,
    isCreateImageError,
    createImageError,
    createImageData,
    isCreateImageSuccess,
    createImageMutation,
    onClickCreateImage,
    onClearCreateImageErrorMessage,
  };
};

export default useCreateImage;
