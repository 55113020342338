import { forwardRef, HTMLAttributes } from 'react';
import { useFormControl } from '../FormControl';
import useClassNames from 'app/helpers/useClassNames';
import './FormInputBorder.scss';

export type FormInputSize = 'large' | 'medium' | 'small';
export type FormInputFlatSide = 'left' | 'right' | 'both' | 'none';

export interface FormInputBorderProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Sets state of border disabled
   */
  disabled?: boolean;
  /**
   * Sets state of border error
   */
  error?: boolean;
  /**
   * Sets state of border success
   */
  success?: boolean;
  /**
   * Size variants
   */
  inputSize?: FormInputSize;
  /**
   * Side where to remove border radius
   */
  flatSide?: FormInputFlatSide;
}

/**
 * A example of a FormInputBorder component
 */
const FormInputBorder = forwardRef<HTMLDivElement, FormInputBorderProps>((props: FormInputBorderProps, ref) => {
  const {
    children,
    className,
    disabled: disabledProp,
    error: errorProp,
    success: successProp,
    inputSize: inputSizeProp,
    flatSide: flatSideProp,
    ...rest
  } = props;

  const {
    error: errorControl,
    success: successControl,
    disabled: disabledControl,
    inputSize: inputSizeControl,
    flatSide: flatSideControl,
  } = useFormControl();
  const disabled = disabledProp || disabledControl;
  const success = successProp || successControl;
  const error = errorProp || errorControl;
  const inputSize = inputSizeProp || inputSizeControl;
  const flatSide = flatSideProp || flatSideControl;

  const { withClassPrefix, merge } = useClassNames('form-input-border');
  const classes = merge(className, withClassPrefix({ disabled, error, success }, inputSize, `flat-${flatSide}`));

  return (
    <div {...rest} className={classes} ref={ref}>
      {children}
    </div>
  );
});

FormInputBorder.displayName = 'FormInputBorder';

export default FormInputBorder;
