import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const ResultsMainWrapper = styled.div`
  width: 47%;
  margin-bottom: 10px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    width: 100%;
  }
`;

export const ResultsLoadingStateWrapper = styled.div`
  min-height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    min-height: 80%;
  }
`;

export const ResultsWrapper = styled.div`
  // width: 100%;
  min-height: 100%;
  padding-bottom: 16px;
  border-radius: 8px;
  display: flex;
  gap: 12px;
  align-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 40px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    margin: 16px 11px;
    margin: 16px 11px 0px 11px;
    padding-bottom: 45px;
    justify-content: center;
    gap: 15px;
    min-height: 80%;
  }
`;

export const NoResultsWrapper = styled.div`
  height: inherit;
  width: 100%;
  min-height: 753px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    min-height: calc(100vh - 240px);
  }
`;

export const NoResultsWrapperIconContainer = styled.div`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.background};
`;

export const NoResultsWrapperIcon = styled.div`
display: flex;
justify-content: center;
align- items: center; 
color: ${(props) => props.color};
font-size: 45px;
font-weight: 700;

`;

export const NoResultsWrapperText = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

export const MobileSearchInfoWidget = styled.div`
  margin: 16px 11px -2px 11px;
  border-radius: 8px;
  background-color: ${(props) => props.background};
  display: flex;
  gap: 13px;
  padding: 11px 13px;
  @media screen and (min-width: ${breakpoints.lg}px) {
    display: none;
  }
`;

export const MobileSearchInfoCarIconWrapper = styled.div`
  color: ${(props) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 29px;
`;

export const MobileSearchInfoDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const MobileSearchInfoDetailsTextWrapper = styled.div`
  color: #666666;
  font-size: 12px;
`;

export const MobileSearchInfoDetailsTextModelWrapper = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

export const MobileSearchInfoFilterIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobileSearchInfoFilterIconContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
  width: 34px;
  height: 34px;

  font-size: 24px;
  border-radius: 999px;
  cursor: pointer;
`;
