import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import type { Auth0ParseHashError } from 'auth0-js';
import initiateAuth from '../../../auth/initiateAuth';
import handleCallback from '../../../auth/handleCallback';
import { getWebsiteUrl } from '../../config';

const AuthCallback = () => {
  const location = useLocation();

  useEffect(() => {
    const index = location.hash.indexOf('#');
    if (index !== -1) {
      const hash = location.hash.substring(index + 1);
      handleCallback({
        hash,
        onSuccess: () => {
          window.location.href = sessionStorage.getItem('url') || getWebsiteUrl();
        },
        onError: (error: Auth0ParseHashError) => {
          if (error.error === 'invalid_token') {
            initiateAuth();
          }
        },
      });
    }
  }, []);

  return <div>...</div>;
};

export default AuthCallback;
