import { useMemo } from 'react';

interface Props {
  value: number;
}

const PriceLabel = (props: Props) => {
  const fraction = useMemo(() => {
    const fractionValue = String(props.value).split('.')[1] || '0';
    return fractionValue === '0' || fractionValue === '00' || fractionValue === '' ? ' -' : fractionValue;
  }, [props.value]);

  return (
    <div className="price-label">
      <span className="integer">&euro; {Math.floor(props.value)},</span>
      <span className="fraction">{fraction}</span>
    </div>
  );
};

export default PriceLabel;
