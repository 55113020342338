import { useQuery } from '@tanstack/react-query';
import { getAccount } from '../api/account';
import { QueryKeys } from 'app/helpers/QueryKeys';

const useAccount = () => {
  const query = useQuery([QueryKeys.Account], () => getAccount(), {
    enabled: true,
    refetchOnWindowFocus: 'always',
    retry: false,
  });

  return {
    ...query,
    user: query.data,
  };
};
export default useAccount;
