import { getAuthState } from './auth-session';
import hasValidToken from './hasValidToken';
import initiateAuth from './initiateAuth';

export const handleAuth = (): boolean => {
  if (typeof window !== 'undefined') {
    if (hasValidToken()) {
      return true;
    }

    if (!getAuthState()) {
      sessionStorage.setItem('url', window.location.href);
      initiateAuth();
    }
  }
  return false;
};
