import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const ProductResultCardContainer = styled.div`
  height: fit-content;
  max-width: 214.5px;
  min-width: 160px;
  position: relative;

  @media screen and (max-width: 550px) {
    width: calc(50% - 8px);
    max-width: 250px;
  }

  @media screen and (min-width: 551px) and (max-width: 765px) {
    width: calc(33% - 9.5px);
    max-width: 236px;
  }

  @media screen and (min-width: 766px) and (max-width: ${breakpoints.lg - 1}px) {
    width: calc(25% - 11.5px);
    max-width: 239px;
  }

  @media screen and (min-width: 1025px) and (max-width: 1124px) {
    width: calc(50% - 6px);
    max-width: 253px;
  }

  @media screen and (min-width: 1125px) and (max-width: 1500px) {
    width: calc(33% - 6.3px);
    max-width: 225px;
  }

  @media screen and (min-width: 1501px) {
    width: calc(25% - 9px);
    // max-width: 239px;
  }
`;

export const ProductResultCardWrapper = styled.div`
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
  position: relative;
  border-radius: 8px;
  z-index: ${(props) => props.zIndex};
  cursor: ${(props) => props.cursor};
`;

export const ProductResultCardImageLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${(props) => props.height}px;
`;

export const ProductResultCardImageWrapper = styled.div`
  display: flex;
  min-height: 110px;
`;

export const ProductResultCardImageContent = styled.img`
  width: -webkit-fill-available;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;
export const ProductResultCardIconWrapper = styled.div`
  display: flex;
  color: #ffffff;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  cursor: pointer;
`;

export const ProductResultCardInfoWrapper = styled.div`
  padding-top: 9px;
  padding-bottom: 8px;
  display: flex;
  padding-right: 10px;
  justify-content: space-between;
`;

export const ProductResultCardInfoLeftWrapper = styled.div``;

export const ProductResultCardInfoLeftPriceWrapper = styled.div``;

export const ProductResultCardStatusTextWrapper = styled.div`
  color: #262626;
  font-weight: 700;
  font-size: 20.5px;
  padding-top: 1.5px;
  padding-left: 9px;
  padding-bottom: 5.75px;
`;

export const ProductResultCardInfoLeftSeasonWrapper = styled.div`
  display: flex;
  gap: 4px;
  padding-left: 9px;
`;

export const ProductResultCardInfoLeftSeasonIconWrapper = styled.div``;
export const ProductResultCardInfoLeftSeasonTextWrapper = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #949494;
`;

export const ProductResultCardInfoRightWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${(props) => props.zIndex};

  position: absolute;
  right: 10px;
  bottom: 16px;
`;

export const ProductResultCardInfoRightIconWrapper = styled.div`
  background: #e7f0fc;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  color: #136ce2;

  cursor: pointer;
`;

export const ProductResultSeasonIconImgWrapper = styled.img`
  width: 16px;
  height: 16px;
  filter: invert(0.7);
`;

export const ProductResultStockMenuBackground = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #262626;
  opacity: 0.4;
  z-index: 2;
  display: ${(props) => props.display};
`;

export const ProductResultPopupBackground = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 5;
  background-color: ${(props) => props.backgroundColor};
  opacity: 0.4;
`;

export const ProductResultStockMenuWrapper = styled.div`
  position: absolute;
  bottom: -117px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
  z-index: 5;

  display: ${(props) => props.display};
`;

export const ProductResultStockMenuItemWrapper = styled.div`
  font-size: 14px;
  padding: 7px 9px;
  cursor: pointer;
  color: ${(props) => props.color};
  border-radius: ${(props) => props.border};
  border-bottom: ${(props) => props.borderBottom};

  :hover {
    background-color: #f3f3f3;
  }
`;

export const ProductResultCardWhiteSpace = styled.div`
  display: ${(props) => props.display};
  width: 100%;
  height: 9px;
  position: absolute;
  bottom: -125px;
  z-index: 5;
`;
