import apiClient from 'app/axios/api-client';

export const createImage = async (image: FormData) => {
  const result = await apiClient.post(`afbeelding/`, image, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return result.data;
};
