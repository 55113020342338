import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const ToevoegenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  min-height: calc(100vh - 130px);
  margin-top: 19px;

  @media (max-width: ${breakpoints.md}px) {
    margin-top: 20px;
  }

  margin-bottom: 16px;
`;

export const ToevoegenGoBackContainer = styled.div`
  margin-bottom: 11px;
  display: flex;
  align-items: center;
  min-height: 24px;
  width: ${(props) => (props.isLastStep ? '60%' : '45%')};
  color: #136ce2;

  @media (max-width: 1600px) {
    width: ${(props) => !props.isLastStep && '60%'};
  }
  @media (max-width: 1300px) {
    width: ${(props) => !props.isLastStep && '70%'};
  }
  @media (max-width: 1100px) {
    width: ${(props) => !props.isLastStep && '80%'};
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    min-width: ${(props) => props.isLastStep && '930px'};
  }

  @media screen and (min-width: ${breakpoints.xl}px) {
    min-width: ${(props) => props.isLastStep && '1050px'};
  }

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    display: none;
  }
`;
export const ToevoegenGoBackIcon = styled.div`
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
  :hover {
    color: #0b4188;
  }
`;

export const ToevoegenGoBackText = styled.div`
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
  :hover {
    color: #0b4188;
  }
`;
