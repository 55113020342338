import type { AuthOptions } from 'auth0-js';

const authConfig: AuthOptions = {
  domain: 'broekhuis-public.eu.auth0.com',
  clientID: 'OzFxL1EJXgkGsMvAYehNegFxVGsRBjwX',
  audience: 'https://tyreswap.esb.broekhuis.online',
  redirectUri: 'http://localhost:3000/auth/callback',
  responseType: 'token id_token',
  scope: [
    'admin',
    'company',
    'company:create',
    'company:delete',
    'company:update',
    'order:create',
    'order:delete',
    'order:read',
    'order:update',
    'tyre_set:create',
    'tyre_set:update',
    'tyre_set:delete',
  ].join(' '),
};

export const getAuthConfig = (): AuthOptions => {
  if (typeof window !== 'undefined' && window.bsConfig) {
    return {
      ...authConfig,
      domain: window.bsConfig.authDomain,
      clientID: window.bsConfig.authClientId,
      audience: window.bsConfig.authAudience,
      redirectUri: window.bsConfig.authRedirectUri,
    };
  }

  return authConfig;
};
