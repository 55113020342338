import { useMutation } from '@tanstack/react-query';
import type { UseMutationResult } from '@tanstack/react-query';
import { deleteFavoriet, deleteFavorietPropsType } from 'app/api/favoriet';

type DeleteFavorietProps = {
  id: number | string;
};

const useDeleteFavoriet = (setFavoriteState: React.Dispatch<React.SetStateAction<boolean>>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const deleteFavorietMutatation: UseMutationResult<any, any, deleteFavorietPropsType, unknown> = useMutation(
    deleteFavoriet,
    {
      onSuccess: () => {
        setFavoriteState(false);
      },
    }
  );

  const onClickDeleteFavorieten = (data: DeleteFavorietProps) => deleteFavorietMutatation.mutate(data);

  const onClearErrorMessage = () => deleteFavorietMutatation.reset();

  const { isLoading, isError, error, data, isSuccess } = deleteFavorietMutatation;

  return {
    deleteFavorietMutatation,
    onClickDeleteFavorieten,
    onClearErrorMessage,
    isLoading,
    isError,
    error,
    isSuccess,
    data,
  };
};

export default useDeleteFavoriet;
