import React from 'react';
import styles from '../../../../scss/_variables.scss';
import {
  BetalenWrapper,
  BetalenTitleWrapper,
  BetalenPayInformationWrapper,
  BetalenOneButtonAditionalInfoContainer,
  BetalenButtonWrapper,
  BetalenButton,
  BetalenTotalWrapper,
  BetalenTotalTextWrapper,
  BetalenTotalValueWrapper,
} from './Betalen.styles';

const Index = ({ placeOrder, price }) => {
  const handleBevestigenButtonClick = () => {
    placeOrder();
  };
  return (
    <BetalenWrapper>
      <BetalenTitleWrapper>Betaling</BetalenTitleWrapper>
      <BetalenPayInformationWrapper>
        Je hoeft nu niet te betalen. Het bedrag wordt aan het eind van de maand verrekend op de holding factuur.
      </BetalenPayInformationWrapper>
      <BetalenOneButtonAditionalInfoContainer>
        <BetalenTotalWrapper backgroundColor={styles.primaryColorContact}>
          <BetalenTotalTextWrapper>Totaal:</BetalenTotalTextWrapper>
          <BetalenTotalValueWrapper color={styles.primaryColor}>&euro; {price}</BetalenTotalValueWrapper>
        </BetalenTotalWrapper>
        <BetalenButtonWrapper>
          <BetalenButton
            onClick={handleBevestigenButtonClick}
            backgroundColor={styles.primaryColor}
            hover={styles.primaryColorContactHoverContent}
            cursor="pointer"
          >
            Bevestigen
          </BetalenButton>
        </BetalenButtonWrapper>
      </BetalenOneButtonAditionalInfoContainer>
    </BetalenWrapper>
  );
};

export default Index;
