import React from 'react';
import { PageNavWrapper } from './PageNav.styles';

type PageNavPropsType = {
  symbol: string | React.ReactElement | number;
  isActive?: boolean;
  paddingTop?: string;
  cursor?: string;
  handlePageChange?: (() => void) | ((value: number) => void);
};

const PageNav: React.FC<PageNavPropsType> = ({ symbol, isActive = false, paddingTop, cursor, handlePageChange }) => (
  <PageNavWrapper
    cursor={cursor}
    color={isActive ? '#FFFFFF' : '#136CE2'}
    backgroundColor={isActive ? '#136CE2' : '#FFFFFF'}
    paddingTop={paddingTop}
    onClick={handlePageChange}
  >
    {symbol}
  </PageNavWrapper>
);

export default PageNav;
