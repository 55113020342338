import { forwardRef, PropsWithChildren, ReactElement, ReactNode, useMemo } from 'react';
import ButtonBase, { ButtonBaseProps } from '../ButtonBase';
import { useButtonGroup } from '../ButtonGroup';
import Spinner from '../Spinner/Spinner';
import SvgIcon from '../SvgIcon';
import useClassNames from 'app/helpers/useClassNames';
import './Button.scss';

export type GenericButtonProps = ButtonBaseProps & {
  /**
   * The color variants
   */
  color?: 'alert' | 'warning' | 'success' | 'gray';
  /**
   * Makes the button 100% width
   */
  fullwidth?: boolean;
  /**
   * Adds icon element to button
   * @deprecated
   */
  icon?: ReactElement<typeof SvgIcon>;
  /**
   * Placement of icon in the button
   * @deprecated
   */
  iconPlacement?: 'leading' | 'trailing';
  isLoading?: boolean;

  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
};

export type NormalButtonProps = GenericButtonProps & {
  /**
   * Style of the button
   */
  appearance?: 'filled' | 'hollow' | 'tinted' | 'ghost';
  /**
   * Size of the height of the button
   */
  size?: 'large' | 'medium' | 'small';
  /**
   * Side to remove border radius (only works with appearance filled, hollow, tinted and ghost)
   */
  flatSide?: 'left' | 'right';
};

export type LinkButtonProps = GenericButtonProps & {
  appearance: 'link';
  /**
   * Sets size of font
   */
  fontSize?: 'large' | 'medium' | 'small' | 'inherit';
  /**
   * Sets font weight bold, (only works with appearance link)
   */
  fontWeight?: 'regular' | 'bold';
};

export type ClearButtonProps = GenericButtonProps & {
  appearance: 'clear';
  /**
   * medium and regular (only works with appearance clear)
   */
  fontWeight?: 'bold' | 'medium' | 'regular';
  /**
   * Sets size of font
   */
  fontSize?: 'large' | 'medium' | 'small' | 'inherit';
};

export type ButtonColor = GenericButtonProps['color'];
export type ButtonIconPlacement = GenericButtonProps['iconPlacement'];
export type ButtonAppearance =
  | NormalButtonProps['appearance']
  | LinkButtonProps['appearance']
  | ClearButtonProps['appearance'];
export type ButtonSize = NormalButtonProps['size'];
export type ButtonFlatSide = NormalButtonProps['flatSide'];
export type ButtonFontSize = LinkButtonProps['fontSize'];
export type ButtonFontWeight = LinkButtonProps['fontWeight'] | ClearButtonProps['fontWeight'];

type OptionalProps = {
  size?: ButtonSize;
  flatSide?: ButtonFlatSide;
  fontSize?: ButtonFontSize;
  fontWeight?: ButtonFontWeight;
};

export type ButtonProps = NormalButtonProps | LinkButtonProps | ClearButtonProps;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ButtonAdornment = ({ children }: PropsWithChildren<any>) => {
  const { withClassPrefix } = useClassNames('btn-adornment');

  return <div className={withClassPrefix()}>{children}</div>;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props: ButtonProps, ref) => {
  const {
    appearance = 'filled',
    className,
    color,
    children,
    fullwidth: fullwidthProp,
    icon,
    isLoading,
    disabled,
    iconPlacement = 'leading',
    startAdornment: startAdornmentProp,
    endAdornment: endAdornmentProp,
    ...rest
  } = props;

  const { fullwidth: fullwidthContext } = useButtonGroup();

  const { buttonProps, size, fontSize, fontWeight, flatSide } = useMemo(() => {
    const optionalProps: OptionalProps = {};
    if ('size' in rest) {
      optionalProps.size = rest.size;
      delete rest.size;
    }
    if ('fontSize' in rest) {
      optionalProps.fontSize = rest.fontSize;
      delete rest.fontSize;
    }
    if ('fontWeight' in rest) {
      optionalProps.fontWeight = rest.fontWeight;
      delete rest.fontWeight;
    }
    if ('flatSide' in rest) {
      optionalProps.flatSide = rest.flatSide;
      delete rest.flatSide;
    }

    return { buttonProps: rest, ...optionalProps };
  }, [rest]);

  const fullwidth = fullwidthProp || fullwidthContext;

  const { withClassPrefix, merge } = useClassNames('btn');
  const classes = merge(
    className,
    withClassPrefix(
      appearance,
      size && `size-${size}`,
      fontSize && `font-size-${fontSize}`,
      fontWeight && `font-weight-${fontWeight}`,
      color && `${appearance}-${color}`,
      flatSide && `flat-${flatSide}`,
      isLoading && 'is-loading',
      { fullwidth }
    )
  );

  const startAdornment = startAdornmentProp || (iconPlacement === 'leading' && icon);
  const endAdornment = endAdornmentProp || (iconPlacement === 'trailing' && icon);

  return (
    <ButtonBase {...buttonProps} disabled={isLoading || disabled} ref={ref} className={classes}>
      {isLoading && <Spinner size={size === 'small' || appearance === 'clear' ? 'xs' : 'small'} />}
      {startAdornment && <ButtonAdornment>{startAdornment}</ButtonAdornment>}
      {children}
      {endAdornment && <ButtonAdornment>{endAdornment}</ButtonAdornment>}
    </ButtonBase>
  );
});

Button.displayName = 'Button';

export default Button;
