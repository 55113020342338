import { useQuery } from '@tanstack/react-query';
import { getPurchase } from 'app/api/aankoop';
import { QueryKeys } from 'app/helpers/QueryKeys';

const useAankoop = (id: number) => {
  const query = useQuery([QueryKeys.Aankop, id], () => getPurchase(id), {
    enabled: true,
    refetchOnWindowFocus: 'always',
    retry: false,
  });

  return {
    ...query,
    purchase: query.data,
  };
};

export default useAankoop;
