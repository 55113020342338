import React, { ChangeEvent, useCallback, useState } from 'react';
import FormControl from '../../../../bhComponents/FormControl';
import FormSelect from '../../../../bhComponents/FormSelect';
import { ProfileDepths } from '../../AddTyreBlock/AddTyreBlock';
import FormLabel from '../../../../bhComponents/FormLabel';
import FormInputText from '../../../../bhComponents/FormInputText';
import { FormSelectOptionType } from '../../../../bhComponents/FormSelectDropdown';
import { FormSelectDropdownOptionEventHandler } from '../../../../bhComponents/FormSelect/FormSelect';
import Typography from '../../../../bhComponents/Typography';
import FormErrorText from '../../../../bhComponents/FormErrorText';
import styles from './Toevoegen2NewRow.module.scss';

interface Toevoegen2NewRowProps {
  id: number;
  setDotCode: (value: (prevProfile: Record<string, string>) => { [p: string]: string }) => void;
  initialDotCode: string;
  initialSelect?: FormSelectOptionType;
  setProfileDepth: (value: (prevProfile: Record<string, string>) => { [p: string]: string }) => void;
}

const Toevoegen2NewRow = (props: Toevoegen2NewRowProps) => {
  const { setDotCode, id, initialSelect, initialDotCode, setProfileDepth } = props;
  const dotId = `dot${id}`;
  const profileId = `dropdown${id}Value`;
  const [valueInput, setValueInput] = useState<string>(initialDotCode);
  const [invalid, setInvalid] = useState(false);

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 4 || isNaN(Number(event.target.value))) {
      return null;
    }
    setValueInput(event.target.value);
    setDotCode((prevProfile: Record<string, string>) => ({
      ...prevProfile,
      [dotId]: event.target.value,
    }));
  }, []);

  const [value, setValue] = useState<FormSelectOptionType | undefined>(initialSelect);
  const handleChange: FormSelectDropdownOptionEventHandler = useCallback((_, options) => {
    setValue(options);
    setProfileDepth((prevProfile: Record<string, string>) => ({
      ...prevProfile,
      [profileId]: options?.value,
    }));
  }, []);

  const handleBlur = useCallback(() => {
    if (valueInput && valueInput.length !== 4) {
      setInvalid(true);
    }
  }, [valueInput]);
  const handleFocus = useCallback(() => {
    setInvalid(false);
  }, []);

  return (
    <div className={styles.main}>
      <Typography as="h2" variant="heading-24" fontFamily="bold" className={styles.title}>
        Wiel {id}
      </Typography>
      <FormControl id={`wiel ${id}`}>
        <FormSelect
          options={ProfileDepths}
          value={value}
          onChange={handleChange}
          label={<FormLabel>Profieldiepte</FormLabel>}
        />
      </FormControl>
      <FormControl error={invalid} id="select">
        <FormInputText
          value={valueInput}
          onChange={handleInputChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          label={<FormLabel>Dot-code</FormLabel>}
        />
        {invalid && <FormErrorText>Vul een geldige DOT-code in. (4 cijfers)</FormErrorText>}
      </FormControl>
    </div>
  );
};

export default Toevoegen2NewRow;
