import { FocusEvent, useCallback, useRef, useState } from 'react';
import { StandardProps } from '../../../helpers/types';
import { useFormControl } from '../FormControl';
import FormErrorText from '../FormErrorText';
import IconButton from '../IconButton';
import IconVinkje from '../../../../assets/svg/IconVinkje';
import IconKruis from '../../../../assets/svg/IconKruis';
import useClassNames from 'app/helpers/useClassNames';
import './FormInlineEdit.scss';

export interface FormInlineEditProps extends StandardProps {
  onClickConfirm?: () => void;
  onClickCancel?: () => void;
  errorText?: string;
  disabled?: boolean;
}

const FormInlineEdit = (props: FormInlineEditProps) => {
  const { onClickConfirm, onClickCancel, errorText, className, style, children, disabled: disabledProp } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const { disabled: disabledControl } = useFormControl();
  const [hasFocus, setFocus] = useState(false);

  const { prefix, withClassPrefix, merge } = useClassNames('form-inline-edit');
  const classes = merge(className, withClassPrefix());

  const handleClickConfirm = useCallback(() => {
    onClickConfirm?.();
    setFocus(false);
  }, [onClickConfirm]);

  const handleClickCancel = useCallback(() => {
    onClickCancel?.();
    setFocus(false);
  }, [onClickCancel]);

  const handleContainerFocus = useCallback(() => {
    if (disabledControl || disabledProp) {
      return;
    }
    setFocus(true);
  }, [disabledControl, disabledProp]);

  const handleContainerBlur = useCallback(
    (event: FocusEvent<HTMLDivElement>) => {
      if (disabledControl || disabledProp || errorText) {
        return;
      }
      // confirm on blur outside click and when the target is not one of the buttons
      if (!containerRef.current?.contains(event.relatedTarget as Node)) {
        handleClickConfirm();
      }
    },
    [disabledControl, disabledProp, errorText, handleClickConfirm]
  );

  return (
    <div
      className={prefix('container')}
      ref={containerRef}
      style={style}
      onFocus={handleContainerFocus}
      onBlur={handleContainerBlur}
    >
      {children}
      {onClickCancel && onClickConfirm && (hasFocus || errorText) ? (
        <div className={classes}>
          <div className={prefix('buttons')}>
            <IconButton size="small" onClick={handleClickCancel} icon={<IconKruis />} color="gray" tabIndex={0} />
            <IconButton
              size="small"
              onClick={handleClickConfirm}
              icon={<IconVinkje />}
              disabled={Boolean(errorText)}
              tabIndex={0}
            />
          </div>
          {errorText && <FormErrorText>{errorText}</FormErrorText>}
        </div>
      ) : null}
    </div>
  );
};

export default FormInlineEdit;
