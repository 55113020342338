import { forwardRef } from 'react';
import { StandardProps } from '../../../helpers/types';
import useClassNames from 'app/helpers/useClassNames';
import './FormInputAdornment.scss';

export interface FormInputAdornmentProps extends StandardProps {
  /**
   * Position of children
   */
  position: 'start' | 'end';
}

const FormInputAdornment = forwardRef<HTMLDivElement, FormInputAdornmentProps>(
  (props: FormInputAdornmentProps, ref) => {
    const { className, children, position, style } = props;

    const { merge, withClassPrefix } = useClassNames('form-input-adornment');
    const classes = merge(className, withClassPrefix(position));

    return (
      <div ref={ref} className={classes} style={style}>
        {children}
      </div>
    );
  }
);

FormInputAdornment.displayName = 'FormInputAdornment';

export default FormInputAdornment;
