import { ChangeEvent, ChangeEventHandler, forwardRef, InputHTMLAttributes, useCallback, useMemo } from 'react';
import { RadioValueType } from '../RadioBase';
import { StandardProps } from '../../../helpers/types';
import RadioGroupContext, { RadioGroupContextValue } from './RadioGroupContext';
import useClassNames from 'app/helpers/useClassNames';
import './RadioGroup.scss';

export type RadioGroupPlacement = 'start' | 'center' | 'end';

export interface FormControlBaseProps<ValueType = InputHTMLAttributes<HTMLInputElement>['value']> {
  name?: string;
  value?: ValueType;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  readOnly?: boolean;
}

export interface RadioGroupProps<T = RadioValueType> extends StandardProps, FormControlBaseProps<T> {
  placement?: RadioGroupPlacement;
  column?: boolean;
  nowrap?: boolean;
}

const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>((props: RadioGroupProps, ref) => {
  const {
    className,
    children,
    placement = 'center',
    column,
    nowrap,
    value,
    name,
    disabled,
    readOnly,
    onChange,
    style,
  } = props;

  const { merge, withClassPrefix } = useClassNames('radio-group');
  const classes = merge(className, withClassPrefix(placement, column ? 'column' : 'row', nowrap ? 'nowrap' : 'wrap'));

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (disabled || readOnly) {
        return;
      }

      onChange?.(event);
    },
    [disabled, onChange, readOnly]
  );

  const contextValue = useMemo<RadioGroupContextValue>(
    (): RadioGroupContextValue => ({
      name,
      value,
      readOnly,
      disabled,
      onChange: handleChange,
      isRadioGroup: true,
    }),
    [disabled, handleChange, name, readOnly, value]
  );

  return (
    <RadioGroupContext.Provider value={contextValue}>
      <div role="radiogroup" ref={ref} className={classes} style={style}>
        {children}
      </div>
    </RadioGroupContext.Provider>
  );
});

RadioGroup.displayName = 'RadioGroup';

export default RadioGroup;
