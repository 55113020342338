import { useState, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import tireSizeFormat from '../../../../helpers/tireSizeFormat';
import {
  DetailsGeneralContainer,
  DetailsWrapper,
  DetailsLoaderPlaceholder,
  DetailsTopDateWrapper,
  DetailsSecondRowWrapper,
  DetailsSecondRowElementWrapper,
  DetailsSecondRowElementTextWrapper,
  DetailsSecondRowElementContentWrapper,
  DetailsThirdRowWrapper,
  DetailsThirdRowFirstRowWrapper,
  DetailsFourthRowWrapper,
  DetailsFourthRowWarningIconWrapper,
  DetailsFourthRowWarningBoxWrapper,
  DetailsImageLoaderPlaceholder,
  DetailsFifthRowWrapper,
  DetailsFifthRowFirstWrapper,
  DetailsFIfthRowSecondWrapper,
  DetailsFifthRowSecondImageWrapper,
  DetailsFifthRowSecondProductDetailsWrapper,
  DetailsFifthRowSecondProductNameWrapper,
  DetailsFifthRowSecondProductSecondDetailsWrapper,
  DetailsFifthRowSecondProductTyreSizeWrapper,
  DetailsFifthRowSecondProductSeasonOnlyWrapper,
  DetailsFifthRowSecondProductSizeOnlyWrapper,
  DetailsFifthRowSecondProductPriceWrapper,
  DetailsSixthRowWrapper,
  DetailsSixthRowContentWrapper,
  DetailsSixthRowSecondRowWrapper,
  DetailsSixthRowSecondTotaalTextWrapper,
  DetailsSixthRowSecondTotaalPriceWrapper,
} from './Details.styles';
import useWindowSize from 'app/hooks/useWindowSize';
import { breakpoints } from 'app/config/breakpoints';
import ExampleImage from 'assets/img/band_example.png';
import { dateConverter, besteldatumConverter } from 'app/helpers/utils';
import useBestelling from 'app/hooks/useBestelling';
import Loader from 'app/components/Loader/Loader';
import Toast from 'app/components/Toast/Toast';
import MyAccountLayout from 'app/components/MijnAccountComponents/MijnAccoundDesktop/MyAccountLayout';
// import NotificationIcon from '../../../../../assets/svg/notifications_black_24dp.svg';
import OrderStatusBar from 'app/components/MijnAccountComponents/OrderStatusBar/OrderStatusBar';
const Details = () => {
  const [imageFile, setImageFile] = useState();

  const location = useLocation();

  const isBestellingenPage = location.pathname.includes('bestellingen');

  const { width } = useWindowSize();
  const isLargeScreen = width && width >= breakpoints.lg;

  const orderParamId = useParams().id;

  const { data: bestellingDetailsData, isLoading, isError, error } = useBestelling(orderParamId);

  const properDateFormat = dateConverter(bestellingDetailsData?.payment.timestamp);

  const bestelDatumFormat = besteldatumConverter(bestellingDetailsData?.payment.timestamp);

  const bezorgadresValue = `${bestellingDetailsData?.location?.street} ${bestellingDetailsData?.location?.house_number} ${bestellingDetailsData?.location?.postalcode} ${bestellingDetailsData?.location?.city}`;

  const seasonOnly =
    bestellingDetailsData?.models[0]?.model.season === 'Summer'
      ? 'Zomer'
      : bestellingDetailsData?.models[0]?.model.season === 'Winter'
      ? 'Winter'
      : 'All season';

  const productName = `${bestellingDetailsData?.models[0].model.condition}e ${bestellingDetailsData?.tyre_set.brand} ${seasonOnly} banden`;

  const totalPrice = bestellingDetailsData?.order.price_value;

  const tyreSize = tireSizeFormat(
    bestellingDetailsData?.models[0].model.width,
    bestellingDetailsData?.models[0].model.height,
    bestellingDetailsData?.models[0].model.inches
  );
  const imageUrl =
    bestellingDetailsData?.models?.[0]?.model.condition === 'Nieuw'
      ? bestellingDetailsData?.models?.[3]?.image_url
      : bestellingDetailsData?.models?.[0]?.image_url;

  // const tyreProfile =
  // 	bestellingDetailsData?.models[0].model.profile_depth + 'mm';

  const deliveryStatus = useMemo(
    () => (bestellingDetailsData ? JSON.parse(bestellingDetailsData?.order?.delivery_status)?.status : ''),
    [bestellingDetailsData]
  );

  useEffect(() => {
    if (bestellingDetailsData && !imageFile) {
      if (imageUrl) {
        setImageFile(imageUrl);
      } else {
        setImageFile(ExampleImage);
      }
    }
  }, [bestellingDetailsData]);

  const DetailsContent = (
    <DetailsGeneralContainer>
      <DetailsWrapper>
        {isLoading ||
          (!imageFile && (
            <DetailsLoaderPlaceholder>
              <Loader />
            </DetailsLoaderPlaceholder>
          ))}

        {bestellingDetailsData && !isError && imageFile && (
          <>
            <DetailsTopDateWrapper>{properDateFormat}</DetailsTopDateWrapper>
            <DetailsSecondRowWrapper>
              <DetailsSecondRowElementWrapper>
                <DetailsSecondRowElementTextWrapper>Ordernummer:</DetailsSecondRowElementTextWrapper>
                <DetailsSecondRowElementContentWrapper>
                  {bestellingDetailsData?.order.id}
                </DetailsSecondRowElementContentWrapper>
              </DetailsSecondRowElementWrapper>
              <DetailsSecondRowElementWrapper>
                <DetailsSecondRowElementTextWrapper>Besteldatum:</DetailsSecondRowElementTextWrapper>
                <DetailsSecondRowElementContentWrapper>{bestelDatumFormat}</DetailsSecondRowElementContentWrapper>
              </DetailsSecondRowElementWrapper>
              <DetailsSecondRowElementWrapper>
                <DetailsSecondRowElementTextWrapper>Bezorgadres:</DetailsSecondRowElementTextWrapper>
                <DetailsSecondRowElementContentWrapper>{bezorgadresValue}</DetailsSecondRowElementContentWrapper>
              </DetailsSecondRowElementWrapper>
            </DetailsSecondRowWrapper>
            <DetailsThirdRowWrapper>
              <DetailsThirdRowFirstRowWrapper>Status:</DetailsThirdRowFirstRowWrapper>
              <OrderStatusBar status={deliveryStatus} />
            </DetailsThirdRowWrapper>

            {isBestellingenPage && (
              <DetailsFourthRowWrapper>
                <DetailsFourthRowWarningIconWrapper>
                  <NotificationsIcon fontSize="inherit" />
                </DetailsFourthRowWarningIconWrapper>
                <DetailsFourthRowWarningBoxWrapper>
                  De transporteur komt deze bestelling binnenkort bij jouw vestiging ophalen.
                </DetailsFourthRowWarningBoxWrapper>
              </DetailsFourthRowWrapper>
            )}

            <DetailsFifthRowWrapper>
              <DetailsFifthRowFirstWrapper>Product:</DetailsFifthRowFirstWrapper>

              <DetailsFIfthRowSecondWrapper>
                {!imageFile ? (
                  <DetailsImageLoaderPlaceholder>
                    {/* <Loader size='22px' loaderWidth='2px' /> */}
                  </DetailsImageLoaderPlaceholder>
                ) : (
                  <DetailsFifthRowSecondImageWrapper>
                    <img src={imageFile} className="imageContainer" alt="Tyre" />
                  </DetailsFifthRowSecondImageWrapper>
                )}

                <DetailsFifthRowSecondProductDetailsWrapper>
                  <DetailsFifthRowSecondProductNameWrapper>{productName}</DetailsFifthRowSecondProductNameWrapper>

                  <DetailsFifthRowSecondProductSecondDetailsWrapper>
                    <DetailsFifthRowSecondProductTyreSizeWrapper>
                      {tyreSize}
                    </DetailsFifthRowSecondProductTyreSizeWrapper>
                    <DetailsFifthRowSecondProductSeasonOnlyWrapper>
                      {seasonOnly}
                    </DetailsFifthRowSecondProductSeasonOnlyWrapper>
                    <DetailsFifthRowSecondProductSizeOnlyWrapper>
                      {/* {tyreProfile} */}
                    </DetailsFifthRowSecondProductSizeOnlyWrapper>
                  </DetailsFifthRowSecondProductSecondDetailsWrapper>
                </DetailsFifthRowSecondProductDetailsWrapper>
                <DetailsFifthRowSecondProductPriceWrapper>
                  &euro; {`${totalPrice}`.replace('.', ',')}
                </DetailsFifthRowSecondProductPriceWrapper>
              </DetailsFIfthRowSecondWrapper>
            </DetailsFifthRowWrapper>
            <DetailsSixthRowWrapper>
              <DetailsSixthRowContentWrapper>
                {/* <DetailsSixthRowFirstRowWrapper>
									<DetailsSixthRowFirstMontageTextWrapper>
										Montage service:
									</DetailsSixthRowFirstMontageTextWrapper>
									<DetailsSixthRowFirstMontagePriceWrapper>
										&euro;{' '}
										{('' + mockedDetailsData.montageService).replace('.', ',')}
									</DetailsSixthRowFirstMontagePriceWrapper>
								</DetailsSixthRowFirstRowWrapper> */}
                <DetailsSixthRowSecondRowWrapper>
                  <DetailsSixthRowSecondTotaalTextWrapper>Totaal:</DetailsSixthRowSecondTotaalTextWrapper>
                  <DetailsSixthRowSecondTotaalPriceWrapper>
                    &euro; {`${totalPrice}`.replace('.', ',')}
                  </DetailsSixthRowSecondTotaalPriceWrapper>
                </DetailsSixthRowSecondRowWrapper>
              </DetailsSixthRowContentWrapper>
            </DetailsSixthRowWrapper>
          </>
        )}

        {isError && <Toast type="error" message={error?.message} addditionalMessage standalone standaloneAutoRemove />}
      </DetailsWrapper>
    </DetailsGeneralContainer>
  );
  return isLargeScreen ? (
    <MyAccountLayout isLargeScreen={isLargeScreen}>{DetailsContent}</MyAccountLayout>
  ) : (
    DetailsContent
  );
};

export default Details;
