import React, { useState } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import {
  FavorietenGeneralContainer,
  FavorietenWrapper,
  FavorietenLoaderPlaceholder,
  NoFavorietenWrapper,
  NoFavorietenIconWrapper,
  NoFavorietenText,
  NoFavorietenWinkelenButton,
} from './Favorieten.styles';
import useWindowSize from 'app/hooks/useWindowSize';
import { breakpoints } from 'app/config/breakpoints';
import ExampleImage from 'assets/img/band_example.png';
import ProductResultCard from 'app/components/cards/Product-Result-Card/ProductResultCard';
import Toast from 'app/components/Toast/Toast';
import Loader from 'app/components/Loader/Loader';
import ToastContainer from 'app/components/Toast/ToastContainer';

import MyAccountLayout from 'app/components/MijnAccountComponents/MijnAccoundDesktop/MyAccountLayout';

import useFavorieten from 'app/hooks/Favorite/useFavorieten';
import { QueryKeys } from 'app/helpers/QueryKeys';

const Favorieten = () => {
  const { data: favorietenData, isLoading, isError, error } = useFavorieten();

  const [toastId, setToastId] = useState(0);
  const [toastList, setToastList] = useState([]);

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { width } = useWindowSize();
  const isLargeScreen = width && width >= breakpoints.lg;

  const refreshUponProductResultCardAction = () => {
    queryClient.invalidateQueries([QueryKeys.Favorieten]);
  };

  const FavorietenContent = (
    <FavorietenGeneralContainer>
      <FavorietenWrapper>
        {isLoading && (
          <FavorietenLoaderPlaceholder>
            <Loader />
          </FavorietenLoaderPlaceholder>
        )}
        <ToastContainer toastList={toastList} autoRemove removeInterval={4750} />
        {isError && (
          <Toast
            type="error"
            message={error?.message}
            additionalMessage={error?.response?.data?.detail}
            standalone
            standaloneAutoRemove
          />
        )}

        {favorietenData && favorietenData?.length === 0 && (
          <NoFavorietenWrapper>
            <NoFavorietenIconWrapper>
              <FavoriteIcon fontSize="inherit" />
            </NoFavorietenIconWrapper>
            <NoFavorietenText>Nog geen favorieten</NoFavorietenText>
            <NoFavorietenWinkelenButton onClick={() => navigate('/')}>Winkelen</NoFavorietenWinkelenButton>
          </NoFavorietenWrapper>
        )}

        {favorietenData &&
          favorietenData?.length > 0 &&
          !isLoading &&
          favorietenData.map((product) => (
            <ProductResultCard
              key={product.tyre_set.id}
              isFavorite={product.tyre_set.is_favorite}
              status={product.tyre_set.sale_status}
              season={product.models[0]?.model.season.toUpperCase()}
              price={product.tyre_set.price_value}
              image={ExampleImage}
              id={product.tyre_set.id}
              isOwner={product.tyre_set.is_owner}
              condition={product.models[0].model.condition}
              toastId={toastId}
              setToastId={setToastId}
              toastList={toastList}
              setToastList={setToastList}
              imageId={
                product.models[0].model.condition === 'Nieuw'
                  ? product.models[3]?.image_id
                  : product.models[0]?.image_id
              }
              imageUrl={
                product.models[0].model.condition === 'Nieuw'
                  ? product.models[3]?.image_url
                  : product.models[0]?.image_url
              }
              advertentieId={product?.advertisement?.id}
              isListerPage
              refreshUponProductResultCardAction={refreshUponProductResultCardAction}
            />
          ))}
      </FavorietenWrapper>
    </FavorietenGeneralContainer>
  );

  return isLargeScreen ? (
    <MyAccountLayout isLargeScreen={isLargeScreen}>{FavorietenContent}</MyAccountLayout>
  ) : (
    FavorietenContent
  );
};

export default Favorieten;
