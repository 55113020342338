import { forwardRef, AnchorHTMLAttributes, useMemo, PropsWithChildren } from 'react';
import {
  ButtonFlatSide,
  ButtonFontSize,
  ButtonFontWeight,
  ButtonSize,
  ClearButtonProps,
  LinkButtonProps,
  NormalButtonProps,
} from '../Button';
import useClassNames from 'app/helpers/useClassNames';
import './Anchor.scss';

type OptionalProps = {
  size?: ButtonSize;
  flatSide?: ButtonFlatSide;
  fontSize?: ButtonFontSize;
  fontWeight?: ButtonFontWeight;
};

type AnchorBase =
  | Omit<NormalButtonProps, 'isLoading' | 'onClick'>
  | Omit<LinkButtonProps, 'isLoading' | 'onClick'>
  | Omit<ClearButtonProps, 'isLoading' | 'onClick'>;

export type AnchorProps = AnchorBase &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'onClick'> & {
    disabled?: boolean;
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AnchorAdornment = ({ children }: PropsWithChildren<any>) => {
  const { withClassPrefix } = useClassNames('anchor-adornment');

  return <div className={withClassPrefix()}>{children}</div>;
};

/**
 *  A example of an anchor component
 */
const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>((props: AnchorProps, ref) => {
  const { children, className, color, fullwidth, startAdornment, endAdornment, href, appearance, disabled, ...rest } =
    props;
  const { anchorProps, size, fontSize, fontWeight, flatSide } = useMemo(() => {
    const optionalProps: OptionalProps = {};
    if ('size' in rest) {
      optionalProps.size = rest.size as ButtonSize;
      delete rest.size;
    }
    if ('fontSize' in rest) {
      optionalProps.fontSize = rest.fontSize as ButtonFontSize;
      delete rest.fontSize;
    }
    if ('fontWeight' in rest) {
      optionalProps.fontWeight = rest.fontWeight as ButtonFontWeight;
      delete rest.fontWeight;
    }
    if ('flatSide' in rest) {
      optionalProps.flatSide = rest.flatSide as ButtonFlatSide;
      delete rest.flatSide;
    }

    return { anchorProps: rest, ...optionalProps };
  }, [rest]);

  const { merge, withClassPrefix } = useClassNames('anchor');
  const classes = merge(
    className,
    withClassPrefix(
      appearance,
      size && `size-${size}`,
      fontSize && `font-size-${fontSize}`,
      fontWeight && `font-weight-${fontWeight}`,
      color && `${appearance}-${color}`,
      flatSide && `flat-${flatSide}`,
      { fullwidth, disabled }
    )
  );

  if (disabled) {
    anchorProps.tabIndex = -1;
    anchorProps['aria-disabled'] = true;
  }

  return (
    <a ref={ref} href={href} {...anchorProps} className={classes}>
      {startAdornment && <AnchorAdornment>{startAdornment}</AnchorAdornment>}
      {children}
      {endAdornment && <AnchorAdornment>{endAdornment}</AnchorAdornment>}
    </a>
  );
});

Anchor.displayName = 'Anchor';

export default Anchor;
