import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const Toevoegen2Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 45%;
  height: auto;

  @media (max-width: 1600px) {
    width: 60%;
  }

  @media (max-width: 1300px) {
    width: 70%;
  }

  @media (max-width: 1100px) {
    width: 80%;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 95%;
  }
`;

export const Toevoegen2MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 70px;

  @media (max-width: ${breakpoints.md}px) {
    padding: 20px 20px;
  }
`;

export const Toevoegen2Title = styled.h1`
  font-size: 40px;
  font-weight: 800;

  @media (max-width: ${breakpoints.md}px) {
    font-size: 24px;
  }
`;

export const Toevoegen2Subtitle = styled.span`
  margin-top: 20px;
  font-size: 1rem;
  font-weight: bold;
`;

export const Toevoegen2SelectWrapper = styled.div`
  width: 75%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-top: 10px;

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;
    width: 100%;
    height: 220px;
  }
`;

export const Toevoegen2SelectItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 50px;
  border: 1px solid ${(props) => props.borderColor};
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  font-size: 18px;
  font-weight: ${(props) => props.fw};
  cursor: pointer;
  transition: all 0.3s ease;

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
  }
`;

export const Toevoegen2AddPhotoWrapper = styled.div`
  width: 50%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #707070;
  border-radius: 10px;
  margin-top: 40px;

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
  }
`;

export const Toevoegen2AddPhotoInput = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

export const Toevoegen2UploadedPhotoWrapper = styled.div`
  width: 50%;
  height: 260px;
  border-radius: 10px;
  margin-top: 40px;
  margin-right: 20px;

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
    margin-right: 0;
    margin-top: 0;
  }
`;

export const Toevoegen2UploadedImg = styled.img`
  width: 100%;
  height: 220px;
  border-radius: 10px 10px 0px 0px;
  margin-bottom: 0;
  object-fit: cover;

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
  }
`;

export const Toevoegen2UploadedImgFix = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 0px 0px 10px 10px;
  background-color: ${(props) => props.backgroundColor};
  margin-top: -10px;
`;

export const Toevoegen2AddPhotoLabel = styled.label`
  color: #0072ce;
  text-decoration: underline;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
`;

export const Toevoegen2InputWrapper = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  color: ${(props) => props.color};

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
  }
`;

export const Toevoegen2ISelectItem = styled.select`
  color: ${(props) => props.color};
  display: flex !important;
  border: 1px solid #999 !important;
  border-radius: 10px;
  margin-top: 10px;
  height: 50px;
  padding: 0px 15px !important;

  /* StackOverflow answer from Romualds Cirsis except SVG Url */
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='grey' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
  /**/
  cursor: pointer;

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
  }
`;

export const Toevoegen2UsedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;
  }
`;

export const Toevoegen2UsedPhotoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 30px;

  @media (max-width: ${breakpoints.md}px) {
    margin-right: 0;
    justify-content: start;
  }
`;

export const Toevoegen2Dotcode = styled.input`
  width: 100% !important;
  height: 50px !important;
  border: 1px solid #949494 !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  padding: 0px 10px !important;
  margin-top: 10px !important;
  background-color: white !important;
  box-sizing: inherit !important;

  :focus {
    border: 1px solid ${(props) => props.focus} !important;
  }
`;

export const Toevoegen2SpecsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
`;

export const Toevoegen2AddUsedPhotoWrapper = styled.div`
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #555;
  border-radius: 10px;
  margin-top: 100px;

  @media (max-width: ${breakpoints.md}px) {
    height: 80px;
    margin-top: 0px;
  }
`;

export const Toevoegen2UsedDropdownWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: start;
  margin-top: ${(props) => props.marginTop};

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
  }
`;

export const Toevoegen2AddUsedSubtitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  margin-top: ${(props) => props.marginTop};

  @media (max-width: ${breakpoints.md}px) {
    margin-top: 50px;
  }
`;

export const Toevoegen2UsedP = styled.p`
  font-size: 13px;
  color: ${(props) => props.color};
`;

export const Toevoegen2SelectUsedWrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-top: 10px;
`;
