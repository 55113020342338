import apiClient from 'app/axios/api-client';

export interface addFavorietPropsType {
  id: number | string;
}

export interface deleteFavorietPropsType {
  id: number | string;
}

export const getFavorieten = async () => {
  const result = await apiClient.get('/favorieten/');

  return result.data;
};

export const addFavoriet = async (data: addFavorietPropsType) => {
  const response = await apiClient.post(`favorieten/add?tyre_set_id=${data.id}`);

  return response.data;
};

export const deleteFavoriet = async (data: deleteFavorietPropsType) => {
  const response = await apiClient.post(`favorieten/remove?tyre_set_id=${data.id}`);

  return response.data;
};
