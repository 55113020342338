import { AllHTMLAttributes, forwardRef, InputHTMLAttributes, FocusEvent, useCallback, useEffect } from 'react';
import { useFormControl } from '../FormControl';
import useClassNames from '../../../helpers/useClassNames';
import './FormInput.scss';

const hasValue = (value: AllHTMLAttributes<HTMLInputElement>['value']) =>
  value != null && !(Array.isArray(value) && value.length === 0) && value !== '';

export interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
}

const FormInput = forwardRef<HTMLInputElement, FormInputProps>((props: FormInputProps, ref) => {
  const {
    className,
    id: idProp,
    value,
    onFocus: onFocusProp,
    onBlur: onBlurProp,
    disabled: disabledProp,
    error: errorProp,
    ...rest
  } = props;

  const {
    controlId,
    helpTextId,
    errorTextId,
    error: errorControl,
    disabled: disabledControl,
    onFocus,
    onBlur,
    setFilled,
    shrink,
  } = useFormControl();
  const disabled = disabledProp || disabledControl;

  const error = errorProp || errorControl;
  const { withClassPrefix, merge } = useClassNames('form-input');
  const classes = merge(className, withClassPrefix({ error, disabled, shrink }));

  const id = controlId || idProp;

  useEffect(() => {
    setFilled?.(hasValue(value));
  }, [setFilled, value]);

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      onFocusProp?.(event);
      onFocus?.();
    },
    [onFocus, onFocusProp]
  );
  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      onBlurProp?.(event);
      onBlur?.();
    },
    [onBlur, onBlurProp]
  );

  return (
    <input
      {...rest}
      id={id}
      ref={ref}
      className={classes}
      aria-invalid={error}
      aria-describedby={helpTextId}
      aria-errormessage={error ? errorTextId : undefined}
      value={value}
      onBlur={handleBlur}
      onFocus={handleFocus}
      disabled={disabled}
    />
  );
});

FormInput.displayName = 'FormInput';

export default FormInput;
