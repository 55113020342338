import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const OrderStatusBarWrapper = styled.div`
  margin-top: 12px;
`;

export const OrderStatusNamesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
  font-size: 14px;
  color: #666666;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    font-size: 10px;
  }
`;

export const OrderStatusSkeletonTextWrapper = styled.div``;

export const OrderStatusSkeletonTextExceptionWrapper = styled.div`
  margin-left: 14px;
`;

export const OrderBulletsSkeletonWrapper = styled.div`
  position: relative;
  margin-top: 3px;
  display: flex;
  justify-content: space-between;
  margin: 0px 20px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    margin: 0px 12px;
  }
`;

export const OrderBulletsSkeletonContentWrapper = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 9999px;
  background-color: ${(props) => props.color};
  cursor: pointer;
  z-index: 1;
`;

export const OrderProgressSkeletonWrapper = styled.div`
  border: 3px solid #e6e6e6;
  position: absolute;
  top: 50%;
  bottom: 50%;
  margin: auto;
  margin-left: 5px;
  width: calc(100% - 10px);
`;

export const OrderProgressWrapper = styled.div`
  border: 3px solid #136ce2;
  border-radius: 0px 30px 30px 0px;
  position: absolute;
  top: 50%;
  bottom: 50%;
  margin: auto;
  margin-left: 5px;
  width: calc(${(props) => props.width}% - 10px);
`;
