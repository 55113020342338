import { MouseEvent, UIEvent, RefObject, useCallback, useMemo, useRef, useState, forwardRef } from 'react';
import { usePopper } from 'react-popper';
import type { Modifier } from 'react-popper';
import { CSSTransition } from 'react-transition-group';
import Dropdown from '../Dropdown';
import DropdownItem from '../DropdownItem';
import DropdownItemCheckbox from '../DropdownItemCheckbox';
import './FormSelectDropdown.scss';
import useClassNames from 'app/helpers/useClassNames';

export interface FormSelectOptionType {
  // must be a unique string
  label: string;

  // allow other keys in the option object
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export type FormSelectOptionEventHandler<T> = (event: UIEvent, option: T) => void;
export type FormSelectDropdownOptionEventHandler = FormSelectOptionEventHandler<FormSelectOptionType>;

type DropdownItemComponentType = typeof DropdownItem | typeof DropdownItemCheckbox;

interface SelectItemProps {
  onClick: FormSelectOptionEventHandler<FormSelectOptionType>;
  onFocus?: FormSelectOptionEventHandler<FormSelectOptionType>;
  option: FormSelectOptionType;
  selected?: boolean;
  focussed?: boolean;
  component?: DropdownItemComponentType;
}

const SelectItem = forwardRef<HTMLLIElement, SelectItemProps>((props: SelectItemProps, ref) => {
  const { onClick, onFocus, option, selected, focussed, component: Component = DropdownItem } = props;
  const handleClick = useCallback(
    (event: MouseEvent<HTMLLIElement>) => {
      onClick(event, option);
    },
    [onClick, option]
  );
  const handleHover = useCallback(
    (event: MouseEvent<HTMLLIElement>) => {
      if (!focussed && event.type !== 'mouseover') {
        onFocus?.(event, option);
      }
    },
    [focussed, onFocus, option]
  );

  return (
    <Component
      ref={ref}
      onClick={handleClick}
      onMouseOver={handleHover}
      onMouseMove={handleHover}
      focussed={focussed}
      selected={selected}
    >
      {option.label}
    </Component>
  );
});
SelectItem.displayName = 'SelectItem';

const sameWidthModifier: Modifier<string> = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    const referenceRect = state.elements.reference.getBoundingClientRect();
    state.elements.popper.style.width = `${referenceRect.width}px`;
  },
};

const offsetModifier = {
  name: 'offset',
  options: {
    offset: [0, 4], // top spacing-1
  },
};

export interface FormSelectDropdownProps {
  focussedOption?: FormSelectOptionType;
  itemComponent?: DropdownItemComponentType;
  onClick: FormSelectDropdownOptionEventHandler;
  onFocusOption?: FormSelectDropdownOptionEventHandler;
  options: FormSelectOptionType[];
  referenceRef?: RefObject<HTMLDivElement>;
  selectedOptions?: FormSelectOptionType[];
  show: boolean;
}

const FormSelectDropdown = (props: FormSelectDropdownProps) => {
  const { referenceRef, options, onClick, onFocusOption, selectedOptions, itemComponent, show, focussedOption } = props;
  const [dropdownElement, setDropdownElement] = useState<HTMLDivElement | null>(null);
  const transitionRef = useRef(null);
  const focussedItemRef = useRef<HTMLLIElement>();

  const { styles, attributes } = usePopper(referenceRef?.current, dropdownElement, {
    placement: 'bottom-start',
    modifiers: [sameWidthModifier, offsetModifier],
  });

  const { prefix } = useClassNames('form-select-dropdown');
  const { classRoot, classNoResults } = useMemo(
    () => ({
      classRoot: prefix('root'),
      classNoResults: prefix('no-results'),
    }),
    [prefix]
  );

  const handleFocussedItemRef = useCallback((element: HTMLLIElement) => {
    if (element !== focussedItemRef.current) {
      focussedItemRef.current = element;
      element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, []);

  const selectedLabels = useMemo(() => selectedOptions?.map((option) => option.label) || [], [selectedOptions]);

  return (
    <CSSTransition nodeRef={transitionRef} in={show} timeout={300} unmountOnExit classNames={classRoot}>
      <div ref={transitionRef}>
        <Dropdown ref={setDropdownElement} style={styles.popper} {...attributes.popper}>
          {options?.map((option: FormSelectOptionType) => {
            const isFocussed = focussedOption?.label === option.label;
            return (
              <SelectItem
                key={option.label}
                ref={isFocussed ? handleFocussedItemRef : undefined}
                onClick={onClick}
                option={option}
                selected={selectedLabels.includes(option.label)}
                focussed={isFocussed}
                component={itemComponent}
                onFocus={onFocusOption}
              />
            );
          })}
          {options.length === 0 && <div className={classNoResults}>Geen resultaten</div>}
        </Dropdown>
      </div>
    </CSSTransition>
  );
};

export default FormSelectDropdown;
