import { forwardRef } from 'react';
import RadioBase, { RadioBaseProps } from '../RadioBase';
import useClassNames from 'app/helpers/useClassNames';
import './TogglePanel.scss';

export type TogglePanelProps = Required<Pick<RadioBaseProps, 'label'>> & Omit<RadioBaseProps, 'children'>;

const TogglePanel = forwardRef<HTMLDivElement, TogglePanelProps>((props: TogglePanelProps, ref) => {
  const { className, label, ...rest } = props;
  const { withClassPrefix, merge } = useClassNames('toggle-panel');
  const classes = merge(className, withClassPrefix());

  return (
    <RadioBase className={classes} label={label} {...rest} ref={ref}>
      {label}
    </RadioBase>
  );
});

TogglePanel.displayName = 'TogglePanel';

export default TogglePanel;
