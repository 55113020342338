import { KeyboardEvent, RefObject, useCallback } from 'react';
import { FormSelectOptionType } from '../components/bhComponents/FormSelectDropdown';
import getNextFocusIndex, { FocusDirection } from './getNextFocusIndex';

const useKeyDownSelect = (
  showDropdown: boolean,
  setShowDropdown: (value: boolean) => void,
  options: FormSelectOptionType[],
  setFocusedOption: (options: () => FormSelectOptionType) => void,
  handleItemClickDelete: (event: KeyboardEvent) => void,
  handleItemClick: (event: KeyboardEvent, option: FormSelectOptionType) => void,
  focusedOption?: FormSelectOptionType,
  searchInputRef?: RefObject<HTMLInputElement>,
  hasSearchValue?: boolean
) => {
  const focusOption = useCallback(
    (direction: FocusDirection = 'first') => {
      if (!options.length) {
        return;
      }
      setFocusedOption(() => {
        const focusedIndex = focusedOption ? options.indexOf(focusedOption) : -1;
        const nextFocus = getNextFocusIndex(direction, focusedIndex, options);

        return options[nextFocus];
      });
    },
    [focusedOption, setFocusedOption, options]
  );
  return useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      switch (event.key) {
        case 'Delete':
        case 'Backspace':
          if (!hasSearchValue) {
            handleItemClickDelete(event);
          }
          break;
        case 'Tab': {
          if (showDropdown && focusedOption) {
            handleItemClick(event, focusedOption);
            setShowDropdown(false);
            event.preventDefault();
          } else {
            setShowDropdown(false);
          }
          break;
        }
        case 'Escape': {
          if (showDropdown) {
            setShowDropdown(false);
          }
          break;
        }
        case 'Enter': {
          if (showDropdown && focusedOption) {
            handleItemClick(event, focusedOption);
          }
          break;
        }
        case ' ': // space
          if (hasSearchValue) {
            break;
          }
          if (!showDropdown) {
            event.preventDefault();
            setShowDropdown(true);
            focusOption('first');
            break;
          }
          if (focusedOption) {
            handleItemClick(event, focusedOption);
          }
          break;
        case 'ArrowUp': {
          event.preventDefault();
          if (!showDropdown) {
            setShowDropdown(true);
            focusOption('last');
          } else {
            focusOption('up');
          }
          break;
        }
        case 'ArrowDown': {
          event.preventDefault();
          if (showDropdown) {
            focusOption('down');
          } else {
            setShowDropdown(true);
            focusOption('first');
          }
          break;
        }
        case 'PageUp':
          if (showDropdown) {
            focusOption('pageup');
          }
          break;
        case 'PageDown':
          if (showDropdown) {
            focusOption('pagedown');
          }
          break;
        case 'Home':
          if (showDropdown) {
            focusOption('first');
          }
          break;
        case 'End':
          if (showDropdown) {
            focusOption('last');
          }
          break;
        default:
          searchInputRef?.current?.focus();
      }
    },
    [
      hasSearchValue,
      handleItemClickDelete,
      setShowDropdown,
      focusedOption,
      handleItemClick,
      focusOption,
      showDropdown,
      searchInputRef,
    ]
  );
};

export default useKeyDownSelect;
