import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22%;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-left: 30px;
  padding: 50px;

  @media screen and (min-width: ${breakpoints.lg}px) {
    min-width: 360px;
  }
  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    width: 100%;
    margin-left: 0px;
    max-width: 625px;
    padding: 24px 20px 24px 20px;
    min-height: auto;
  }
`;

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ContactTitle = styled.span`
  font-size: 20px;
  font-weight: bold;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    font-size: 18px;
  }
`;

export const ContactContent = styled.p`
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 400;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    font-size: 14px;
  }
`;

export const ContactButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const ContactButtonElementContainer = styled.div`
  position: relative;
`;

export const ContactButtonItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 10px;
  color: ${(props) => props.color};
  margin-top: 20px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.backgroundColorHover};
    color: ${(props) => props.colorHover};
  }

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    height: 41px;
  }
`;

export const ContactButtonContent = styled.span`
  font-size: 18px;
  font-weight: bold;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    font-size: 16px;
  }
`;
