import { getAuthAccessToken, getAuthExpireTimestamp } from './auth-session';

const hasValidToken = (): boolean => {
  const token = getAuthAccessToken();
  const expireTimestamp = getAuthExpireTimestamp();

  return Boolean(token && expireTimestamp && Date.now() < expireTimestamp);
};

export default hasValidToken;
