import { useQuery } from '@tanstack/react-query';
import { getFavorieten } from 'app/api/favoriet';
import { QueryKeys } from 'app/helpers/QueryKeys';

const useFavorieten = () => {
  const query = useQuery([QueryKeys.Favorieten], getFavorieten, {
    enabled: true,
    refetchOnWindowFocus: 'always',
    retry: false,
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: 'always',
  });

  return {
    ...query,
    orders: query.data,
  };
};

export default useFavorieten;
