import React, { useEffect, useState } from 'react';
import FormInputLicensePlate from '../bhComponents/FormInputLicensePlate';
import type { LicensePlateData } from '../bhComponents/FormInputLicensePlate/FormInputLicensePlate';
import FormControl from '../bhComponents/FormControl';
import useKenteken from 'app/hooks/useKenteken';
import { ToastListType, AutoGegevensType } from 'app/helpers/types';

interface Props {
  className?: string;
  disabled?: boolean;
  kentekenInputValue: string;
  setKentekenInputValue: React.Dispatch<React.SetStateAction<string>>;
  setAutogegevens: React.Dispatch<React.SetStateAction<AutoGegevensType>>;
  toastId: number;
  data: LicensePlateData;
  setToastId: React.Dispatch<React.SetStateAction<number>>;
  toastList: Array<ToastListType>;
  setToastList: React.Dispatch<React.SetStateAction<Array<ToastListType>>>;
  onDisable?: (value: boolean) => void;
}

const Kenteken = ({
  className,
  kentekenInputValue,
  setKentekenInputValue,
  setAutogegevens,
  toastId,
  setToastId,
  toastList,
  data,
  setToastList,
  onDisable,
}: Props) => {
  const [isEnabled, setEnabled] = useState(false);

  const { isError, isSuccess, error, isLoading } = useKenteken(
    kentekenInputValue,
    isEnabled,
    setEnabled,
    setAutogegevens,
    toastId,
    setToastId,
    toastList,
    setToastList
  );

  useEffect(() => {
    if (kentekenInputValue.length < 6) {
      setEnabled(false);
    }
  }, [kentekenInputValue]);

  useEffect(() => {
    if (kentekenInputValue !== '' && kentekenInputValue.length > 5) {
      const searchTimer = setTimeout(() => {
        setEnabled(true);
        setEnabled(false);
      }, 500);

      return () => clearTimeout(searchTimer);
    }
    if (setAutogegevens && kentekenInputValue.length < 6) {
      setAutogegevens({
        merk: '',
        type: '',
        bouwjaar: '',
      });
    }
  }, [kentekenInputValue]);

  useEffect(() => {
    onDisable?.(isLoading || isError || !isSuccess);
  }, [isLoading, isSuccess, isError]);

  return (
    <FormControl id="licanse-plate" success={isSuccess} error={isError}>
      <FormInputLicensePlate
        isLoading={isLoading && kentekenInputValue.length > 5}
        errorText={error?.response.data.detail?.toString() || ''}
        data={data}
        className={className}
        value={kentekenInputValue}
        setValue={setKentekenInputValue}
      />
    </FormControl>
  );
};

export default Kenteken;
