import { InputHTMLAttributes, MouseEvent, ReactNode, RefObject, useCallback, useRef } from 'react';
import { useFormControl } from '../FormControl';
import FormInlineEdit, { FormInlineEditProps } from '../FormInlineEdit/FormInlineEdit';
import FormInput from '../FormInput';
import FormInputBorder from '../FormInputBorder';
import FormInputLabelWrapper from '../FormInputLabelWrapper';
import FormInputWrapper from '../FormInputWrapper';
import useClassNames from 'app/helpers/useClassNames';
import './FormInputText.scss';

export type FormInputBorderClickEventWithRefHandler = (
  event: MouseEvent<HTMLDivElement>,
  inputRef?: RefObject<HTMLInputElement>
) => void;

export interface BaseFormInputTextProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'disabled'> {
  label?: ReactNode;
}

export interface FormInputTextProps extends BaseFormInputTextProps, FormInlineEditProps {
  /**
   * Add Element inside FormInputWrapper at the start position
   */
  startTextAdornment?: ReactNode;
  /**
   * Add Element inside FormInputWrapper at the end position
   */
  endTextAdornment?: ReactNode;
  /**
   * Add Element outside FormInputWrapper at the start position
   */
  startIconAdornment?: ReactNode;
  /**
   * Add Element outside FormInputWrapper at the end position
   */
  endIconAdornment?: ReactNode;
  onBorderClick?: FormInputBorderClickEventWithRefHandler;
  disabled?: boolean;
}

const FormInputText = (props: FormInputTextProps) => {
  const {
    className,
    startIconAdornment,
    endIconAdornment,
    startTextAdornment,
    endTextAdornment,
    errorText,
    label,
    onClickCancel,
    onClickConfirm,
    onBorderClick,
    disabled: disabledProp,
    ...rest
  } = props;

  const { disabled: disabledControl } = useFormControl();
  const { withClassPrefix, merge } = useClassNames('form-input-text');
  const classes = merge(className, withClassPrefix({ label }));
  const inputRef = useRef<HTMLInputElement>(null);

  const handleBorderClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (disabledControl || disabledProp) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      inputRef.current?.focus();
      onBorderClick?.(event, inputRef);
    },
    [disabledControl, disabledProp, onBorderClick]
  );

  return (
    <FormInlineEdit
      errorText={errorText}
      onClickCancel={onClickCancel}
      onClickConfirm={onClickConfirm}
      disabled={disabledProp}
    >
      <FormInputBorder className={classes} onClick={handleBorderClick} disabled={disabledProp}>
        {startIconAdornment}
        <FormInputLabelWrapper>
          {label}
          <FormInputWrapper>
            {startTextAdornment}
            <FormInput {...rest} disabled={disabledProp} ref={inputRef} />
            {endTextAdornment}
          </FormInputWrapper>
        </FormInputLabelWrapper>
        {endIconAdornment}
      </FormInputBorder>
    </FormInlineEdit>
  );
};

export default FormInputText;
