import classNames, { ArgumentArray } from 'classnames';
import { useCallback } from 'react';

const addPrefix = (pre: string, className: string | string[]): string => {
  if (!pre || !className) {
    return '';
  }

  if (Array.isArray(className)) {
    return classNames(className.filter((name) => !!name).map((name) => `${pre}-${name}`));
  }

  if (pre[pre.length - 1] === '-') {
    return `${pre}${className}`;
  }

  return `${pre}-${className}`;
};

const classPrefix = 'bs-';

const useClassNames = (str: string) => {
  const componentName = addPrefix(classPrefix, str);

  /**
   * @example
   *
   * if str = 'button':
   * prefix('red', { active: true }) => 'bs-button-red bs-button-active'
   */
  const prefix = useCallback(
    (...classes: ArgumentArray) => {
      const mergeClasses = classes.length
        ? classNames(...classes)
            .split(' ')
            .map((item) => addPrefix(componentName, item))
        : [];

      return mergeClasses.filter((cls) => cls).join(' ');
    },
    [componentName]
  );

  /**
   * @example
   *
   * if str = 'button':
   * withClassPrefix('red', { active: true }) => 'bs-button bs-button-red bs-button-active'
   */
  const withClassPrefix = useCallback(
    (...classes: ArgumentArray) => {
      const mergeClasses = prefix(classes);
      return mergeClasses ? `${componentName} ${mergeClasses}` : componentName;
    },
    [componentName, prefix]
  );

  /**
   * @example
   * rootPrefix('btn') => 'bs-btn'
   * rootPrefix('btn', { active: true }) => 'bs-btn bs-active'
   */
  const rootPrefix = (...classes: ArgumentArray) => {
    const mergeClasses = classes.length
      ? classNames(...classes)
          .split(' ')
          .map((item) => addPrefix(classPrefix, item))
      : [];

    return mergeClasses.filter((cls) => cls).join(' ');
  };

  return {
    withClassPrefix,
    merge: classNames,
    prefix,
    rootPrefix,
  };
};

export default useClassNames;
