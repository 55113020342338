import { useMutation } from '@tanstack/react-query';

import { placeOrder } from 'app/api/placeOrder';

type PlaceOrderPropsType = {
  tyre_set_id: string;
};

const usePlaceOrder = () => {
  const placeOrderMutation = useMutation(placeOrder);

  const onClickPlaceOrder = (data: PlaceOrderPropsType) => {
    placeOrderMutation.mutate(data);
  };

  const onClearPlaceOrderErrorMessage = () => placeOrderMutation.reset();

  const {
    isLoading: isPlaceOrderLoading,
    isError: isPlaceOrderError,
    error: placeOrderError,
    data: placeOrderData,
    isSuccess: isPlaceOrderSuccess,
  } = placeOrderMutation;

  return {
    isPlaceOrderLoading,
    isPlaceOrderError,
    placeOrderError,
    placeOrderData,
    isPlaceOrderSuccess,
    placeOrderMutation,
    onClickPlaceOrder,
    onClearPlaceOrderErrorMessage,
  };
};

export default usePlaceOrder;
