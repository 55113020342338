import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const DetailsGeneralContainer = styled.div`
  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 95px);
  }
`;
export const DetailsWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
  padding: 35px 40px;

  background-color: #fff;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    max-width: 625px;
    margin: 16px 11px;
    padding: 25px 20px;
  }
`;

export const DetailsLoaderPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
`;

export const DetailsTopDateWrapper = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: 700;
`;

export const DetailsSecondRowWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  width: 80%;
  gap: 10px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const DetailsSecondRowElementWrapper = styled.div`
  max-width: 200px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    display: flex;
    max-width: none;
    gap: 20px;
  }
`;

export const DetailsSecondRowElementTextWrapper = styled.div`
  min-width: 105px;
  font-size: 14px;
  color: #949494;
`;

export const DetailsSecondRowElementContentWrapper = styled.div`
  font-size: 14px;
`;

export const DetailsThirdRowWrapper = styled.div`
  margin-top: 35px;
`;

export const DetailsThirdRowFirstRowWrapper = styled.div`
  font-size: 14px;
  font-weight: 700;
`;

export const DetailsFourthRowWrapper = styled.div`
  display: flex;
  padding: 8px 10px;
  margin-top: 35px;
  align-items: center;
  gap: 2px;
  background-color: #fff9e6;
  border-radius: 8px;
`;

export const DetailsFourthRowWarningIconWrapper = styled.div`
  color: #ffc400;
  font-size: 20px;
  display: flex;
  margin-right: 6px;
`;

export const DetailsFourthRowWarningBoxWrapper = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

export const DetailsFourthRowWarningMessageWrapper = styled.span`
  font-weight: 600;
`;
export const DetailsFourthRowWarningDateWrapper = styled.span`
  font-weight: 800;
`;

export const DetailsFifthRowWrapper = styled.div`
  margin-top: 35px;
`;

export const DetailsFifthRowFirstWrapper = styled.div`
  font-size: 14px;
  font-weight: 700;
`;

export const DetailsFIfthRowSecondWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 18px;
  align-items: center;
  border-bottom: 0.5px solid #e6e6e6;
  padding-bottom: 15px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    border-bottom: none;
    padding-bottom: 10px;
    gap: 5px;
  }
`;

export const DetailsImageLoaderPlaceholder = styled.div`
  width: 85px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DetailsFifthRowSecondImageWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DetailsFifthRowSecondProductDetailsWrapper = styled.div``;

export const DetailsFifthRowSecondProductNameWrapper = styled.div`
  font-size: 16px;
  font-weight: 700;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    font-size: 14px;
    // font-weight: normal;
  }
`;

export const DetailsFifthRowSecondProductSecondDetailsWrapper = styled.div`
  margin-top: 6px;
  display: flex;
  gap: 16px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    flex-direction: column;
    gap: 0px;
  }
`;

export const DetailsFifthRowSecondProductTyreSizeWrapper = styled.div`
  font-size: 12px;
  color: #666666;
`;

export const DetailsFifthRowSecondProductSeasonOnlyWrapper = styled.div`
  font-size: 12px;
  color: #666666;
`;

export const DetailsFifthRowSecondProductSizeOnlyWrapper = styled.div`
  font-size: 12px;
  color: #666666;
`;

export const DetailsFifthRowSecondProductPriceWrapper = styled.div`
  font-weight: 700;
  font-size: 16px;
  display: flex;
  flex: 1;
  justify-content: flex-end;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    font-size: 14px;
  }
`;

export const DetailsSixthRowWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
`;

export const DetailsSixthRowContentWrapper = styled.div`
  width: 50%;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    width: 100%;
  }
`;

export const DetailsSixthRowFirstRowWrapper = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
`;

export const DetailsSixthRowFirstMontageTextWrapper = styled.div`
  font-size: 16px;
  color: #666666;
`;

export const DetailsSixthRowFirstMontagePriceWrapper = styled.div`
  font-size: 14px;
  font-weight: 700;
`;

export const DetailsSixthRowSecondRowWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DetailsSixthRowSecondTotaalTextWrapper = styled.div`
  font-size: 16px;
  color: #666666;
  font-weight: 700;
`;

export const DetailsSixthRowSecondTotaalPriceWrapper = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #136ce2;
`;
