import React from 'react';
import { MijnMainContainer, MijnAccountWrapper, MijnContentWrapper } from './MyAccountLayout.styles';
import Account from 'app/components/MijnAccountComponents/account';

type MyAccountLayoutProps = { children?: React.ReactNode; isLargeScreen?: boolean | 0 };

const MyAccountLayout: React.FC<MyAccountLayoutProps> = ({ children, isLargeScreen }) => (
  <MijnMainContainer>
    <MijnAccountWrapper>
      <Account isLargeScreen={isLargeScreen} />
    </MijnAccountWrapper>
    <MijnContentWrapper>{children}</MijnContentWrapper>
  </MijnMainContainer>
);

export default MyAccountLayout;
