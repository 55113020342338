import { forwardRef, ReactNode } from 'react';
import DropdownItemBase, { DropdownItemBaseProps } from '../DropdownItemBase';
import DropdownItemSlot from '../DropdownItemSlot';
import IconVinkje from '../../../../assets/svg/IconVinkje';
import useClassNames from 'app/helpers/useClassNames';
import './DropdownItem.scss';

export interface DropdownItemProps extends DropdownItemBaseProps {
  /**
   * Adds icon on the right
   */
  icon?: ReactNode;
  /**
   * Sets DropDownItem to selected state
   */
  selected?: boolean;
}

const DropdownItem = forwardRef<HTMLLIElement, DropdownItemProps>((props: DropdownItemProps, ref) => {
  const { className, children, selected, icon: iconProp, ...rest } = props;
  const { withClassPrefix, merge } = useClassNames('dropdown-item');

  const classes = merge(className, withClassPrefix({ selected }));
  const icon = selected ? <IconVinkje /> : iconProp;

  return (
    <DropdownItemBase {...rest} ref={ref} className={classes}>
      <DropdownItemSlot>{children}</DropdownItemSlot>
      {icon && <DropdownItemSlot>{icon}</DropdownItemSlot>}
    </DropdownItemBase>
  );
});

DropdownItem.displayName = 'DropdownItem';

export default DropdownItem;
