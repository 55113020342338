import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import { Conditions } from '../../form/checkbox-list';
import { VorraadPageContainer, MobileVoorraadTransparentBackground } from './Voorraad.styles';
import usePriceRange from 'app/hooks/usePriceRange';
import useVoorraad from 'app/hooks/useVoorraad';
import { tyreSetActions } from 'redux/tyreSet-slice';
import { QueryKeys } from 'app/helpers/QueryKeys';
import Zoeken from 'app/components/HomeComponents/Zoeken/Zoeken';
import Results from 'app/components/HomeComponents/Results/Results';
import useWindowSize from 'app/hooks/useWindowSize';
import ToastContainer from 'app/components/Toast/ToastContainer';
import { breakpoints } from 'app/config/breakpoints';
import { handleSubmit } from 'app/helpers/utils';

const Voorraad = () => {
  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const [isSearchEnabled, setSearchEnabled] = useState(false);

  const [voorraadCarModelAndYear, setVoorraadCarModelAndYear] = useState({
    make: '',
    model: '',
    year: '',
  });

  const [voorraadKentekenInputValue, setVoorraadKentekenInputValue] = useState('');

  const [voorraadAutogegevens, setVoorraadAutogegevend] = useState({
    merk: '',
    type: '',
    bouwjaar: '',
  });

  const [voorraadMerkValue, setVoorraadMerkValue] = useState('');

  const [voorraadModelValue, setVoorraadModelValue] = useState('');

  const [voorraadBouwjaarValue, setVoorraadBouwjaarValue] = useState('');

  const [voorraadSorterenValue, setVoorraadSorterenValue] = useState({
    label: 'Toegevoegd: nieuw naar oud',
    value: 'Toegevoegd: nieuw naar oud',
  });

  const [voorraadSeasonValue, setVoorraadSeasonValue] = useState([]);

  const [voorraadSearchMenuState, setVoorraadSearchMenuState] = useState(false);

  const [voorraadfilterSearchValues, setVoorraadFilterSearchValues] = useState([]);

  const [voorraadPriceRangeValues, setVoorraadPriceRangeValues] = useState([]);

  const min = useRef(voorraadPriceRangeValues[0]);
  const max = useRef(voorraadPriceRangeValues[1]);

  const [voorraadCurrentPage, setVoorraadCurrentPage] = useState(1);

  const [voorraadSearchedWithCarDetails, setVoorraadSearchedWithCarDetails] = useState({
    merk: '',
    type: '',
    bouwjaar: '',
  });

  const [voorraadSearchedWithFilters, setVoorraadSearchedWithFilters] = useState({});

  const [toastId, setToastId] = useState(0);
  const [toastList, setToastList] = useState([]);

  const { width } = useWindowSize();

  const isMobileScreen = useMemo(() => width && width < breakpoints.lg, [width]);

  const isNewTyreSetAdded = useSelector((state) => state.tyreSet.isTyreSetAdded);

  const merkValueOverride = useMemo(
    () =>
      (voorraadAutogegevens.merk && voorraadAutogegevens.merk !== 'N/A' && voorraadAutogegevens.merk) ||
      voorraadMerkValue ||
      '',
    [voorraadAutogegevens.merk, voorraadMerkValue]
  );

  const modelValueOverride = useMemo(
    () =>
      (voorraadAutogegevens.type && voorraadAutogegevens.type !== 'N/A' && voorraadAutogegevens.type) ||
      voorraadModelValue ||
      '',
    [voorraadAutogegevens.type, voorraadModelValue]
  );

  const bouwjaarValueOverride = useMemo(
    () =>
      (voorraadAutogegevens.bouwjaar && voorraadAutogegevens.bouwjaar !== 'N/A' && voorraadAutogegevens.bouwjaar) ||
      voorraadBouwjaarValue ||
      '',
    [voorraadAutogegevens.bouwjaar, voorraadBouwjaarValue]
  );

  const sortedVoorraadFilterSearchValues = useMemo(
    () => [...voorraadfilterSearchValues, merkValueOverride, modelValueOverride, bouwjaarValueOverride].sort(),
    [voorraadfilterSearchValues, merkValueOverride, modelValueOverride, bouwjaarValueOverride]
  );

  const voorraadSearchPerformedWithCarData = useCallback(() => {
    setVoorraadSearchedWithCarDetails({
      merk: merkValueOverride,
      type: modelValueOverride,
      bouwjaar: bouwjaarValueOverride,
    });
  }, [merkValueOverride, modelValueOverride, bouwjaarValueOverride]);

  const voorraadSearchPerformedWithFilters = useCallback(() => {
    const allFiltersApplied = [
      ...voorraadfilterSearchValues,
      merkValueOverride,
      modelValueOverride,
      bouwjaarValueOverride,
    ];

    setVoorraadSearchedWithFilters(allFiltersApplied.sort());
  }, [voorraadfilterSearchValues, merkValueOverride, modelValueOverride, bouwjaarValueOverride]);

  useEffect(() => {
    if (isMobileScreen && voorraadSearchMenuState) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  });

  useEffect(() => {
    if (isSearchEnabled && voorraadPriceRangeValues.length !== 0) {
      voorraadSearchPerformedWithCarData();
      voorraadSearchPerformedWithFilters();
      onSearchClick(mijnVoorraadSearchDataObj());
    }
  }, [isSearchEnabled]);

  useEffect(() => {
    if (isSearchEnabled && voorraadPriceRangeValues.length !== 0) {
      if (JSON.stringify(voorraadSearchedWithFilters) === JSON.stringify(sortedVoorraadFilterSearchValues)) {
        voorraadSearchPerformedWithCarData();
        voorraadSearchPerformedWithFilters();
        onSearchClick(mijnVoorraadSearchDataObj());
      } else {
        setVoorraadCurrentPage(1);
      }
    }
  }, [voorraadCurrentPage]);

  useEffect(() => {
    if (
      isSearchEnabled &&
      voorraadPriceRangeValues.length !== 0 &&
      voorraadCurrentPage === 1 &&
      JSON.stringify(voorraadSearchedWithFilters) !== JSON.stringify(sortedVoorraadFilterSearchValues)
    ) {
      voorraadSearchPerformedWithCarData();
      voorraadSearchPerformedWithFilters();
      onSearchClick(mijnVoorraadSearchDataObj());
    }
  }, [voorraadCurrentPage]);

  useEffect(() => {
    if (voorraadAutogegevens.merk !== '') {
      setVoorraadCarModelAndYear({
        make: voorraadAutogegevens.merk,
        model: voorraadAutogegevens.type,
        year: voorraadAutogegevens.bouwjaar,
      });
    }
  }, [voorraadAutogegevens]);

  const cachedResult = queryClient.getQueryData([QueryKeys.Kenteken, voorraadKentekenInputValue]);

  const cashedVoorraadPriceRangeValues = queryClient.getQueryData([QueryKeys.PriceRange]);

  useEffect(() => {
    if (voorraadPriceRangeValues.length === 0 && cashedVoorraadPriceRangeValues) {
      min.current = 0;
      max.current = cashedVoorraadPriceRangeValues?.max;
      setVoorraadPriceRangeValues([0, cashedVoorraadPriceRangeValues?.max]);
      setSearchEnabled(true);
    }
  }, []);

  useEffect(() => {
    if (voorraadKentekenInputValue.length === 6 && cachedResult) {
      setVoorraadAutogegevend({
        merk: cachedResult.merk,
        type: cachedResult.type,
        bouwjaar: cachedResult.bouwjaar,
      });
    }
  }, [voorraadKentekenInputValue]);

  useEffect(() => {
    if (
      (voorraadMerkValue !== '' || voorraadModelValue !== '' || voorraadBouwjaarValue !== '') &&
      (voorraadKentekenInputValue !== '' || voorraadAutogegevens.merk !== '')
    ) {
      setVoorraadAutogegevend({
        merk: '',
        type: '',
        bouwjaar: '',
      });
      setVoorraadKentekenInputValue('');
    }
  }, [voorraadMerkValue, voorraadModelValue, voorraadBouwjaarValue]);

  useEffect(() => {
    if (
      (voorraadMerkValue !== '' || voorraadModelValue !== '' || voorraadBouwjaarValue !== '') &&
      voorraadKentekenInputValue !== ''
    ) {
      setVoorraadMerkValue('');
      setVoorraadModelValue('');
      setVoorraadBouwjaarValue('');
    }
  }, [voorraadKentekenInputValue]);

  const { isError: isVoorraadPriceRangeError, error: voorraadPriceRangeError } = usePriceRange(
    setVoorraadPriceRangeValues,
    setSearchEnabled,
    min,
    max
  );

  useEffect(() => {
    if (isVoorraadPriceRangeError) {
      const toast = {
        id: toastId,
        type: 'error',
        message: voorraadPriceRangeError?.message,
        additionalMessage: voorraadPriceRangeError?.response?.statusText,
      };

      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isVoorraadPriceRangeError]);

  const {
    data: VoorraadSearchResultData,
    onSearchClick,
    onClearErrorMessage: onVoorraadPageClearErrorMessage,
    isLoading: isVoorraadLoading,
    isError: isVoorraadError,
    error: voorraadError,
  } = useVoorraad();

  useEffect(() => {
    if (isVoorraadError) {
      const toast = {
        id: toastId,
        type: 'error',
        message: voorraadError?.message,
        additionalMessage: voorraadError?.response?.statusText,
        onClose: onVoorraadPageClearErrorMessage,
      };

      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isVoorraadError]);

  useEffect(() => {
    if (isNewTyreSetAdded && VoorraadSearchResultData?.data) {
      const toast = {
        id: toastId,
        type: 'success',
        message: 'Er is succesvol een nieuwe bandenset toegevoegd',
      };

      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);

      dispatch(tyreSetActions.setTyreSetFalse());
    }
  }, [VoorraadSearchResultData]);

  const totalPages = useMemo(
    () =>
      VoorraadSearchResultData
        ? Math.ceil(VoorraadSearchResultData?.total_results / VoorraadSearchResultData?.results_per_page)
        : 1,
    [VoorraadSearchResultData?.total_results, VoorraadSearchResultData?.results_per_page]
  );

  const mijnVoorraadSearchDataObj = () => {
    const TempConditionFilters = voorraadfilterSearchValues.filter(
      (item) => item === Conditions.USED || item === Conditions.GOOD || item === Conditions.NEW
    );

    const TempBrandFilters = voorraadfilterSearchValues.filter(
      (item) =>
        item !== Conditions.USED &&
        item !== Conditions.GOOD &&
        item !== Conditions.NEW &&
        item !== 'Zomer' &&
        item !== 'All Season' &&
        item !== 'Winter' &&
        !item.includes('€')
    );

    const TempSeasonFilters = voorraadSeasonValue.map((item) => (item === 'Zomer' ? 'Summer' : item));

    const sortField = voorraadSorterenValue.value.includes('Toegevoegd')
      ? 'date_added'
      : voorraadSorterenValue.value.includes('Prijs')
      ? 'price'
      : '';

    const ascending = () => {
      switch (voorraadSorterenValue.value) {
        case 'Toegevoegd: nieuw naar oud':
          return false;
        case 'Toegevoegd: oud naar nieuw':
          return true;
        case 'Prijs: hoog naar laag':
          return false;
        case 'Prijs: laag naar hoog':
          return true;
        default:
          return '';
      }
    };

    const data = {
      id: 1234,
      page: voorraadCurrentPage,
      merk: merkValueOverride,
      model: modelValueOverride,
      bouwjaar: bouwjaarValueOverride,
      sortField,
      ascending: ascending(),
      condition: TempConditionFilters,
      brand: TempBrandFilters,
      season: TempSeasonFilters,
      priceRangeStart: voorraadPriceRangeValues[0],
      priceRangeEnd: voorraadPriceRangeValues[1],
    };

    return data;
  };

  const isCarInfoRequired = useMemo(
    () => voorraadMerkValue !== '' || voorraadModelValue !== '' || voorraadBouwjaarValue !== '',
    [voorraadMerkValue, voorraadModelValue, voorraadBouwjaarValue]
  );

  const isCarInfoInvalid = useMemo(
    () => !(voorraadMerkValue !== '' && voorraadModelValue !== '' && voorraadBouwjaarValue !== ''),
    [voorraadMerkValue, voorraadModelValue, voorraadBouwjaarValue]
  );

  const isPriceRangeInvalid = useMemo(
    () =>
      voorraadPriceRangeValues[0] === null ||
      voorraadPriceRangeValues[0] < min.current ||
      voorraadPriceRangeValues[0] >= voorraadPriceRangeValues[1] ||
      voorraadPriceRangeValues[1] === undefined ||
      voorraadPriceRangeValues[1] <= voorraadPriceRangeValues[0],
    [voorraadPriceRangeValues, min.current]
  );

  const handleSearchButtonClick = useCallback(() => {
    if (isPriceRangeInvalid || (isCarInfoRequired && isCarInfoInvalid)) {
      return;
    }
    if (voorraadCurrentPage === 1) {
      voorraadSearchPerformedWithCarData();
      voorraadSearchPerformedWithFilters();
      onSearchClick(mijnVoorraadSearchDataObj());
    } else if (voorraadCurrentPage && voorraadCurrentPage !== 1) {
      setVoorraadCurrentPage(1);
    }
    if (isMobileScreen) {
      setVoorraadSearchMenuState(false);
    }
  }, [
    isPriceRangeInvalid,
    isCarInfoRequired,
    isCarInfoInvalid,
    voorraadCurrentPage,
    voorraadSearchPerformedWithCarData,
    voorraadSearchPerformedWithFilters,
    onSearchClick,
    mijnVoorraadSearchDataObj,
    isMobileScreen,
  ]);

  const refreshUponProductResultCardAction = useCallback(() => {
    onSearchClick(mijnVoorraadSearchDataObj());
  }, [onSearchClick, mijnVoorraadSearchDataObj]);

  return (
    <form onSubmit={handleSubmit}>
      <VorraadPageContainer>
        <Zoeken
          isSearchMenuOpen={voorraadSearchMenuState}
          isMobileScreen={isMobileScreen}
          kentekenInputValue={voorraadKentekenInputValue}
          setKentekenInputValue={setVoorraadKentekenInputValue}
          autoGegevens={voorraadAutogegevens}
          setAutogegevens={setVoorraadAutogegevend}
          carModelAndYear={voorraadCarModelAndYear}
          filterSearchValues={voorraadfilterSearchValues}
          setFilterSearchValues={setVoorraadFilterSearchValues}
          setSearchMenuState={setVoorraadSearchMenuState}
          merkValue={voorraadMerkValue}
          setMerkValue={setVoorraadMerkValue}
          modelValue={voorraadModelValue}
          setModelValue={setVoorraadModelValue}
          bouwjaarValue={voorraadBouwjaarValue}
          setBouwjaarValue={setVoorraadBouwjaarValue}
          sorterenValue={voorraadSorterenValue}
          setSorterenValue={setVoorraadSorterenValue}
          seasonValue={voorraadSeasonValue}
          setSeasonValue={setVoorraadSeasonValue}
          priceRangeValues={voorraadPriceRangeValues}
          setPriceRangeValues={setVoorraadPriceRangeValues}
          handleSearchButtonClick={handleSearchButtonClick}
          filterMerks={VoorraadSearchResultData?.brands}
          filterConditie={VoorraadSearchResultData?.conditions}
          toastId={toastId}
          setToastId={setToastId}
          toastList={toastList}
          setToastList={setToastList}
          min={min}
          max={max}
          isCarInfoRequired={isCarInfoRequired}
        />
        <Results
          isSearchMenuOpen={voorraadSearchMenuState}
          setSearchMenuState={setVoorraadSearchMenuState}
          data={VoorraadSearchResultData?.data}
          currentPage={voorraadCurrentPage}
          setCurrentPage={setVoorraadCurrentPage}
          pageStart={1}
          totalPages={totalPages}
          searchedWithCarDetails={voorraadSearchedWithCarDetails}
          isLoading={isVoorraadLoading}
          toastId={toastId}
          setToastId={setToastId}
          toastList={toastList}
          setToastList={setToastList}
          refreshUponProductResultCardAction={refreshUponProductResultCardAction}
          isValid={!isPriceRangeInvalid && (isCarInfoRequired ? !isCarInfoInvalid : true)}
        />
        {isMobileScreen && voorraadSearchMenuState && <MobileVoorraadTransparentBackground />}
        <ToastContainer toastList={toastList} autoRemove removeInterval={4750} />
      </VorraadPageContainer>
    </form>
  );
};

export default Voorraad;
