import { setAuthState } from './auth-session';
import webAuth from './webAuth';

declare global {
  interface Crypto {
    randomUUID: () => string;
  }
}

const initiateAuth = () => {
  const state = crypto.randomUUID();
  setAuthState(state);

  webAuth.authorize({ state });
};

export default initiateAuth;
