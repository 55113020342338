import { useNavigate } from 'react-router-dom';

import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import {
  MijnAankopenGeneralContainer,
  MijnAankopenWrapper,
  NoAankopeenWrapper,
  MijnAankopenContentWrapper,
  MijnAankopenLoaderPlaceholder,
  NoAankopeenIconWrapper,
  NoAankopeenText,
  NoAnkopeenWinkelenButton,
  MijnAankopenContentTopFader,
  MijnAankopenContentBottomFader,
} from './MijnAankopen.styles';
import useAankopen from 'app/hooks/useAankopen';
import useWindowSize from 'app/hooks/useWindowSize';
import { breakpoints } from 'app/config/breakpoints';
import Toast from 'app/components/Toast/Toast';
import Loader from 'app/components/Loader/Loader';
import MyAccountLayout from 'app/components/MijnAccountComponents/MijnAccoundDesktop/MyAccountLayout';
import AccountCardRow from 'app/components/MijnAccountComponents/AccountCardRow';

const MijnAankopen = () => {
  const { data: aankopenData, isLoading, isError, error } = useAankopen();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isLargeScreen = width && width >= breakpoints.lg;

  const MijnAnkoopenPurchasesContent = (
    <MijnAankopenGeneralContainer>
      <MijnAankopenWrapper>
        <MijnAankopenContentTopFader />

        <MijnAankopenContentWrapper className="customScrollbar" padding={aankopenData?.length > 4 && '20px'}>
          {isLoading && (
            <MijnAankopenLoaderPlaceholder>
              <Loader />
            </MijnAankopenLoaderPlaceholder>
          )}

          {isError && (
            <Toast
              type="error"
              message={error?.message}
              additionalMessage={error?.response?.data?.detail}
              standalone
              standaloneAutoRemove
            />
          )}

          {aankopenData?.length > 0 &&
            aankopenData.map((item, index) => (
              <AccountCardRow key={item?.order?.id} item={item} index={index} length={aankopenData.length} />
            ))}
          {aankopenData && aankopenData?.length === 0 && (
            <NoAankopeenWrapper>
              <NoAankopeenIconWrapper>
                <ShoppingBagIcon fontSize="inherit" />
              </NoAankopeenIconWrapper>
              <NoAankopeenText>Nog geen aankopen</NoAankopeenText>
              <NoAnkopeenWinkelenButton onClick={() => navigate('/')}>Winkelen</NoAnkopeenWinkelenButton>
            </NoAankopeenWrapper>
          )}
        </MijnAankopenContentWrapper>

        <MijnAankopenContentBottomFader />
      </MijnAankopenWrapper>
    </MijnAankopenGeneralContainer>
  );

  return isLargeScreen ? (
    <MyAccountLayout isLargeScreen={isLargeScreen}>{MijnAnkoopenPurchasesContent}</MyAccountLayout>
  ) : (
    MijnAnkoopenPurchasesContent
  );
};

export default MijnAankopen;
