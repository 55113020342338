import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const valuetext = (value: number) => `${value}°C`;

interface Props {
  priceRangeValues: Array<number>;
  setPriceRangeValues: React.Dispatch<React.SetStateAction<Array<number | null | undefined>>>;
  min: React.MutableRefObject<never>;
  max: React.MutableRefObject<never>;
  filterSearchValues: Array<string>;
  setFilterSearchValues: React.Dispatch<React.SetStateAction<Array<string>>>;
}

const PriceSlider = (props: Props) => {
  const { priceRangeValues, setPriceRangeValues, min, max, filterSearchValues, setFilterSearchValues } = props;
  useEffect(() => {
    handleFilterPriceFeature();
  }, [priceRangeValues]);

  const maxMinDistance = priceRangeValues[1] - priceRangeValues[0];

  const minDistanceCalculator = () => {
    if (maxMinDistance <= 100) {
      return 5;
    } else if (maxMinDistance > 100 && maxMinDistance <= 1000) {
      return 50;
    } else if (maxMinDistance > 1000 && maxMinDistance <= 10000) {
      return 500;
    } else if (maxMinDistance > 1000 && maxMinDistance <= 100000) {
      return 5000;
    } else {
      return 10000;
    }
  };

  const minDistance = minDistanceCalculator();

  const handleChange1 = (_event: Event, newValue: number | number[], activeThumb: number) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setPriceRangeValues([Math.min(newValue[0], priceRangeValues[1] - minDistance), priceRangeValues[1]]);
    } else {
      setPriceRangeValues([priceRangeValues[0], Math.max(newValue[1], priceRangeValues[0] + minDistance)]);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPriceRangeValues([event.target.value === '' ? null : Number(event.target.value), priceRangeValues[1]]);
  };

  const handleInputChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPriceRangeValues([priceRangeValues[0], event.target.value === '' ? undefined : Number(event.target.value)]);
  };

  const handleFilterPriceFeature = () => {
    let indexOfPriceLabel = 0;
    const isPriceLabelAdded = filterSearchValues.some((item, index) => {
      if (item.includes('€')) {
        indexOfPriceLabel = index;
      }
      return item.includes('€');
    });
    const validValues = typeof priceRangeValues[0] === 'number' && typeof priceRangeValues[1] === 'number';

    const tempAllFiltersArray = filterSearchValues;
    const priceValueChip = `€ ${priceRangeValues[0]} - € ${priceRangeValues[1]}`;

    if (validValues && (priceRangeValues[0] !== min.current || priceRangeValues[1] !== max.current)) {
      if (isPriceLabelAdded) {
        tempAllFiltersArray[indexOfPriceLabel] = priceValueChip;
        setFilterSearchValues([...tempAllFiltersArray]);
      } else {
        setFilterSearchValues([...filterSearchValues, priceValueChip]);
      }
    } else if (
      (priceRangeValues[0] === min.current && priceRangeValues[1] === max.current && isPriceLabelAdded) ||
      !validValues
    ) {
      tempAllFiltersArray.splice(indexOfPriceLabel, 1);

      setFilterSearchValues([...tempAllFiltersArray]);
    }
  };

  return (
    <div className="price-slider">
      <div id="slider--mui">
        <Box>
          <Slider
            getAriaLabel={() => 'Minimum distance'}
            value={priceRangeValues}
            onChange={handleChange1}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
            disableSwap
            min={min.current}
            max={max.current}
          />
        </Box>
      </div>
      <div className="input-field">
        <div className="input-icon">
          <input
            required
            value={priceRangeValues[0]}
            onChange={handleInputChange}
            type="number"
            className="pricerange-min"
            min={min.current}
            max={priceRangeValues[1] - 1 || undefined}
          />
          <i>€</i>
        </div>
        <div className="input-icon">
          <input
            required
            value={priceRangeValues[1]}
            onChange={handleInputChange2}
            type="number"
            className="pricerange-max"
            min={priceRangeValues[0] + 1 || undefined}
          />
          <i>€</i>
        </div>
      </div>
    </div>
  );
};

export default PriceSlider;
