import { forwardRef } from 'react';
import SvgIcon, { SvgIconProps } from '../../app/components/bhComponents/SvgIcon';
import { ForwardedRef } from 'app/helpers/types';
const IconExternalLink = forwardRef((props: SvgIconProps, ref: ForwardedRef<SVGSVGElement>) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    name="IconExternalLink"
    ref={ref}
    {...props}
  >
    <path fill="none" d="M0 0h24v24H0Z" data-name="Path 1692" />
    <path
      d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2v-7h-2ZM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3Z"
      data-name="Path 1693"
    />
  </SvgIcon>
));
IconExternalLink.displayName = 'IconExternalLink';
export default IconExternalLink;
