import { useState, useEffect } from 'react';
import { Navbar } from 'react-materialize';
import { useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';
import InventoryIcon from '@mui/icons-material/Inventory';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import DesktopNavBar from 'app/components/navbar/desktop-navbar';
import { GlobalStateType } from 'app/helpers/types';
import { bestellingenActions } from 'redux/bestellingen-slice';
import { toevoegenActions } from 'redux/toevoegen-slice';
import useBestellingen from 'app/hooks/useBestellingen';
import { useAppSelector } from 'app/hooks';
import useWindowSize from 'app/hooks/useWindowSize';
import { accessibleOnClick } from 'app/helpers/utils';

const NavBar = () => {
  const { width } = useWindowSize();

  const dispatch = useDispatch();

  const location = useLocation();

  const pageTitle = useAppSelector((state) => state.ui.pageTitle);

  const isLargeScreen = width && width >= 1024;

  const disableUrls = ['/intro', '/user/login', '/user/register'];
  const hideIconUrls = ['/user/account', '/orders'];

  const toevoegenStep = useSelector((state: GlobalStateType) => state.toevoegen.step);

  const shouldHideHamburgerMenu = location.pathname === '/add' && toevoegenStep > 0;

  const shouldHideBlankSpace = location.pathname === '/user/account';

  const hideIcons = hideIconUrls.includes(location.pathname);

  const { data: bestellingenData, isLoading, isError, error } = useBestellingen();

  let bestellingenActuelData: Array<object> = [];
  let bestellingenHistorieData: Array<object> = [];
  const [aantalBestellingen, setAantel] = useState<number>(0);

  useEffect(() => {
    dispatch(bestellingenActions.setIsLoading(isLoading));
  }, [isLoading]);

  useEffect(() => {
    dispatch(bestellingenActions.setIsError(isError));
    dispatch(bestellingenActions.setError(error));
  }, [isError]);

  useEffect(() => {
    bestellingenActuelData = [];
    bestellingenHistorieData = [];

    // eslint-disable-next-line array-callback-return, @typescript-eslint/no-explicit-any
    bestellingenData?.map((item: any) => {
      if (item?.order.status !== 'Bezorgd') {
        bestellingenActuelData.push(item);
      }
    });
    // eslint-disable-next-line array-callback-return,  @typescript-eslint/no-explicit-any
    bestellingenData?.map((item: any) => {
      if (item?.order.status === 'Bezorgd') {
        bestellingenHistorieData.push(item);
      }
    });

    dispatch(bestellingenActions.setBestellingenData(bestellingenData));
    dispatch(bestellingenActions.setActueelBestellingen(bestellingenActuelData));
    dispatch(bestellingenActions.setAantalBestellingen(bestellingenActuelData?.length));
    dispatch(bestellingenActions.setHistorieBestellingen(bestellingenHistorieData));
    setAantel(bestellingenActuelData?.length);
  }, [bestellingenData]);

  // hide for intro
  if (disableUrls.includes(location.pathname)) {
    return null;
  }

  // desktop navbar if breakpoint hit
  if (isLargeScreen) {
    return <DesktopNavBar aantalBestellingen={aantalBestellingen} />;
  }

  const isLocationActive = (value: string) =>
    value === location.pathname ? 'navLinkActivePath' : 'navLinkNotActivePath';

  const handleGoBackClick = () => {
    if (toevoegenStep > 0) {
      dispatch(toevoegenActions.decrementToevoegenStep());
    }
  };

  return (
    <>
      <div className="navbar-mobile-custom-container">
        <Navbar
          alignLinks="right"
          brand={
            <Link className="navbar-title" to="/">
              {pageTitle || 'Home'}
            </Link>
          }
          id="mobile-nav"
          menuIcon={
            <div style={{ display: shouldHideHamburgerMenu ? 'none' : 'block' }}>
              <MenuIcon>Menu</MenuIcon>
            </div>
          }
          centerLogo
          extendWith={
            hideIcons ? null : (
              <div className="icons">
                {/* temporarily hides the search and shopping cart icon from the mobile navbar, not included in this phase */}
                {/* <SearchIcon />
						<ShoppingCartIcon /> */}
              </div>
            )
          }
          options={{
            draggable: true,
            edge: 'left',
            inDuration: 250,
            outDuration: 200,
            preventScrolling: true,
          }}
        >
          <div className="navBarCloseIcon">
            <CloseIcon className="close-button sidenav-close" fontSize="inherit" />
          </div>
          <Link to="/" className={isLocationActive('/')}>
            <HomeIcon className={isLocationActive('/')} />
            <span>Home</span>
          </Link>
          <Link to="/mijn-voorraad" className={isLocationActive('/mijn-voorraad')}>
            <InventoryIcon className={isLocationActive('/mijn-voorraad')} />
            <span>Mijn voorraad</span>
          </Link>
          <Link to="add" className={isLocationActive('/add')}>
            <AddIcon className={isLocationActive('/add')} />
            <span>Toevoegen</span>
          </Link>
          <Link to="/user/account" className={isLocationActive('/user/account')}>
            <PersonIcon className={isLocationActive('/user/account')} />
            <span>Profiel</span>
          </Link>
          <Link to="faq" className={isLocationActive('/faq')}>
            <HelpIcon className={isLocationActive('/faq')} />
            <span>FAQ en contact</span>
          </Link>
        </Navbar>
        <div className="toevoegen-back" style={{ display: !shouldHideHamburgerMenu ? 'none' : 'flex' }}>
          <div className="toevoegen-icon" {...accessibleOnClick(handleGoBackClick)}>
            <NavigateBeforeIcon fontSize="inherit" />
          </div>
          <div className="toevoegen-text" {...accessibleOnClick(handleGoBackClick)}>
            Terug
          </div>
        </div>
      </div>
      {!shouldHideBlankSpace && <div className="navbar-fixed-mobile" />}
    </>
  );
};
/*
const mapStateToProps = (state: any) => ({
  auth: state.auth.auth,
});

export default connect(mapStateToProps, null)(NavBar); */

export default NavBar;
