import { FormSelectOptionType } from '../components/bhComponents/FormSelectDropdown';

export type FocusDirection = 'up' | 'down' | 'pageup' | 'pagedown' | 'first' | 'last';

const getNextFocusIndex = (direction: FocusDirection, focusedIndex: number, options: FormSelectOptionType[]) => {
  switch (direction) {
    case 'up': {
      return focusedIndex > 0 ? focusedIndex - 1 : options.length - 1;
    }
    case 'down': {
      return (focusedIndex + 1) % options.length;
    }
    case 'pageup': {
      const nextFocus = focusedIndex - 4;
      return nextFocus < 0 ? 0 : nextFocus;
    }
    case 'pagedown': {
      const nextFocus = focusedIndex + 4;
      return nextFocus > options.length - 1 ? options.length - 1 : nextFocus;
    }
    case 'last': {
      return options.length - 1;
    }
  }

  return 0; // first
};

export default getNextFocusIndex;
