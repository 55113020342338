import type { Placement, VirtualElement } from '@popperjs/core';
import React, { useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { StandardProps } from '../../../helpers/types';
import useClassNames from 'app/helpers/useClassNames';
import './Tooltip.scss';

export interface TooltipProps extends StandardProps {
  /**
   * Placement of the Arrow
   */
  placement?: Placement;
  /**
   * Ref to the element you want it to point at
   */
  referenceElement: Element | VirtualElement | null;
  /**
   * Removes arrow
   */
  hideArrow?: boolean;
  /**
   * Offset of toolTip (default [0,8]
   */
  offset?: number[];
}

const preventOverflowModifier = {
  name: 'preventOverflow',
  options: {
    padding: 16,
  },
};

/**
 * A example of a Tooltip Component
 */
const Tooltip = (props: TooltipProps) => {
  const {
    className,
    children,
    referenceElement,
    style,
    placement = 'top',
    hideArrow,
    offset = [0, 8],
    ...rest
  } = props;

  const { merge, prefix, withClassPrefix } = useClassNames('tooltip');
  const classes = merge(className, withClassPrefix(placement));

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

  const popperOptions = useMemo(
    () => ({
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset,
          },
        },
        preventOverflowModifier,
        {
          name: 'arrow',
          options: {
            element: arrowElement,
          },
        },
      ],
    }),
    [offset, placement, arrowElement]
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, popperOptions);

  const toolTipStyle = useMemo(() => {
    const textLength = React.Children.toArray(children).reduce<number>(
      (textLength, child) => textLength + (typeof child === 'string' ? child.length : 0),
      0
    );

    return {
      maxWidth: `min(calc(100% - 2rem), ${Math.max(12.5, Math.min(37.5, textLength * 0.25))}rem)`,
      ...style,
      ...styles.popper,
    };
  }, [children, style, styles.popper]);

  return (
    <div
      {...rest}
      role="tooltip"
      ref={setPopperElement}
      className={classes}
      style={toolTipStyle}
      {...attributes.popper}
    >
      <div className={prefix('container')}>{children}</div>
      {!hideArrow && <div ref={setArrowElement} className="bs-tooltip-arrow" style={styles.arrow} />}
    </div>
  );
};

export default Tooltip;
