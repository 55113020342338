import React from 'react';
import { LoaderWrapper } from './Loader.styles';
import { breakpoints } from 'app/config/breakpoints';
import useWindowSize from 'app/hooks/useWindowSize';

interface LoaderProps {
  size?: string;
  loaderWidth?: string;
}
const Loader = ({ size, loaderWidth }: LoaderProps) => {
  const { width } = useWindowSize();

  const isLargeScreen = width && width >= breakpoints.lg;
  return size && loaderWidth ? (
    <LoaderWrapper size={size} loaderWidth={loaderWidth} />
  ) : (
    <LoaderWrapper size={isLargeScreen ? '40px' : '33px'} loaderWidth={isLargeScreen ? '5px' : '4px'} />
  );
};

export default Loader;
