import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

interface IDictionary {
  [index: string]: string;
}

export interface FormState {
  values: Array<IDictionary>;
  step: number;
}

const initialState: FormState = {
  values: [],
  step: 0,
};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setFormValues: (state, action: PayloadAction<Array<IDictionary>>) => {
      state.values = action.payload;
    },
    setFormStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
  },
});

export const { setFormValues, setFormStep } = formSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectFormValues = (state: RootState) => state.form.values;
export const selectFormStep = (state: RootState) => state.form.step;

export default formSlice.reducer;
