import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const BezorgenLoaderPlaceholder = styled.div`
  width: 100%;
  min-height: calc(100vh - 110px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BezorgenMainContainer = styled.div`
  min-height: calc(100vh - 68px);
  padding-top: 53px;
  padding-botton: 10px;
  display: flex;
  justify-content: center;
  gap: 18px;
  width: 100%;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    padding-top: 0px;
  }
`;
