import styled from 'styled-components';

export const SplashScreenGeneralWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 101;
  background: linear-gradient(#75c42e, #8ced37);
`;
export const SplashScreenLogoContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SplashScreenLogoWrapper = styled.img``;
