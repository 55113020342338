import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import ToastContainer from '../Toast/ToastContainer';
import styles from '../../../scss/_variables.scss';
import {
  AnnulerenButton,
  DeleteButton,
  PoptupInput,
  PopupContainer,
  PopupInputLabel,
  PopupInputP,
  PopupSpan,
  PopupTitle,
  PopupWrapper,
} from './Popup.styles';
import { QueryKeys } from 'app/helpers/QueryKeys';
import useInventoryToSale from 'app/hooks/useInventoryToSale';
import useSaleToInventory from 'app/hooks/useSaleToInventory';
import useDeleteProduct from 'app/hooks/useDeleteProduct';
// Styled Components
// Colors

const Popup = ({
  selectedOpties,
  setSelectedOpties,
  setOpties,
  toastId,
  setToastId,
  toastList,
  setToastList,
  advertentieId,
  overrideProductId,
  isListerPage,
  refreshUponProductResultCardAction,
  stockMenuState,
  setStockMenuState,
}) => {
  const [verkoopprijs, setVerkoopprijs] = useState('');
  const [publicerenButtonFromInventoryToActive, setPublicerenButtonFromInventoryToActive] = useState(false);

  // validation
  useEffect(() => {
    if (verkoopprijs === 0 || verkoopprijs === '0' || verkoopprijs[0] === '0' || verkoopprijs === '') {
      setPublicerenButtonFromInventoryToActive(false);
    } else {
      setPublicerenButtonFromInventoryToActive(true);
    }
  }, [verkoopprijs]);

  const queryClient = useQueryClient();
  const { id: productId } = useParams();

  const id = productId || overrideProductId;
  const navigate = useNavigate();

  const {
    isInventoryToSaleError,
    inventoryToSaleError,
    isInventoryToSaleSuccess,
    onClickInventoryToSale,
    onClearInventoryToSaleErrorMessage,
  } = useInventoryToSale();

  useEffect(() => {
    if (isInventoryToSaleError) {
      const toast = {
        id: toastId,
        type: 'error',
        message: inventoryToSaleError?.message,
        additionalMessage: inventoryToSaleError?.response?.statusText,
        onClose: onClearInventoryToSaleErrorMessage,
      };
      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isInventoryToSaleError]);

  const payLoad = () => {
    const data = {
      tyre_set_id: id,
      price: Number(verkoopprijs),
    };

    return data;
  };

  const publishInventoryProductForSale = () => {
    onClickInventoryToSale(payLoad());
  };

  const handleSuccessInventoryToSale = () => {
    queryClient.refetchQueries(['priceRange']);
    if (isListerPage) {
      refreshUponProductResultCardAction();
    } else {
      queryClient.invalidateQueries([QueryKeys.Product, id]);
    }
    handleAnnulerenClick();
  };

  const {
    isSaleToInventoryError,
    saleToInventoryError,
    isSaleToInventorySuccess,
    onClickSaleToInventory,
    onClearSaleToInventoryErrorMessage,
  } = useSaleToInventory();

  useEffect(() => {
    if (isSaleToInventoryError) {
      const toast = {
        id: toastId,
        type: 'error',
        message: saleToInventoryError?.message,
        additionalMessage: saleToInventoryError?.response?.statusText,
        onClose: onClearSaleToInventoryErrorMessage,
      };
      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isSaleToInventoryError]);

  const payLoadForWithdrawAd = () => {
    const data = {
      id: advertentieId,
    };

    return data;
  };

  const withdrawAdvertisement = () => {
    onClickSaleToInventory(payLoadForWithdrawAd());
  };

  const handleSuccessWidthdrawAd = () => {
    queryClient.refetchQueries(['priceRange']);
    if (isListerPage) {
      refreshUponProductResultCardAction();
    } else {
      queryClient.invalidateQueries([QueryKeys.Product, id]);
    }
    handleAnnulerenClick();
  };

  const {
    isDeleteProductError,
    deleteProductError,
    isDeleteProductSuccess,
    onClickDeleteProduct,
    onClearDeleteProductErrorMessage,
  } = useDeleteProduct();

  useEffect(() => {
    if (isDeleteProductError) {
      const toast = {
        id: toastId,
        type: 'error',
        message: deleteProductError?.message,
        additionalMessage: deleteProductError?.response?.statusText,
        onClose: onClearDeleteProductErrorMessage,
      };
      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isDeleteProductError]);

  const payloadForDeleteProduct = () => {
    const data = {
      id,
    };

    return data;
  };

  const deleteProduct = () => {
    onClickDeleteProduct(payloadForDeleteProduct());
  };

  const handleSuccessDeleteProduct = () => {
    queryClient.refetchQueries(['priceRange']);
    if (isListerPage) {
      refreshUponProductResultCardAction();
      handleAnnulerenClick();
    } else {
      navigate('/mijn-voorraad');
    }
  };

  const handleAnnulerenClick = () => {
    setSelectedOpties('');
    setOpties(false);

    if (isListerPage) {
      setStockMenuState(!stockMenuState);
    }
  };

  const advertentieIntrekken = () => (
    <PopupContainer backgroundColor={styles.white} height="294">
      <PopupWrapper>
        <PopupTitle color={styles.primaryColor}>Let op!</PopupTitle>
        <PopupSpan fontSize="14">Wanneer uw de advertentie intrekt zal deze online niet meer zichtbaar zijn.</PopupSpan>
        <AnnulerenButton
          hoverBackgroundColor={styles.primaryColorContactHover}
          hoverColor={styles.primaryColorContactHoverContent}
          color={styles.primaryColor}
          backgroundColor={styles.primaryColorContact}
          margin="45"
          onClick={() => handleAnnulerenClick()}
        >
          Annuleren
        </AnnulerenButton>
        <DeleteButton
          hoverBackgroundColor={styles.primaryColorContactHoverContent}
          hoverColor={styles.white}
          color={styles.white}
          backgroundColor={styles.primaryColor}
          onClick={withdrawAdvertisement}
        >
          Advertentie intrekken
        </DeleteButton>
      </PopupWrapper>
      <ToastContainer toastList={toastList} autoRemove removeInterval={4750} />
      {isSaleToInventorySuccess && handleSuccessWidthdrawAd()}
    </PopupContainer>
  );

  const Delen = () => (
    <PopupContainer backgroundColor={styles.white} height="404">
      <PopupWrapper>
        <PopupTitle color={styles.primaryColor}>Let op!</PopupTitle>
        <PopupInputLabel>Verkoopprijs</PopupInputLabel>
        <PoptupInput
          value={verkoopprijs}
          onChange={(event) => {
            const properPriceFormat = event.target.value.match(/^(\d*\.{0,2}\d{0,2}$)/);
            if (properPriceFormat) {
              setVerkoopprijs(event.target.value);
            }
          }}
          type="number"
          border={styles.primaryColor}
        />
        <PopupInputP color={styles.greyNormal}>Inclusief transportkosten en handelings fee</PopupInputP>
        <AnnulerenButton
          hoverBackgroundColor={styles.primaryColorContactHover}
          hoverColor={styles.primaryColorContactHoverContent}
          color={styles.primaryColor}
          backgroundColor={styles.primaryColorContact}
          margin="20"
          onClick={() => handleAnnulerenClick()}
        >
          Annuleren
        </AnnulerenButton>
        <DeleteButton
          disabled={!publicerenButtonFromInventoryToActive}
          onClick={publishInventoryProductForSale}
          hoverColor={styles.white}
          color={styles.white}
          backgroundColor={publicerenButtonFromInventoryToActive ? styles.primaryColor : styles.greyNormal}
          hoverBackgroundColor={
            publicerenButtonFromInventoryToActive ? styles.primaryColorContactHoverContent : styles.greyNormal
          }
          cursor={publicerenButtonFromInventoryToActive ? 'pointer' : 'not-allowed'}
        >
          Publiceren
        </DeleteButton>
      </PopupWrapper>
      <ToastContainer toastList={toastList} autoRemove removeInterval={4750} />
      {isInventoryToSaleSuccess && handleSuccessInventoryToSale()}
    </PopupContainer>
  );

  const Verwijderen = () => (
    <PopupContainer backgroundColor={styles.white} height="278">
      <PopupWrapper>
        <PopupTitle color={styles.primaryColor}>Weet je het zeker?</PopupTitle>
        <PopupSpan fontSize="17">Deze actie kan niet ongedaan worden gemaakt.</PopupSpan>
        <AnnulerenButton
          hoverBackgroundColor={styles.primaryColorContactHover}
          hoverColor={styles.primaryColorContactHoverContent}
          color={styles.primaryColor}
          backgroundColor={styles.primaryColorContact}
          margin="45"
          onClick={() => handleAnnulerenClick()}
        >
          Annuleren
        </AnnulerenButton>
        <DeleteButton
          hoverBackgroundColor="#CC3100"
          hoverColor={styles.white}
          color={styles.white}
          backgroundColor="#FF3D00"
          onClick={deleteProduct}
        >
          Verwijderen
        </DeleteButton>
      </PopupWrapper>
      <ToastContainer toastList={toastList} autoRemove removeInterval={4750} />
      {isDeleteProductSuccess && handleSuccessDeleteProduct()}
    </PopupContainer>
  );

  return (
    <>
      {selectedOpties === 'Advertentie intrekken'
        ? advertentieIntrekken()
        : selectedOpties === 'Te koop aanbieden'
        ? Delen()
        : Verwijderen()}
    </>
  );
};

export default Popup;
