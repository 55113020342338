import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Styled Components
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import {
  ToevoegenContainer,
  ToevoegenGoBackContainer,
  ToevoegenGoBackIcon,
  ToevoegenGoBackText,
} from './Toevoegen.styles';
// Components
import Toevoegen1 from 'app/components/ToevoegenComponents/Toevoegen1';
import Toevoegen2 from 'app/components/ToevoegenComponents/Toevoegen2';
import Toevoegen3 from 'app/components/ToevoegenComponents/Toevoegen3';
import Toevoegen4 from 'app/components/ToevoegenComponents/Toevoegen4';
import Toevoegen5 from 'app/components/ToevoegenComponents/Toevoegen5';

import { QueryKeys } from 'app/helpers/QueryKeys';
import useVerifyCarInfo from 'app/hooks/useVerifyCarInfo';
import useCreateProduct from 'app/hooks/useCreateProduct';
import ToastContainer from 'app/components/Toast/ToastContainer';
import useCreateImage from 'app/hooks/useCreateImage';
import { toevoegenActions } from 'redux/toevoegen-slice';
import { tyreSetActions } from 'redux/tyreSet-slice';

const Toevoegen = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // First step
  const queryClient = useQueryClient();

  const toevoegenStep = useSelector((state) => state.toevoegen.step);

  const [carModelAndYear, setCarModelAndYear] = useState({ make: '', model: '', year: '' });

  const [merkValue, setMerkValue] = useState('');

  const [modelValue, setModelValue] = useState('');

  const [bouwjaarValue, setBouwjaarValue] = useState('');

  const [conditie, setConditie] = useState('');
  const [bandenmerk, setBandenmerk] = useState('');
  const [seizoen, setSeizoen] = useState('');

  const [kentekenInputValue, setKentekenInputValue] = useState('');

  const [autoGegevens, setAutogegevens] = useState({
    merk: '',
    type: '',
    bouwjaar: '',
  });

  useEffect(() => {
    if (autoGegevens) {
      setCarModelAndYear({ make: autoGegevens.merk, model: autoGegevens.type, year: autoGegevens.bouwjaar });
    }
  }, [autoGegevens]);

  const cachedResult = queryClient.getQueryData([QueryKeys.Kenteken, kentekenInputValue]);

  useEffect(() => {
    if (kentekenInputValue.length === 6 && cachedResult) {
      setAutogegevens({
        merk: cachedResult.merk,
        type: cachedResult.type,
        bouwjaar: cachedResult.bouwjaar,
      });
    }
  }, [kentekenInputValue]);

  useEffect(() => {
    if (
      (merkValue !== '' || modelValue !== '' || bouwjaarValue !== '') &&
      (kentekenInputValue !== '' || autoGegevens.merk !== '')
    ) {
      setAutogegevens({
        merk: '',
        type: '',
        bouwjaar: '',
      });
      setKentekenInputValue('');
    }
  }, [merkValue, modelValue, bouwjaarValue]);

  useEffect(() => {
    if ((merkValue !== '' || modelValue !== '' || bouwjaarValue !== '') && kentekenInputValue !== '') {
      setMerkValue('');
      setModelValue('');
      setBouwjaarValue('');
    }
  }, [kentekenInputValue]);

  const [file2, setFile2] = useState(null);
  const [usedFiles2, setUsedFiles2] = useState({
    file1: null,
    file2: null,
    file3: null,
    file4: null,
  });

  const [file1Image, setFile1Image] = useState();
  const [file1ImageId, setFile1ImageId] = useState();

  const [file2Image, setFile2Image] = useState();
  const [file2ImageId, setFile2ImageId] = useState();

  const [file3Image, setFile3Image] = useState();
  const [file3ImageId, setFile3ImageId] = useState();

  const [file4Image, setFile4Image] = useState();
  const [file4ImageId, setFile4ImageId] = useState();

  const [usedDropdown2, setUsedDropdown2] = useState({
    weil1: -1,
    weil2: -1,
    weil3: -1,
    weil4: -1,
  });

  const [nieuAndAlsNiewPhoto2, setNieuwAndAlsNiewPhoto2] = useState(null);
  const [nieuwPhotoId, setNieuwPhotoId] = useState();

  const [dropDownValues, setDropdownValues] = useState({
    dropdown1Value: '',
    dropdown2Value: '',
    dropdown3Value: '',
    dropdown4Value: '',
  });

  const [dotCode, setDotCode] = useState({
    dot1: '',
    dot2: '',
    dot3: '',
    dot4: '',
  });

  const [stepThreeValues, setStepThreeValues] = useState({
    seizoen: -1,
    ms: -1,
    pmsf: -1,
    tpms: -1,
    runflat: -1,
  });

  const [breedte, setBreedte] = useState('');
  const [hoogte, setHoogte] = useState('');
  const [inchMaat, setInchmaat] = useState('');
  const [tyreBandenmerk, setTyreBandenMerk] = useState('');
  const [velgenMerkOptional, setVelgenMerkOptional] = useState('');

  const [tyreStatus, setTyreStatus] = useState('FOR_SALE');
  const [isClicked, setIsClicked] = useState(1);
  const [priceIfAvailable, setPriceIfAvailable] = useState('');
  const [optionalStoragePlace, setOptionalStoragePlace] = useState('');

  const [searchedWithCarDetails, setSearchedWithCarDetails] = useState({
    brand: '',
    model: '',
    build_year: 0,
  });

  const [toastId, setToastId] = useState(0);
  const [toastList, setToastList] = useState([]);

  const { isVerifyCarInfoError, verifyCarInfoError, isVerfiyCarInfoSuccess, onClickVerify, onClearErrorMessage } =
    useVerifyCarInfo();

  const verifyCarInfoPayload = () => {
    const data = {
      brand: merkValue,
      model: modelValue,
      build_year: Number(bouwjaarValue),
    };

    return data;
  };

  const verifyCarInformation = () => {
    if (
      merkValue !== searchedWithCarDetails.brand ||
      modelValue !== searchedWithCarDetails.model ||
      Number(bouwjaarValue) !== searchedWithCarDetails.build_year
    ) {
      onClickVerify(verifyCarInfoPayload());
    } else {
      setToevoegenStep(1);
    }
  };

  useEffect(() => {
    if (isVerifyCarInfoError) {
      const toast = {
        id: toastId,
        type: 'error',
        message: verifyCarInfoError?.message,
        additionalMessage: verifyCarInfoError?.response?.data?.detail,
        onClose: onClearErrorMessage,
      };
      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isVerifyCarInfoError]);

  useEffect(() => {
    if (isVerfiyCarInfoSuccess) {
      setSearchedWithCarDetails({
        brand: merkValue,
        model: modelValue,
        build_year: Number(bouwjaarValue),
      });
      setToevoegenStep(1);
    }
  }, [isVerfiyCarInfoSuccess]);

  const {
    isCreateImageLoading,
    isCreateImageError,
    createImageError,
    createImageData,
    isCreateImageSuccess,
    onClickCreateImage,
    OnClearCreateImageErrorMessage,
  } = useCreateImage();

  const createImage = () => {
    const formData = new FormData();
    formData.append('afbeelding', nieuAndAlsNiewPhoto2);

    onClickCreateImage(formData);
  };

  useEffect(() => {
    if (nieuAndAlsNiewPhoto2) {
      createImage();
    }
  }, [nieuAndAlsNiewPhoto2]);

  useEffect(() => {
    if (isCreateImageError) {
      const toast = {
        id: toastId,
        type: 'error',
        message: createImageError?.message,
        additionalMessage: createImageError?.response?.statusText,
        onClose: OnClearCreateImageErrorMessage,
      };
      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isCreateImageError]);

  useEffect(() => {
    if (isCreateImageSuccess) {
      setNieuwPhotoId(createImageData?.image_id);
    }
  }, [isCreateImageSuccess]);

  const {
    isCreateImageLoading: isCreateImage1Loading,
    isCreateImageError: isCreateImage1Error,
    createImageError: createImage1Error,
    createImageData: createImage1Data,
    isCreateImageSuccess: isCreateImage1Success,
    onClickCreateImage: onClickCreateImage1,
    OnClearCreateImageErrorMessage: OnClearCreateImage1ErrorMessage,
  } = useCreateImage();

  const createImage1 = () => {
    const formData = new FormData();
    formData.append('afbeelding', file1Image);

    onClickCreateImage1(formData);
  };

  useEffect(() => {
    if (file1Image) {
      createImage1();
    }
  }, [file1Image]);

  useEffect(() => {
    if (isCreateImage1Error) {
      const toast = {
        id: toastId,
        type: 'error',
        message: createImage1Error?.message,
        additionalMessage: createImage1Error?.response?.statusText,
        onClose: OnClearCreateImage1ErrorMessage,
      };
      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isCreateImage1Error]);

  useEffect(() => {
    if (isCreateImage1Success) {
      setFile1ImageId(createImage1Data?.image_id);
    }
  }, [isCreateImage1Success]);

  const {
    isCreateImageLoading: isCreateImage2Loading,
    isCreateImageError: isCreateImage2Error,
    createImageError: createImage2Error,
    createImageData: createImage2Data,
    isCreateImageSuccess: isCreateImage2Success,
    onClickCreateImage: onClickCreateImage2,
    OnClearCreateImageErrorMessage: OnClearCreateImage2ErrorMessage,
  } = useCreateImage();

  const createImage2 = () => {
    const formData = new FormData();
    formData.append('afbeelding', file2Image);

    onClickCreateImage2(formData);
  };

  useEffect(() => {
    if (file2Image) {
      createImage2();
    }
  }, [file2Image]);

  useEffect(() => {
    if (isCreateImage2Error) {
      const toast = {
        id: toastId,
        type: 'error',
        message: createImage2Error?.message,
        additionalMessage: createImage2Error?.response?.statusText,
        onClose: OnClearCreateImage2ErrorMessage,
      };
      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isCreateImage2Error]);

  useEffect(() => {
    if (isCreateImage2Success) {
      setFile2ImageId(createImage2Data?.image_id);
    }
  }, [isCreateImage2Success]);

  const {
    isCreateImageLoading: isCreateImage3Loading,
    isCreateImageError: isCreateImage3Error,
    createImageError: createImage3Error,
    createImageData: createImage3Data,
    isCreateImageSuccess: isCreateImage3Success,
    onClickCreateImage: onClickCreateImage3,
    OnClearCreateImageErrorMessage: OnClearCreateImage3ErrorMessage,
  } = useCreateImage();

  const createImage3 = () => {
    const formData = new FormData();
    formData.append('afbeelding', file3Image);

    onClickCreateImage3(formData);
  };

  useEffect(() => {
    if (file3Image) {
      createImage3();
    }
  }, [file3Image]);

  useEffect(() => {
    if (isCreateImage3Error) {
      const toast = {
        id: toastId,
        type: 'error',
        message: createImage3Error?.message,
        additionalMessage: createImage3Error?.response?.statusText,
        onClose: OnClearCreateImage3ErrorMessage,
      };
      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isCreateImage3Error]);

  useEffect(() => {
    if (isCreateImage3Success) {
      setFile3ImageId(createImage3Data?.image_id);
    }
  }, [isCreateImage3Success]);

  const {
    isCreateImageLoading: isCreateImage4Loading,
    isCreateImageError: isCreateImage4Error,
    createImageError: createImage4Error,
    createImageData: createImage4Data,
    isCreateImageSuccess: isCreateImage4Success,
    onClickCreateImage: onClickCreateImage4,
    OnClearCreateImageErrorMessage: OnClearCreateImage4ErrorMessage,
  } = useCreateImage();

  const createImage4 = () => {
    const formData = new FormData();
    formData.append('afbeelding', file4Image);

    onClickCreateImage4(formData);
  };

  useEffect(() => {
    if (file4Image) {
      createImage4();
    }
  }, [file4Image]);

  useEffect(() => {
    if (isCreateImage4Error) {
      const toast = {
        id: toastId,
        type: 'error',
        message: createImage4Error?.message,
        additionalMessage: createImage4Error?.response?.statusText,
        onClose: OnClearCreateImage4ErrorMessage,
      };
      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isCreateImage4Error]);

  useEffect(() => {
    if (isCreateImage4Success) {
      setFile4ImageId(createImage4Data?.image_id);
    }
  }, [isCreateImage4Success]);

  const payLoadData = () => {
    const merkValueOverride =
      (autoGegevens.merk && autoGegevens.merk !== 'N/A' && autoGegevens.merk) || merkValue || '';
    const modelValueOverride =
      (autoGegevens.type && autoGegevens.type !== 'N/A' && autoGegevens.type) || modelValue || '';
    const bouwjaarValueOverride =
      (autoGegevens.bouwjaar &&
        autoGegevens.bouwjaar !== 'N/A' &&
        autoGegevens.bouwjaar &&
        Number(autoGegevens.bouwjaar)) ||
      Number(bouwjaarValue) ||
      '';

    const imageValuesOverride =
      conditie !== 'Gebruikt' && conditie !== 'Zo goed als nieuw'
        ? {
            first: nieuwPhotoId,
            second: nieuwPhotoId,
            third: nieuwPhotoId,
            fourth: nieuwPhotoId,
          }
        : {
            first: file1ImageId,
            second: file2ImageId,
            third: file3ImageId,
            fourth: file4ImageId,
          };

    const imagesContent = conditie !== 'Gebruikt' && conditie !== 'Zo goed als nieuw' ? file2 : usedFiles2;

    const profielDepthValuesOverride = dropDownValues;

    const profileDepthValuesFormat = {
      dropdown1Value: profielDepthValuesOverride?.dropdown1Value,
      dropdown2Value: profielDepthValuesOverride?.dropdown2Value,
      dropdown3Value: profielDepthValuesOverride?.dropdown3Value,
      dropdown4Value: profielDepthValuesOverride?.dropdown4Value,
    };

    const dotCodeFormat = {
      dot1: dotCode?.dot1,
      dot2: dotCode?.dot2,
      dot3: dotCode?.dot3,
      dot4: dotCode?.dot4,
    };

    const ms = stepThreeValues.ms === 0;
    const pmsf3 = stepThreeValues.pmsf === 0;
    const tpms = stepThreeValues.tpms === 0 ? 'yes' : stepThreeValues.tpms === 1 ? 'no' : 'unknown';
    const runFlat = stepThreeValues.runflat === 0 ? 'yes' : stepThreeValues.runflat === 1 ? 'no' : 'unknown';

    const kenteken = kentekenInputValue === '' ? null : kentekenInputValue;

    const price = tyreStatus !== 'FOR_SALE' ? 0 : Number(priceIfAvailable);

    const schadeForAllTires =
      conditie === 'Gebruikt' || conditie === 'Zo goed als nieuw'
        ? {
            schade1: usedDropdown2.weil1 === 0,
            schade2: usedDropdown2.weil2 === 0,
            schade3: usedDropdown2.weil3 === 0,
            schade4: usedDropdown2.weil4 === 0,
          }
        : {
            schade1: false,
            schade2: false,
            schade3: false,
            schade4: false,
          };

    const data = {
      car_brand: merkValueOverride,
      car_model: modelValueOverride,
      build_year: bouwjaarValueOverride,
      license_plate: kenteken,
      storage: optionalStoragePlace,
      tyreBandenmerk,
      velgenMerkOptional,
      sale_status: tyreStatus,
      price,
      runflat: runFlat,
      TPMS: tpms,
      season: seizoen,
      profile_depth: profileDepthValuesFormat,
      dot_code: dotCodeFormat,
      condition: conditie,
      width: Number(breedte),
      height: Number(hoogte),
      inches: Number(inchMaat),
      m_plus_s: ms,
      drie_PMSF: pmsf3,
      image_ids: imageValuesOverride,
      damage: schadeForAllTires,
      imagesContent,
    };

    return data;
  };

  const {
    isLoading: isCreateProductLoading,
    isError: isCreateProductError,
    error: errorCreatingaNewProduct,
    onClearErrorMessage: onClearCreatingAProductMessage,
    onClickCreate,
    isSuccess,
  } = useCreateProduct();

  const createProduct = () => {
    onClickCreate(payLoadData());
  };

  useEffect(() => {
    if (isCreateProductError) {
      const toast = {
        id: toastId,
        type: 'error',
        message: errorCreatingaNewProduct?.message,
        onClose: onClearCreatingAProductMessage,
      };
      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isCreateProductError]);

  useEffect(async () => {
    if (isSuccess) {
      dispatch(tyreSetActions.setTyreSetTrue());
      await queryClient.refetchQueries(['priceRange']);
      navigate(`/mijn-voorraad`);
    }
  }, [isSuccess]);

  useEffect(
    () => () => {
      dispatch(toevoegenActions.resetToevoegenStep());
    },
    []
  );

  const setToevoegenStep = (value) => {
    dispatch(toevoegenActions.setToevoegenStepValue(value));
  };

  const handleGoBackClick = () => {
    if (toevoegenStep > 0) {
      dispatch(toevoegenActions.decrementToevoegenStep());
    }
  };

  return (
    <ToevoegenContainer>
      <ToevoegenGoBackContainer isLastStep={toevoegenStep === 4}>
        {toevoegenStep >= 1 && (
          <ToevoegenGoBackIcon onClick={handleGoBackClick}>
            <NavigateBeforeIcon fontSize="inherit" />
            <ToevoegenGoBackText>Terug</ToevoegenGoBackText>
          </ToevoegenGoBackIcon>
        )}
      </ToevoegenGoBackContainer>
      {toevoegenStep === 0 && (
        <Toevoegen1
          kentekenInputValue={kentekenInputValue}
          setKentekenInputValue={setKentekenInputValue}
          setAutogegevens={setAutogegevens}
          merkValue={merkValue}
          setMerkValue={setMerkValue}
          modelValue={modelValue}
          setModelValue={setModelValue}
          bouwjaarValue={bouwjaarValue}
          setBouwjaarValue={setBouwjaarValue}
          carModelAndYear={carModelAndYear}
          toastId={toastId}
          setToastId={setToastId}
          verifyCarInformation={verifyCarInformation}
          toastList={toastList}
          setToastList={setToastList}
        />
      )}
      {toevoegenStep === 1 && (
        <Toevoegen2
          conditie={conditie}
          setConditie={setConditie}
          setToevoegenStep={setToevoegenStep}
          file2={file2}
          setFile2={setFile2}
          usedFiles2={usedFiles2}
          setUsedFiles2={setUsedFiles2}
          usedDropdown2={usedDropdown2}
          setUsedDropdown2={setUsedDropdown2}
          setNieuwAndAlsNiewPhoto2={setNieuwAndAlsNiewPhoto2}
          dropDownValues={dropDownValues}
          setDropdownValues={setDropdownValues}
          dotCode={dotCode}
          setDotCode={setDotCode}
          setFile1Image={setFile1Image}
          file2Image={file2Image}
          setFile2Image={setFile2Image}
          setFile3Image={setFile3Image}
          setFile4Image={setFile4Image}
          isCreateImageLoading={isCreateImageLoading}
          isCreateImageError={isCreateImageError}
          isCreateImage1Loading={isCreateImage1Loading}
          isCreateImage1Error={isCreateImage1Error}
          isCreateImage2Loading={isCreateImage2Loading}
          isCreateImage2Error={isCreateImage2Error}
          isCreateImage3Loading={isCreateImage3Loading}
          isCreateImage3Error={isCreateImage3Error}
          isCreateImage4Loading={isCreateImage4Loading}
          isCreateImage4Error={isCreateImage4Error}
        />
      )}
      {toevoegenStep === 2 && (
        <Toevoegen3
          setSeizoen={setSeizoen}
          setBandenmerk={setBandenmerk}
          setToevoegenStep={setToevoegenStep}
          stepThreeValues={stepThreeValues}
          setStepThreeValues={setStepThreeValues}
          breedte={breedte}
          setBreedte={setBreedte}
          hoogte={hoogte}
          setHoogte={setHoogte}
          inchMaat={inchMaat}
          setInchmaat={setInchmaat}
          tyreBandenmerk={tyreBandenmerk}
          setTyreBandenMerk={setTyreBandenMerk}
          velgenMerkOptional={velgenMerkOptional}
          setVelgenMerkOptional={setVelgenMerkOptional}
        />
      )}
      {toevoegenStep === 3 && (
        <Toevoegen4
          setToevoegenStep={setToevoegenStep}
          tyreStatus={tyreStatus}
          setTyreStatus={setTyreStatus}
          priceIfAvailable={priceIfAvailable}
          setPriceIfAvailable={setPriceIfAvailable}
          optionalStoragePlace={optionalStoragePlace}
          setOptionalStoragePlace={setOptionalStoragePlace}
          isClicked={isClicked}
          setIsClicked={setIsClicked}
        />
      )}
      {toevoegenStep === 4 && (
        <Toevoegen5
          conditie={conditie}
          bandenmerk={bandenmerk}
          seizoen={seizoen}
          setToevoegenStep={setToevoegenStep}
          payLoadData={payLoadData}
          isLoading={isCreateProductLoading}
          onClickCreate={createProduct}
        />
      )}
      <ToastContainer toastList={toastList} autoRemove removeInterval={4750} />
    </ToevoegenContainer>
  );
};

export default Toevoegen;
