import React from 'react';
import splashLogo from '../../../../assets/svg/splashLogo.svg';
import { SplashScreenGeneralWrapper, SplashScreenLogoContainer, SplashScreenLogoWrapper } from './Splash.styles';
const Splash = () => (
  <SplashScreenGeneralWrapper>
    <SplashScreenLogoContainer>
      <SplashScreenLogoWrapper src={splashLogo} />
    </SplashScreenLogoContainer>
  </SplashScreenGeneralWrapper>
);

export default Splash;
