import { forwardRef, LabelHTMLAttributes } from 'react';
import { useFormControl } from '../FormControl';
import useClassNames from 'app/helpers/useClassNames';
import './FormLabel.scss';

export interface FormLabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  /**
   * Set state to required
   */
  required?: boolean;
  /**
   * Set state to shrink
   */
  shrink?: boolean;
  /**
   * Set state to success
   */
  success?: boolean;
  /**
   * Set state to error
   */
  error?: boolean;
  /**
   * Set state to disabled
   */
  disabled?: boolean;
  /**
   * Set state to optional
   */
  optional?: boolean;
}

/**
 * A example of a FromLabel component
 */
const FormLabel = forwardRef<HTMLLabelElement, FormLabelProps>((props: FormLabelProps, ref) => {
  const {
    id,
    htmlFor: htmlForProp,
    className,
    children,
    required: requiredProp,
    shrink: shrinkProp,
    success: successProp,
    error: errorProp,
    disabled: disabledProp,
    optional: optionalProp,
    ...rest
  } = props;

  const {
    controlId,
    required: requiredControl,
    shrink: shrinkControl,
    success: successControl,
    error: errorControl,
    disabled: disabledControl,
    focused,
    optional: optionalControl,
  } = useFormControl();

  const success = successProp || successControl;
  const error = errorProp || errorControl;
  const disabled = disabledProp || disabledControl;
  const required = requiredProp || requiredControl;
  const optional = !required && (optionalProp || optionalControl);
  const labelId = controlId ? `${controlId}-label` : id;
  const htmlFor = controlId || id;
  const { prefix, withClassPrefix, merge } = useClassNames('form-label');
  const shrink = shrinkProp === true || shrinkProp === false ? shrinkProp : shrinkControl;
  const classes = merge(className, withClassPrefix({ shrink, error, disabled, success, focused }));

  return (
    <label {...rest} id={labelId || id} htmlFor={htmlFor || htmlForProp} ref={ref} className={classes}>
      {children}
      {optional && <span className={prefix('optional-text')}>(optioneel)</span>}
      {required && (
        <span aria-hidden className={prefix('required')}>
          &thinsp;*
        </span>
      )}
    </label>
  );
});

FormLabel.displayName = 'FormLabel';

export default FormLabel;
