import { ChangeEvent, InputHTMLAttributes, MouseEvent, useCallback, useRef } from 'react';
import { FormInputBorderClickEventWithRefHandler } from '../../../helpers/types';
import { useFormControl } from '../FormControl';
import FormInput from '../FormInput';
import FormInputBorder from '../FormInputBorder';
import Typography from '../Typography';
import IconVinkjeCircle from '../../../../assets/svg/IconVinkjeCircle';
import IconErrorCircle from '../../../../assets/svg/IconErrorCircle';
import Spinner from '../Spinner';
import IconLicensePlateCountryNL from './IconLicensePlateCountryNL';
import IconLicensePlateEUStars from './IconLicensePlateEUStars';
import useClassNames from 'app/helpers/useClassNames';
import './FormInputLicensePlate.scss';

export interface LicensePlateData {
  make: string;
  model: string;
  year: string;
}

export interface FormInputLicensePlateProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'disabled'> {
  onBorderClick?: FormInputBorderClickEventWithRefHandler;
  /**
   * Add data of Data type: (make: string, model: string, year: string and fuel: string)
   */
  data: LicensePlateData;
  /**
   * Color variant
   */
  color?: 'yellow' | 'green';
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  errorText: string;
  isLoading?: boolean;
}

const FormInputLicensePlate = (props: FormInputLicensePlateProps) => {
  const {
    className,
    data,
    isLoading,
    errorText,
    value,
    setValue,
    color = 'yellow',
    onBorderClick,
    onChange,
    ...rest
  } = props;
  const { disabled, success, error } = useFormControl();
  const { prefix, withClassPrefix, merge } = useClassNames('form-input-license-plate');
  const classes = merge(className, withClassPrefix(color, { success, error }));
  const inputRef = useRef<HTMLInputElement>(null);

  const handleBorderClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (disabled) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      inputRef.current?.focus();
      onBorderClick?.(event, inputRef);
    },
    [disabled, onBorderClick]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.length < 7) {
        const modifiedValue = event.target.value?.toUpperCase().replace(/[^a-zA-Z0-9]/gi, '');
        setValue(modifiedValue);
        onChange?.(event);
      }
    },
    [onChange]
  );

  return (
    <div className={classes}>
      <FormInputBorder className={prefix('inner')} onClick={handleBorderClick}>
        <div className={prefix('icon-wrapper')}>
          <IconLicensePlateEUStars className="eu-stars" />
          <IconLicensePlateCountryNL className="country-id" />
        </div>
        <FormInput placeholder="KENTEKEN" value={value} onChange={handleChange} {...rest} ref={inputRef} />
      </FormInputBorder>
      {(success || error || isLoading) && (
        <div className={prefix('success-wrapper')}>
          {!isLoading && (error ? <IconErrorCircle /> : <IconVinkjeCircle />)}
          <div className={prefix('text-wrapper')}>
            {isLoading && <Spinner className="spinner" />}
            {error && <Typography as="p">{errorText}</Typography>}
            {success && (
              <>
                <Typography as="p" variant="text-16" fontFamily="semi-bold">
                  {data.make} {data.model}
                </Typography>
                <Typography as="p" variant="text-16" color="gray">
                  {data.year}
                </Typography>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FormInputLicensePlate;
