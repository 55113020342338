import { forwardRef } from 'react';
import SvgIcon, { SvgIconProps } from '../../app/components/bhComponents/SvgIcon';
import { ForwardedRef } from 'app/helpers/types';
const IconVinkje = forwardRef((props: SvgIconProps, ref: ForwardedRef<SVGSVGElement>) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    name="IconVinkje"
    ref={ref}
    {...props}
  >
    <path data-name="Path 1298" d="M0 0h24v24H0Z" fill="none" />
    <path data-name="Path 1299" d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4Z" />
  </SvgIcon>
));
IconVinkje.displayName = 'IconVinkje';
export default IconVinkje;
