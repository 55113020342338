import { ChangeEvent, forwardRef, useCallback, useMemo } from 'react';
import { FormControlBaseProps, StandardProps } from '../../../helpers/types';
import type { CheckboxValueType } from '../CheckboxBase';
import CheckboxGroupContext from './CheckboxGroupContext';
import useClassNames from 'app/helpers/useClassNames';
import './CheckboxGroup.scss';

export type CheckboxGroupPlacement = 'start' | 'center' | 'end';

export interface CheckboxGroupProps<V = CheckboxValueType[]> extends StandardProps, FormControlBaseProps<V> {
  /**
   * Placement of items
   */
  placement?: CheckboxGroupPlacement;
  /**
   * Sets item in a column
   */
  column?: boolean;
  /**
   * Sets item to have nowrap styling
   */
  nowrap?: boolean;
}

const CheckboxGroup = forwardRef<HTMLDivElement, CheckboxGroupProps>((props: CheckboxGroupProps, ref) => {
  const {
    className,
    children,
    placement = 'center',
    column,
    nowrap,
    name,
    value,
    disabled,
    readOnly,
    onChange,
    style,
  } = props;

  const { merge, withClassPrefix } = useClassNames('checkbox-group');
  const classes = merge(className, withClassPrefix(placement, column ? 'column' : 'row', nowrap ? 'nowrap' : 'wrap'));

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (disabled || readOnly) {
        return;
      }

      onChange?.(event);
    },
    [disabled, onChange, readOnly]
  );

  const contextValue = useMemo(
    () => ({
      name,
      value,
      readOnly,
      disabled,
      onChange: handleChange,
      isCheckboxGroup: true,
    }),
    [disabled, handleChange, name, readOnly, value]
  );

  return (
    <CheckboxGroupContext.Provider value={contextValue}>
      <div ref={ref} role="group" className={classes} style={style}>
        {children}
      </div>
    </CheckboxGroupContext.Provider>
  );
});

CheckboxGroup.displayName = 'CheckboxGroup';

export default CheckboxGroup;
