import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const Toevoegen1Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 45%;

  @media (max-width: 1600px) {
    width: 60%;
  }

  @media (max-width: 1300px) {
    width: 70%;
  }

  @media (max-width: 1100px) {
    width: 80%;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 95%;
  }
`;

export const Toevoegen1MainWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 70px;

  @media (max-width: ${breakpoints.md}px) {
    padding: 20px 20px;
  }
`;

export const Toevoegen1Title = styled.h1`
  font-size: 40px;
  font-weight: 800;
`;

export const Toevoegen1ContentSpan = styled.span`
  font-size: 18px;
  font-weight: 500;
`;

export const Toevoegen1InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 600px);
  width: 50%;
  margin-top: 50px;

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
  }

  @media screen and (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg - 1}px) {
    min-width: 310px;
  }
`;
