import { createContext, useContext } from 'react';
import { ExpandableClickHandler } from './ExpandableControl';
export interface ExpandableControlValue {
  onClick?: ExpandableClickHandler;
  openIds?: string[];
  onId: (value: string) => void;
}

const ExpandableControlContext = createContext<ExpandableControlValue>({ onId: () => undefined });

export const useExpandableControlContext = () => useContext<ExpandableControlValue>(ExpandableControlContext);

export default ExpandableControlContext;
