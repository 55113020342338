import React, { Component } from 'react';

interface Props {
  className?: string;
}
class Card extends Component<Props> {
  render() {
    const baseClassName = ' card';
    return (
      <div className={this.props.className ? this.props.className + baseClassName : baseClassName}>
        {this.props.children}
      </div>
    );
  }
}

export default Card;
