import { useQuery } from '@tanstack/react-query';
import { getHoogte } from 'app/api/tyreProperties/hoogte';
import { QueryKeys } from 'app/helpers/QueryKeys';

const useHoogte = () => {
  const query = useQuery([QueryKeys.Hoogte], getHoogte, {
    enabled: true,
    refetchOnWindowFocus: 'always',
    retry: false,
  });

  return {
    ...query,
    hoogte: query.data,
  };
};

export default useHoogte;
