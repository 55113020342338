import apiClient from 'app/axios/api-client';

export const getPurchases = async () => {
  const result = await apiClient.get('/inkoopen/');

  return result.data;
};

export const getPurchase = async (id: number) => {
  const result = await apiClient.get(`inkoopen/${id}`);

  return result.data;
};
