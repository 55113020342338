import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import styles from '../../../../scss/_variables.scss';
import {
  ResultsMainWrapper,
  ResultsLoadingStateWrapper,
  ResultsWrapper,
  NoResultsWrapper,
  NoResultsWrapperIconContainer,
  NoResultsWrapperIcon,
  NoResultsWrapperText,
  MobileSearchInfoWidget,
  MobileSearchInfoCarIconWrapper,
  MobileSearchInfoDetailsWrapper,
  MobileSearchInfoDetailsTextWrapper,
  MobileSearchInfoDetailsTextModelWrapper,
  MobileSearchInfoFilterIconWrapper,
  MobileSearchInfoFilterIconContent,
} from './Results.styles';
import ProductResultCard from 'app/components/cards/Product-Result-Card/ProductResultCard';
import Loader from 'app/components/Loader/Loader';
import ExampleImage from 'assets/img/band_example.png';
import Pagination from 'app/components/Pagination/Pagination';

const Results = ({
  pageStart,
  currentPage,
  setCurrentPage,
  totalPages,
  isLoading,
  data,
  setSearchMenuState,
  searchedWithCarDetails,
  toastId,
  setToastId,
  toastList,
  setToastList,
  refreshUponProductResultCardAction,
  isValid,
}) => {
  const location = useLocation();

  const searchTerm = `${searchedWithCarDetails?.merk} ${searchedWithCarDetails?.type}${
    searchedWithCarDetails?.bouwjaar !== '' ? ` (${searchedWithCarDetails?.bouwjaar})` : ''
  }`;

  const shouldDisplaySearchTerm = useMemo(
    () =>
      searchedWithCarDetails?.merk !== '' ||
      searchedWithCarDetails?.type !== '' ||
      searchedWithCarDetails?.bouwjaar !== '',
    [searchedWithCarDetails]
  );

  return (
    <ResultsMainWrapper>
      <MobileSearchInfoWidget background={styles.white}>
        <MobileSearchInfoCarIconWrapper color={styles.variantIconColor}>
          <DirectionsCarIcon fontSize="inherit" />
        </MobileSearchInfoCarIconWrapper>
        <MobileSearchInfoDetailsWrapper>
          {shouldDisplaySearchTerm && (
            <MobileSearchInfoDetailsTextWrapper>Resultaten voor:</MobileSearchInfoDetailsTextWrapper>
          )}
          {shouldDisplaySearchTerm && (
            <MobileSearchInfoDetailsTextModelWrapper>{searchTerm}</MobileSearchInfoDetailsTextModelWrapper>
          )}
        </MobileSearchInfoDetailsWrapper>
        <MobileSearchInfoFilterIconWrapper>
          <MobileSearchInfoFilterIconContent
            onClick={() => setSearchMenuState(true)}
            background={styles.variantColor}
            color={styles.variantIconColor}
          >
            <FilterAltIcon fontSize="inherit" />
          </MobileSearchInfoFilterIconContent>
        </MobileSearchInfoFilterIconWrapper>
      </MobileSearchInfoWidget>
      {isLoading ? (
        <ResultsLoadingStateWrapper>
          <Loader />
        </ResultsLoadingStateWrapper>
      ) : (
        <ResultsWrapper>
          {data &&
            location.pathname === '/' &&
            data.map((product) => (
              <ProductResultCard
                key={product.tyre_set.id}
                isFavorite={product.tyre_set.is_favorite}
                status={product.tyre_set.sale_status}
                season={product.models[0]?.model.season.toUpperCase()}
                price={product.tyre_set.price_value}
                image={ExampleImage}
                id={product.tyre_set.id}
                isOwner={product.tyre_set.is_owner === 1}
                condition={product.models[0].model.condition}
                searchedWithCarDetails={searchedWithCarDetails}
                toastId={toastId}
                setToastId={setToastId}
                toastList={toastList}
                setToastList={setToastList}
                imageId={
                  product.models[0].model.condition === 'Nieuw'
                    ? product.models[3]?.image_id
                    : product.models[0]?.image_id
                }
                imageUrl={
                  product.models[0].model.condition === 'Nieuw'
                    ? product.models[3]?.image_url
                    : product.models[0]?.image_url
                }
                advertentieId={product?.advertisement?.id}
                isListerPage
                refreshUponProductResultCardAction={refreshUponProductResultCardAction}
              />
            ))}

          {data &&
            location.pathname === '/mijn-voorraad' &&
            data.map((product) => (
              <ProductResultCard
                key={product.tyre_set.id}
                isFavorite={product.tyre_set.is_favorite}
                status={product.tyre_set.sale_status}
                season={product.models[0]?.model.season.toUpperCase()}
                price={product.tyre_set.price_value}
                image={ExampleImage}
                id={product.tyre_set.id}
                isOwner={product.tyre_set.is_owner}
                condition={product.models[0].model.condition}
                toastId={toastId}
                setToastId={setToastId}
                toastList={toastList}
                setToastList={setToastList}
                imageId={
                  product.models[0].model.condition === 'Nieuw'
                    ? product.models[3]?.image_id
                    : product.models[0]?.image_id
                }
                imageUrl={
                  product.models[0].model.condition === 'Nieuw'
                    ? product.models[3]?.image_url
                    : product.models[0]?.image_url
                }
                advertentieId={product?.advertisement?.id}
                isListerPage
                refreshUponProductResultCardAction={refreshUponProductResultCardAction}
              />
            ))}
          {data?.length === 0 && (
            <NoResultsWrapper>
              <NoResultsWrapperIconContainer background={styles.primaryColorContact}>
                <NoResultsWrapperIcon color={styles.primaryColor}>
                  <SentimentVeryDissatisfiedIcon fontSize="inherit" />
                </NoResultsWrapperIcon>
              </NoResultsWrapperIconContainer>
              <NoResultsWrapperText>Geen resultaten</NoResultsWrapperText>
            </NoResultsWrapper>
          )}

          <Pagination
            pageStart={pageStart}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isValid={isValid}
          />
        </ResultsWrapper>
      )}
    </ResultsMainWrapper>
  );
};

export default Results;
