import { createContext, useContext } from 'react';

export interface ButtonGroupContextValue {
  fullwidth?: boolean;
}

const ButtonGroupContext = createContext<ButtonGroupContextValue>({});

export const useButtonGroup = () => useContext<ButtonGroupContextValue>(ButtonGroupContext);

export default ButtonGroupContext;
