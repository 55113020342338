import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PrintIcon from '@mui/icons-material/Print';
import { addBusinessDays, format } from 'date-fns';
import styles from '../../../../scss/_variables.scss';
import RetyreLogo from '../../../../assets/svg/tyreswap_logo.svg';
import {
  TyreLabelsGeneralContainer,
  TyreLabelLoaderPlaceholder,
  TyreLabelErrorPlaceholder,
  TyreLabelsContentWrapper,
  TyreLabelsPrintButtonWrapper,
  TyreLabelsPrintButton,
  TyreLabelsPrintIconWrapper,
  TyreLabelsPrintTextWrapper,
  TyreLabelsContent,
  TyreLabelWrapper,
  TyreLabelHeaderLogoWrapper,
  TyreLabelHeaderLogoContent,
  TyreLabelHeaderLeftLogoWrapper,
  TyreLabelHeaderRightTextWrapper,
  TyreLabelVanWrapper,
  TyreLabelVanTextWrapper,
  TyreLabelVanContentWrapper,
  TyreLabelAANGeneralWrapper,
  TyreLabelAANTextWrapper,
  TyreLabelAANRightContainerWrapper,
  TyreLabelAANRightBuyerWrapper,
  TyreLabelAANRightTavWrapper,
  TyreLabelAANRightTavTextWrapper,
  TyreLabelWrapperTavContentWrapper,
  TyreLabelContentOrangeWrapper,
  TyreLabelWrapperAANBuyerCountryWrapper,
  TyreLabelZendinggevensContentWrapper,
  TyreLabelZendinggegevensHeaderWrapper,
  TyreLabelZendinggegevensFirstWrapper,
  TyreLabelZendinggegevensSecondWrapper,
  TyreLabelZendinggegevensReferentieWrapper,
  TyreLabelZendinggegevensReferentieFirstWrapper,
  TyreLabelZendinggegevensReferentieSecondWrapper,
  TyreLabelZendinggegevensTotalGewichtWrapper,
  TyreLabelZendinggegevensTotalGewichtFirstWrapper,
  TyreLabelZendinggegevensTotalGewichtSecondWrapper,
  TyreLabelZendinggegevensColliWrapper,
  TyreLabelZendinggegevensTyreNumberWrapper,
  TyreLabelZendinggegevensColliTextWrapper,
  TyreLabelZendinggegevensColliContentWrapper,
  TyreLabelZendinggegevensReferentieFirstWrapperFirst,
  TyreLabelZendinggegevensReferentieFirstWrapperSecond,
  TyreLabelZendinggegevensTyreNumberWrapperFirst,
  TyreLAbelZendinggegevendTyreNumberWrapperSecond,
  TyreLabelBarcodeWrapper,
  TyreLabelBarcode,
  TyreLabelFooterLogoWrapper,
  TyreLabelFooterLogo,
} from './TyreLabels.styles';
import Loader from 'app/components/Loader/Loader';
import useTyreLabels from 'app/hooks/useTyreLabels';
import ToastContainer from 'app/components/Toast/ToastContainer';

const TyreLabels = () => {
  const params = useParams();

  const navigate = useNavigate();

  const { id } = params;

  const [sellerLocation, setSellerLocation] = useState('');
  const [buyerLocation, setBuyerLocation] = useState('');
  const [buyerName, setBuyerName] = useState('');
  const [buyerAddress, setBuyerAddress] = useState('');
  const [buyerZipcode, setBuyerZipcode] = useState('');
  const [buyerCity, setBuyerCity] = useState('');
  const [buyerCountry, setBuyerCountry] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [todayPlusOneWorkingDay, setTodayPlusOneWorkingDay] = useState('');
  const [totalWeight, setTotalWeight] = useState();
  const [barcodeUrls, setBarcodeUrls] = useState([]);

  const [toastId, setToastId] = useState(0);
  const [toastList, setToastList] = useState([]);

  const { data: tyreLabelsData, isLoading, isError, error } = useTyreLabels(id);

  useEffect(() => {
    if (isError) {
      if (error?.response?.status === 404) {
        navigate('/404');
      } else {
        const toast = {
          id: toastId,
          type: 'error',
          message: error?.message,
          additionalMessage: error?.response?.data?.detail,
        };
        setToastId((prev) => prev + 1);
        setToastList([...toastList, toast]);
      }
    }
  }, [isError]);

  useEffect(() => {
    if (tyreLabelsData && orderNumber === '') {
      setSellerLocation(tyreLabelsData?.seller_location ? tyreLabelsData?.seller_location : '');
      setBuyerLocation(tyreLabelsData?.buyer_location ? tyreLabelsData?.buyer_location : '');
      setBuyerName(tyreLabelsData?.buyer_name ? tyreLabelsData?.buyer_name : '');
      setBuyerAddress(tyreLabelsData?.buyer_address ? tyreLabelsData?.buyer_address : '');
      setBuyerZipcode(tyreLabelsData?.buyer_zipcode ? tyreLabelsData?.buyer_zipcode : '');
      setBuyerCity(tyreLabelsData?.buyer_city ? tyreLabelsData?.buyer_city : '');
      setBuyerCountry(tyreLabelsData?.buyer_country ? tyreLabelsData?.buyer_country : '');
      setOrderNumber(tyreLabelsData?.order_number);
      setTodayPlusOneWorkingDay(format(addBusinessDays(new Date(), 1), 'dd/MM/yy'));
      setTotalWeight(tyreLabelsData?.total_weight);
      setBarcodeUrls([
        tyreLabelsData?.barcode_urls[0],
        tyreLabelsData?.barcode_urls[1],
        tyreLabelsData?.barcode_urls[2],
        tyreLabelsData?.barcode_urls[3],
      ]);
    }
  }, [tyreLabelsData]);

  const tyreLabelsClickHandler = () => {
    window.print();
  };

  return (
    <TyreLabelsGeneralContainer>
      {isLoading ? (
        <TyreLabelLoaderPlaceholder>
          <Loader />
        </TyreLabelLoaderPlaceholder>
      ) : isError ? (
        <TyreLabelErrorPlaceholder />
      ) : (
        <>
          <TyreLabelsPrintButtonWrapper>
            <TyreLabelsPrintButton
              onClick={tyreLabelsClickHandler}
              backgroundColor={styles.primaryColor}
              hover={styles.primaryColorContactHoverContent}
            >
              <TyreLabelsPrintIconWrapper>
                <PrintIcon />
              </TyreLabelsPrintIconWrapper>
              <TyreLabelsPrintTextWrapper>Print</TyreLabelsPrintTextWrapper>
            </TyreLabelsPrintButton>
          </TyreLabelsPrintButtonWrapper>
          <TyreLabelsContentWrapper id="tyreLabelContent">
            <TyreLabelsContent id="tyreLabelActual">
              {tyreLabelsData?.barcode_urls.map((_item, index) => (
                <TyreLabelWrapper key={index} className={index > 1 ? 'printBottomCards' : 'printTopCards'}>
                  <TyreLabelHeaderLogoWrapper>
                    <TyreLabelHeaderLeftLogoWrapper>
                      <TyreLabelHeaderLogoContent src={RetyreLogo} />
                    </TyreLabelHeaderLeftLogoWrapper>
                    <TyreLabelHeaderRightTextWrapper className="nlWrapper">NL - SDE</TyreLabelHeaderRightTextWrapper>
                  </TyreLabelHeaderLogoWrapper>

                  <TyreLabelVanWrapper>
                    <TyreLabelVanTextWrapper>VAN:</TyreLabelVanTextWrapper>
                    <TyreLabelVanContentWrapper className="ml-9">{sellerLocation}</TyreLabelVanContentWrapper>
                  </TyreLabelVanWrapper>

                  <TyreLabelAANGeneralWrapper>
                    <TyreLabelAANTextWrapper>AAN:</TyreLabelAANTextWrapper>

                    <TyreLabelAANRightContainerWrapper className="ml-9">
                      <TyreLabelAANRightBuyerWrapper>{buyerLocation}</TyreLabelAANRightBuyerWrapper>
                      <TyreLabelAANRightTavWrapper>
                        <TyreLabelAANRightTavTextWrapper>T.a.v.</TyreLabelAANRightTavTextWrapper>
                        <TyreLabelWrapperTavContentWrapper className="ml-7">
                          {buyerName}
                        </TyreLabelWrapperTavContentWrapper>
                      </TyreLabelAANRightTavWrapper>
                      <TyreLabelContentOrangeWrapper>{buyerAddress}</TyreLabelContentOrangeWrapper>
                      <TyreLabelContentOrangeWrapper>
                        {`${buyerZipcode} `}
                        {buyerCity}
                      </TyreLabelContentOrangeWrapper>
                      <TyreLabelWrapperAANBuyerCountryWrapper>{buyerCountry}</TyreLabelWrapperAANBuyerCountryWrapper>
                    </TyreLabelAANRightContainerWrapper>
                  </TyreLabelAANGeneralWrapper>

                  <TyreLabelZendinggevensContentWrapper>
                    <TyreLabelZendinggegevensHeaderWrapper>
                      <TyreLabelZendinggegevensFirstWrapper>ZENDINGGEGEVENS</TyreLabelZendinggegevensFirstWrapper>
                      <TyreLabelZendinggegevensSecondWrapper>Verzenddatum:</TyreLabelZendinggegevensSecondWrapper>
                    </TyreLabelZendinggegevensHeaderWrapper>
                    <TyreLabelZendinggegevensReferentieWrapper>
                      <TyreLabelZendinggegevensReferentieFirstWrapper>
                        <TyreLabelZendinggegevensReferentieFirstWrapperFirst>
                          Referentie:
                        </TyreLabelZendinggegevensReferentieFirstWrapperFirst>
                        <TyreLabelZendinggegevensReferentieFirstWrapperSecond className="ml-6">
                          {orderNumber}
                        </TyreLabelZendinggegevensReferentieFirstWrapperSecond>
                      </TyreLabelZendinggegevensReferentieFirstWrapper>
                      <TyreLabelZendinggegevensReferentieSecondWrapper>
                        {todayPlusOneWorkingDay}
                      </TyreLabelZendinggegevensReferentieSecondWrapper>
                    </TyreLabelZendinggegevensReferentieWrapper>
                    <TyreLabelZendinggegevensTotalGewichtWrapper>
                      <TyreLabelZendinggegevensTotalGewichtFirstWrapper>
                        Total Gewicht: {totalWeight}kg
                      </TyreLabelZendinggegevensTotalGewichtFirstWrapper>
                      <TyreLabelZendinggegevensTotalGewichtSecondWrapper className="ml-6" />
                    </TyreLabelZendinggegevensTotalGewichtWrapper>

                    <TyreLabelZendinggegevensColliWrapper>
                      <TyreLabelZendinggegevensColliTextWrapper>4 COLLI</TyreLabelZendinggegevensColliTextWrapper>
                      <TyreLabelZendinggegevensColliContentWrapper className="ml-6" />
                    </TyreLabelZendinggegevensColliWrapper>

                    <TyreLabelZendinggegevensTyreNumberWrapper>
                      <TyreLabelZendinggegevensTyreNumberWrapperFirst>
                        {index + 1}
                      </TyreLabelZendinggegevensTyreNumberWrapperFirst>
                      <TyreLAbelZendinggegevendTyreNumberWrapperSecond className="ml-4">
                        OF 4
                      </TyreLAbelZendinggegevendTyreNumberWrapperSecond>
                    </TyreLabelZendinggegevensTyreNumberWrapper>
                  </TyreLabelZendinggevensContentWrapper>

                  <TyreLabelBarcodeWrapper>
                    <TyreLabelBarcode src={barcodeUrls[index]} />
                  </TyreLabelBarcodeWrapper>

                  <TyreLabelFooterLogoWrapper>
                    <TyreLabelFooterLogo src="https://dam.broekhuis.online/online/broekhuis-logos/logo-broekhuis.svg" />
                  </TyreLabelFooterLogoWrapper>
                </TyreLabelWrapper>
              ))}
            </TyreLabelsContent>
          </TyreLabelsContentWrapper>
        </>
      )}

      <ToastContainer toastList={toastList} autoRemove removeInterval={4750} />
    </TyreLabelsGeneralContainer>
  );
};

export default TyreLabels;
