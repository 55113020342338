import { Checkbox } from 'react-materialize';
import { useCallback, useState } from 'react';

interface Props {
  items: Array<{ name: string; label: string; checked: boolean }>;
  readMoreMax: number;
  filterSearchValues: Array<string>;
  setFilterSearchValues: React.Dispatch<React.SetStateAction<Array<string>>>;
}

export enum Conditions {
  NEW = 'new',
  USED = 'used',
  GOOD = 'good',
}

const CheckboxList = (props: Props) => {
  const { items, readMoreMax, filterSearchValues, setFilterSearchValues } = props;
  const [itemsLocal, setItemsLocal] = useState(items);
  const [expanded, setExpanded] = useState(false);

  const onChange = useCallback(
    (name: string) => {
      // update an internal array here so we report one object back up to the form
      const newItems = itemsLocal.map((item) => {
        const label =
          item.name.toLowerCase() === 'gebruikt'
            ? Conditions.USED
            : item.name.toLowerCase() === 'nieuw'
            ? Conditions.NEW
            : item.name.toLowerCase() === 'zo goed als nieuw'
            ? Conditions.GOOD
            : item.name;

        if (label === name) {
          item.checked = !item.checked;
          if (!filterSearchValues.includes(name)) {
            setFilterSearchValues((prevState) => [...prevState, name]);
          } else if (filterSearchValues.includes(name)) {
            setFilterSearchValues((prevState) => prevState.filter((item: string) => item !== name));
          }
        }
        return item;
      });
      setItemsLocal(newItems);
    },
    [filterSearchValues, itemsLocal]
  );

  let readMoreLink = null;
  return (
    <div className="checkbox-list">
      {items.map((i, index: number) => {
        const label =
          i.name.toLowerCase() === 'gebruikt'
            ? Conditions.USED
            : i.name.toLowerCase() === 'nieuw'
            ? Conditions.NEW
            : i.name.toLowerCase() === 'zo goed als nieuw'
            ? Conditions.GOOD
            : i.name;

        const checked = props?.filterSearchValues?.includes(label);
        let listItemClass = 'checkbox-list-item row';
        if (expanded || index < readMoreMax) {
          listItemClass += ' expanded';
        }
        if (index === readMoreMax - 1) {
          readMoreLink = (
            <button
              className="readMore"
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              {!expanded ? '+ Toon meer' : '+ Toon minder'}
            </button>
          );
        }

        return (
          <div className={listItemClass} key={`checkbox_${i.name}`}>
            <div className="col s2" id="checkBoxRadio">
              <Checkbox
                checked={checked}
                filledIn
                label=""
                value={label}
                onChange={() => {
                  onChange(label);
                }}
              />
            </div>
            <div className="col s10" id="checkBoxName">
              {i.name} <span className="checkbox-sublabel">{i.label ? `(${i.label})` : ''}</span>
            </div>
          </div>
        );
      })}
      {items.length > readMoreMax && readMoreLink}
    </div>
  );
};

export default CheckboxList;
