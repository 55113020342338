import { Navbar } from 'react-materialize';
import MenuIcon from '@mui/icons-material/Menu';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import InventoryIcon from '@mui/icons-material/Inventory';
import HelpIcon from '@mui/icons-material/Help';
import { useLocation, Link } from 'react-router-dom';
import Logo from '../../../assets/svg/tyreswap_logo.svg';

type DesktopNavBarPropsType = {
  aantalBestellingen: number;
};

const DesktopNavBar = ({ aantalBestellingen }: DesktopNavBarPropsType) => {
  const disableUrls = ['/intro', '/user/login', '/user/register'];
  const hideIconUrls = ['/user/account', '/orders'];

  const location = useLocation();

  // hide for intro
  if (disableUrls.includes(location.pathname)) {
    return null;
  }

  const hideIcons = hideIconUrls.includes(location.pathname);

  const isLocationActive = (value: string) =>
    value === location.pathname ? 'navLinkActivePath' : 'navLinkNotActivePath';

  return (
    <Navbar
      className="desktop-nav"
      alignLinks="left"
      id="desktop-nav"
      menuIcon={<MenuIcon />}
      centerLogo={false}
      extendWith={
        hideIcons ? null : (
          <div className="icons">
            <Link to="/user/bestellingen">
              <div className="personIconWrapper">
                <PersonIcon />
                {aantalBestellingen > 0 && <div className="navbar-bestellingen-badge">{aantalBestellingen}</div>}
              </div>
            </Link>
          </div>
        )
      }
      options={{
        draggable: true,
        edge: 'left',
        inDuration: 250,
        outDuration: 200,
        preventScrolling: true,
      }}
    >
      <Link to="/">
        <div className="desktop-brand">
          <img src={Logo} alt="" />
        </div>
      </Link>
      <Link to="add" className={isLocationActive('/add')}>
        <AddIcon className={isLocationActive('/add')} />
        <span>Toevoegen</span>
      </Link>
      <Link to="/" className={isLocationActive('/')}>
        <DirectionsCarFilledIcon className={isLocationActive('/')} />
        <span>Aanbod</span>
      </Link>
      <Link to="mijn-voorraad" className={isLocationActive('/mijn-voorraad')}>
        <InventoryIcon className={isLocationActive('/mijn-voorraad')} />
        <span>Mijn voorraad</span>
      </Link>
      <Link to="faq" className={isLocationActive('/faq')}>
        <HelpIcon className={isLocationActive('/faq')} />
        <span>FAQ en contact</span>
      </Link>
    </Navbar>
  );
};

export default DesktopNavBar;
