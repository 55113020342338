import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const AccountOrderRowWrapper = styled.div`
  padding: 16px 0px;
  border-bottom: ${(props) => props.borderBottom};
`;

export const DateAndStatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const DateWrapper = styled.div`
  font-size: 18px;
  font-weight: bold;
`;
export const StatusWrapper = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

export const AccountBottomRowWrapper = styled.div`
  display: flex;
  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    flex-direction: column;
  }
`;

export const OrderNumberAndTotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 32%;
  min-width: 225px;
  gap: 20px;
`;

export const OrderNummerContainer = styled.div`
  font-size: 14px;
`;

export const OrderNummerTextWrapper = styled.div`
  color: #949494;
`;

export const OrderNummerValueWrapper = styled.div``;

export const TotalContainer = styled.div`
  font-size: 14px;
`;

export const TotalTextWrapper = styled.div`
  color: #949494;
`;

export const TotalValueWrapper = styled.div``;

export const DetailsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 16px;
  color: #136ce2;
  font-weight: bold;
  flex: 1;
`;

export const DetailsActualContent = styled.div`
  margin-right: -7px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
`;
