import clsx from 'clsx';
import { forwardRef, useState, useCallback, HTMLAttributes, useMemo, useEffect } from 'react';
import { useExpandableControlContext } from '../ExpandableControl';
import Typography from '../Typography';
import IconUiArrowMediumBounded from '../../../../assets/svg/IconUiArrowMediumBounded';
import useClassNames from 'app/helpers/useClassNames';
import './Expandable.scss';

export interface ExpandableProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Title of the expandable
   */
  header: string;
  id: string;
}

/**
 * A example of a expandable component
 */

const Expandable = forwardRef<HTMLDivElement, ExpandableProps>((props: ExpandableProps, ref) => {
  const { className, children, id, header, ...rest } = props;
  const { openIds, onClick, onId } = useExpandableControlContext();
  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const [mounted, setMounted] = useState(false);
  const open = useMemo(() => openIds?.includes(id), [openIds, id]);

  const { prefix, withClassPrefix, merge } = useClassNames('expandable');
  const classes = merge(className, withClassPrefix({ open }));

  useEffect(() => {
    onId(id);
    const timeoutId = setTimeout(() => {
      setMounted(true);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [onId, id]);

  const body = clsx(prefix('body'), mounted && prefix('mounted'));

  const height = useMemo(() => (open && element && element.offsetHeight) || 0, [open, element]);

  const handleClick = useCallback(() => {
    onClick?.(id);
  }, [onClick, id]);

  return (
    <div aria-expanded={open} {...rest} ref={ref} className={classes}>
      <button className={prefix('header')} onClick={handleClick}>
        <Typography as="h3" fontFamily="semi-bold" variant="text-16">
          {header}
        </Typography>
        <IconUiArrowMediumBounded className={prefix('icon')} />
      </button>
      <div style={{ height }} className={body}>
        <div ref={setElement}>{children}</div>
      </div>
    </div>
  );
});

Expandable.displayName = 'Expandable';

export default Expandable;
