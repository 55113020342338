import React, { Component } from 'react';
import LightModeIcon from '@mui/icons-material/LightMode';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { accessibleOnClick } from 'app/helpers/utils';
import SummerWinterIcon from 'assets/svg/winter_summer_black_24dp.svg';

interface Props {
  className?: string;
  startValue?: string;
  seasonValue: Array<string>;
  setSeasonValue: React.Dispatch<React.SetStateAction<Array<string>>>;
  filterSearchValues: Array<string>;
  setFilterSearchValues: React.Dispatch<React.SetStateAction<Array<string>>>;
}
class SeasonInput extends Component<Props> {
  state: { selected: string | string[] };

  constructor(props: Props) {
    super(props);
    this.state = { selected: props.seasonValue ? props.seasonValue : '' };
    this.getSelectedClass = this.getSelectedClass.bind(this);
  }

  getSelectedClass(value: string) {
    if (this.state.selected.includes(value)) {
      return ' selected';
    }
    return '';
  }

  onClick(value: string) {
    this.setState({ selected: value });
    if (this.props.setSeasonValue && this.props.seasonValue) {
      if (this.props.seasonValue.includes(value)) {
        const filteredArray = this.props.seasonValue.filter((item: string) => item !== value);

        this.props.setSeasonValue(filteredArray);
      } else {
        this.props.setSeasonValue([...this.props.seasonValue, value]);
      }
      if (this.props.filterSearchValues.includes(value)) {
        const filteredArray = this.props.filterSearchValues.filter((item: string) => item !== value);

        this.props.setFilterSearchValues(filteredArray);
      } else {
        this.props.setFilterSearchValues([...this.props.filterSearchValues, value]);
      }
    }
  }

  render() {
    const baseClassName = 'row season center-align ';
    return (
      <div role="group" aria-labelledby="my-radio-group" className={baseClassName + this.props.className}>
        <div
          className={this.props.seasonValue.includes('Zomer') ? 'col s4 selected' : 'col s4'}
          {...accessibleOnClick(() => {
            this.onClick('Zomer');
          })}
        >
          <LightModeIcon fontSize="inherit" />
        </div>
        <div
          className={this.props.seasonValue.includes('All Season') ? 'col s4 selected' : 'col s4'}
          {...accessibleOnClick(() => {
            this.onClick('All Season');
          })}
        >
          <img src={SummerWinterIcon} alt="" />
        </div>
        <div
          className={this.props.seasonValue.includes('Winter') ? 'col s4 selected' : 'col s4'}
          {...accessibleOnClick(() => {
            this.onClick('Winter');
          })}
        >
          <AcUnitIcon fontSize="inherit" />
        </div>
      </div>
    );
  }
}

export default SeasonInput;
