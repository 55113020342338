import React, { useCallback, useMemo, useState } from 'react';
// Styled Components
// Colors
// MUI Icons
import styles from '../../../../scss/_variables.scss';
import Button from '../../bhComponents/Button';
import { Conditions } from '../../form/checkbox-list';
import CheckboxGroup from '../../bhComponents/CheckboxGroup';
import Selectable from '../../bhComponents/Selectable';
import { Toevoegen2Container, Toevoegen2MainWrapper, Toevoegen2Subtitle, Toevoegen2Title } from './Toevoegen2.styles';
import classes from './index.module.scss';

// Break Points
import AddTyreBlock, { ProfileDepths } from './AddTyreBlock/AddTyreBlock';
import Toevoegen2NewItem from './Toevoegen2NewItem/Toevoegen2NewItem';

const Toevoegen2 = ({
  conditie,
  setToevoegenStep,
  setConditie,
  dotCode,
  setDotCode,
  file2,
  setFile2,
  setUsedDropdown2,
  usedFiles2,
  setUsedFiles2,
  usedDropdown2,
  setNieuwAndAlsNiewPhoto2,
  dropDownValues,
  setDropdownValues,
  setFile1Image,
  setFile2Image,
  setFile3Image,
  setFile4Image,
  isCreateImageLoading,
  isCreateImageError,
  isCreateImage1Loading,
  isCreateImage1Error,
  isCreateImage2Loading,
  isCreateImage2Error,
  isCreateImage3Loading,
  isCreateImage3Error,
  isCreateImage4Loading,
  isCreateImage4Error,
}) => {
  // validation for Nieuw, Zo goed als nieuw and Gebruikt
  const isDisabledExtended = useMemo(() => {
    if (
      conditie !== 'Nieuw' &&
      (!usedFiles2.file1 ||
        !usedFiles2.file2 ||
        !usedFiles2.file3 ||
        !usedFiles2.file4 ||
        usedDropdown2.weil1 < 0 ||
        usedDropdown2.weil2 < 0 ||
        usedDropdown2.weil3 < 0 ||
        usedDropdown2.weil4 < 0 ||
        dotCode.dot1.length !== 4 ||
        dotCode.dot2.length !== 4 ||
        dotCode.dot3.length !== 4 ||
        dotCode.dot4.length !== 4 ||
        dropDownValues.dropdown1Value === '' ||
        dropDownValues.dropdown2Value === '' ||
        dropDownValues.dropdown3Value === '' ||
        dropDownValues.dropdown4Value === '' ||
        isCreateImage1Loading ||
        isCreateImage1Error ||
        isCreateImage2Loading ||
        isCreateImage2Error ||
        isCreateImage3Loading ||
        isCreateImage3Error ||
        isCreateImage4Loading ||
        isCreateImage4Error)
    ) {
      return true;
    }
    return false;
  }, [
    conditie,
    usedFiles2,
    usedDropdown2,
    dotCode,
    dropDownValues,
    isCreateImage1Loading,
    isCreateImage1Error,
    isCreateImage2Loading,
    isCreateImage2Error,
    isCreateImage3Loading,
    isCreateImage3Error,
    isCreateImage4Loading,
    isCreateImage4Error,
  ]);

  const isDisabledSimple = useMemo(() => {
    if (
      conditie === 'Nieuw' &&
      (file2 === null ||
        dropDownValues.dropdown1Value === '' ||
        dropDownValues.dropdown2Value === '' ||
        dropDownValues.dropdown3Value === '' ||
        dropDownValues.dropdown4Value === '' ||
        dotCode.dot1.length !== 4 ||
        dotCode.dot2.length !== 4 ||
        dotCode.dot3.length !== 4 ||
        dotCode.dot4.length !== 4 ||
        isCreateImageLoading ||
        isCreateImageError)
    ) {
      return true;
    }
    return false;
  }, [dropDownValues, conditie, dotCode, file2, isCreateImageLoading, isCreateImageError]);

  const handleClick = useCallback(() => {
    setToevoegenStep(2);
  }, []);

  const handlePhotoChange = (event) => {
    setFile2(URL.createObjectURL(event.target.files[0]));
    setNieuwAndAlsNiewPhoto2(event.target.files[0]);
  };

  const [valueToggle, setValueToggle] = useState([]);
  const handleToggleChange = (event) => {
    if (event.target.checked) {
      setValueToggle([event.target.value]);
      setConditie(
        event.target.value === Conditions.USED
          ? 'Gebruikt'
          : event.target.value === Conditions.NEW
          ? 'Nieuw'
          : 'Zo goed als nieuw'
      );
    } else {
      setValueToggle([]);
      setConditie('');
    }
  };

  const { initialValueSelect1, initialValueSelect2, initialValueSelect3, initialValueSelect4 } = useMemo(
    () => ({
      initialValueSelect1: ProfileDepths.find((item) => item.value === dropDownValues.dropdown1Value) || undefined,
      initialValueSelect2: ProfileDepths.find((item) => item.value === dropDownValues.dropdown2Value) || undefined,
      initialValueSelect3: ProfileDepths.find((item) => item.value === dropDownValues.dropdown3Value) || undefined,
      initialValueSelect4: ProfileDepths.find((item) => item.value === dropDownValues.dropdown4Value) || undefined,
    }),
    [dropDownValues]
  );

  return (
    <Toevoegen2Container backgroundColor={styles.white}>
      <Toevoegen2MainWrapper>
        <Toevoegen2Title>Foto's</Toevoegen2Title>
        <Toevoegen2Subtitle>Conditie</Toevoegen2Subtitle>
        <CheckboxGroup nowrap className={classes.toggle} value={valueToggle} onChange={handleToggleChange}>
          <Selectable appearance="none" selectBackground value={Conditions.NEW}>
            Nieuw
          </Selectable>
          <Selectable appearance="none" selectBackground value={Conditions.GOOD}>
            Zo goed als nieuw
          </Selectable>
          <Selectable appearance="none" selectBackground value={Conditions.USED}>
            Gebruikt
          </Selectable>
        </CheckboxGroup>
        {valueToggle.includes(Conditions.GOOD) || valueToggle.includes(Conditions.USED) ? (
          <>
            <AddTyreBlock
              title="Wiel 1"
              id={1}
              setUsedFiles={setUsedFiles2}
              setFileImage={setFile1Image}
              usedFiles={usedFiles2.file1}
              setDotCode={setDotCode}
              initialSchade={usedDropdown2.weil1}
              setSchade={setUsedDropdown2}
              initialDotCode={dotCode.dot1}
              initialSelect={initialValueSelect1}
              setProfileDepth={setDropdownValues}
            />
            <AddTyreBlock
              title="Wiel 2"
              id={2}
              setDotCode={setDotCode}
              setUsedFiles={setUsedFiles2}
              setFileImage={setFile2Image}
              usedFiles={usedFiles2.file2}
              setSchade={setUsedDropdown2}
              initialSchade={usedDropdown2.weil2}
              initialDotCode={dotCode.dot2}
              setProfileDepth={setDropdownValues}
              initialSelect={initialValueSelect2}
            />
            <AddTyreBlock
              title="Wiel 3"
              id={3}
              setDotCode={setDotCode}
              setUsedFiles={setUsedFiles2}
              setFileImage={setFile3Image}
              usedFiles={usedFiles2.file3}
              initialSchade={usedDropdown2.weil3}
              setSchade={setUsedDropdown2}
              initialDotCode={dotCode.dot3}
              setProfileDepth={setDropdownValues}
              initialSelect={initialValueSelect3}
            />
            <AddTyreBlock
              title="Wiel 4"
              id={4}
              setDotCode={setDotCode}
              setUsedFiles={setUsedFiles2}
              setFileImage={setFile4Image}
              usedFiles={usedFiles2.file4}
              setSchade={setUsedDropdown2}
              initialSchade={usedDropdown2.weil4}
              initialDotCode={dotCode.dot4}
              setProfileDepth={setDropdownValues}
              initialSelect={initialValueSelect4}
            />
          </>
        ) : (
          valueToggle.includes(Conditions.NEW) && (
            <Toevoegen2NewItem
              setDotCode={setDotCode}
              dotCode={dotCode}
              setProfileDepth={setDropdownValues}
              initialSelect={dropDownValues}
              handlePhotoChange={handlePhotoChange}
              file={file2}
            />
          )
        )}
        <Button
          className={classes.button}
          disabled={valueToggle.includes(Conditions.NEW) ? isDisabledSimple : isDisabledExtended}
          onClick={handleClick}
        >
          Verder
        </Button>
      </Toevoegen2MainWrapper>
    </Toevoegen2Container>
  );
};

export default Toevoegen2;
