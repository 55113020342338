import { useQuery } from '@tanstack/react-query';
import { getKenteken } from 'app/api/kenteken';
import { QueryKeys } from 'app/helpers/QueryKeys';
import { GeneralResponseType, ToastListType, AutoGegevensType } from 'app/helpers/types';
const useKenteken = (
  value: string,
  isEnabled: boolean,
  setEnabled: React.Dispatch<React.SetStateAction<boolean>>,
  setAutogegevens: React.Dispatch<React.SetStateAction<AutoGegevensType>>,
  toastId: number,
  setToastId: React.Dispatch<React.SetStateAction<number>>,
  toastList: Array<ToastListType>,
  setToastList: React.Dispatch<React.SetStateAction<Array<ToastListType>>>
) => {
  const query = useQuery([QueryKeys.Kenteken, value], () => getKenteken(value), {
    onError: (data: GeneralResponseType) => {
      setEnabled(false);
      const toast = {
        id: toastId,
        type: 'error',
        message: data.message,
        additionalMessage: data?.response?.statusText,
      };
      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    },

    onSuccess: (data) => {
      if (setAutogegevens) {
        setAutogegevens(data);
      }
      setEnabled(false);
    },

    enabled: isEnabled,
    refetchOnWindowFocus: 'always',
    retry: false,
    staleTime: 300000,
  });
  return {
    ...query,
    order: query.data,
  };
};

export default useKenteken;
