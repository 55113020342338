import React from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from '../../../scss/_variables.scss';
import {
  AccountOrderRowWrapper,
  DateAndStatusWrapper,
  DateWrapper,
  StatusWrapper,
  AccountBottomRowWrapper,
  OrderNumberAndTotalWrapper,
  OrderNummerTextWrapper,
  OrderNummerValueWrapper,
  OrderNummerContainer,
  TotalContainer,
  TotalTextWrapper,
  TotalValueWrapper,
  DetailsContainer,
  DetailsActualContent,
} from './AccountCardRow.styles';
import { dateConverter } from 'app/helpers/utils';

const AccountCardRow = ({ item, index, length }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const shouldRenderBottomBorder = index < length - 1;

  const shippingStatusStyler = (status) => {
    switch (status) {
      case 'Bezorgd':
        return 'success-font';
      case 'Onderweg':
        return 'active-font';
      case 'Afgehaald':
        return 'active-font';
      case 'Verwerken':
        return 'warning-font';
      case 'Geannuleerd':
        return 'error-font';
      case 'Niet beschikbaar':
        return 'error-font';
      default:
        return '';
    }
  };

  const properDateFormat = dateConverter(item?.payment.timestamp);

  const handleOnDetailsClick = () => {
    if (location.pathname.includes('bestellingen')) {
      navigate(`${location.pathname}/order-details/${item?.order.id}`);
    } else if (location.pathname.includes('mijn-aankopen')) {
      navigate(`${location.pathname}/purchase-details/${item?.order.id}`);
    }
  };

  return (
    <AccountOrderRowWrapper borderBottom={shouldRenderBottomBorder && '0.5px solid #e6e6e6'}>
      <DateAndStatusWrapper>
        <DateWrapper>{properDateFormat}</DateWrapper>
        <StatusWrapper className={shippingStatusStyler(item.shipment_status)}>{item.shipment_status}</StatusWrapper>
      </DateAndStatusWrapper>
      <AccountBottomRowWrapper>
        <OrderNumberAndTotalWrapper>
          <OrderNummerContainer>
            <OrderNummerTextWrapper color={styles.greyNormal}>Ordernummer:</OrderNummerTextWrapper>
            <OrderNummerValueWrapper>{item?.order.id}</OrderNummerValueWrapper>
          </OrderNummerContainer>
          <TotalContainer>
            <TotalTextWrapper>Totaal:</TotalTextWrapper>
            <TotalValueWrapper>&euro; {`${item?.order?.price_value}`.replace('.', ',')}</TotalValueWrapper>
          </TotalContainer>
        </OrderNumberAndTotalWrapper>
        <DetailsContainer>
          <DetailsActualContent onClick={handleOnDetailsClick}>
            Details <ChevronRightIcon />
          </DetailsActualContent>
        </DetailsContainer>
      </AccountBottomRowWrapper>
    </AccountOrderRowWrapper>
  );
};

export default AccountCardRow;
