import { forwardRef } from 'react';
import RadioGroup, { RadioGroupProps } from '../RadioGroup';
import useClassNames from 'app/helpers/useClassNames';
import './TogglePanelGroup.scss';

export type TogglePanelGroupSize = 'small' | 'medium' | 'large';

export interface TogglePanelGroupProps extends Omit<RadioGroupProps, 'column' | 'nowrap'> {
  size?: TogglePanelGroupSize;
  label?: string;
  autoWidth?: boolean;
}

const TogglePanelGroup = forwardRef<HTMLDivElement, TogglePanelGroupProps>((props: TogglePanelGroupProps, ref) => {
  const { className, size = 'medium', label, autoWidth, ...rest } = props;
  const { merge, prefix, withClassPrefix } = useClassNames('toggle-panel-group');
  const classes = merge(className, withClassPrefix(size, autoWidth && 'auto-width'));

  return (
    <div className={classes}>
      {label && <label className={prefix('label')}>{label}</label>}
      <RadioGroup {...rest} ref={ref} nowrap />
    </div>
  );
});

TogglePanelGroup.displayName = 'TogglePanelGroup';

export default TogglePanelGroup;
