import React from 'react';
import styles from '../../../../scss/_variables.scss';
import {
  SamenvattingGeneralWrapper,
  SamenvattingTitleWrapper,
  SamenvattingDetailsContainer,
  SamenvattingDetailsTextWrapper,
  SamenvattingDetailsValueWrapper,
  SamenvattingSingleBorderWrapper,
  SamenvattingTotalContainer,
  SamenvattingTotalTextWrapper,
  SamenvattingTotalValueWrapper,
} from './Samenvatting.styles';
const Samenvatting = ({ tyreBrand, season, price }) => (
  <SamenvattingGeneralWrapper background={styles.white}>
    <SamenvattingTitleWrapper>Samenvatting</SamenvattingTitleWrapper>
    <SamenvattingDetailsContainer>
      <SamenvattingDetailsTextWrapper>{`${tyreBrand} ${season}`}</SamenvattingDetailsTextWrapper>
      <SamenvattingDetailsValueWrapper>&euro; {price}</SamenvattingDetailsValueWrapper>
    </SamenvattingDetailsContainer>
    <SamenvattingDetailsContainer>
      <SamenvattingDetailsTextWrapper>Levering</SamenvattingDetailsTextWrapper>
      <SamenvattingDetailsValueWrapper>Inbegrepen</SamenvattingDetailsValueWrapper>
    </SamenvattingDetailsContainer>
    <SamenvattingSingleBorderWrapper />
    <SamenvattingTotalContainer>
      <SamenvattingTotalTextWrapper>Totaal:</SamenvattingTotalTextWrapper>
      <SamenvattingTotalValueWrapper>&euro; {price}</SamenvattingTotalValueWrapper>
    </SamenvattingTotalContainer>
  </SamenvattingGeneralWrapper>
);

export default Samenvatting;
