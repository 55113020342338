import { StandardProps } from '../../../helpers/types';
import { useFormControl } from '../FormControl';
import useClassNames from 'app/helpers/useClassNames';
import './FormInputWrapper.scss';

export type FormInputWrapperProps = StandardProps;

const FormInputWrapper = (props: FormInputWrapperProps) => {
  const { children, className, style } = props;
  const { shrink } = useFormControl();

  const { merge, withClassPrefix } = useClassNames('form-input-wrapper');
  const classes = merge(className, withClassPrefix({ shrink }));

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
};

export default FormInputWrapper;
