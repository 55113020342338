import { forwardRef } from 'react';
import { StandardProps } from '../../../helpers/types';
import useClassNames from 'app/helpers/useClassNames';
import './Divider.scss';

export type DividerColor = 'primary' | 'semi-light-gray';

export interface DividerProps extends StandardProps {
  /**
   * If divider is vertical
   */
  vertical?: boolean;
  /**
   * Thickness of divider
   */
  appearance?: 'normal' | 'bold';
  /**
   * Color Variant
   */
  color?: DividerColor;
}

/**
 * A example of a Divider
 */
const Divider = forwardRef<HTMLHRElement, DividerProps>((props: DividerProps, ref) => {
  const { children, className, color = 'semi-light-gray', appearance = 'normal', vertical, style } = props;
  const { withClassPrefix, merge } = useClassNames('divider');
  const classes = merge(className, withClassPrefix(color, vertical ? 'vertical' : 'horizontal', appearance));

  return (
    <hr ref={ref} style={style} className={classes} aria-orientation={vertical ? 'vertical' : 'horizontal'}>
      {children}
    </hr>
  );
});

Divider.displayName = 'Divider';

export default Divider;
