import { useMutation } from '@tanstack/react-query';

import { inventoryToSaleProduct } from 'app/api/inventory-to-sale';

type StatusInventoryToSalePropsType = {
  tyre_set_id: string;
  price: number | string;
};

const useInventoryToSale = () => {
  const inventoryToSaleMutation = useMutation(inventoryToSaleProduct);

  const onClickInventoryToSale = (data: StatusInventoryToSalePropsType) => {
    inventoryToSaleMutation.mutate(data);
  };

  const onClearInventoryToSaleErrorMessage = () => inventoryToSaleMutation.reset();

  const {
    isLoading: isInventoryToSaleLoading,
    isError: isInventoryToSaleError,
    error: inventoryToSaleError,
    data: inventoryToSaleData,
    isSuccess: isInventoryToSaleSuccess,
  } = inventoryToSaleMutation;

  return {
    isInventoryToSaleLoading,
    isInventoryToSaleError,
    inventoryToSaleError,
    inventoryToSaleData,
    isInventoryToSaleSuccess,
    inventoryToSaleMutation,
    onClickInventoryToSale,
    onClearInventoryToSaleErrorMessage,
  };
};

export default useInventoryToSale;
