import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import { Conditions } from './form/checkbox-list';

interface Props {
  label: string;
  color?: string;
  backgroundColor?: string;
  filterSearchValues: Array<string>;
  setFilterSearchValues: React.Dispatch<React.SetStateAction<Array<string>>>;
  seasonValue: Array<string>;
  setSeasonValue: React.Dispatch<React.SetStateAction<Array<string>>>;
  min: React.MutableRefObject<never>;
  max: React.MutableRefObject<never>;
  priceRangeValues: Array<number>;
  setPriceRangeValues: React.Dispatch<React.SetStateAction<Array<number>>>;
}

const FilterChip = (props: Props) => {
  const handleDelete = () => {
    if (props.label === 'Alle filters wissen') {
      props.setFilterSearchValues([]);
      props.setSeasonValue([]);

      if (props.priceRangeValues[0] !== props.min.current || props.priceRangeValues[1] !== props.max.current) {
        props.setPriceRangeValues([props.min.current, props.max.current]);
      }
    } else {
      props.setFilterSearchValues((prevState) => prevState.filter((item: string) => item !== props.label));
      props.setSeasonValue((prevState) => prevState.filter((item: string) => item !== props.label));
      if (
        (props.filterSearchValues.some((item) => item.includes('€')) &&
          props.priceRangeValues[0] !== props.min.current) ||
        props.priceRangeValues[1] !== props.max.current
      ) {
        props.setPriceRangeValues([props.min.current, props.max.current]);
      }
    }
  };

  const label =
    props.label === Conditions.USED
      ? 'Gebruikt'
      : props.label === Conditions.NEW
      ? 'Nieuw'
      : props.label === Conditions.GOOD
      ? 'Zo goed als nieuw'
      : props.label;

  return (
    <Chip
      className="filter-chip"
      label={label}
      size="small"
      variant="filled"
      sx={{
        color: props.color,
        background: `${props.backgroundColor} !important`,
      }}
      onDelete={handleDelete}
      deleteIcon={<CloseIcon style={{ color: props.color }} />}
    />
  );
};

export default FilterChip;
