import { useState } from 'react';
import { useSelector } from 'react-redux';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import styles from '../../../../../scss/_variables.scss';
import {
  BestellingenGeneralContainer,
  BestellingenWrapper,
  BestellingenContentWrapper,
  BestellingenLoaderPlaceholder,
  BestellingenContentTopFader,
  BestellingenContentBottomFader,
  BestellingenTogglerWrapper,
  BestellingenActueelWrapper,
  BestellingenHistorieWrapper,
  BestellingenNoResultsPlaceholder,
  BestellingenNoResultsIconContainer,
  BestellingenNoResultsIcon,
  BestellingenNoResultsText,
} from './Bestellingen.styles';
import { breakpoints } from 'app/config/breakpoints';
import useWindowSize from 'app/hooks/useWindowSize';
import MyAccountLayout from 'app/components/MijnAccountComponents/MijnAccoundDesktop/MyAccountLayout';

import AccountCardRow from 'app/components/MijnAccountComponents/AccountCardRow';
import Toast from 'app/components/Toast/Toast';
import Loader from 'app/components/Loader/Loader';

const Bestellingen = () => {
  const isLoading = useSelector((state) => state.bestellingen.isLoading);
  const isError = useSelector((state) => state.bestellingen.isError);
  const error = useSelector((state) => state.bestellingen.error);
  const bestellingenActuelData = useSelector((state) => state.bestellingen.actueelBestellingen);
  const bestellingenHistorieData = useSelector((state) => state.bestellingen.historieBestellingen);

  const [bestellingenActuel, setBestellingenActuel] = useState(true);

  const { width } = useWindowSize();
  const isLargeScreen = width && width >= breakpoints.lg;

  const handlebestellingenClick = (event) => {
    const value = event.target.innerText;

    // eslint-disable-next-line chai-friendly/no-unused-expressions
    value === 'Actueel' && !bestellingenActuel
      ? setBestellingenActuel(!bestellingenActuel)
      : value === 'Historie' && bestellingenActuel && setBestellingenActuel(!bestellingenActuel);
  };

  const BestellingenNoContent = (
    <BestellingenNoResultsPlaceholder>
      <BestellingenNoResultsIconContainer background={styles.primaryColorContact}>
        <BestellingenNoResultsIcon color={styles.primaryColor}>
          <LocalShippingIcon fontSize="inherit" />
        </BestellingenNoResultsIcon>
      </BestellingenNoResultsIconContainer>
      <BestellingenNoResultsText>Nog geen bestellingen</BestellingenNoResultsText>
    </BestellingenNoResultsPlaceholder>
  );

  const BestellingenContent = (
    <BestellingenGeneralContainer>
      <BestellingenWrapper>
        <BestellingenContentTopFader />
        <BestellingenTogglerWrapper>
          <BestellingenActueelWrapper
            backgroundColor={`${bestellingenActuel && styles.white}`}
            onClick={handlebestellingenClick}
          >
            Actueel
          </BestellingenActueelWrapper>
          <BestellingenHistorieWrapper
            backgroundColor={`${!bestellingenActuel && styles.white}`}
            onClick={handlebestellingenClick}
          >
            Historie
          </BestellingenHistorieWrapper>
        </BestellingenTogglerWrapper>

        {bestellingenActuel ? (
          <BestellingenContentWrapper
            className="customScrollbar"
            padding={bestellingenActuelData?.length > 4 && '20px'}
          >
            {isLoading && (
              <BestellingenLoaderPlaceholder>
                <Loader />
              </BestellingenLoaderPlaceholder>
            )}

            {isError && (
              <Toast
                type="error"
                message={error?.message}
                additionalMessage={error?.response?.data?.detail}
                standalone
                standaloneAutoRemove
              />
            )}

            {bestellingenActuel &&
              !isLoading &&
              !isError &&
              bestellingenActuelData?.length === 0 &&
              BestellingenNoContent}

            {bestellingenActuelData?.length > 0 &&
              !isError &&
              bestellingenActuelData.map((item, index) => (
                <AccountCardRow
                  key={item?.order.id}
                  item={item}
                  index={index}
                  length={bestellingenActuelData?.length}
                />
              ))}
          </BestellingenContentWrapper>
        ) : (
          <BestellingenContentWrapper
            className="customScrollbar"
            padding={bestellingenHistorieData?.length > 4 && '20px'}
          >
            {isError && (
              <Toast
                type="error"
                message={error?.message}
                additionalMessage={error?.response?.data?.detail}
                standalone
                standaloneAutoRemove
              />
            )}

            {!bestellingenActuel &&
              !isLoading &&
              !isError &&
              bestellingenHistorieData?.length === 0 &&
              !isError &&
              BestellingenNoContent}
            {bestellingenHistorieData?.length > 0 &&
              bestellingenHistorieData.map((item, index) => (
                <AccountCardRow
                  key={item?.order.id}
                  item={item}
                  index={index}
                  length={bestellingenHistorieData?.length}
                />
              ))}
          </BestellingenContentWrapper>
        )}
        <BestellingenContentBottomFader />
      </BestellingenWrapper>
    </BestellingenGeneralContainer>
  );

  return isLargeScreen ? (
    <MyAccountLayout isLargeScreen={isLargeScreen}>{BestellingenContent}</MyAccountLayout>
  ) : (
    BestellingenContent
  );
};

export default Bestellingen;
