import { createContext } from 'react';
import { FormControlBaseProps } from '../../../helpers/types';
import type { CheckboxValueType } from '../CheckboxBase';

export interface CheckboxGroupContextValue<V = CheckboxValueType[]> extends FormControlBaseProps<V> {
  isCheckboxGroup: boolean;
}

const CheckboxGroupContext = createContext<CheckboxGroupContextValue>({ isCheckboxGroup: false, value: [] });

export default CheckboxGroupContext;
