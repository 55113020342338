import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const BevestigingWrapper = styled.div`
  width: 52%;
  max-height: 400px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
  padding: 40px 55px 35px 55px;
  display: flex;
  justify-content: center;
  min-width: 800px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    width: 100%;
    min-width: auto;
    max-width: 625px;
    max-height: 100%;
    margin: 20px 11px;
    padding: 20px;
    min-height: 100%;
  }
`;

export const BevestingenContentWrapper = styled.div`
  width: 700px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    width: 100%;
  }
`;

export const BevestingenTitleWrapper = styled.div`
  text-align: center;
  margin-top: 5px;
  color: ${(props) => props.color};
  font-size: 48px;
  font-weight: 800;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    margin-top: 35px;
    font-size: 33px;
  }
`;

export const BevestingenInfoWrapper = styled.div`
  text-align: center;
  font-size: 16px;

  width: 80%;
  margin: 20px auto 0px auto;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    width: 100%;
  }
`;

export const BevestingenProgressWrapper = styled.div`
  margin: 40px auto 0px auto;
  width: 77%;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    width: 100%;
    max-width: 480px;
  }
`;

export const BevestingenButtonArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const BevestingenButtonContainer = styled.div`
  display: flex;
  padding: 5px;
  gap: 15px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    flex-direction: column;
  }
`;

export const BevestingenButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 8px;
  color: ${(props) => props.color};
  margin-top: 20px;
  transition: 0.2s;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  width: 50%;
  padding-top: 8px;
  padding-bottom: 8px;

  &:hover {
    background-color: ${(props) => props.backgroundColorHover};
    color: ${(props) => props.colorHover};
  }

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    margin-top: 0px;
    width: 100%;
  }
`;
