import { ChangeEventHandler, createContext, InputHTMLAttributes } from 'react';
import { RadioValueType } from '../RadioBase';

export interface FormControlBaseProps<ValueType = InputHTMLAttributes<HTMLInputElement>['value']> {
  name?: string;
  value?: ValueType;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  readOnly?: boolean;
}

export interface RadioGroupContextValue<V = RadioValueType> extends FormControlBaseProps<V> {
  isRadioGroup: boolean;
}

const RadioGroupContext = createContext<RadioGroupContextValue>({ isRadioGroup: false });

export default RadioGroupContext;
