import { useRef, useState, MouseEvent, useCallback, useMemo, ChangeEvent, FocusEvent, UIEvent } from 'react';
import { useFormControl } from '../FormControl';
import FormInput from '../FormInput';
import FormInputAdornment from '../FormInputAdornment';
import FormInputBorder from '../FormInputBorder';
import FormInputLabelWrapper from '../FormInputLabelWrapper';
import { BaseFormInputTextProps } from '../../../helpers/types';
import FormInputWrapper from '../FormInputWrapper';
import FormSelectDropdown, { FormSelectOptionEventHandler, FormSelectOptionType } from '../FormSelectDropdown';
import IconPijlOmhoog from '../../../../assets/svg/IconPijlBeneden';
import useKeyDownSelect from '../../../helpers/useKeyDownSelect';
import useClassNames from 'app/helpers/useClassNames';
import './FormSelect.scss';

export type FormSelectChangeHandler = FormSelectOptionEventHandler<FormSelectOptionType | undefined>;
export type FormSelectDropdownOptionEventHandler = FormSelectOptionEventHandler<FormSelectOptionType | undefined>;

export interface FormSelectProps extends Omit<BaseFormInputTextProps, 'onChange' | 'value'> {
  /**
   * Current selected item
   */
  value?: FormSelectOptionType;
  /**
   * The Items in the dropdown (Label: string, value: string | number)
   */
  options: FormSelectOptionType[];
  onChange?: FormSelectChangeHandler;
  disabled?: boolean;
}

const FormSelect = (props: FormSelectProps) => {
  const {
    className,
    label,
    options,
    onChange,
    value,
    tabIndex = 0,
    autoComplete = 'off',
    disabled: disabledProp,
    ...rest
  } = props;

  const [showDropdown, setShowDropdown] = useState(false);

  const { withClassPrefix, merge } = useClassNames('form-select');
  const classes = merge(className, withClassPrefix({ 'dropdown-open': showDropdown, label, disabled: disabledProp }));

  const [searchValue, setSearchValue] = useState<string>('');
  const searchInputRef = useRef<HTMLInputElement>(null);
  const borderRef = useRef<HTMLDivElement>(null);
  const [inputFocus, setInputFocus] = useState(false);
  const [focusedOption, setFocusedOption] = useState<FormSelectOptionType>();
  const { disabled: disabledControl, setForceShrink } = useFormControl();

  const disabled = disabledProp || disabledControl;

  const handleInputFocus = useCallback(
    (event: FocusEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setInputFocus(true);
      setSearchValue('');
      setForceShrink?.(true);
    },
    [setForceShrink]
  );

  const handleInputBlur = useCallback(() => {
    setInputFocus(false);
  }, []);

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setShowDropdown(true);
    setSearchValue(event.target.value);
  }, []);

  const handleBorderClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      if (disabled) {
        return;
      }
      // toggle dropdown open/close
      setShowDropdown((currentValue) => {
        const nextValue = !currentValue;
        if (nextValue) {
          setFocusedOption(value || options[0]);
        } else {
          setFocusedOption(undefined);
        }
        return nextValue;
      });
      searchInputRef.current?.focus();
    },
    [disabled, options, value]
  );

  const handleBorderBlur = useCallback(
    (event: FocusEvent<HTMLDivElement>) => {
      if (disabled) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      // close dropdown on outside click
      if (!borderRef.current?.contains(event.relatedTarget as Node)) {
        setShowDropdown(false);
        setForceShrink?.(false);
      }
    },
    [disabled, setForceShrink]
  );

  const handleItemClick = useCallback(
    (event: UIEvent, option: FormSelectOptionType) => {
      event.preventDefault();
      event.stopPropagation();
      onChange?.(event, option);
      setShowDropdown(false);
      searchInputRef.current?.focus();
    },
    [onChange]
  );

  const handleItemClickDelete = useCallback(
    (event: UIEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onChange?.(event, undefined);
      setSearchValue('');
      searchInputRef.current?.focus();
    },
    [onChange]
  );

  const handleOptionFocus = useCallback((_: UIEvent, option: FormSelectOptionType) => {
    setFocusedOption(option);
  }, []);

  const filteredOptions = useMemo(
    () =>
      !searchValue
        ? options
        : options?.filter((option) => option.label.toLowerCase().includes(searchValue.toLowerCase())),
    [options, searchValue]
  );

  const hasSearchValue = Boolean(searchValue);

  const handleKeyDown = useKeyDownSelect(
    showDropdown,
    setShowDropdown,
    options,
    setFocusedOption,
    handleItemClickDelete,
    handleItemClick,
    focusedOption,
    searchInputRef,
    hasSearchValue
  );

  const selectedOptions = useMemo(() => (value ? [value] : value), [value]);

  return (
    <FormInputBorder
      className={classes}
      onClick={handleBorderClick}
      onBlur={handleBorderBlur}
      ref={borderRef}
      tabIndex={-1}
      onKeyDown={handleKeyDown}
    >
      <FormInputLabelWrapper>
        {label}
        <FormInputWrapper>
          <FormInput
            {...rest}
            tabIndex={tabIndex}
            ref={searchInputRef}
            value={inputFocus ? searchValue : value?.label || ''}
            onFocus={handleInputFocus}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            placeholder={value && `${value?.label}`}
            autoComplete={autoComplete}
            disabled={disabled}
          />
        </FormInputWrapper>
      </FormInputLabelWrapper>
      <FormSelectDropdown
        referenceRef={borderRef}
        options={filteredOptions}
        selectedOptions={selectedOptions}
        focussedOption={focusedOption}
        onClick={handleItemClick}
        onFocusOption={handleOptionFocus}
        show={!disabled && showDropdown}
      />
      <FormInputAdornment position="end">
        <IconPijlOmhoog />
      </FormInputAdornment>
    </FormInputBorder>
  );
};

export default FormSelect;
