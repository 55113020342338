import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: ${(props) => (props.border ? props.border : '10px')};
`;

export const Arrow = styled.div`
  display: ${(props) => props.display};
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: ${(props) => props.direction === 'left' && '20px'};
  right: ${(props) => props.direction === 'right' && '10px'};
  cursor: pointer;
  opacity: 1;
  z-index: 2;
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  transition: all 0.3s ease;
  transform: translateX(${(props) => props.slideIndex * -props.width}px);
`;

export const Slide = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const ImgContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const Image = styled.img`
  height: 100%;
  width: ${(props) => props.width}px;
  object-fit: cover;
  cursor: pointer;
`;
