import { useQuery } from '@tanstack/react-query';
import { getPurchases } from 'app/api/aankoop';
import { QueryKeys } from 'app/helpers/QueryKeys';

const useAankopen = () => {
  const query = useQuery([QueryKeys.Aankopen], getPurchases, {
    enabled: true,
    refetchOnWindowFocus: 'always',
    retry: false,
  });

  return {
    ...query,
    purchases: query.data,
  };
};

export default useAankopen;
