import { useQuery } from '@tanstack/react-query';

import { getLabels } from 'app/api/labels';

import { QueryKeys } from 'app/helpers/QueryKeys';

const useTyreLabels = (orderId: number) => {
  const query = useQuery([QueryKeys.Labels], () => getLabels(orderId), {
    enabled: true,
    retry: false,
  });

  return {
    ...query,
    orders: query.data,
  };
};

export default useTyreLabels;
