import React, { useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import {
  ToastMainContainer,
  ToastWrapper,
  ToastLeftWrapper,
  ToastLeftIconWrapper,
  ToastMiddleWrapper,
  ToastMiddleTitleWrapper,
  ToastMiddleMessageWrapper,
  ToastMiddleAdditionalMessageWrapper,
  ToastRightWrapper,
  ToastRightCloseIconWrapper,
} from './Toast.styles';

type ToastPropsType = {
  type: string;
  icon?: React.ReactNode;
  title?: string;
  message?: string;
  additionalMessage?: string;
  onClose?: () => void;
  id?: number;
  deleteToast?: (id: number | undefined) => void;
  standalone?: boolean;
  standaloneAutoRemove?: boolean;
};

const Toast: React.FC<ToastPropsType> = ({
  type,
  icon,
  title,
  message,
  additionalMessage,
  onClose,
  id,
  deleteToast,
  standalone,
  standaloneAutoRemove,
}) => {
  const [toastState, setToastState] = useState(true);

  if (standalone && standaloneAutoRemove) {
    setTimeout(() => {
      handleOnCloseClick();
    }, 5000);
  }

  const handleIconSelector = () => {
    switch (type) {
      case 'error':
        return (
          <ToastLeftIconWrapper color="#ff3d00">
            <ErrorIcon />
          </ToastLeftIconWrapper>
        );
      case 'warning':
        return (
          <ToastLeftIconWrapper color="#ffc400">
            <WarningIcon />
          </ToastLeftIconWrapper>
        );
      case 'success':
        return (
          <ToastLeftIconWrapper color="#75c42e">
            <CheckCircleIcon />
          </ToastLeftIconWrapper>
        );
      default:
        return icon;
    }
  };

  const handleTitleSelector = () => {
    switch (type) {
      case 'error':
        return 'Error';
      case 'warning':
        return 'Warning';
      case 'success':
        return 'Success';
      default:
        return title;
    }
  };

  const handleOnCloseClick = () => {
    if (onClose) {
      onClose();
    }

    if (deleteToast) {
      deleteToast(id);
    }
    if (standalone) {
      setToastState(false);
    }
  };
  return (
    <ToastMainContainer standalone={standalone} display={toastState ? 'block' : 'none'}>
      <ToastWrapper>
        <ToastLeftWrapper>{handleIconSelector()}</ToastLeftWrapper>
        <ToastMiddleWrapper>
          <ToastMiddleTitleWrapper>{handleTitleSelector()}</ToastMiddleTitleWrapper>
          <ToastMiddleMessageWrapper>{message}</ToastMiddleMessageWrapper>
          <ToastMiddleAdditionalMessageWrapper>{additionalMessage}</ToastMiddleAdditionalMessageWrapper>
        </ToastMiddleWrapper>
        <ToastRightWrapper>
          <ToastRightCloseIconWrapper onClick={handleOnCloseClick}>
            <CloseIcon fontWeight="inherit" />
          </ToastRightCloseIconWrapper>
        </ToastRightWrapper>
      </ToastWrapper>
    </ToastMainContainer>
  );
};

export default Toast;
