import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const ErrorPageContainer = styled.div`
  position: relative;
`;

export const ErrorPageTopImageWrapper = styled.div`
  position: relative;
`;

export const ErrorPageTopImage = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  width: 27%;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    min-width: 130px;
  }
`;

export const ErrorPageContentWrapper = styled.div`
  padding-top: 175px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    height: 77vh;
    display: flex;
    align-items: center;
    padding-top: 0px;
  }
`;

export const ErrorPageContent = styled.div`
  width: 44%;
  margin-left: 15%;
  line-height: 1.35;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    margin-left: 20px;
    margin-right: 20px;
    width: 60%;
    min-width: 330px;
    max-width: 600px;
  }
`;

export const ErrorPageHeaderContentWrapper = styled.div`
  font-size: 56px;
  font-weight: 800;
  line-height: 1.1;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    font-size: 24px;
  }
`;

export const ErrorPageInfoContentWrapper = styled.div`
  margin-top: 20px;
  font-size: 24px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    font-size: 16px;
  }
`;

export const ErrorPageBackToHomepageContentWrapper = styled.div`
  margin-top: 18px;
  font-size: 16px;
  font-weight: 700;
  color: #136ce2;
  width: fit-content;
  cursor: pointer;
`;
