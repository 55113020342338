import './FullscreenSlider.scss';
import React, { useState, useMemo, forwardRef, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import RightArrowIcon from '@mui/icons-material/ArrowForwardIos';
import LeftArrowIcon from '@mui/icons-material/ArrowBackIos';
import useClassNames from '../../helpers/useClassNames';
import useWindowSize from '../../hooks/useWindowSize';
import Loader from '../Loader/Loader';
import { accessibleOnClick } from 'app/helpers/utils';

interface ImagePreviewProps {
  active?: boolean;
  url: string;
  onClick: () => void;
}

const ImagePreviewItem = forwardRef((props: ImagePreviewProps, ref) => {
  const { active, url, onClick } = props;
  const { withClassPrefix } = useClassNames('thumbnail');

  const classes = withClassPrefix(active && 'active');
  return (
    <div className={classes} ref={ref as React.LegacyRef<HTMLDivElement>} {...accessibleOnClick(onClick)}>
      <img src={url} alt="Thumbnail Preview" />
    </div>
  );
});

ImagePreviewItem.displayName = 'ImagePreviewItem';

export interface FullscreenSliderProps {
  onClose: () => void;
  slideIndex: number;
  items: {
    id: number;
    img: string;
  }[];
  isLoading?: boolean;
}

const FullscreenSlider = (props: FullscreenSliderProps) => {
  const { onClose, slideIndex, items, isLoading } = props;

  const { width } = useWindowSize();

  const isLargeScreen = width && width >= 1024;

  const [currentSlideIndex, setCurrentSlideIndex] = useState(slideIndex);

  const [slideDirection, setSlideDirection] = useState('');

  const totalItems = useMemo(() => items.length, [items]);

  const clearSlideDirection = useCallback(() => {
    setSlideDirection('');
  }, []);

  const handleLeftArrowClick = useCallback(() => {
    if (currentSlideIndex > 0) {
      setCurrentSlideIndex((prevValue) => prevValue - 1);
    } else {
      setCurrentSlideIndex(totalItems - 1);
    }
    setSlideDirection('left');
  }, [currentSlideIndex, totalItems]);

  const handleRightArrowClick = useCallback(() => {
    if (currentSlideIndex + 1 < totalItems) {
      setCurrentSlideIndex((prevValue) => prevValue + 1);
    } else {
      setCurrentSlideIndex(0);
    }
    setSlideDirection('right');
  }, [currentSlideIndex, totalItems]);

  const handleThumbnailClick = useCallback((index: number) => {
    setCurrentSlideIndex(index);
  }, []);

  const { withClassPrefix, prefix } = useClassNames('fullscreen-slider');

  const classes = withClassPrefix();

  return (
    <div className={classes}>
      {isLoading ? (
        <div className={prefix('loader-container')}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={prefix('header')}>
            <p>
              {currentSlideIndex + 1} / {totalItems}
            </p>
            <CloseIcon onClick={onClose} />
          </div>

          <div className={prefix('image-container')}>
            {isLargeScreen && totalItems > 1 && <LeftArrowIcon onClick={handleLeftArrowClick} />}
            <div
              className={`wrapper ${
                slideDirection === 'left' ? 'incoming-left' : slideDirection === 'right' ? 'incoming-right' : ''
              }`}
              onAnimationEnd={clearSlideDirection}
            >
              {items.map((item, index) => (
                <div
                  key={index}
                  className="img-content"
                  style={{
                    transform: `translateX(${(index - currentSlideIndex) * 100}%)`,
                  }}
                >
                  <img src={item.img} alt="Current Slide" />
                </div>
              ))}
            </div>
            {isLargeScreen && totalItems > 1 && <RightArrowIcon onClick={handleRightArrowClick} />}
          </div>
          {totalItems > 1 && (
            <>
              <div className={prefix('mobile-contols')}>
                <LeftArrowIcon onClick={handleLeftArrowClick} />
                <p>
                  {currentSlideIndex + 1} / {totalItems}
                </p>
                <RightArrowIcon onClick={handleRightArrowClick} />
              </div>
              <div className={prefix('preview')}>
                {items.map((item, index) => (
                  <ImagePreviewItem
                    key={index}
                    active={index === currentSlideIndex}
                    url={item.img}
                    onClick={() => {
                      handleThumbnailClick(index);
                    }}
                  />
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default FullscreenSlider;
