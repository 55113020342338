import { forwardRef } from 'react';
import SvgIcon, { SvgIconProps } from '../../app/components/bhComponents/SvgIcon';
import { ForwardedRef } from 'app/helpers/types';
const IconEditItem = forwardRef((props: SvgIconProps, ref: ForwardedRef<SVGSVGElement>) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    name="IconEditItem"
    ref={ref}
    {...props}
  >
    <path fill="none" d="M0 0h24v24H0Z" data-name="Path 1547" />
    <path
      d="M6 14.7V18h3.3l9.8-9.8-3.3-3.3Zm15.7-9.1a1.05 1.05 0 0 0 0-1.3l-2.1-2.1a1.05 1.05 0 0 0-1.3 0l-1.6 1.6L20 7.1c.1.1 1.7-1.5 1.7-1.5M16 22H4a2.006 2.006 0 0 1-2-2V8a2.006 2.006 0 0 1 2-2h7.8l-2 2H4v12h12v-5.8l2-2V20a2.006 2.006 0 0 1-2 2"
      data-name="Path 1548"
    />
  </SvgIcon>
));
IconEditItem.displayName = 'IconEditItem';
export default IconEditItem;
