import { useMutation } from '@tanstack/react-query';
import type { UseMutationResult } from '@tanstack/react-query';
import { addFavoriet, addFavorietPropsType } from 'app/api/favoriet';

type AddFavorietProps = {
  id: number | string;
};

const useAddFavoriet = (setFavoriteState: React.Dispatch<React.SetStateAction<boolean>>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const addFavorietMutation: UseMutationResult<any, any, addFavorietPropsType, unknown> = useMutation(addFavoriet, {
    onSuccess: () => {
      setFavoriteState(true);
    },
  });

  const onClickAddFavorieten = (data: AddFavorietProps) => addFavorietMutation.mutate(data);

  const onClearErrorMessage = () => addFavorietMutation.reset();

  const { isLoading, isError, error, data, isSuccess } = addFavorietMutation;

  return {
    addFavorietMutation,
    onClickAddFavorieten,
    onClearErrorMessage,
    isLoading,
    isError,
    error,
    isSuccess,
    data,
  };
};

export default useAddFavoriet;
