import { useQuery } from '@tanstack/react-query';
import { getPriceRange } from 'app/api/priceRange';
import { QueryKeys } from 'app/helpers/QueryKeys';

const usePriceRange = (
  setPriceRangeValues: React.Dispatch<React.SetStateAction<Array<number>>>,
  setSearchEnabled: React.Dispatch<React.SetStateAction<boolean>>,
  min: React.MutableRefObject<number>,
  max: React.MutableRefObject<number>
) => {
  const query = useQuery([QueryKeys.PriceRange], getPriceRange, {
    enabled: true,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 30000,
    onSuccess: (data) => {
      min.current = 0;
      max.current = data?.max;
      setPriceRangeValues([0, data?.max]);
      setSearchEnabled(true);
    },
  });

  return {
    ...query,
    priceRange: query.data,
  };
};

export default usePriceRange;
