import { forwardRef } from 'react';
import { StandardProps } from '../../../helpers/types';
import useClassNames from 'app/helpers/useClassNames';
import './Spinner.scss';

export type SpinnerSize = 'xs' | 'small' | 'medium' | 'large';
export type SpinnerColor = 'primary' | 'secondary' | 'neutral';

export interface SpinnerProps extends StandardProps {
  size?: SpinnerSize;
  color?: SpinnerColor;
  backdrop?: boolean;
}

const Spinner = forwardRef<HTMLDivElement, SpinnerProps>((props: SpinnerProps, ref) => {
  const { className, backdrop, size, color, style } = props;

  const { merge, withClassPrefix } = useClassNames('spinner');

  const classes = merge(
    className,
    withClassPrefix(size, color, {
      backdrop,
    })
  );

  return (
    <div role="progressbar" ref={ref} className={classes} style={style}>
      <svg viewBox="0 0 50 50">
        <circle cx="25" cy="25" r="20" fill="none" />
      </svg>
    </div>
  );
});

Spinner.displayName = 'Spinner';

export default Spinner;
