import { forwardRef } from 'react';
import SvgIcon, { SvgIconProps } from '../../app/components/bhComponents/SvgIcon';
import { ForwardedRef } from 'app/helpers/types';
const IconPijlBeneden = forwardRef((props: SvgIconProps, ref: ForwardedRef<SVGSVGElement>) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    name="IconPijlBeneden"
    ref={ref}
    {...props}
  >
    <path data-name="Path 1655" d="M0 0h24v24H0Z" fill="none" />
    <path data-name="Path 1656" d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6Z" />
  </SvgIcon>
));
IconPijlBeneden.displayName = 'IconPijlBeneden';
export default IconPijlBeneden;
