import { useQuery } from '@tanstack/react-query';
import { getBrands } from 'app/api/brands';
import { QueryKeys } from 'app/helpers/QueryKeys';

const useBrands = () => {
  const query = useQuery([QueryKeys.Brands], getBrands, {
    enabled: true,
    retry: false,
  });

  return {
    ...query,
    purchases: query.data,
  };
};

export default useBrands;
