import {
  OrderStatusBarWrapper,
  OrderStatusNamesWrapper,
  OrderStatusSkeletonTextWrapper,
  OrderStatusSkeletonTextExceptionWrapper,
  OrderBulletsSkeletonWrapper,
  OrderBulletsSkeletonContentWrapper,
  OrderProgressSkeletonWrapper,
  OrderProgressWrapper,
} from './OrderStatusBar.styles';
import { bulletStatus, orderProgressStatus } from 'app/helpers/deliveryStatus';
const OrderStatusBar = ({ status }) => (
  <OrderStatusBarWrapper>
    <OrderBulletsSkeletonWrapper>
      <OrderBulletsSkeletonContentWrapper color={bulletStatus(status, 1)} />
      <OrderBulletsSkeletonContentWrapper color={bulletStatus(status, 2)} />
      <OrderBulletsSkeletonContentWrapper color={bulletStatus(status, 3)} />
      <OrderProgressSkeletonWrapper />
      <OrderProgressWrapper width={orderProgressStatus(status)} />
    </OrderBulletsSkeletonWrapper>

    <OrderStatusNamesWrapper>
      <OrderStatusSkeletonTextWrapper>Besteld</OrderStatusSkeletonTextWrapper>
      <OrderStatusSkeletonTextExceptionWrapper>Onderweg</OrderStatusSkeletonTextExceptionWrapper>
      <OrderStatusSkeletonTextWrapper>Afgeleverd</OrderStatusSkeletonTextWrapper>
    </OrderStatusNamesWrapper>
  </OrderStatusBarWrapper>
);

export default OrderStatusBar;
