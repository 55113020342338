import { forwardRef, MouseEventHandler, ReactElement, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { ButtonColor, ButtonFlatSide } from '../Button';
import ButtonBase, { ButtonBaseProps } from '../ButtonBase';
import Spinner from '../Spinner';
import SvgIcon from '../SvgIcon';
import Tooltip, { TooltipProps } from '../Tooltip';
import useRefElementWithForwardedRef from '../../../helpers/useRefElementWithForwardedRef';
import useClassNames from 'app/helpers/useClassNames';
import './IconButton.scss';

export type GenericIconButtonProps = Omit<ButtonBaseProps, 'children'> & {
  /**
   * Color variants
   */
  color?: ButtonColor;
  /**
   * Removes border radius on 1 side (only works with small, medium and large)
   */
  flatSide?: ButtonFlatSide;
  /**
   * Adds icon to button
   */
  icon: ReactElement<typeof SvgIcon>;
  isLoading?: boolean;
  toolTipPlacement?: TooltipProps['placement'];
  offset?: number[];
};

export type NormalIconButtonProps = GenericIconButtonProps & {
  /**
   * Style variants
   */
  appearance?: 'filled' | 'hollow' | 'tinted';
  size?: 'large' | 'medium' | 'small' | 'xs' | 'xxs';
};

export type ClearIconButtonProps = GenericIconButtonProps & {
  appearance: 'clear';
  size?: 'large' | 'medium' | 'small' | 'xs';
};

export type GhostIconButtonProps = GenericIconButtonProps & {
  appearance: 'ghost';
  size?: 'large' | 'medium' | 'small' | 'custom' | 'custom-small';
};

export type IconButtonSize =
  | NormalIconButtonProps['size']
  | ClearIconButtonProps['size']
  | GhostIconButtonProps['size'];

export type IconButtonProps = NormalIconButtonProps | ClearIconButtonProps | GhostIconButtonProps;

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props: IconButtonProps, ref) => {
  const {
    appearance = 'filled',
    icon,
    className,
    disabled,
    isLoading,
    color,
    size,
    toolTipPlacement = 'bottom',
    flatSide,
    title,
    onMouseEnter,
    onMouseLeave,
    ...rest
  } = props;
  const [refElement, setRefElement] = useRefElementWithForwardedRef<HTMLButtonElement>(ref);
  const [show, setShow] = useState(false);
  const enterTimer = useRef<NodeJS.Timeout>();

  const offset = useMemo(
    () => [
      refElement?.offsetWidth && refElement?.offsetWidth < 25
        ? toolTipPlacement.includes('start') || toolTipPlacement === 'auto-end'
          ? -8
          : toolTipPlacement.includes('end')
          ? 8
          : 0
        : 0,
      12,
    ],
    [refElement?.offsetWidth, toolTipPlacement]
  );

  const { merge, withClassPrefix } = useClassNames('btn-icon');
  const classes = merge(
    className,
    withClassPrefix(
      appearance,
      size && `size-${size}`,
      color && `${appearance}-${color}`,
      flatSide && `flat-${flatSide}`,
      isLoading && 'is-loading'
    )
  );

  useEffect(
    () => () => {
      if (enterTimer.current) {
        clearTimeout(enterTimer.current);
      }
    },
    []
  );

  const handleMouseEnter: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      onMouseEnter?.(event);
      if (title && enterTimer.current) {
        clearTimeout(enterTimer.current);
        enterTimer.current = setTimeout(() => {
          setShow(true);
        }, 500);
      }
    },
    [onMouseEnter, title]
  );

  const handleMouseLeave: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      onMouseLeave?.(event);
      if (title && enterTimer.current) {
        clearTimeout(enterTimer.current);
        setShow(false);
      }
    },
    [onMouseLeave, title]
  );

  return (
    <>
      <ButtonBase
        disabled={isLoading || disabled}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...rest}
        ref={setRefElement}
        className={classes}
      >
        {isLoading && (
          <Spinner
            size={
              (appearance === 'ghost' && size === 'custom-small') ||
              appearance === 'clear' ||
              size === 'small' ||
              size === 'xxs' ||
              size === 'xs'
                ? 'xs'
                : 'small'
            }
          />
        )}
        {icon}
      </ButtonBase>
      {show && title && (
        <Tooltip offset={offset} placement={toolTipPlacement} referenceElement={refElement}>
          {title}
        </Tooltip>
      )}
    </>
  );
});

IconButton.displayName = 'IconButton';

export default IconButton;
