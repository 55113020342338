import { forwardRef, SVGAttributes } from 'react';
import useClassNames from 'app/helpers/useClassNames';
import './SvgIcon.scss';

export interface SvgIconProps extends SVGAttributes<SVGSVGElement> {
  transformRotate?: '90' | '180' | '270';
}

const SvgIcon = forwardRef<SVGSVGElement, SvgIconProps>((props: SvgIconProps, ref) => {
  const { children, className, transformRotate, ...rest } = props;
  const { withClassPrefix, merge } = useClassNames('svg-icon');
  const classes = merge(className, withClassPrefix(transformRotate && `rotate-${transformRotate}`));

  return (
    <svg {...rest} className={classes} ref={ref}>
      {children}
    </svg>
  );
});

SvgIcon.displayName = 'SvgIcon';

export default SvgIcon;
