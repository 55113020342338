import apiClient from 'app/axios/api-client';

type StatusInventoryToSalePropsType = {
  tyre_set_id: string;
  price: number | string;
};

export const inventoryToSaleProduct = async (data: StatusInventoryToSalePropsType) => {
  const response = await apiClient.post(`advertentie/${data.tyre_set_id}?price=${data.price}`);

  return response.data;
};
