import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const Toevoegen5MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Toevoegen5Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  width: 60%;
  min-height: 685px;
  height: 1060px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 10px;

  @media screen and (min-width: ${breakpoints.lg}px) {
    min-width: 930px;
  }

  @media screen and (min-width: ${breakpoints.xl}px) {
    min-width: 1050px;
  }
`;

export const Toevoegen5TitlePlusInfoContainer = styled.div`
  width: 100%;
  margin-top: 20px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    margin-top: 0px;
    padding: 12px 18px 0px 18px;
  }
`;
export const Toevoegen5TitleBevestigenWrapper = styled.div`
  font-weight: 700;
  font-size: 35px;
  color: #262626;
  margin-left: 40px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    margin-left: 0px;
    font-size: 20px;
  }
`;

export const Toevoegen5MoreInfoBevestigenWrapper = styled.div`
  font-size: 18px;
  margin-left: 40px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    margin-left: 0px;
    padding-bottom: 12px;
    font-size: 12px;
  }
`;

export const Toevoegen5TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 450px;
  width: 100%;
  margin-top: 7px;
`;

export const Toevoegen5UpLeftContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 42%;
  height: 90%;
  flex: 1;
  margin-left: 40px;
`;

export const Toevoegen5UpRightContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  margin-bottom: 30px;
  margin-top: 20px;
`;

export const Toevoegen5UpRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 80%;
`;

export const Toevoegen5UpRightTitle = styled.h3`
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 30px;
`;

export const Toevoegen5UpRightSubtitleWrapper = styled.div`
  display: flex;
  justify-content: start;
`;

export const Toevoegen5VoorraadTextContainer = styled.div`
  font-weight: bold;
  font-size: 34px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    font-size: 30px;
    padding-top: 7px;
    margin-bottom: -10px;
  }
`;

export const Toevoegen5UpRightSubtitleKey = styled.span`
  font-size: 18px;
  color: #666666;
`;

export const Toevoegen5UpRightSubtitleValue = styled.span`
  font-size: 18px;
  margin-left: 10px;
  font-weight: 700;
`;

export const Toevoegen5BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 345px;
  width: 100%;
  margin-bottom: 110px;
`;

export const Toevoegen5BottomLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  padding: 34px 40px;
  margin-bottom: 30.5px;
`;

export const Toevoegen5BottomTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 50%;
`;

export const Toevoegen5BottomLeftTitle = styled.span`
  font-size: 22px;
  color: #136ce2;
  margin-left: 10px;
  font-weight: bold;
`;

export const Toevoegen5BottomLeftWrapper = styled.div`
  height: 90%;
`;

export const Toevoegen5BottomDetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid #e4e4e4;
  margin-top: ${(props) => props.margin};

  :last-child {
    border-bottom: none;
  }
`;

export const Toevoegen5BottomDetailKey = styled.span`
  font-size: 18px;
  color: #666666;
  margin-bottom: 4px;
`;

export const Toevoegen5BottomDetailValue = styled.span`
  font-size: 18px;
  margin-bottom: 4px;
`;

export const Toevoegen5BottomRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1.1;
  margin-top: 52.5px;
  height: 100%;
  padding: 22px 55px;
`;

export const Toevoegen5ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    position: sticky;
    bottom: 15px;
  }
`;

export const Toevoegen5Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  color: #fff;
  border: none;
  background-color: ${(props) => props.backgroundColor};
  height: 45px;
  width: 75%;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 400;
  cursor: ${(props) => props.cursor};

  :focus {
    background-color: ${(props) => props.backgroundColor};
  }

  :hover {
    background-color: ${(props) => props.hover};
  }
`;

// --------------MOBILE-------------------- //

export const MobileToevoegen5Container = styled.div`
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 64px);
  width: 100%;
  padding: 11px;
`;

export const MobileToevoegen5Content = styled.div`
  background-color: white;
  border-radius: 10px;
  width: 100%;
  max-width: 625px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding-bottom: 20px;
  margin-bottom: 5px;
  margin-top: 3px;
`;

export const MobileToevoegen5 = styled.div`
  margin: 18px 18px 5px 18px;
  padding: 0px;
`;

export const MobileToevoegen5Title = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin: 0px;
`;

export const MobileToevoegen5ButtonWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  gap: 20px;
`;

export const MobileToevoegen5FavButton = styled.button`
  background-color: #e7f0fc;
  width: 20%;
  height: 40px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobileToevoegen5OrderButton = styled.button`
  background-color: #136ce2;
  color: white;
  font-size: 16px;
  font-weight: 300;
  width: 80%;
  height: 40px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
`;

export const MobileToevoegen5tModelWrapper = styled.div`
  background-color: #f1fae9;
  display: flex;
  align-items: center;
  margin-top: 30px;
  width: 100%;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  height: 40px;
  padding: 0px 10px;
`;

export const MobileToevoegen5Specifications = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #136ce2;
  font-weight: bold;
  margin-top: 26px;
`;

export const MobileToevoegen5DetailKey = styled.span`
  font-size: 16px;
  color: #666666;
  margin-bottom: 3px;
`;

export const MobileToevoegen5DetailValue = styled.span`
  font-size: 16px;
`;

export const MobileSliderWrapper = styled.div`
  height: 300px;
  padding: 0px 18px;
`;

export const Toevoegen5MobileButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  color: #fff;
  border: none;
  background-color: ${(props) => props.backgroundColor};
  height: 45px;
  width: 100%;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 400;
  cursor: ${(props) => props.cursor};
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;

  :focus {
    background-color: ${(props) => props.backgroundColor};
  }

  :hover {
    background-color: ${(props) => props.hover};
  }
`;
