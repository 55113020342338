import { useMutation } from '@tanstack/react-query';

import { saleToInventoryProduct } from 'app/api/sale-to-inventory';

type StatusSaleToInventoryPropsType = {
  id: number;
};

const useSaleToInventory = () => {
  const saleToInventoryMutation = useMutation(saleToInventoryProduct);

  const onClickSaleToInventory = (data: StatusSaleToInventoryPropsType) => {
    saleToInventoryMutation.mutate(data);
  };

  const onClearSaleToInventoryErrorMessage = () => saleToInventoryMutation.reset();

  const {
    isLoading: isSaleToInventoryLoading,
    isError: isSaleToInventoryError,
    error: saleToInventoryError,
    data: saleToInventoryData,
    isSuccess: isSaleToInventorySuccess,
  } = saleToInventoryMutation;

  return {
    isSaleToInventoryLoading,
    isSaleToInventoryError,
    saleToInventoryError,
    saleToInventoryData,
    isSaleToInventorySuccess,
    saleToInventoryMutation,
    onClickSaleToInventory,
    onClearSaleToInventoryErrorMessage,
  };
};

export default useSaleToInventory;
