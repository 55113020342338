import React from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PageNav from './PageNav';
import {
  PaginationContainer,
  PaginationWrapper,
  PaginationLeftIconWrapper,
  PaginationPagesWrapper,
  PaginationRightIconWrapper,
} from './Pagination.styles';

type PaginationPropsType = {
  pageStart: number;
  totalPages: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  isValid?: boolean;
};

const Pagination: React.FC<PaginationPropsType> = ({
  pageStart,
  totalPages,
  currentPage,
  setCurrentPage,
  isValid = true,
}) => {
  const handlePageDecrement = () => {
    if (currentPage - 1 >= pageStart && isValid) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageIncrement = () => {
    if (currentPage + 1 <= totalPages && isValid) {
      setCurrentPage(currentPage + 1);
    }
  };

  const onPageClick = (value: number) => {
    if (isValid) {
      setCurrentPage(value);
    }
  };

  if (totalPages <= 1) {
    return null;
  }
  return (
    <PaginationContainer>
      <PaginationWrapper>
        <PaginationLeftIconWrapper>
          <PageNav
            handlePageChange={handlePageDecrement}
            symbol={<ChevronLeftIcon fontSize="small" />}
            isActive
            cursor={currentPage > pageStart ? 'pointer' : 'default'}
          />
        </PaginationLeftIconWrapper>

        <PaginationPagesWrapper>
          <PageNav
            symbol={pageStart}
            handlePageChange={() => onPageClick(pageStart)}
            isActive={pageStart === currentPage}
            cursor="pointer"
          />

          {(currentPage - pageStart > 1 && currentPage - 1 === pageStart) ||
            (currentPage - 1 > pageStart && currentPage - 2 !== pageStart && (
              <PageNav paddingTop="10px" symbol={<MoreHorizIcon fontSize="small" />} />
            ))}

          {currentPage - 1 > pageStart && (
            <PageNav symbol={currentPage - 1} handlePageChange={() => onPageClick(currentPage - 1)} cursor="pointer" />
          )}

          {currentPage !== pageStart && currentPage !== totalPages && (
            <PageNav symbol={currentPage} isActive cursor="pointer" />
          )}

          {currentPage + 1 < totalPages && (
            <PageNav symbol={currentPage + 1} handlePageChange={() => onPageClick(currentPage + 1)} cursor="pointer" />
          )}

          {(totalPages - currentPage === 1 && currentPage + 1 !== totalPages) ||
            (currentPage + 1 < totalPages && currentPage + 2 !== totalPages && (
              <PageNav paddingTop="10px" symbol={<MoreHorizIcon fontSize="small" />} />
            ))}

          <PageNav
            symbol={totalPages}
            handlePageChange={() => onPageClick(totalPages)}
            isActive={totalPages === currentPage}
            cursor="pointer"
          />
        </PaginationPagesWrapper>

        <PaginationRightIconWrapper>
          <PageNav
            handlePageChange={handlePageIncrement}
            symbol={<ChevronRightIcon fontSize="small" />}
            isActive
            cursor={currentPage < totalPages ? 'pointer' : 'default'}
          />
        </PaginationRightIconWrapper>
      </PaginationWrapper>
    </PaginationContainer>
  );
};

export default Pagination;
