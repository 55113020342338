import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setPageTitle } from 'redux/ui-slice';
import useWindowSize from 'app/hooks/useWindowSize';
import { breakpoints } from 'app/config/breakpoints';

interface Props {
  children: React.ReactNode;
  title: string;
}

const Page = (props: Props) => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const isLargeScreen = width && width >= breakpoints.lg;
  useEffect(() => {
    dispatch(setPageTitle(props.title));
  });

  let className = 'page-container';
  className = isLargeScreen ? `${className} desktop-page-container` : className;
  return <div className={className}>{props.children}</div>;
};

export default Page;
