import { useRef, useState, MouseEvent, useCallback, useMemo, FocusEvent, UIEvent } from 'react';
import { useFormControl } from '../FormControl';
import FormInput from '../FormInput';
import FormInputAdornment from '../FormInputAdornment';
import FormInputBorder from '../FormInputBorder';
import FormInputLabelWrapper from '../FormInputLabelWrapper';
import { BaseFormInputTextProps } from '../FormInputText';
import FormInputWrapper from '../FormInputWrapper';
import FormSelectDropdown, { FormSelectOptionEventHandler, FormSelectOptionType } from '../FormSelectDropdown';
import IconPijlOmhoog from '../../../../assets/svg/IconPijlBeneden';
import useKeyDownSelect from '../../../helpers/useKeyDownSelect';
import useClassNames from 'app/helpers/useClassNames';
import './FormBasicSelect.scss';

export type FormBasicSelectChangeHandler = FormSelectOptionEventHandler<FormSelectOptionType | undefined>;

export interface FormBasicSelectProps extends Omit<BaseFormInputTextProps, 'onChange' | 'value'> {
  /**
   * Current selected item
   */
  value?: FormSelectOptionType;
  /**
   * The Items in the dropdown (Label: string, value: string | number)
   */
  options: FormSelectOptionType[];
  onChange?: FormBasicSelectChangeHandler;
}

const FormBasicSelect = (props: FormBasicSelectProps) => {
  const { className, label, options, onChange, value, tabIndex = 0, ...rest } = props;

  const [showDropdown, setShowDropdown] = useState(false);

  const { withClassPrefix, merge } = useClassNames('form-basic-select');
  const classes = merge(className, withClassPrefix({ 'dropdown-open': showDropdown, label }));

  const inputRef = useRef<HTMLInputElement>(null);
  const borderRef = useRef<HTMLDivElement>(null);
  const [focusedOption, setFocusedOption] = useState<FormSelectOptionType>();
  const { disabled, setForceShrink } = useFormControl();

  const handleInputFocus = useCallback(
    (event: FocusEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setForceShrink?.(true);
    },
    [setForceShrink]
  );

  const handleBorderClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      if (disabled) {
        return;
      }
      // toggle dropdown open/close
      setShowDropdown((currentValue) => {
        const nextValue = !currentValue;
        if (nextValue) {
          setFocusedOption(value || options[0]);
        } else {
          setFocusedOption(undefined);
        }
        return nextValue;
      });
      inputRef.current?.focus();
    },
    [disabled, options, value]
  );

  const handleBorderBlur = useCallback(
    (event: FocusEvent<HTMLDivElement>) => {
      if (disabled) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      // close dropdown on outside click
      if (!borderRef.current?.contains(event.relatedTarget as Node)) {
        setShowDropdown(false);
        setForceShrink?.(false);
      }
    },
    [disabled, setForceShrink]
  );

  const handleItemClick = useCallback(
    (event: UIEvent, option: FormSelectOptionType) => {
      event.preventDefault();
      event.stopPropagation();
      onChange?.(event, option);
      setShowDropdown(false);
      inputRef.current?.focus();
    },
    [onChange]
  );

  const handleItemClickDelete = useCallback(
    (event: UIEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onChange?.(event, undefined);
    },
    [onChange]
  );

  const handleOptionFocus = useCallback((_: UIEvent, option: FormSelectOptionType) => {
    setFocusedOption(option);
  }, []);

  const handleKeyDown = useKeyDownSelect(
    showDropdown,
    setShowDropdown,
    options,
    setFocusedOption,
    handleItemClickDelete,
    handleItemClick,
    focusedOption
  );

  const selectedOptions = useMemo(() => (value ? [value] : value), [value]);

  return (
    <FormInputBorder
      className={classes}
      onClick={handleBorderClick}
      onBlur={handleBorderBlur}
      ref={borderRef}
      tabIndex={-1}
      onKeyDown={handleKeyDown}
    >
      <FormInputLabelWrapper>
        {label}
        <FormInputWrapper>
          <FormInput
            {...rest}
            ref={inputRef}
            onFocus={handleInputFocus}
            tabIndex={tabIndex}
            value={value?.label || ''}
            placeholder={value && `${value?.label}`}
            readOnly
          />
        </FormInputWrapper>
      </FormInputLabelWrapper>
      <FormSelectDropdown
        referenceRef={borderRef}
        options={options}
        selectedOptions={selectedOptions}
        focussedOption={focusedOption}
        onClick={handleItemClick}
        onFocusOption={handleOptionFocus}
        show={!disabled && showDropdown}
      />
      <FormInputAdornment position="end">
        <IconPijlOmhoog />
      </FormInputAdornment>
    </FormInputBorder>
  );
};

export default FormBasicSelect;
