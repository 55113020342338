import { forwardRef } from 'react';
import SvgIcon, { SvgIconProps } from '../../app/components/bhComponents/SvgIcon';
import { ForwardedRef } from 'app/helpers/types';
const IconErrorCircle = forwardRef((props: SvgIconProps, ref: ForwardedRef<SVGSVGElement>) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    name="IconErrorCircle"
    ref={ref}
    {...props}
  >
    <path data-name="Path 1296" d="M0 0h24v24H0Z" fill="none" />
    <path data-name="Path 1297" d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2Zm1 15h-2v-2h2Zm0-4h-2V7h2Z" />
  </SvgIcon>
));
IconErrorCircle.displayName = 'IconErrorCircle';
export default IconErrorCircle;
