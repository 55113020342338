import { forwardRef } from 'react';
import SvgIcon, { SvgIconProps } from '../../app/components/bhComponents/SvgIcon';
import { ForwardedRef } from 'app/helpers/types';
const IconVinkjeCircle = forwardRef((props: SvgIconProps, ref: ForwardedRef<SVGSVGElement>) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    name="IconVinkjeCircle"
    ref={ref}
    {...props}
  >
    <path data-name="Path 1300" d="M0 0h24v24H0Z" fill="none" />
    <path
      data-name="Path 1301"
      d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2Zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8Z"
    />
  </SvgIcon>
));
IconVinkjeCircle.displayName = 'IconVinkjeCircle';
export default IconVinkjeCircle;
