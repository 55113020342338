const setSessionValue = (key: string, value: string): void => {
  if (typeof window !== 'undefined') {
    sessionStorage.setItem(key, value);
  }
};
const getSessionValue = (key: string): string | null => {
  if (typeof window !== 'undefined') {
    return sessionStorage.getItem(key);
  }

  return null;
};

const removeSessionValue = (key: string): void => {
  if (typeof window !== 'undefined') {
    sessionStorage.removeItem(key);
  }
};

const SESSION_AUTH_STATE = 'auth-state';
const SESSION_AUTH_TOKEN = 'auth-token';
const SESSION_AUTH_EXPIRES_TIMESTAMP = 'auth-expires';

export const setAuthState = (token: string) => setSessionValue(SESSION_AUTH_STATE, token);
export const getAuthState = () => getSessionValue(SESSION_AUTH_STATE);
export const removeAuthState = () => removeSessionValue(SESSION_AUTH_STATE);

export const setAuthAccessToken = (token: string) => setSessionValue(SESSION_AUTH_TOKEN, token);
export const getAuthAccessToken = () => getSessionValue(SESSION_AUTH_TOKEN);
export const removeAuthAccessToken = () => removeSessionValue(SESSION_AUTH_TOKEN);

export const setAuthExpireTimestamp = (expires: number) =>
  setSessionValue(SESSION_AUTH_EXPIRES_TIMESTAMP, expires.toString());
export const getAuthExpireTimestamp = () => {
  const value = getSessionValue(SESSION_AUTH_EXPIRES_TIMESTAMP);

  return value && parseInt(value);
};
