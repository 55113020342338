import { RefCallback, useCallback, useState } from 'react';
import { ForwardedRef } from 'app/helpers/types';

const useRefElementWithForwardedRef = <T = undefined>(ref?: ForwardedRef<T>): [T | null, RefCallback<T>] => {
  const [refElement, setRefElement] = useState<T | null>(null);

  const handleSetElement: RefCallback<T> = useCallback(
    (element: T) => {
      if (ref && element) {
        if (typeof ref === 'function') {
          ref(element);
        } else {
          ref.current = element;
        }
      }
      setRefElement(element);
    },
    [ref]
  );

  return [refElement, handleSetElement];
};

export default useRefElementWithForwardedRef;
