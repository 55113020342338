import { useQuery } from '@tanstack/react-query';
import { getOrder } from 'app/api/bestelling';
import { QueryKeys } from 'app/helpers/QueryKeys';

const useBestelling = (id: number) => {
  const query = useQuery([QueryKeys.Bestelling, id], () => getOrder(id), {
    enabled: true,
    refetchOnWindowFocus: 'always',
    retry: false,
  });

  return {
    ...query,
    order: query.data,
  };
};

export default useBestelling;
