import { ChangeEvent, InputHTMLAttributes, MouseEvent, Ref, forwardRef, useCallback, useContext, useMemo } from 'react';
import { FormControlBaseProps, StandardProps } from '../../../helpers/types';
import { CheckboxGroupContext } from '../CheckboxGroup';
import useClassNames from 'app/helpers/useClassNames';

export type CheckboxValueType = string | number;

const handleInputClick = (event: MouseEvent<HTMLInputElement>) => event.stopPropagation();

export interface CheckboxBaseProps<V = CheckboxValueType> extends StandardProps, FormControlBaseProps<V> {
  title?: string;
  indeterminate?: boolean;
  checked?: boolean;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  inputRef?: Ref<HTMLInputElement>;
  tabIndex?: number;
}

const CheckboxBase = forwardRef<HTMLDivElement, CheckboxBaseProps>((props: CheckboxBaseProps, ref) => {
  const {
    name: nameContext,
    disabled: disabledContext,
    readOnly: readOnlyContext,
    onChange: onGroupChange,
    value: contextValue,
    isCheckboxGroup,
  } = useContext(CheckboxGroupContext);

  const {
    checked: checkedProp,
    className,
    children,
    title,
    inputRef,
    inputProps,
    tabIndex = 0,
    disabled = disabledContext,
    readOnly = readOnlyContext,
    name = nameContext,
    indeterminate = false,
    value,
    onChange,
    style,
  } = props;

  const checked = useMemo(() => {
    if (!isCheckboxGroup) {
      return checkedProp;
    }

    return Boolean(contextValue?.some((checkedValue) => checkedValue === value));
  }, [checkedProp, contextValue, isCheckboxGroup, value]);

  const { merge, prefix, withClassPrefix } = useClassNames('checkbox');
  const { withClassPrefix: withClassPrefixLabel } = useClassNames('checkbox-label');
  const { withClassPrefix: withClassPrefixInner } = useClassNames('checkbox-inner');
  const classes = merge(className || withClassPrefix(), prefix({ checked }));
  const classesLabel = withClassPrefixLabel({ indeterminate, disabled });
  const classesInner = withClassPrefixInner({
    disabled,
    checked: checked && !indeterminate,
    indeterminate: checked && indeterminate,
  });
  const { classWrapper, classInput } = useMemo(
    () => ({
      classWrapper: prefix('wrapper'),
      classInput: prefix('input'),
    }),
    [prefix]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (disabled || readOnly) {
        return;
      }

      if (isCheckboxGroup) {
        onGroupChange?.(event);
      } else {
        onChange?.(event);
      }
    },
    [disabled, readOnly, isCheckboxGroup, onGroupChange, onChange]
  );

  return (
    <div ref={ref} className={classes} style={style}>
      <label className={classesLabel} title={title}>
        <span className={classWrapper} aria-disabled={disabled} aria-checked={checked}>
          <input
            {...inputProps}
            ref={inputRef}
            type="checkbox"
            className={classInput}
            name={name}
            value={value}
            checked={checked}
            tabIndex={tabIndex}
            readOnly={readOnly}
            disabled={disabled}
            onChange={handleChange}
            onClick={handleInputClick}
          />
          <span className={classesInner} aria-hidden role="presentation" />
        </span>
        {children}
      </label>
    </div>
  );
});

CheckboxBase.displayName = 'CheckboxBase';

export default CheckboxBase;
