import { forwardRef } from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';
import { ForwardedRef } from 'app/helpers/types';
const IconLicensePlateEUStars = forwardRef((props: SvgIconProps, ref: ForwardedRef<SVGSVGElement>) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width={17.999}
    height={18}
    viewBox="0 0 17.999 18"
    name="IconLicensePlateEUStars"
    ref={ref}
    {...props}
  >
    <path
      id="licence-plate-eu-stars"
      d="M-13560.5-1654.5a1.5,1.5,0,0,1,1.5-1.5,1.5,1.5,0,0,1,1.5,1.5,1.5,1.5,0,0,1-1.5,1.5A1.5,1.5,0,0,1-13560.5-1654.5Zm6.034-.844a1.5,1.5,0,0,1,0-2.121,1.5,1.5,0,0,1,2.121,0,1.5,1.5,0,0,1,0,2.121,1.5,1.5,0,0,1-1.061.439A1.5,1.5,0,0,1-13554.465-1655.344Zm-11.193,0a1.5,1.5,0,0,1,0-2.121,1.5,1.5,0,0,1,2.122,0,1.5,1.5,0,0,1,0,2.121,1.493,1.493,0,0,1-1.06.439A1.5,1.5,0,0,1-13565.658-1655.344ZM-13553-1662a1.5,1.5,0,0,1,1.5-1.5,1.5,1.5,0,0,1,1.5,1.5,1.5,1.5,0,0,1-1.5,1.5A1.5,1.5,0,0,1-13553-1662Zm-15,0a1.5,1.5,0,0,1,1.5-1.5,1.5,1.5,0,0,1,1.5,1.5,1.5,1.5,0,0,1-1.5,1.5A1.5,1.5,0,0,1-13568-1662Zm13.535-4.536a1.5,1.5,0,0,1,0-2.121,1.5,1.5,0,0,1,2.121,0,1.5,1.5,0,0,1,0,2.121,1.5,1.5,0,0,1-1.061.439A1.5,1.5,0,0,1-13554.465-1666.537Zm-11.193,0a1.5,1.5,0,0,1,0-2.121,1.5,1.5,0,0,1,2.122,0,1.5,1.5,0,0,1,0,2.121,1.5,1.5,0,0,1-1.061.439A1.5,1.5,0,0,1-13565.658-1666.537Zm5.159-2.962a1.5,1.5,0,0,1,1.5-1.5,1.5,1.5,0,0,1,1.5,1.5,1.5,1.5,0,0,1-1.5,1.5A1.5,1.5,0,0,1-13560.5-1669.5Z"
      transform="translate(13568 1671.001)"
    />
  </SvgIcon>
));
IconLicensePlateEUStars.displayName = 'IconLicensePlateEUStars';
export default IconLicensePlateEUStars;
