import type { Property } from 'csstype';
import { forwardRef, useMemo } from 'react';
import CheckboxBase, { CheckboxBaseProps } from '../CheckboxBase';
import useClassNames from 'app/helpers/useClassNames';
import './Selectable.scss';

export interface SelectableProps extends CheckboxBaseProps {
  borderRadius?: 'small' | 'medium';
  elevation?: 'shadow' | 'line' | 'none';
  selectBackground?: boolean;
  appearance?: 'checkbox' | 'radio' | 'none';
  size?: 'small' | 'medium' | 'large';
  height?: Property.Height;
}

/**
 * A example of a Selectable component
 */
const Selectable = forwardRef<HTMLDivElement, SelectableProps>((props: SelectableProps, ref) => {
  const {
    className,
    disabled,
    selectBackground,
    elevation = 'line',
    borderRadius = 'small',
    appearance = 'checkbox',
    size,
    height,
    style,
    ...rest
  } = props;
  const { withClassPrefix, merge } = useClassNames('selectable');

  const classes = merge(
    className,
    withClassPrefix(
      borderRadius && `border-${borderRadius}`,
      appearance && `appearance-${appearance}`,
      elevation && `elevation-${elevation}`,
      selectBackground && 'background',
      {
        disabled,
      },
      size && `size-${size}`
    )
  );

  const modifiedStyle = useMemo(
    () => ({
      ...style,
      height,
    }),
    [style, height]
  );

  return <CheckboxBase disabled={disabled} ref={ref} className={classes} {...rest} style={modifiedStyle} />;
});

Selectable.displayName = 'Selectable';

export default Selectable;
