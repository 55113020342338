import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { get, postFormData } from './services/api-service';

export interface ApiState {
  token: string;
  loading: boolean;
}

const initialState: ApiState = {
  token: '',
  loading: false,
};

export const sendForm = createAsyncThunk(
  'api/post/form',
  async (obj: { formId: string; formValues: string }) => await postFormData(obj.formId, obj.formValues)
);

export const fetch = createAsyncThunk(
  'api/get',
  async (obj: { formId: string; queryParams?: string }) => await get(obj.formId, obj.queryParams ? obj.queryParams : '')
);

export const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    setAuthToken: (state, action: PayloadAction<string>) => {
      const cookies = Cookies.get();
      Object.keys(cookies).forEach((cookieKey: string) => {
        if (cookieKey.includes('auth0')) {
          // Cookies.remove(cookieKey);
        }
      });
      state.token = action.payload;
    },
    deleteAuthToken: (state) => {
      state.token = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetch.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetch.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(fetch.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setAuthToken, deleteAuthToken } = apiSlice.actions;

export default apiSlice.reducer;
