import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import ExpandableControlContext, { ExpandableControlValue } from './ExpandableControlContext';

export type ExpandableClickHandler = (value?: string) => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ExpandableControlProps extends PropsWithChildren<any> {
  initialOpen?: string[];
  singleExpandableOpen?: boolean;
  initialOpenAll?: boolean;
}

const ExpandableControl = (props: ExpandableControlProps) => {
  const { children, initialOpen = [], initialOpenAll = false, singleExpandableOpen } = props;
  const [value, setValue] = useState<string[]>(initialOpen);

  const setId = useCallback(
    (id: string) => {
      if (initialOpenAll) {
        setValue((currentValue) => [...currentValue, id]);
      }
    },
    [initialOpenAll]
  );

  const handleClick = useCallback(
    (internalValue?: string) => {
      if (internalValue && !value?.includes(internalValue)) {
        setValue([internalValue]);
      } else {
        setValue([]);
      }
    },
    [value]
  );

  const handleClickMulti = useCallback(
    (internalValue?: string) => {
      if (internalValue && !value?.includes(internalValue)) {
        setValue((currentState) => currentState?.concat(internalValue));
      } else {
        setValue((currentState) => currentState?.filter((stateValue) => stateValue !== internalValue));
      }
    },
    [value]
  );

  const contextValue: ExpandableControlValue = useMemo(
    () => ({ onClick: singleExpandableOpen ? handleClick : handleClickMulti, onId: setId, openIds: value }),
    [handleClick, setId, singleExpandableOpen, value, handleClickMulti]
  );

  return <ExpandableControlContext.Provider value={contextValue}>{children}</ExpandableControlContext.Provider>;
};

export default ExpandableControl;
