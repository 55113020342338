import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const MijnMainContainer = styled.div`
  min-height: calc(100vh - 70px);
  width: 100%;
  display: flex;
  justify-content: center;
  // align-items: center;
  gap: 18px;
  padding-top: 33px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    display: none;
  }
`;

export const MijnAccountWrapper = styled.div`
  width: 22.5%;
  height: fit-content;
  min-width: 330px;
`;

export const MijnContentWrapper = styled.div`
  width: 47%;
  height: 700px;
  padding: 20px 0px 15px 0px;
`;
