import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const FavorietenGeneralContainer = styled.div`
  min-height: 100%;
  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 95px);
  }
`;
export const FavorietenWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  padding-bottom: 16px;
  border-radius: 8px;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    margin: 16px 11px;
    justify-content: center;
  }
`;

export const FavorietenLoaderPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    min-height: calc(100vh - 147px);
  }
`;

export const NoFavorietenWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 29px;
  min-height: 615px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    padding-bottom: 60px;
    min-height: calc(100vh - 147px);
  }
`;

export const NoFavorietenIconWrapper = styled.div`
  width: 75px;
  height: 75px;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #136ce2;
  background-color: #e7f0fc;
  font-size: 45px;
`;

export const NoFavorietenText = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const NoFavorietenWinkelenButton = styled.button`
  color: #136ce2;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  padding: 10px;
  width: 220px;
  background-color: #ffffff;
  margin-top: 8px;
  border: 2px solid #136ce2;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-out;
  cursor: pointer;
  &:hover {
    color: #0f56b5;
    border-color: #0f56b5;
  }
  &: focus {
    background-color: #ffffff;
  }
`;
