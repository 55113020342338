import { useState, useEffect } from 'react';
// React Router
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// Styled Components
// MUI Icons
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import tireSizeFormat from '../../../helpers/tireSizeFormat';
import Typography from '../../bhComponents/Typography';
import ProductDetailCarInfo from '../../ProductDetail/ProductDetailCarInfo/ProductDetailCarInfo';
import ProductListItem from '../../ProductDetail/ProductListItem/ProductListItem';
import {
  ProductDetailsRightButtonWrapper,
  ProductDetailsRightFavButton,
  ProductDetailsRightOrderButton,
} from './ProductDetails.styles';
// Break Points
import classes from './index.module.scss';
import useWindowSize from 'app/hooks/useWindowSize';
import { breakpoints } from 'app/config/breakpoints';
// Components
import useAddFavoriet from 'app/hooks/Favorite/useAddFavoriet';
import useDeleteFavoriet from 'app/hooks/Favorite/useDeleteFavoriet';
import Slider from 'app/components/Slider';
import useProduct from 'app/hooks/useProduct';
import Loader from 'app/components/Loader/Loader';
import Toast from 'app/components/Toast/Toast';
import ToastContainer from 'app/components/Toast/ToastContainer';

const ProductDetails = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isLargeScreen = width && width >= breakpoints.lg;
  const { id } = useParams();
  const [isFavorite, setFavorite] = useState(0);

  const [toastId, setToastId] = useState(0);
  const [toastList, setToastList] = useState([]);

  const [nieuwImage, setNieuwImage] = useState(null);
  const [nieuwPhotoId, setNieuwPhotoId] = useState();

  const [file1Image, setFile1Image] = useState();
  const [file1ImageId, setFile1ImageId] = useState();

  const [file2Image, setFile2Image] = useState();
  const [file2ImageId, setFile2ImageId] = useState();

  const [file3Image, setFile3Image] = useState();
  const [file3ImageId, setFile3ImageId] = useState();

  const [file4Image, setFile4Image] = useState();
  const [file4ImageId, setFile4ImageId] = useState();
  const {
    data: productDetailsData,
    isLoading: isProductDetailsDataLoading,
    isError: isProductDetailsDataError,
    error: productDetailsDataError,
  } = useProduct(true, id, true);

  if (productDetailsData && isFavorite === 0) {
    setFavorite(productDetailsData?.tyre_set?.is_favorite);
  }

  const {
    onClickAddFavorieten,
    onClearErrorMessage: onAddClearErrorMessage,
    isLoading: isAddLoading,
    isError: isAddError,
    isSuccess: isAddSuccess,
    error: addError,
  } = useAddFavoriet(setFavorite);

  useEffect(() => {
    if (isAddError) {
      const toast = {
        id: toastId,
        type: 'error',
        message: addError?.message,
        additionalMessage: addError?.response?.data.detail,
        onClose: onAddClearErrorMessage,
      };
      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isAddError]);

  useEffect(() => {
    if (isAddSuccess) {
      const toast = {
        id: toastId,
        type: 'success',
        message: 'Het product is succesvol toegevoegd aan uw favorieten',
      };
      setToastId(toastId + 1);
      setToastList([...toastList, toast]);
    }
  }, [isAddSuccess]);

  const {
    onClickDeleteFavorieten,
    onClearErrorMessage: onDeleteClearErrorMessage,
    isLoading: isDeleteLoading,
    isError: isDeleteError,
    isSuccess: isDeleteSuccess,
    error: deleteError,
  } = useDeleteFavoriet(setFavorite);

  useEffect(() => {
    if (isDeleteError) {
      const toast = {
        id: toastId,
        type: 'error',
        message: deleteError?.message,
        additionalMessage: deleteError?.response?.data.detail,
        onClose: onDeleteClearErrorMessage,
      };
      setToastId((prev) => prev + 1);
      setToastList([...toastList, toast]);
    }
  }, [isDeleteError]);

  useEffect(() => {
    if (isDeleteSuccess) {
      const toast = {
        id: toastId,
        type: 'success',
        message: 'Het product is succesvol verwijderd uit uw favorieten',
      };
      setToastId(toastId + 1);
      setToastList([...toastList, toast]);
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (productDetailsData) {
      if (productDetailsData?.models[0]?.model.condition === 'Nieuw') {
        setNieuwPhotoId(productDetailsData?.models[3]?.image_url);
      } else if (
        productDetailsData?.models[0]?.model.condition === 'Gebruikt' ||
        productDetailsData?.models[0]?.model.condition === 'Zo goed als nieuw'
      ) {
        setFile1ImageId(productDetailsData?.models[0]?.image_url);
        setFile2ImageId(productDetailsData?.models[1]?.image_url);
        setFile3ImageId(productDetailsData?.models[2]?.image_url);
        setFile4ImageId(productDetailsData?.models[3]?.image_url);
      }
    }
  }, [productDetailsData]);

  useEffect(() => {
    if (nieuwPhotoId) {
      setNieuwImage(nieuwPhotoId);
    }
  }, [nieuwPhotoId]);

  useEffect(() => {
    if (file1ImageId && file2ImageId && file3ImageId && file4ImageId) {
      setFile1Image(file1ImageId);
      setFile2Image(file2ImageId);
      setFile3Image(file3ImageId);
      setFile4Image(file4ImageId);
    }
  }, [file1ImageId, file1ImageId, file1ImageId, file1ImageId]);

  const payLoadData = () => ({
    id,
  });

  const handleFavoriteIconClick = () => {
    if (!isFavorite && !isAddLoading && !isDeleteLoading) {
      onClickAddFavorieten(payLoadData());
    } else if (isFavorite && !isAddLoading && !isDeleteLoading) {
      onClickDeleteFavorieten(payLoadData());
    }
  };
  const location = useLocation();

  const schadeValue = () => {
    if (
      !productDetailsData?.models[0]?.damage.is_damaged &&
      !productDetailsData?.models[1]?.damage.is_damaged &&
      !productDetailsData?.models[2]?.damage.is_damaged &&
      !productDetailsData?.models[3]?.damage.is_damaged
    ) {
      return 'Geen';
    } else {
      const damagedWheels = [];
      if (productDetailsData?.models[0]?.damage.is_damaged) {
        damagedWheels.push(1);
      }
      if (productDetailsData?.models[1]?.damage.is_damaged) {
        damagedWheels.push(2);
      }
      if (productDetailsData?.models[2]?.damage.is_damaged) {
        damagedWheels.push(3);
      }
      if (productDetailsData?.models[3]?.damage.is_damaged) {
        damagedWheels.push(4);
      }

      return `Wiel ${damagedWheels.map((item) => ` ${item}`)}`;
    }
  };

  const profileDepth = (value) => {
    const depthMap = {
      ONETWO: '1 tot 2',
      TWOTHREE: '2 tot 3',
      THREEFOUR: '3 tot 4',
      FOURFIVE: '4 tot 5',
      FIVESIX: '5 tot 6',
      SIXSEVEN: '6 tot 7',
      SEVENEIGHT: '7 tot 8',
      EIGHTPLUS: '8+',
    };

    return `${depthMap[value]} mm` || value;
  };

  const listData = [
    {
      id: 1,
      key: 'Merk',
      value: productDetailsData?.tyre_set.brand,
    },
    {
      id: 2,
      key: 'Inchmaat',
      value: tireSizeFormat(
        productDetailsData?.models[0].model.width,
        productDetailsData?.models[0].model.height,
        productDetailsData?.models[0].model.inches
      ),
    },
    {
      id: 3,
      key: 'Seizoen',
      value:
        productDetailsData?.models[0]?.model.season === 'Summer'
          ? 'Zomer'
          : productDetailsData?.models[0]?.model.season === 'Winter'
          ? 'Winter'
          : 'All season',
    },
    {
      id: 4,
      key: 'TPMS',
      value:
        productDetailsData?.models[0]?.model.is_TPMS === 'yes'
          ? 'Ja'
          : productDetailsData?.models[0]?.model.is_TPMS === 'no'
          ? 'Nee'
          : 'Onbekend',
    },
    {
      id: 5,
      key: 'M + S',
      value: productDetailsData?.models[0]?.model.m_plus_s ? 'Ja' : 'Nee',
    },
    {
      id: 6,
      key: '3PMSF',
      value: productDetailsData?.models[0]?.model.drie_PMSF ? 'Ja' : 'Nee',
    },
    {
      id: 7,
      key: 'Runflat',
      value:
        productDetailsData?.models[0]?.model.is_runflat === 'yes'
          ? 'Ja'
          : productDetailsData?.models[0]?.model.is_runflat === 'no'
          ? 'Nee'
          : 'Onbekend',
    },
    {
      id: 8,
      key: 'Conditie',
      value:
        productDetailsData?.models[0]?.model.condition === 'Zo goed als nieuw' ? 'ZGAN' : productDetailsData?.models[0]?.model.condition,
    },
    {
      id: 9,
      key: 'Schade',
      value: schadeValue(),
    },
    {
      id: 10,
      key: 'Profieldiepte wiel 1',
      value: profileDepth(productDetailsData?.models[0]?.model.profile_depth),
    },
    {
      id: 11,
      key: 'Profieldiepte wiel 2',
      value: profileDepth(productDetailsData?.models[1]?.model.profile_depth),
    },
    {
      id: 12,
      key: 'Profieldiepte wiel 3',
      value: profileDepth(productDetailsData?.models[2]?.model.profile_depth),
    },
    {
      id: 13,
      key: 'Profieldiepte wiel 4',
      value: profileDepth(productDetailsData?.models[3]?.model.profile_depth),
    },
    {
      id: 14,
      key: 'Dot-code wiel 1',
      value: productDetailsData?.models[0].model.dot_code,
    },
    {
      id: 15,
      key: 'Dot-code wiel 2',
      value: productDetailsData?.models[1].model.dot_code,
    },
    {
      id: 16,
      key: 'Dot-code wiel 3',
      value: productDetailsData?.models[2].model.dot_code,
    },
    {
      id: 17,
      key: 'Dot-code wiel 4',
      value: productDetailsData?.models[3].model.dot_code,
    },
  ];

  const title = `${productDetailsData?.models[0]?.model.condition}e ${productDetailsData?.tyre_set.brand} ${
    productDetailsData?.models[0]?.model.season === 'Summer'
      ? 'zomer'
      : productDetailsData?.models[0]?.model.season === 'Winter'
      ? 'winter'
      : 'all season'
  } banden.`;

  const price = productDetailsData?.tyre_set.price_value;
  const saleStatus = productDetailsData?.tyre_set.sale_status;

  const condition = productDetailsData?.models[0]?.model.condition;

  const sliderItems =
    condition === 'Nieuw'
      ? [
          {
            id: 1,
            img: nieuwImage,
          },
        ]
      : [
          {
            id: 1,
            img: file1Image,
          },
          {
            id: 2,
            img: file2Image,
          },
          {
            id: 3,
            img: file3Image,
          },
          {
            id: 4,
            img: file4Image,
          },
        ];

  const fract = (n) => String(n).split('.')[1] || 0;

  const handleOvernemenClick = () => {
    navigate(`/bezorgen/${id}`);
  };

  const priceLabel = (
    <div className={classes['price-label']}>
      {saleStatus === 'SOLD' ? (
        <Typography as="p" variant={isLargeScreen ? 'heading-36' : 'heading-32'}>
          Verkocht
        </Typography>
      ) : (
        <>
          <Typography as="p" variant={isLargeScreen ? 'heading-36' : 'heading-32'}>
            &euro; {Math.floor(price)},
          </Typography>
          <Typography as="p" variant={isLargeScreen ? 'heading-20' : 'heading-18'}>
            {fract(price) === '00' ? ' -' : fract(price)}
          </Typography>
        </>
      )}
    </div>
  );

  if (isProductDetailsDataError) {
    return (
      <Toast
        type="error"
        message={productDetailsDataError?.message}
        additionalMessage={productDetailsDataError?.response?.data?.detail}
        standalone
        standaloneAutoRemove
      />
    );
  }

  return (
    <div className={classes.main}>
      <ToastContainer toastList={toastList} autoRemove removeInterval={4750} />
      {isProductDetailsDataLoading || !(nieuwImage || (file1Image && file2Image && file3Image && file4Image)) ? (
        <div className={classes.loader}>
          <Loader />
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.wrapper}>
            <div className={classes['img-container']}>
              <Slider sliderItems={sliderItems} />
            </div>
            <div className={classes['info-wrapper']}>
              <Typography as="p" className={classes.title} fontFamily="semi-bold" variant="heading-36">
                {title}
              </Typography>
              {priceLabel}
              {saleStatus === 'SOLD' ? (
                ''
              ) : (
                <ProductDetailsRightButtonWrapper>
                  <ProductDetailsRightFavButton onClick={handleFavoriteIconClick}>
                    {isFavorite ? (
                      <FavoriteIcon color="primary" fontSize="large" />
                    ) : (
                      <FavoriteBorderIcon color="primary" fontSize="large" />
                    )}
                  </ProductDetailsRightFavButton>
                  <ProductDetailsRightOrderButton onClick={handleOvernemenClick}>
                    Overnemen
                  </ProductDetailsRightOrderButton>
                </ProductDetailsRightButtonWrapper>
              )}
            </div>
          </div>
          {location.search ? (
            <div className={classes.grid}>
              <div className={classes.list}>
                <Typography as="h3" fontFamily="bold" className={classes.header} variant="heading-20">
                  Specificaties
                </Typography>
                {listData.map((data, index) => (
                  <ProductListItem key={data.id} showDivider={listData.length !== index + 1} data={data} />
                ))}
              </div>
              <ProductDetailCarInfo listData={listData} />
            </div>
          ) : (
            <div className={classes['grid-small']}>
              <Typography as="h3" fontFamily="bold" className={classes['header-long']} variant="heading-20">
                Specificaties
              </Typography>
              <div>
                {listData
                  .map((data, index) => (
                    <ProductListItem
                      key={data.id}
                      data={data}
                      showDivider={
                        (!Math.round(listData.length / 2) !== index + 1 && !isLargeScreen) ||
                        Math.round(listData.length / 2) !== index + 1
                      }
                    />
                  ))
                  .slice(0, listData.length / 2 + 1)}
              </div>
              <div>
                {listData
                  .map((data, index) => (
                    <ProductListItem key={data.id} data={data} showDivider={listData.length !== index + 1} />
                  ))
                  .slice(listData.length / 2 + 1, listData.length)}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
