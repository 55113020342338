import { useMutation } from '@tanstack/react-query';

import { updateProduct, EditProductsPropsType } from 'app/api/updateProduct';

const useUpdateProduct = () => {
  const editProductMutation = useMutation(updateProduct);

  const onClickUpdateProduct = (data: EditProductsPropsType) => editProductMutation.mutate(data);

  const onClearErrorMessage = () => editProductMutation.reset();

  const {
    isLoading: isUpdateProductLoading,
    isError: isUpdateProductError,
    error: updateProductError,
    data: updateProductData,
    isSuccess: isUpdateProductSuccess,
  } = editProductMutation;

  return {
    editProductMutation,
    onClickUpdateProduct,
    onClearErrorMessage,
    isUpdateProductLoading,
    isUpdateProductError,
    updateProductError,
    isUpdateProductSuccess,
    updateProductData,
  };
};

export default useUpdateProduct;
