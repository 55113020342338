import apiClient from 'app/axios/api-client';

type VerifyCarInfoPropsType = {
  brand: string;
  model: string;
  build_year: number;
};

export const verifyCarInfo = async (data: VerifyCarInfoPropsType) => {
  const response = await apiClient.post('search/car_info/', {
    merk: data.brand,
    type: data.model,
    bouwjaar: data.build_year,
  });

  return response.data;
};
