import { forwardRef, LiHTMLAttributes } from 'react';
import useClassNames from 'app/helpers/useClassNames';
import './DropdownItemBase.scss';

export interface DropdownItemBaseProps extends LiHTMLAttributes<HTMLLIElement> {
  focussed?: boolean;
}

const DropdownItemBase = forwardRef<HTMLLIElement, DropdownItemBaseProps>((props: DropdownItemBaseProps, ref) => {
  const { children, className, focussed, ...rest } = props;
  const { withClassPrefix, merge } = useClassNames('dropdown-item-base');

  const classes = merge(className, withClassPrefix({ focussed, hover: focussed !== true && focussed !== false }));

  return (
    <li {...rest} ref={ref} className={classes}>
      {children}
    </li>
  );
});

DropdownItemBase.displayName = 'DropdownItemBase';

export default DropdownItemBase;
