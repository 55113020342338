import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const ToastMainContainer = styled.div`
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 0px 12px #00000014;
  display: ${(props) => props.display};

  transition: 0.2s;

  width: 475px;

  position: ${(props) => props.standalone && 'fixed'};
  bottom: ${(props) => props.standalone && '15px'};
  right: ${(props) => props.standalone && '15px'};
  z-index: ${(props) => props.standalone && 3};

  @media screen and (max-width: ${breakpoints.md}px) {
    width: auto;

    right: ${(props) => props.standalone && '11px'};
    left: ${(props) => props.standalone && '11px'};
    bottom: ${(props) => props.standalone && '15px'};
    width: ${(props) => props.standalone && 'auto'};
  }
`;

export const ToastWrapper = styled.div`
  padding: 10px 16px;
  gap: 16px;
  display: flex;
`;

export const ToastLeftWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ToastLeftIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.color};
`;

export const ToastMiddleWrapper = styled.div`
  flex: 1;
`;

export const ToastMiddleTitleWrapper = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

export const ToastMiddleMessageWrapper = styled.div`
  font-size: 16px;
`;

export const ToastMiddleAdditionalMessageWrapper = styled.div`
  font-size: 16px;
`;

export const ToastRightWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ToastRightCloseIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease;
  color: rgb(102, 102, 102);

  :hover {
    color: black;
  }
`;
