import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../../../scss/_variables.scss';
import {
  BevestigingWrapper,
  BevestingenContentWrapper,
  BevestingenTitleWrapper,
  BevestingenInfoWrapper,
  BevestingenProgressWrapper,
  BevestingenButtonArea,
  BevestingenButtonContainer,
  BevestingenButtons,
} from './Bevestiging.styles';
import OrderStatusBar from 'app/components/MijnAccountComponents/OrderStatusBar/OrderStatusBar';

const Index = () => {
  const navigate = useNavigate();
  return (
    <BevestigingWrapper>
      <BevestingenContentWrapper>
        <BevestingenTitleWrapper color={styles.primaryColor}>Bedankt!</BevestingenTitleWrapper>
        <BevestingenInfoWrapper>Je ontvangt binnen enkele minuten een bevestiging per mail.</BevestingenInfoWrapper>
        <BevestingenProgressWrapper>
          <OrderStatusBar status="NIEUW" />
        </BevestingenProgressWrapper>

        <BevestingenButtonArea>
          <BevestingenButtonContainer>
            <BevestingenButtons
              onClick={() => navigate('/user/mijn-aankopen')}
              color={styles.primaryColor}
              colorHover={styles.primaryColorContactHoverContent}
              backgroundColor={styles.primaryColorContact}
              backgroundColorHover={styles.primaryColorContactHover}
            >
              Mijn aankopen
            </BevestingenButtons>

            <BevestingenButtons
              onClick={() => navigate('/')}
              color={styles.white}
              colorHover={styles.white}
              backgroundColor={styles.primaryColor}
              backgroundColorHover={styles.primaryColorContactHoverContent}
            >
              Home
            </BevestingenButtons>
          </BevestingenButtonContainer>
        </BevestingenButtonArea>
      </BevestingenContentWrapper>
    </BevestigingWrapper>
  );
};

export default Index;
