import { useEffect, useState, useRef } from 'react';

type Size = {
  width: number | undefined;
  height: number | undefined;
};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });

  const windowRef = useRef(windowSize);

  const handleResize = () => {
    windowRef.current = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    setWindowSize(windowRef.current);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
};

export default useWindowSize;
