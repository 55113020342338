import { forwardRef } from 'react';
import SvgIcon, { SvgIconProps } from '../../app/components/bhComponents/SvgIcon';
import { ForwardedRef } from 'app/helpers/types';

const IconUiArrowMediumBounded = forwardRef((props: SvgIconProps, ref: ForwardedRef<SVGSVGElement>) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    name="IconUiArrowMediumBounded"
    ref={ref}
    {...props}
  >
    <path data-name="Rectangle 5144" fill="none" d="M0 0h24v24H0z" />
    <path data-name="Path 2053" d="m9.707 19.707-1.414-1.414L14.586 12 8.293 5.707l1.414-1.414L17.414 12Z" />
  </SvgIcon>
));
IconUiArrowMediumBounded.displayName = 'IconUiArrowMediumBounded';
export default IconUiArrowMediumBounded;
