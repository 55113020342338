import styled from 'styled-components';
import { breakpoints } from 'app/config/breakpoints';

export const PaginationContainer = styled.div`
  position: absolute;
  bottom: 7px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;

  @media screen and (max-width: ${breakpoints.lg - 1}px) {
    bottom: 0px;
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const PaginationLeftIconWrapper = styled.div``;

export const PaginationPagesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

export const PaginationRightIconWrapper = styled.div``;
